import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { ReactComponent as BenchmarkLogo } from "../../assets/icons/commons/evsPurpleLogoNew.svg";
import loginImg1 from "../../assets/images/login/loginImg1.png";
import loginImg2 from "../../assets/images/login/loginImg2.png";
import loginImg3 from "../../assets/images/login/loginImg3.png";
import { useCookies } from "react-cookie";

function Copyright(props) {
  return (
    <Typography varian="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="/">
        Benchmark Lens
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const tileData = [
  {
    img: loginImg1,
    title: "Calculate the efficiency of your teams",
    alt: "login Image 1",
  },
  {
    img: loginImg2,
    title: "Process and make decisions based on your data",
    alt: "login Image 2",
  },
  {
    img: loginImg3,
    title: "Analyze and compare your company's production units",
    alt: "login Image 3",
  },
];

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useCookies(["token"]);
  const api = process.env.REACT_APP_API_URL; //Base API URL

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit login form data to the link
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: username, password: password }),
    };
    fetch(`${api}/rest_auth/login/`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Incorrect username or password.");
        }
        return response.json();
      })
      .then((data) => {
        // Handle response from login link
        console.log(data);
        setToken("token", data.key);
        //reload window
        window.location.reload();
        //setToken(data.key)
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        component="main"
        sx={{ display: "flex", flexFlow: "row nowrap", height: "100vh" }}
      >
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: "rgba(71, 37, 75, 1)",
            display: "flex",
            width: "60%",
            height: "100%",
            flexFlow: "column",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            marginBottom: "10%",
            paddingRight: "5%",
            paddingLeft: "5%",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              color="rgba(255, 255, 255, 1)"
              sx={{ marginBottom: "1rem" }}
            >
              The necessary tool to measure the efficiency of your business.
            </Typography>
            <Typography variant="body1" color="#F3F6F9">
              <strong>BenchamarkLens</strong> is a secure, precise and clear
              platform that will allow you to make the adjustments required to
              evolve your business.
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                gap: "20px",
                width: "100%",
              }}
            >
              <Typography variant="body2" color="#F3F6F9">
                <span
                  style={{
                    textDecoration: "underline #E0044E",
                    textUnderlineOffset: "5px",
                  }}
                >
                  What´s
                </span>{" "}
                new
              </Typography>
            </Box>
            <ImageList
              cellHeight={"auto"}
              sx={{ width: "100%", height: "auto" }}
              cols={3}
              gap={5}
            >
              {tileData.map((tile, index) => (
                <Card
                  key={index}
                  square={true}
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "250px",
                    height: "100%",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                >
                  <ImageListItem key={index}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="auto"
                      image={"" + tile.img}
                      title="Contemplative Reptile"
                    />
                    <CardContent
                      sx={{ position: "absolute", bottom: 0, left: 0 }}
                    >
                      <Typography variant="body2" style={{ color: "white" }}>
                        {tile.title}
                      </Typography>
                    </CardContent>
                  </ImageListItem>
                </Card>
              ))}
            </ImageList>
          </Box>
          <Typography variant="caption" color="#F3F6F9">
            Help & Support
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "40%",
            padding: "0 60px",
            maxWidth: "1000px",
            justifySelf: "center",
            justifyContent: "center",
            gap: { xs: "0", xl: "3.5rem" },
          }}
        >
          {/* <EvsLogo/> */}
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: "100px",
              textAlign: "center",
            }}
          >
            <BenchmarkLogo />
          </Box>
          {/* <Typography component="h1" variant="h5">
            Welcome!
          </Typography> */}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, display: "flex", flexFlow: "column", width: "100%" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={handleUsernameChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
            <Box
              sx={{
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Keep me signed in"
              /> */}
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                p: "10px 0",
                mt: 7,
                mb: 2,
                backgroundColor: "#0994FF",
                width: "50%",
                alignSelf: "center",
              }}
            >
              Login
            </Button>
            {/* <Typography variant="body2" color="rgba(171, 175, 179, 1)" spacing= {10} sx={{display: 'flex', justifyContent: 'center'}}>
                Don't have an account? 
                <Link href="#" variant="body2" style={{marginLeft: '4px'}}>
                  {"Register Now"}
                </Link>
              </Typography> */}
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
