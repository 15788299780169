import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { DetailsContainer, DetailsTable } from '../../components/common/commonContainer';
import EmptyCardMessage from '../../components/common/emptyCardMessage';
import FilterSidebar from '../../components/common/filtersSidebar';
import MainUpperBar from '../../components/common/mainUpperBar';
import BenchmarkScenarioTable from './benchmarkScenarioTable';
import { getData } from './services/benchmarkScenarioHomeServices';
import { BenchmarkScenariosContext } from '../home/projectDetails/benchmarkScenarios';

export default function BenchmarkScenarioHome() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [fieldChange, setFieldChange] = useState(false);
  const [deletedID, setDeletedID] = useState('');
  //BENCHMARK HANDLE TABLE PAGINATION
  const isSmallScreen = useMediaQuery('(max-width:1536px)');


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(isSmallScreen ? 4 : 8);

  const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

  // //Search bar BENCHMARK Value handler
  // const [searchBenchmark, setSearchBenchmark] = useState('')
  // const searchHandler = (event) => {
  //   setSearchBenchmark(event.target.value)
  //   }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
    setRowsPerPage(newDefaultRowsPerPage);
  }, [isSmallScreen]);


  const [filterFields, setFilterFields] = useState([
    {key: 'searchScenario', title: 'Search',type: "text", label: "Search Scenario", name: "searchScenario", value: ""},
    {
      key: 'status',
      title: 'Status',
      type: "select",
      label: "Status",
      name: "status",
      value: "",
      placeHolder: '',
      options: [
        { label: "All", value: "" },
        { label: "New", value: "new" },
        { label: "Saved", value: "saved" },
        { label: "Processing", value: "processing" },
        { label: "Completed", value: "completed" },
        { label: "Published", value: "published" }
      ]
    },
    {
      key: 'objctive',
      title: 'Objective',
      type: "select",
      label: "",
      name: "Objective",
      value: "",
      placeHolder: '',
      options: [
        { label: "All", value: "" },
        { label: "Input Based", value: "input" },
        { label: "Output Based", value: "output" },
        { label: "Input & Output independently", value: "input_output_independent" },
        { label: "Input & Output simultaneously", value: "input_output_together" },
      ]
    }, 
    {
      key: 'useSegmentation',
      title: 'Use Segmentation',
      type: "select",
      label: "",
      name: "useSegmentation",
      value: "",
      placeHolder: '',
      options: [
        { label: "All", value: "" },
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ]
    },   
  ]);

  const searchFilter = filterFields[0]

  const [delayedBenchmarkTerm, setDelayedBenchmarkTerm] = useState('');
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDelayedBenchmarkTerm(searchFilter.value);
      setPage(0)
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchFilter.value]);

  
  //filterfields values consts
  const searchValue = delayedBenchmarkTerm
  const statusOption = filterFields[1].value
  const objectiveOption = filterFields[2].value
  const useSegmentationOption = filterFields[3].value

    //FETCH FUNCTION
    useEffect(() => {
      setLoading(true);
      //SCENARIO AND DMU DATA FETCH
      Promise.all([
          getData({ page, rowsPerPage,searchValue, statusOption, objectiveOption, useSegmentationOption }),
      ])
      .then(([fetchedData]) => {
        setData(fetchedData)
      })
      .catch(error => {
          console.error('Error fetching data:', error);
          setError(error)
      })
      .finally(() => {
          setLoading(false);
      });
  }, [page, rowsPerPage,searchValue, statusOption, objectiveOption, useSegmentationOption, fieldChange, deletedID]);

  return (
    <BenchmarkScenariosContext.Provider value={{ setFieldChange, setDeletedID }}>
      <Box>
          <MainUpperBar UpperTitle={'Benchmark Scenario'} backLink={"/"} />
          <DetailsContainer>
            <FilterSidebar filteredData={data.results} filterFields={filterFields} setFilterFields={setFilterFields} />
              <DetailsTable sx={{display: 'flex', justifyContent: 'center'}}>
                {loading ? (
                      <CircularProgress />
                  ) : error ? ( // Render error component when error exists
                  <div>Error: {error.message}</div>)
                  :
                  data && data.count > 0 ? 
                  <BenchmarkScenarioTable
                    filteredData={data.results}

                    page={page}
                    count={data.count}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                  />
              :
              <EmptyCardMessage
                text1={'Results'} 
                text2={'Projects'}     
              />}
            </DetailsTable>
          </DetailsContainer>
      </Box>
    </BenchmarkScenariosContext.Provider>
  )
}
