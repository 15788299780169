import { get_new } from "../../../../../../utils/httpClient";

export const getTopEfficiencyData = ({ benchmark_id, baseSegment, orientVaue }) => {
    const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}&ordering=rank_overall`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error fetching Top Efficiency Data:', error);
    throw error;
  })
};

export const getBottomEfficiencyData = ({ benchmark_id, baseSegment, orientVaue }) => {
    const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}&ordering=-rank_overall`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error Bottom Efficiency Data:', error);
    throw error;
  })
};