import React, {useContext } from 'react';
import {
	FormControlLabel,
	Radio,
    RadioGroup,
	Box,
    Grid
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';

export default function PurposeWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData } = useContext(CreateBenchmarkContext);

    return (
        <>
            <TabPanel value={tabValue} index={index}>
                <div><b>What would you like to do?</b></div>
                <br />
                <br />
            
                <Box sx={{ width: '100%', flexGrow: 1 }}>
                    <RadioGroup
                    value={formData.purpose}
                    onChange={(event) => changeFormData('purpose', event.target.value)}
                    >
                        <Grid
                            container
                            rowSpacing={2}
                            margin={0}
                            padding={0}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            {/*Option Benchmark*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                    sx={{
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                 >
                                    <Box>
                                    <FormControlLabel value='benchmark' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Benchmark + Improvement Plan </b></div> 
                                            <div style={{fontSize: 12}}>
                                                Use relative benchmarking methods to assess entity 'performance' relative to
                                                it's peers through 'efficiency' scores. Creates individual target and improvement plans
                                                for each entity. Entities might share the same rank and efficiency scores.     
                                            </div>
                                        </div>   
                                        } />
                                    
                                        
                                    </Box>
                                </Grid>
                                {/*Options Rank*/}
                        <Grid item
                            xs={12}
                            sx={{
                                
                                '&:hover': {
                                backgroundColor: 'lightblue',
                            },
                            }}
                            padding={1}
                        >
                            <Box>
                                <FormControlLabel value='rank' control={<Radio disabled/>}
                                        label={
                                         <div>
                                            <div> <b>Rank </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    Use relative benchmarking methods to rank a collection of entities
                                                    using related or unrelated metrics. Entities will be forced to 
                                                    determine a unique ranking position.
                                            </div>
                                        </div>   
                                        } />
                                    
                                </Box>
                            </Grid>
                        
                        </Grid>
                    </RadioGroup>
                </Box>
                
            </TabPanel>
        </>
    )
}