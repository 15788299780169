import React from 'react';
import { Scatter } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { numeralFormatter } from '../../utils/numeralFormatter';






export default function EfficiencyQuadrantsComparison({efficiencyQuadrantsApi}) {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const boxplotValues = [
              `Entity: ${efficiencyQuadrantsApi[0]?.results[0]?.dmu_id}`,
              `Output: ${numeralFormatter(context.parsed.y)}`,
              `Efficiency: ${numeralFormatter(context.parsed.x)}%`,
            ];
            return boxplotValues
          }
        }
      },
        datalabels: {display: false},
        legend: {
          position: 'top',
          display: false
        },
        title: {
          display: true,
          text: 'Efficiency Quadrants',
          color: '#4c4c4d',
          font: {
            size: 14,
            weight: 'bold',
            lineHeight: 1.2,
          },    
        },
      },
    scales: {
      x: {
        display: true,
        beginAtZero: true,
        max: 110,
        title: {
          display: true,
          text: 'Efficiency ( % )',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        title: {
          display: true,
          align: 'end',
          text: 'Outputs',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      }
    }
  };
  //Select max output value, it is used to determine y axis quadrant max value
  const outputValues = efficiencyQuadrantsApi.map(output => output?.results?.[0]?.pc_output)
  const maxOutputValue = Math.max(...outputValues)

  const data = {
    datasets: [
      {
        label: 'Entity',
        data: efficiencyQuadrantsApi.map(value => ({x: value?.results[0]?.efficiency * 100, y: value?.results[0]?.pc_output, size: 20})),
        backgroundColor: [colors.entity1, colors.entity2, colors.entity3, colors.entity4, colors.entity5],
        pointRadius: 8
      },
      {
        type:'line',
        label:'Horizontal line',
        data: [{x:0,y:1.5}, {x:110,y:1.5}],
        borderColor: colors.cluster1,
        backgroundColor: colors.cluster1,
        yAxisID: 'y',
        borderWidth: 1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
      },
      {
        type:'line',
        label:'Vertical line',
        data: [{x:80,y:0}, {x:80,y: maxOutputValue < 1.5 ? 1.7 : maxOutputValue + 1}],
        borderColor: colors.cluster1,
        backgroundColor: colors.cluster1,
        yAxisID: 'y',
        borderWidth: 1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
  
  
      }
    ],
      
  };
return <Scatter options={options} data={data} />;
}