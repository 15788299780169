import { useEffect, useRef, useMemo } from 'react';
import 'chart.js/auto';
import { Chart as ChartJS } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import colors from '../../../assets/variables.scss';
import { numFormatFloat } from '../../../utils/numeralFormatter';

ChartJS.register(BoxPlotController, BoxAndWiskers);

export default function ChartBoxPlot(props) {
	const { data, y } = props;
	const canvasRef = useRef(undefined);
	const chartRef = useRef(undefined);

	const chartData = useMemo(() => {
		return {
			labels: [''],
			datasets: [
				{
					data: [data.map((row) => row.value)],
					borderColor: colors.extra4,
					backgroundColor: ['#FF548F'],
				},
			],
		};
	}, [data]);

	useEffect(() => {
		chartRef.current = new ChartJS(canvasRef.current, {
			type: 'boxplot',
			data: chartData,
			options: {
				responsive: false,
				plugins: {
					datalabels: { display: false },
					legend: { display: false },
					tooltip: {
						callbacks: {
							label: (context) => {
								const boxplotValues = [
									`Min: ${numFormatFloat(context.parsed.min)}`,
									`25% Quartile: ${numFormatFloat(context.parsed.q1)}`,
									`Median: ${numFormatFloat(context.parsed.median)}`,
									`Mean: ${numFormatFloat(context.parsed.mean)}`,
									`75% Quartile: ${numFormatFloat(context.parsed.q3)}`,
									`Max: ${numFormatFloat(context.parsed.max)}`,
								];
								return boxplotValues;
							},
						},
					},
                    
				},

				scales: {
					x: {
						display: true,
						grid: { display: false }
					},
					y: {
						display: true,
						grid: { display: false },
						grace: '10%',
						title: {
							display: true,
							text: y,
							color: '#4c4c4d',
							font: {
								size: 10,
								weight: 'bold',
								lineHeight: 1.2,
							},
						},
					},
				},
			},
		});

		return () => {
			chartRef.current.destroy();
		};
	}, [chartData, y]);

	return <canvas ref={canvasRef} width={600} height={400} />;
}
