import {
  Box,
  styled,
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TableContent from "./tableContent";
import { headCells } from "./projectData";
import { EnhancedTableHead } from "../../components/common/table/tableHead";
import { HelperLink } from "../../components/common/styledLinks";
import HomeProjetsCards from "./homeProjects/homeProjetsCards";

export const ProjectContainer = styled(TableContainer)({
  flex: 1,
});

export default function HomeProject({
  projectsTab,
  filteredData,
  setDeletedID = () => {},
  searchProject,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  count,
  rowsPerPageOptions = [4, 8, 12],
  section,
}) {
  const filteredProject =
    filteredData &&
    filteredData.filter(
      (row) =>
        row.project_name
          .toLowerCase()
          .includes(searchProject && searchProject.toLowerCase()) ||
        row.project_id.toString().includes(searchProject && searchProject)
    );

  // LOCAL STATE
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <ProjectContainer>
      {projectsTab === 1 ? (
        <HomeProjetsCards
          page={page}
          rowsPerPage={rowsPerPage}
          filteredData={filteredData}
          setDeletedID={setDeletedID}
        />
      ) : (
        <Table sx={{ padding: 0 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={
              section !== false ? filteredProject.length : filteredData.length
            }
          />
          <TableContent
            orderBy={orderBy}
            handleClick={handleClick}
            isSelected={isSelected}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredData={filteredData}
            filteredProject={filteredProject}
            section={section}
            setDeletedID={setDeletedID}
          />
        </Table>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "20px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
        {section === true ? (
          <HelperLink to="/projects">
            <Typography variant="caption" color="initial">
              View All
            </Typography>
          </HelperLink>
        ) : null}
      </Box>
    </ProjectContainer>
  );
}
