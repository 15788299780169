import React from 'react'
import { MainCard } from '../../common/commonContainer'
import { Box, Typography } from '@mui/material'
import { KnowledgeCardsContent } from './knowledgeBase/knowledgeBaseContent'


export default function Objective() {
  return (
    <MainCard >
    <Box sx={{display:'flex', p:'0 25px', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
      <Typography variant="subtitle2" color="initial">Objective</Typography>
      <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
        <Typography variant="subtitle2" color="initial">Last Update</Typography>
        <Typography variant="caption" color="#777777">21/05/2023</Typography>
      </Box>
    </Box>
    <KnowledgeCardsContent>
      <Typography variant="body2" color="initial">
        In BenchmarkLens, there are three main objectives to assess the efficiency of entities, such as companies or organizations.
      </Typography>
      <Typography variant="body2" color="initial">
        <strong>Input-oriented:</strong> These models focus on assessing whether an entity can reduce its input usage while maintaining the same level of output. In other words, they examine how efficiently an entity utilizes its inputs to produce a given level of output. For example, if an entity E, is not efficient due to using excessive input or insufficient output, the input-oriented model would help determine the potential reduction in inputs required for E to reach a point on the efficiency frontier. This orientation aims to identify the input-saving potential of an entity without compromising the level of output.
      </Typography>
      <Typography variant="body2" color="initial">
        <strong>Output-oriented:</strong> On the other hand, an output-oriented objective examines whether a nentity can increase its output without changing the level of inputs. These models evaluate how effectively an entity can enhance its output given the existing input levels. The output-oriented model would analyze if an entity E can increase its output to reach the efficiency frontier. This orientation focuses on maximizing output levels while keeping the inputs constant.
      </Typography>
      <Typography variant="body2" color="initial">
        <strong>Simultaneous input and output adjustments:</strong> This approach considers the possibility of making both input decreases and output increases simultaneously. It allows for a more comprehensive evaluation of the efficiency of the entity by exploring the potential improvements that can be achieved through adjustments in both inputs and outputs.
      </Typography>
      <Typography variant="body2" color="initial">
        Overall, these different benchmark objectives provide insights into the efficiency of entities from different perspectives. Input-oriented focus on reducing inputs while maintaining output levels, output-oriented aim to increase outputs with constant inputs, and the simultaneous adjustment option considers both input decreases and output increases simultaneously.
      </Typography>
    </KnowledgeCardsContent>

  </MainCard>
  )
}
