import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";

import { get_new } from "../../../utils/httpClient";
import { numFormatFloat, numFormatInt } from "../../../utils/numeralFormatter";

import { DatasetContext } from "../datasetDetails";

export default function Summary() {
  /* 
	Note: 
	 * The state of this component like filters applied will be preserved in the DatasetContext
	 * so that when the user goes back to this tab, the filters will be applied again and reduce
	 * recalculations
	*/

  // LOCAL STATE

  // CONTEXT STATE
  const { dataset, state, setState } = useContext(DatasetContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // HANDLE CHANGE FILTER (PERIOD)
  function changeFilter(name, value) {
    const newFilter = {
      ...state.filters,
      ...{
        [name]: value,
      },
    };
    setState((prevState) => {
      return {
        ...prevState,
        ...{
          filters: newFilter,
        },
      };
    });
  }

  // GET SUMMARY DATA
  useEffect(() => {
    setLoading(true);
    // if not dataset_id, stop
    if (!dataset.dataset_id) return;

    // if periods available but no period selected, stop
    if (dataset.periods.length > 0 && !state.filters.period) {
      console.log("Setting to first period");
      changeFilter("period", dataset.periods[0].date_string);
      return;
    }

    console.log("Retrieving summary data...");
    console.log("filters at summary", state.filters);

    let targetURL = `/dataset/statistics/?dataset_id=${dataset.dataset_id}`;
    targetURL += `&period=${
      dataset.periods.length > 0 ? state.filters.period : "All"
    }`;
    get_new(targetURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("summary data", data);
        setState((prevState) => {
          return {
            ...prevState,
            ...{
              summaryData: data.results,
            },
          };
        });
        return;
      })
      .catch((error) => {
        console.log("Error: " + error);
        setError(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataset.dataset_id, state.filters]);

  return (
    <>
      <Typography fontSize="1.2rem" fontWeight="bold" marginBottom="20px">
        Exploratory Data Analysis
      </Typography>

      <Grid container spacing={2} marginBottom="20px">
        {dataset.periods.length > 0 ? (
          <Grid item>
            <Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
              Period
            </Typography>
            <Select
              value={state.filters.period}
              size="small"
              onChange={(event) => changeFilter("period", event.target.value)}
              renderValue={(selected) =>
                !selected ? <em>Select Period</em> : selected
              }
            >
              {dataset.periods.map((period, index) => (
                <MenuItem key={index} value={period.date_string}>
                  {period.date_string}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        ) : null}
      </Grid>
      {loading ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </DialogContent>
      ) : error ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          Error: {error.message}
        </DialogContent>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Grid bgcolor={"#E8E8E8"}>
              <Typography
                fontSize="1.0rem"
                fontWeight="bold"
                paddingLeft="2.5rem"
                paddingTop="1rem"
                sx={{ color: "#47254B" }}
              >
                Numerical Variables
              </Typography>
            </Grid>
            <Table>
              <TableHead sx={{ bgcolor: "#E8E8E8" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Mean</TableCell>
                  <TableCell>Max</TableCell>
                  <TableCell>Min</TableCell>
                  <TableCell>Missing</TableCell>
                  <TableCell>Zeros</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.summaryData
                  .filter((variable) => {
                    return variable.var_type === "numerical";
                  })
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <b>
                          {row.alternative_field_name ??
                            row.original_field_name}
                        </b>
                      </TableCell>
                      <TableCell>{numFormatFloat(row.mean)}</TableCell>
                      <TableCell>{numFormatFloat(row.max)}</TableCell>
                      <TableCell>{numFormatFloat(row.min)}</TableCell>
                      <TableCell>{numFormatInt(row.missing)}</TableCell>
                      <TableCell>{numFormatInt(row.zeros)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />

          <TableContainer component={Paper}>
            <Grid bgcolor={"#E8E8E8"}>
              <Typography
                fontSize="1.0rem"
                fontWeight="bold"
                paddingLeft="2.5rem"
                paddingTop="1rem"
                sx={{ color: "#47254B" }}
              >
                Categorical Variables
              </Typography>
            </Grid>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "#E8E8E8" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Entities</TableCell>
                  <TableCell>Missing</TableCell>
                  <TableCell>Max Length</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.summaryData
                  .filter((variable) => {
                    return variable.var_type === "categorical";
                  })
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <b>{row.original_field_name}</b>
                      </TableCell>
                      <TableCell>{numFormatInt(row.entities)}</TableCell>
                      <TableCell>{numFormatInt(row.missing)}</TableCell>
                      <TableCell>{numFormatInt(row.max_length)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
