import React, { useEffect, useState, useContext } from "react";
import {
  FormLabel,
  FormControl,
  TextField,
  Autocomplete,
  Switch,
  FormControlLabel,
  Box,
  Grid,
  Chip,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import Cookies from "js-cookie";
import TabPanel from "./tabPanel";
import { CreateBenchmarkContext } from "./createBenchmarkContext";
import { get_new } from "../../../../../../utils/httpClient";

export default function SegmentationWS({ tabValue, index }) {
  //local state
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [segmentationData, setSegmentationData] = useState(null);
  // Context state
  const { formData, changeFormData, variableList } = useContext(
    CreateBenchmarkContext
  );

  /* get list of segmentation scenarios 
    label=segmentationScenarios.segmentation_name
     id=segmentationScenarios.segmentation_scenario_id
     */
  useEffect(() => {
    if (!formData.project_id) return;

    // console.log('retrieving segmentation scenarios')

    let targetURL = "/project/segmentation/?project_id=" + formData.project_id;
    targetURL += "&status=completed";

    get_new(targetURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + " : " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log('Segmentation Scenarios: ', data.results);
        /* Crete options for segmentation scenario dropdown */
        let options = data.results.map((scenario) => {
          return {
            label: scenario.segmentation_name,
            id: scenario.segmentation_scenario_id,
          };
        });
        setSegmentOptions(options);
        // console.log('segmentOptions: ', options)
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  }, [formData.project_id]);

  // Get segmentation scenario information to display after selecting a scenario
  useEffect(() => {
    if (!formData.segmentation_scenario) {
      setSegmentationData(null);
      return;
    }

    const target_url =
      "https://benchmark.breweryda.com/api/project/segmentation/";
    const scenario_id =
      "?segmentation_scenario_id=" + formData.segmentation_scenario.id;
    const url = target_url + scenario_id;
    const token = Cookies.get("token");
    //console.log('url: ', url)
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Segmentation Scenario data: ", data.results[0]);
        setSegmentationData(data.results[0]);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [formData.segmentation_scenario]);

  return (
    <>
      <TabPanel value={tabValue} index={index}>
        <div>
          <b>Segmentation</b>
        </div>
        <br />
        <div>Use segmentation scenario in benchmarking process?</div>
        <div>
          When using segmentation, unites are compared within the segment to
          determine their benchmark and rank. Units in different segments are
          not considered for calculations.
        </div>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              {/*Disable if Project doesn't have segmentation scenarios*/}
              <FormControl>
                <FormControlLabel
                  //value={formData.apply_restricted}
                  label="Apply segmentation"
                  labelPlacement="end"
                  control={
                    <Switch
                      disabled={segmentOptions.length === 0}
                      checked={formData.apply_segmentation}
                      onChange={(event) => {
                        changeFormData(
                          "apply_segmentation",
                          event.target.checked
                        );
                      }}
                    />
                  }
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <FormControl fullWidth>
                <FormLabel htmlFor="segmentation-scenario">
                  Segmentation Scenario{" "}
                </FormLabel>
                <Autocomplete
                  id="segmentation-scenario"
                  size="small"
                  //sx = {{width: '450px'}}
                  required
                  selectOnFocus
                  clearOnBlur
                  clearOnEscape
                  autoComplete
                  autoHighlight
                  fullWidth
                  disabled={!formData.apply_segmentation}
                  options={segmentOptions}
                  getOptionLabel={(option) => option.label}
                  value={formData.segmentation_scenario}
                  onChange={(event, newValue) => {
                    changeFormData("segmentation_scenario", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {formData.apply_segmentation && segmentationData && (
          <Grid>
            <Card sx={{ mt: 2 }}>
              <CardHeader
                size="small"
                disableTypography
                sx={{ fontSize: 14, m: 0, p: 1 }}
                title={
                  <b>{"Scenario: " + segmentationData.segmentation_name} </b>
                }
              />
              <CardContent sx={{ fontSize: 12, px: 1, pt: 0, pb: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={5} md={4} lg={3}>
                        <Box>Segmentation Method:</Box>
                      </Grid>
                      <Grid item xs={6} sm={7} md={8} lg={9}>
                        <Box>{segmentationData.segmentation_method}</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={5} md={4} lg={3}>
                        <Box>Segments:</Box>
                      </Grid>
                      <Grid item xs={6} sm={7} md={8} lg={9}>
                        <Box>{segmentationData.number_of_clusters}</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={3}>
                        <Box>Segmentation Variables:</Box>
                      </Grid>
                      <Grid item xs={12} lg={9}>
                        <Box>
                          {/*Create chips from segmentation variables*/}
                          {console.log("variableList: ", variableList)}
                          {segmentationData.segmentationvariable.map(
                            (variable) => {
                              return (
                                <Chip
                                  key={variable.variable_detail}
                                  label={
                                    variableList.filter((row) => {
                                      return (
                                        row.id === variable.variable_detail
                                      );
                                    })[0].label
                                  }
                                  variant="outlined"
                                  size="small"
                                  sx={{ margin: "2px" }}
                                />
                              );
                            }
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </TabPanel>
    </>
  );
}
