import { Box, styled, Typography } from '@mui/material'
import React from 'react'


export const BaseStatus = styled(Box) ({
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '54px',
    backgroundColor:'gray',
    padding: '1px 20px',
    width: '70px'
  })

export const ProcessingStatus = styled(BaseStatus) ({
    color:'#4B52A7',
    backgroundColor: '#E5E6FD'
})
export const CompletedStatus = styled(BaseStatus) ({
    backgroundColor: '#DAFBF8',
    color:'#009688',
})
export const SetupStatus = styled(BaseStatus) ({
    backgroundColor: '#EDEDEE',
    color:'#7A7A7B',
})
export const PublishedSucessStatus = styled(BaseStatus) ({
    backgroundColor: '#DDF6DD',
    color:'#1AB759',
})
export const NewStatus = styled(BaseStatus) ({
    backgroundColor: '#F5EAF6',
    color: '#47254B',
})

export const SavedStatus = styled(BaseStatus) ({
    backgroundColor: '#DCF0FF',
    color: '#0084FF',
})

export const LoadingStatus = styled(BaseStatus) ({
    backgroundColor: '#EDEDEE',
    color:'#7A7A7B',
})

export const ReadyStatus = styled(BaseStatus) ({
    backgroundColor: '#DAFBF8',
    color:'#009688',
})

export const ErrorStatus = styled(BaseStatus) ({
    backgroundColor: '#FFF0E5',
    color: '#FF6B00',
})

export default function StatusBanner(props) {
  return (
    <>
        {
            props.status ==='processing' ? 
            <ProcessingStatus><Typography variant="body2">{props.status}</Typography></ProcessingStatus> 
            
            : props?.status?.toLowerCase() === 'completed' ?
            <CompletedStatus><Typography variant="body2">{props?.status}</Typography></CompletedStatus> 
            
            : props?.status?.toLowerCase() === 'setup' ?
            <SetupStatus><Typography variant="body2">{props?.status}</Typography></SetupStatus> 
            
            : props?.status?.toLowerCase() === 'new' ?
            <NewStatus><Typography variant="body2">{props?.status}</Typography></NewStatus> 
            
            : props?.status?.toLowerCase() === 'published' ?
            <PublishedSucessStatus><Typography variant="body2">{props?.status}</Typography></PublishedSucessStatus> 
            
            : props?.status?.toLowerCase() === 'saved' ?
            <SavedStatus><Typography variant="body2">{props?.status}</Typography></SavedStatus> 
            
            : props?.status?.toLowerCase() === 'success' ?
            <PublishedSucessStatus><Typography variant="body2">{props?.status}</Typography></PublishedSucessStatus> 
            
            : props?.status?.toLowerCase() === 'loading' ?
            <LoadingStatus><Typography variant="body2">{props?.status}</Typography></LoadingStatus>
            
            : props?.status?.toLowerCase() === 'ready' ?
            <ReadyStatus><Typography variant="body2">{props?.status}</Typography></ReadyStatus> 
            
            : <ErrorStatus> error </ErrorStatus>
        }
    </>
  )
}
