import { Box, styled, TableBody, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import LinearWithValueLabel from '../../components/common/progressBar';
import StatusBanner from '../../components/common/statusBanner';
import { TableLink } from '../../components/common/styledLinks';
import ProjectKebabMenu from './projectKebabMenu';

export const LinearBox = styled(Box) (({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'baseline',
  justifyContent: 'flex-start',

  [theme.breakpoints.up("xl")]: {
    marginRight: '3rem',
},
}))

export const FractionBox = styled(Box) ({
  width: '60px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start'
})

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
export default function TableContent(props) {
    
  // PROPS
  const { order, orderBy, handleClick, isSelected, page, rowsPerPage, filteredData, filteredProject,
    setDeletedID=()=>{}, section } =  props;

    const tableData = section !== false ? filteredProject : filteredData
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length);
    return (
      <TableBody>
        {stableSort(tableData, getComparator(order, orderBy))
        .slice(0, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row["project_name"]);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleClick(event, row["project_name"])}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.project_name}
              selected={isItemSelected}
            >
              <TableCell
              id={labelId}
              scope="row"
              sx={{width: {xs: '30%', xl: '20%'}}}
              >
                <TableLink to={"/projectDetails?project_id=" + row.project_id}>
                 
                  <Box sx={{display: 'flex', flexFlow: 'column'}}>
                    <Typography variant="body2" color="initial"> {row.project_name}</Typography>
                    <Typography variant="body2" color="gray">ID: {row.project_id}</Typography>
                  </Box>

                </TableLink>
              </TableCell>
              <TableCell align="left" sx={{width: {xs: '30%', md: '15%'}, display: {xs: 'none', sm: 'table-cell'}}}>
                <LinearBox>
                  <FractionBox>
                    {row.segmentations_completed} / {row.segmentations_total}
                  </FractionBox>
                  <LinearWithValueLabel color="success" value={(row.segmentations_completed / row.segmentations_total)*100} />
                </LinearBox>
              </TableCell>
              <TableCell align="left" sx={{width: {xs: '30%', md: '15%'}, display: {xs: 'none', sml: 'table-cell'}}}>
                <LinearBox>
                <FractionBox>
                  {row.benchmarks_completed} / {row.benchmarks_total} 
                </FractionBox>
                  <LinearWithValueLabel value={(row.benchmarks_completed / row.benchmarks_total)*100} />
                </LinearBox>
              </TableCell>
              <TableCell align="left" sx={{display: {xs: 'none', md: 'table-cell'}}}>{row["creation_date"]} </TableCell>
              <TableCell align="left" sx={{display: {xs: 'none', md: 'table-cell'}}}>{row["last_update"]}</TableCell>
              <TableCell align="left" sx={{width: {xs: '1rem', md: '8rem'}}}>
                <StatusBanner status={row.status}/>                  
              </TableCell>
              <TableCell align="right">
                <ProjectKebabMenu project_id={row.project_id} setDeletedID={setDeletedID} />
              </TableCell>

            </TableRow>
          );
        })}
        {emptyRows > 0 && (
        <TableRow
          style={{
          height: (props.dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={7} />
        </TableRow>
      )}
    </TableBody>
  )
}
