import { Button, DialogActions } from '@mui/material'
import React from 'react'
import DeleteBenchmark from '../deleteBenchmark'
import { useState } from 'react'

export default function EditPublished({ scenarioData = null, setDeletedID}) {
  //CONTEXT PROVIDER 

  //COMPONENT STATUS
  const [openDelete, setOpenDelete] = useState(false);

  /* HANDLES FOR DELETE OPTION */
  const handleDelete = () => {
  console.log('delete: ', scenarioData?.benchmark_id);
    setOpenDelete(true);
  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  }
  

  return (
    <>
        <DialogActions>
            {/* <Button color='primary' variant='text' autoFocus onClick={handleClose} >
                Cancel
            </Button> */}
            <Button onClick={handleDelete} color='error' variant='text' sx={{marginRight: '8px'}} autoFocus>Delete</Button>
            {/* <Button onClick={() => { handleUnpublishAction('unpublish', summaryData) }} sx={{backgroundColor: 'primary'}} variant='contained' autoFocus>Unpublish</Button> */}
        </DialogActions>
        {openDelete && <DeleteBenchmark
            open={openDelete}
            onClose={handleCloseDelete}
            scenario_id={scenarioData?.benchmark_id}
            setDeletedID={setDeletedID}
            />
        }

    </>
  )
}
