import { Box, CircularProgress, styled, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DetailsContainer, DetailsTable } from '../../components/common/commonContainer'
import EmptyCardMessage from '../../components/common/emptyCardMessage';
import FilterSidebar from '../../components/common/filtersSidebar';
import MainUpperBar from '../../components/common/mainUpperBar';
import { get_new } from '../../utils/httpClient';
import SegmentationScenarioTable from './segmentationScenarioTable';


export const SegmentationDetailsContainer = styled(Box)({
    display: 'flex',
    flexFlow: 'row nowrap'
})

export default function SegmentationScenarioHome() {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);


  //SEGMENTATION HANDLE TABLE PAGINATION
  const isSmallScreen = useMediaQuery('(max-width:1536px)');


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(isSmallScreen ? 4 : 8);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  // //Search bar SEGMENTATION Value handler
  // const [searchSegmentation, setSearchSegmentation] = useState('')
  // const searchHandler = (event) => {
  //   setSearchSegmentation(event.target.value)
  //   }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
    setRowsPerPage(newDefaultRowsPerPage);
  }, [isSmallScreen]);

  const [filterFields, setFilterFields] = useState([
    { key: "name", title: 'Name', type: "text", label: "Name", value: "" },
    {
      key: "status",title: 'Status',
      label: "",
      name: "status",
      value: "",
      type: "select",
      placeHolder: '',
      options: [
        {  label: "All", value: "" },
        { label: "New", value: "new" },
        { label: "Saved", value: "saved" },
        { label: "Processing", value: "processing" },
        { label: "Completed", value: "completed" }
      ]
    }, 
    {
      key: "segmentsNum",
      title: 'Number of Segments',
      label: "Number of Segments",
      name: "Number of Segments",
      value: "",
      type: "select",
      placeHolder: '',
      options: [
        { label: "All", value: "" },
      ]
    }, 
  ]);  

  const [delayedSegmentationTerm, setDelayedSegmentationTerm] = useState('');
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDelayedSegmentationTerm(filterFields[0].value);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterFields[0].value]);


  const searchValue = delayedSegmentationTerm
  const statusOption = filterFields[1].value
  const numSegments = filterFields[2].value

  useEffect(() => {
    setLoading(true);
    setError(null); // Clear any previous errors

    get_new(`/project/segmentations/?page_size=${rowsPerPage}&page=${page + 1}&search=${searchValue}&status=${statusOption}&number_of_clusters=${numSegments}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve API data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
          setData(data)
          setLoading(false)
        })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state
        setLoading(false)

      });
  }, [page, rowsPerPage,searchValue, statusOption, numSegments]);

    //return dmu efficiency api
    useEffect(() => {
      get_new(`/project/segmentations/`)
        .then((response) => {
          if (!response.ok) {
            console.log('Could not retrieve data');
            console.log('Response Error' + response.status);
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          const options = [...new Set(data.results.map(s => s.number_of_clusters))].sort((a, b) => a - b).map(p => ({ label: p, value: p }));
          options.unshift({ label: "All", value: "" });
          const defaultValue = options[0].value; // set default value to first object of fetched data
  
          setFilterFields(prevFields => {
            return prevFields.map(field => {
              if (field.key === 'segmentsNum') {
                return {
                  ...field,
                  options: [...options],
                  value: defaultValue // set default value to last object of fetched data
                };
              }
              return field;
            });
          });
        })
        .catch((error) => {
          console.log('Error: ' + error);
          // TODO: Show user error that data couldn't be retrieved
        });
    }, []);
  

  return (
    <Box>
        <MainUpperBar UpperTitle={'Segmentation Scenario'} backLink={"/"} />
        <DetailsContainer>
            <FilterSidebar filteredData={data.results} filterFields={filterFields} setFilterFields={setFilterFields} />
            <DetailsTable sx={{display: 'flex', justifyContent: 'center'}}>
              {loading ? (
                  <CircularProgress />
                ) : error ? ( // Render error component when error exists
                <div>Error: {error.message}</div>)
                :
                data && data.count > 0 ? 
                <SegmentationScenarioTable
                  segmentationScenarios={data.results}

                  page={page}
                  count={data.count}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}

                />
                :
                <EmptyCardMessage
                  text1={'Results'} 
                  text2={'Projects'}     
                />
              }
            </DetailsTable>
        </DetailsContainer>
    </Box>
  )
}
