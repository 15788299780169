/* Functions to process dataset info */

export const getVariablesList = (dataset, var_type = 'all') => {
    // console.log("Processing Dataset data. Var type: " + var_type);
    // console.log(dataset);
    const variablesList = [];
    dataset.variables.forEach((variable) => {
        if (variable.field_type !== 'Entity ID' && variable.field_type !== 'Entity Name' && variable.field_type !== 'Period') {
            if ((var_type===variable.var_type) || (var_type==='all')){
                variablesList.push({
                    label: variable.alternative_field_name ? variable.alternative_field_name : variable.original_field_name,
                    id: variable.variable_detail_id,
                });
            }
        }
    });
    //console.log(variablesList);
    return variablesList;
};
