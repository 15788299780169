import { styled, Tab, Tabs } from "@mui/material"

export const TabsContainer = styled(Tabs)({
  margin: '0px 20px',
})
  
  
export const RoundTab = styled(Tab)({
    border: '1px solid #47254B',
    color: '#626262',
    minHeight: 0,
    height: 45,
    textTransform: 'none',
    '&:first-of-type': {
      borderRadius: '20px 0 0 20px ',
    },
    '&:last-child': {
      borderRadius: '0 20px 20px 0',
    },
    '&.Mui-selected': {
      color: '#FFFFFF',
      backgroundColor: '#47254B',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  })

  export const SmallRoundTab = styled(RoundTab)({
    height: 20
  })
