import { Button, DialogActions } from '@mui/material'
import React, { useContext } from 'react'
import { useState } from 'react';
import DeleteBenchmark from '../deleteBenchmark';
import ManualSettings from '../manualSettings';
import EditBenchmarkContext from '../../context/editBenchmarkContext';
import { patch_json, post_json } from '../../../../../../utils/httpClient';
import { enqueueSnackbar } from 'notistack';
import { BenchmarkScenariosContext } from '../../../benchmarkScenarios';

export default function EditSaved({ handleClose, scenarioData = null, setDeletedID }) {
    //CONTEXT PROVIDER 
    const { summaryData } = useContext(EditBenchmarkContext)
    const { setFieldChange, setEditSaved } = useContext(BenchmarkScenariosContext)
    //COMPONENT STATUS
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

  /* HANDLES FOR DELETE OPTION */
  const handleDelete = () => {
    console.log('delete: ', scenarioData?.benchmark_id);
    setOpenDelete(true);

  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  }

  /* HANDLES FOR EDIT OPTION */
  const handleEdit = () => {
    setOpenEdit(true)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }
    
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingRun, setLoadingRun] = useState(false);

    /*Handle Publish and SAVE button spinners*/
    function handleButtonSpinners(action, enable) {
        if (action==='edit' && enable) {
            setLoadingSave(true);        
        } else if (action==='edit' && !enable) {
            setLoadingSave(false);
        } else if (action==='execute' && enable) {
            setLoadingRun(true);
        } else if (action==='execute' && !enable) {
            setLoadingRun(false);
        }
        
    }

    /*Handle create benchmark scenario */
    const handleAction = async (action, editObject) => {
        if (!loadingSave || !loadingRun) {
            handleButtonSpinners(action, true);
        }
        
        //Make copy of summaryData
        let data = JSON.parse(JSON.stringify(summaryData));
        data.action = action; // 'save' or 'publish'            
        console.log('action', action)
        console.log('Final Benchmark data', data);
        console.log('Final Benchmark Summary Data', summaryData);  

        //Target URL for posting form
        const targetURL = '/project/benchmark/';

        if(action === 'edit') {
            let edit = {
                action: 'edit',
                benchmark_id: summaryData.benchmark_id,
                ...editObject
            }
            setEditSaved(false)

            patch_json(targetURL, edit)
            .then((response) => {
                console.log('response', response);
                if (!response.ok) {
                    throw new Error(response.status + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log('action',action);
                setEditSaved(true)
                    console.log('Your changes have been successfully saved');
                    console.log(data);
                    enqueueSnackbar('Your changes have been successfully saved for: ' + summaryData.benchmark_name.trim(), { variant: 'info' }); 
                // handleCloseDialog();
            })
            .catch((error)=>{
                console.log('Benchmark Scenario action failed');
                console.log(error)
                // handleButtonSpinners(action, false);
                //handleCloseDialog();
                return 
            })
            .finally(() => {
                handleButtonSpinners(action, false);
            })
        } else if(action === 'execute'){
            let execute = {
                action: 'execute',
                benchmark_id: summaryData.benchmark_id,
            }
            setFieldChange(false)
            
            post_json(targetURL, execute)
            .then((response) => {
                console.log('response', response);
                if (!response.ok) {
                    throw new Error(response.status + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log('action',action);
                setFieldChange(true)
                    console.log('Benchmark Scenario is now Processing');
                    console.log(data);
                    handleClose()
                    enqueueSnackbar('Successfully Processing scenario: ' + summaryData.benchmark_name.trim(), { variant: 'success' });
                // handleCloseDialog();
            })
            .catch((error)=>{
                console.log('Benchmark Scenario action failed');
                console.log(error)
                // handleButtonSpinners(action, false);
                //handleCloseDialog();
                return 
            })
            .finally(() => {
                handleButtonSpinners(action, false);
            })
        } else {
            console.log('execute or edit where not executed');
        }
        
    };

  return (
    <>
        <DialogActions>
            {/* <Button color='primary' variant='text' autoFocus onClick={handleClose} >
                Cancel
            </Button> */}
            <Button onClick={handleDelete} color='error' variant='text' autoFocus>Delete</Button>
            <Button onClick={handleEdit} color='primary' variant='text' autoFocus>
                Edit
            </Button>
            <Button onClick={() => { handleAction('execute') }} sx={{backgroundColor: '#1AB759'}} variant='contained' autoFocus>Run</Button>
        </DialogActions>

        {openDelete && <DeleteBenchmark
        open={openDelete}
        onClose={handleCloseDelete}
        scenario_id={scenarioData?.benchmark_id}
        setDeletedID={setDeletedID}
        />
      }

      {<ManualSettings
        open={openEdit}
        onClose={handleCloseEdit}

        handleAction={handleAction}
      />}
    </>
  )
}
