import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {  Box, styled, Typography } from '@mui/material';
import bell from "../../assets/icons/navbar/bell.svg"
import settingsLogo from "../../assets/icons/navbar/settingsLogo.svg"
import AvatarMenu from './navbarItems/avatarMenu';
import { useLocation } from 'react-router-dom';
import HelpMenu from './navbarItems/helpMenu';

const NavBar = styled(AppBar)({
  display: "flex",
  justifyContent: 'center',
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.07)",
  height: '64px',
  paddingLeft: '5rem'
})

const IconBtn = styled(IconButton)({
  marginRight: '10px'
})

export default function NavBarComponnent({userData}) {
  const location = useLocation();
  let text = '';

  if (location.pathname === '/') {
    text = 'Home';
  } else if (location.pathname === '/dashboard') {
    text = 'Results';
  } else {
    text = location.pathname.split('/')[1].split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }
  return (
    <NavBar >
      <Toolbar variant="dense" sx={{justifyContent: 'space-between'}}>
        <Typography variant="h1" color="black">{text}</Typography>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <HelpMenu/>
          <IconBtn color="inherit">
            <img src={bell} alt="bell" />
          </IconBtn>
          <IconBtn>
            <img src={settingsLogo} alt="settingsLogo" />
          </IconBtn>
          <AvatarMenu userData={userData}/>
          <Typography variant="body1" color="initial"> {userData.first_name}</Typography>
        </Box>
      </Toolbar>
    </NavBar>
  );
}