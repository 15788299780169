import React from 'react';
import { Line } from 'react-chartjs-2';
import { options } from '../graphProps/timeseries'
import colors from '../../assets/variables.scss'

const title = {
  plugins: {
    title: {
      text: "Avg Efficiency By Period",
      display: true,
      color: '#4c4c4d',
      font: {
        size: 14,
        weight: 'bolder',
        lineHeight: 1.2,
        family: 'roboto'
      },
    },
    datalabels: {
      display: true,
      color: "white",
      align: 'center',
      borderRadius: 50,
      backgroundColor: colors.entity5,
      labels: {
        title: {
          font: {
            weight: 'bold',
            size: 10
          }
        },
      },
      formatter: (value) => {
        return `${value}%`
      }
    },
    legend: {display: false}
  }
}

const scale = { scales: {
    x: {
      display: true,
      grid: {display: false},
      title: {
        display: true,
        text: 'Period',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2,
        },
        padding: 10
      }
    },
    y: {

        display: true,
        beginAtZero: false,
        title: {
            align: 'end',
            display: true,
            text: 'Avg. Efficiency ( % )',
            color: '#4c4c4d',
            font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2
                // min: Math.min.apply(Math, api.map(s=>(Math.floor(s.value * 100)))),
        },
        padding: 10
      },
      ticks: {
        // stepSize: 1
      }
    }
  }
}

const options114 = Object.assign({}, options, title, scale)




export default function AvgEfficiencyByPeriod({api}) {

  api?.results?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });
  
    const labels = api.results &&  api.results.map(segment => segment.period);
    const data = {
        labels,
        datasets: [
        {
            label: 'Cluster',
            data: api.results && api.results.map(segment => (segment.avg_efficiency * 100).toFixed(2)),
            borderColor: colors.entity5,
            backgroundColor: colors.entity3,
            yAxisID: 'y',
          }
        ],
      };


  return <Line options={options114} data={data} />;
}
