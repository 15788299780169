import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import StatusBanner from '../../components/common/statusBanner';
import { TableLink } from '../../components/common/styledLinks';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function DatasetsTableContent({dense, order, orderBy, isSelected, page, rowsPerPage, filteredData }) {

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length);

	return (
		<TableBody>
			{stableSort(filteredData, getComparator(order, orderBy))
			.slice(0, page * rowsPerPage + rowsPerPage)
			.map((row, index) => {
					const isItemSelected = isSelected(row['project_name']);
					const labelId = `enhanced-table-checkbox-${index}`;

					return (
						<TableRow
							hover
							//onClick={(event) => handleClick(event, row['project_name'])}
							role="checkbox"
							aria-checked={isItemSelected}
							tabIndex={-1}
							key={row.dataset_id}
							selected={isItemSelected}
						>
							<TableCell id={labelId} scope="row">
								<TableLink to={'/datasetDetails?dataset_id=' + row.dataset_id}>
									<Box sx={{display: 'flex', flexFlow: 'column'}}>
										<Typography variant="body2" color="initial">{row.dataset_name}</Typography>
										<Typography variant="body2" color="gray">ID: {row.dataset_id}</Typography>
									</Box>
								</TableLink>
							</TableCell>
							<TableCell align="left">{row.rows}</TableCell>
							<TableCell align="left">{row.columns}</TableCell>
							<TableCell align="left" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
								{row.creation_date}
							</TableCell>
							<TableCell align="left" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
								{row.last_update}
							</TableCell>
							<TableCell align="left" sx={{ width: '20%' }}>
								<StatusBanner status={row.status.toLowerCase()} />
							</TableCell>
						</TableRow>
					);
				})}
			{emptyRows > 0 && (
				<TableRow
					style={{
						height: (dense ? 33 : 53) * emptyRows,
					}}
				>
					<TableCell colSpan={7} />
				</TableRow>
			)}
		</TableBody>
	);
}
