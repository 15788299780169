import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
export function createData(name, segmentationCurrent, segmentationTotal, benchmarkCurrent, benchmarkTotal, creationDate, lastUpdate, status) {
    return {
        name,
        segmentationCurrent,
        segmentationTotal,
        benchmarkCurrent, 
        benchmarkTotal,
        creationDate,
        lastUpdate,
        status
    };
}

export const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name/ID',
  },
  {
    id: 'segmentation',
    numeric: true,
    disablePadding: false,
    label: 'Segmentation Simulations',
    customDisplay: {xs: 'none', sm: 'table-cell'},
    complement: 
    <Box 
      sx={{
        display: {xs: 'none', md: 'flex'}, 
        alignItems: 'center', 
        paddingLeft:'5px', 
        whiteSpace: 'nowrap'
      }}
    >
    <Typography variant="caption" color="#979797">
      C / D 
    </Typography>
      <Tooltip title="Add" arrow>
        <InfoOutlinedIcon sx={{color: '#0994FF', width: '15px', marginLeft: '5px'}} />
      </Tooltip>
    </Box>
  },
  {
    id: 'benchmark',
    numeric: true,
    disablePadding: false,
    label: 'Benchmark Simulations',
    customDisplay: {xs: 'none', sml: 'table-cell'},
    complement: 
    <Box 
      sx={{
        display: {xs: 'none', md: 'flex'}, 
        alignItems: 'center', 
        paddingLeft:'5px', 
        whiteSpace: 'nowrap'
      }}
    >
    <Typography variant="caption" color="#979797">
      C / D 
    </Typography>
      <Tooltip title="Add" arrow>
        <InfoOutlinedIcon sx={{color: '#0994FF', width: '15px', marginLeft: '5px'}} />
      </Tooltip>
    </Box>
  },
  {
    id: 'creationDate',
    numeric: true,
    disablePadding: false,
    label: 'Creation Date',
    customDisplay: {xs: 'none', md: 'table-cell'},
    complement: <FilterListOutlinedIcon sx={{width: '18px', paddingLeft:'5px'}}/>
  },
  {
    id: 'lastUpdate',
    numeric: true,
    disablePadding: false,
    label: 'Last Update',
    customDisplay: {xs: 'none', md: 'table-cell'},
    complement: <FilterListOutlinedIcon sx={{width: '18px', paddingLeft:'5px'}}/>

  },
  {    
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
  },
  {    
    id: 'kebab',
    numeric: true,
    disablePadding: false,
  },
];
