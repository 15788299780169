import {  FormControlLabel, styled, Table, TableContainer } from '@mui/material';
import React from 'react';
import { EnhancedTableHead } from '../../../../components/common/table/tableHead'
import { resultsHeadCells } from './resultsProjectHomeHeadcells';
import ResultsProjectHomeTableContent from './resultsProjectHomeTableContent';



export const ProjectContainer = styled(TableContainer) ({
   flex: 1
});

export const DenseButton = styled(FormControlLabel) ({
    paddingLeft: '16px'
});

export default function ResultsProjectHomeTable({ filteredData, page, count, rowsPerPage, onPageChange, onRowsPerPageChange }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };

    
    const isSelected = (name) => selected.indexOf(name) !== -1;


  
  return (
    <ProjectContainer>
      <Table
          aria-labelledby="tableTitle"
      >
          <EnhancedTableHead
            headCells={resultsHeadCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredData?.length}
          />
          
          <ResultsProjectHomeTableContent
            orderBy={orderBy}
            handleClick={handleClick}
            isSelected={isSelected}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredData = {filteredData}
          /> 
        </Table>
    </ProjectContainer>
  )
}
