import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import StatusBanner from '../../../../components/common/statusBanner';
import { TableLink } from '../../../../components/common/styledLinks';
import PublishedResultsKebabMenu from './publishedResultsKebabMenu';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
  export default function ResultsProjectHomeTableContent(props) {

    const { order, orderBy, handleClick, isSelected, page, rowsPerPage, filteredData } =
    props;


    return (
    <>
      {
        filteredData ?    
        <TableBody>
          {stableSort(filteredData, getComparator(order, orderBy))
          .slice(0, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const isItemSelected = isSelected(row.benchmark_name);
            const labelId = `enhanced-table-checkbox-${index}`;
            const rowKey = `${row.benchmark_id}-${index}`;
            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.benchmark_name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={rowKey}
                selected={isItemSelected}
              >
                <TableCell key={row.title}
                id={labelId}
                scope="row"
                >
                  <TableLink to={"/dashboard?project_id=" + row.project_id + "&benchmark_id=" + row.benchmark_id}>
                  <Box sx={{display: 'flex', flexFlow: 'column'}}>
                  <Typography variant="body2" color="initial">{row.benchmark_name}</Typography>
                  <Typography variant="body2" color="gray"> ID: {row.benchmark_id}</Typography>
                  </Box>
                  </TableLink>
                </TableCell>
                <TableCell align="left" sx={{display: {xs: 'none', sml: 'table-cell'}}}>
                  <Box sx={{display: 'flex', flexFlow: 'column'}}>
                    <Typography variant="body2" color="initial">{row.apply_segmentation ? 'Yes' : 'No'}</Typography>
                  </Box>
                </TableCell>
                <TableCell key={row.title} align="left">
                  {row.type}
                </TableCell>
                <TableCell key={row.title} align="left" sx={{display: {xs: 'none', md: 'table-cell'}}}>
                  <Typography variant="body2" color="gray">{row.last_update}</Typography>
                </TableCell>
                <TableCell key={row.title} align="left" sx={{width: '15%'}} ><StatusBanner status={row.status}/></TableCell>
                <TableCell key={row.title} align="right">
                  <PublishedResultsKebabMenu
                    project_id={row.project_id}
                    scenario_id={row.benchmark_id}
                    // setDeletedID={setDeletedID}
                    status={row.status}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        :
        null  
      }
    </>
  )
}
