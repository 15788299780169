import React from 'react'
import { MainCard } from '../../common/commonContainer'
import { Box, Typography } from '@mui/material'
import { KnowledgeCardsContent } from './knowledgeBase/knowledgeBaseContent'


export default function Scale() {
  return (
    <MainCard >
        <Box sx={{display:'flex', p:'0 25px', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
          <Typography variant="subtitle2" color="initial">Scale</Typography>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="subtitle2" color="initial">Last Update</Typography>
            <Typography variant="caption" color="#777777">21/05/2023</Typography>
          </Box>
        </Box>
        <KnowledgeCardsContent >
          <Typography variant="body2" color="initial">
            Economies of scale refer to the cost advantages that a company can achieve as its production levels increase. In other words, as a company grows and produces more goods or services, its average costs of production tend to decrease. This cost reduction can be attributed to various factors that arise due to the size and volume of operations.
          </Typography>
          <Typography variant="body2" color="initial">
            Constant economies of scale occur when the average cost per unit remains constant as the scale of production increases. It means that the cost reduction achieved through economies of scale is proportional to the increase in production. In this case, the cost per unit remains unchanged, resulting in a linear relationship between the increase in production and the decrease in average cost.
          </Typography>
          <Typography variant="body2" color="initial">
            In a variable return to scale scenario, when all inputs are increased by a certain proportion, the resulting change in output is not proportional. There are two possible outcomes:
          </Typography>
          <Typography variant="body2" color="initial">
            Increasing economies of scale, when the average cost per unit decreases at an increasing rate as production levels increase. In this scenario, the cost reduction is greater than the proportional increase in production. Factors contributing to increasing economies of scale include better specialization, improved efficiency, bulk purchasing discounts, and increased bargaining power with suppliers.
          </Typography>
          <Typography variant="body2" color="initial">
            Decreasing economies of scale, conversely, decreasing economies of scale occur when the average cost per unit decreases at a decreasing rate as production increases. In this case, the cost reduction is less than the proportional increase in production. Decreasing economies of scale can be a result of diminishing returns to scale or inefficiencies that arise as an organization becomes too large to manage efficiently.
          </Typography>
          <Typography variant="body2" color="initial">
            It's important to note that while economies of scale generally lead to cost reductions, there may be limits to how much cost advantage can be gained. Eventually, diseconomies of scale may occur, where the average cost per unit starts to increase as the scale of production becomes too large and inefficient.
          </Typography>
          <Typography variant="body2" color="initial">
            Overall, economies of scale play a crucial role in the growth and competitiveness of businesses, allowing them to achieve cost efficiencies and potentially lower prices for consumers.
          </Typography>
        </KnowledgeCardsContent>
    </MainCard>
  )
}
