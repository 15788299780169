import React, { useEffect, useState } from "react";
import { Chart as ChartJS, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import colors from "../../assets/variables.scss";
import { get_new } from "../../utils/httpClient";
import { Box, CircularProgress } from "@mui/material";

ChartJS.register(BarElement);

export const options = {
  maintainAspectRatio: false,
  plugins: {
    datalabels: { display: false },
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Entities Ranking By Efficiency Score",
      color: "#4c4c4d",
      font: {
        size: 14,
        weight: "bold",
        lineHeight: 1.2,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      display: true,
      grid: {
        display: false, // hide grid lines
      },
      title: {
        display: true,
        text: "Entities",
        color: "#4c4c4d",
        font: {
          size: 10,
          weight: "bold",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 0 },
      },
    },
    y: {
      display: true,
      grid: {
        display: false, // hide grid lines
      },
      yAxes: [
        {
          stacked: true, // this will stack the bars on top of each other
          gridLines: {
            display: false, // show the vertical grid lines
            color: "#eee", // set the color of the grid lines
          },
        },
      ],
      title: {
        align: "end",
        display: true,
        text: "Efficiency ( % )",
        color: "#4c4c4d",
        font: {
          size: 10,
          weight: "bold",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 0 },
      },
    },
  },
};

export default function EntitiesRankingHistogram({
  benchmark_id,
  periodObject,
  baseSegment,
  orientVaue,
}) {
  const [api, setApi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //current period data
  useEffect(() => {
    setLoading(true);
    setError(null);
    get_new(
      `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&assignment__cluster__cluster_cd=${baseSegment}&ordering=rank_overall&orient=${orientVaue}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve API data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setApi(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state
        setLoading(false);
      });
  }, [periodObject.period, benchmark_id, baseSegment, orientVaue]);

  const bin90100 =
    api.results && api.results.filter((bin) => bin.bin === "90% - 100%");
  const bin8090 =
    api.results && api.results.filter((bin) => bin.bin === "80% - 90%");
  const bin7080 =
    api.results && api.results.filter((bin) => bin.bin === "70% - 80%");
  const bin6070 =
    api.results && api.results.filter((bin) => bin.bin === "60% - 70%");
  const bin060 =
    api.results && api.results.filter((bin) => bin.bin === "0% - 60%");
  const labels = api.results && api.results.map((bin) => bin.dmu_id);
  const data = {
    labels,
    datasets: [
      {
        label: "90% - 100%",
        data:
          api.results &&
          api.results &&
          bin90100.map((value) => value.efficiency * 100),
        backgroundColor: colors.bin90100,
      },
      {
        label: "80% - 90%",
        data:
          api.results &&
          Array(bin90100.length)
            .fill(0)
            .concat(bin8090)
            .map((value) => value.efficiency * 100),
        backgroundColor: colors.bin8090,
      },
      {
        label: "70% - 80%",
        data:
          api.results &&
          Array(bin90100.length + bin8090.length)
            .fill(0)
            .concat(bin7080.map((value) => value.efficiency * 100)),
        backgroundColor: colors.bin7080,
      },
      {
        label: "60% - 70%",
        data:
          api.results &&
          Array(bin90100.length + bin8090.length + bin7080.length)
            .fill(0)
            .concat(bin6070.map((value) => value.efficiency * 100)),
        backgroundColor: colors.bin6070,
      },
      {
        label: "0% - 60%",
        data:
          api.results &&
          Array(
            bin90100.length + bin8090.length + bin7080.length + bin6070.length
          )
            .fill(0)
            .concat(bin060.map((value) => value.efficiency * 100)),
        backgroundColor: colors.bin060,
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "300px" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? ( // Render error component when error exists
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          Error: {error.message}
        </Box>
      ) : (
        <Bar options={options} data={data} grouped="false" />
      )}
    </div>
  );
}
