import React, { useState } from "react";
import {
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { green } from "@mui/material/colors";
import { post, get_new } from "../../../utils/httpClient";

export default function AddDataset({ open, onClose, setCreatedID }) {
  // LOCAL STATE
  const [formData, setFormData] = useState({
    name: "",
    file: "",
  });
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [nameHelperText, setNameHelperText] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [formErrors, setFormErrors] = useState({ error: false, message: "" });

  // CHANGE FORM DATASET
  const changeFormData = (name, event) => {
    const newForm = {
      ...formData,
      ...{
        [name]: event.target.value,
      },
    };

    setFormData(newForm);
  };

  /*CLOSE DIALOG*/
  const handleClose = (event, reason) => {
    /* Disable backdrop close on click*/
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  /* FILE MANAGEMENT */
  const updateFiles = (incomingFiles) => {
    if (incomingFiles.length > 0) {
      checkFileName(incomingFiles[0].file.name)
        .then((result) => {
          if (result) {
            incomingFiles[0].valid = false;
          }
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    }

    //console.log('incomingFiles', incomingFiles);
    setFiles(incomingFiles);
  };

  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
    setFormErrors({
      error: false,
      message: "",
    });
  };

  const checkFileName = async (name) => {
    const targetURL = `/datasets/?filename=${name}`;
    //console.log('Search filename: ' + targetURL);

    let result = false;

    await get_new(targetURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + " : " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.count > 0) {
          setFormErrors({
            error: true,
            message: "A file with this name already exists ",
          });
          result = true;
        } else {
          setFormErrors({
            error: false,
            message: "",
          });
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
    // console.log('result: ' + result);
    return result;
  };

  // APPLY DIALOG DATASET
  const handleCreate = async () => {
    if (!loadingCreate) {
      setLoadingCreate(true);
    }

    const data = new FormData();
    data.append("name", formData.name);
    data.append("file", files[0].file);

    const targetURL = "/csv/";

    /*POST FORM DATA*/
    post(targetURL, data)
      .then((response) => {
        //console.log(response);
        if (response.status >= 500) {
          alert("A server error occurred. Please try again later.");
          throw new Error("500: Internal Server Error");
        }
        if (response.status === 409) {
          setNameHelperText("Project name already exists");
          setNameError(true);
          throw new Error("409: Project name already exists");
        } else if (!response.ok) {
          response.json().then((data) => {
            setFormErrors({
              error: true,
              message: data.Res,
            });
          });

          throw new Error(response.status + ": " + response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        console.log("Dataset uploaded successfully");
        setLoadingCreate(false);
        setCreatedID(1); // for kickback and dataset list reload
        handleClose();
      })
      .catch((error) => {
        console.log("Create project failed");
        console.log(error);
        setLoadingCreate(false);
      });
  };

  /*Disable button if dataset and name are not completed*/
  const isDisabled = () => {
    if (formData.name === "" || files.length === 0) return true;
    if (files.length > 0 && files[0].valid === false) return true;
    return false;
  };

  return (
    <>
      {/* DIALOG ADD DATASET */}
      <Dialog fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle style={{ background: "#47254b", color: "white" }}>
          Add Dataset
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ marginTop: "20px" }}>
          <Box mb={2}>
            Select a File and add a short descriptive name for the dataset{" "}
            <br />
          </Box>
          {formErrors.error ? (
            <Box mb={2} sx={{ color: "red", fontSize: 12 }}>
              <ul>
                <li> {formErrors.message}</li>
              </ul>
            </Box>
          ) : (
            ""
          )}

          <FormControl>
            <FormLabel>Name*</FormLabel>
            <TextField
              id="name"
              //label="Name"
              size="small"
              variant="outlined"
              sx={{ width: "450px" }}
              required
              error={nameError}
              helperText={nameHelperText}
              inputProps={{ maxLength: 50 }}
              onChange={(event) => {
                if (nameError) {
                  setNameError(false);
                  setNameHelperText(null);
                }
                changeFormData("name", event);
              }}
            />
          </FormControl>
          <br />
          <br />

          <Dropzone
            maxFiles={1}
            accept=".csv"
            onChange={updateFiles}
            value={files}
            headerConfig={{ validFilesCount: true }}
            behaviour="replace"
            label={
              <Box>
                <CloudUploadOutlinedIcon fontSize="large" /> <br />
                Drag and drop your files here <br />
                <span style={{ fontSize: 12, color: "primary" }}>
                  or click to browse
                </span>
              </Box>
            }
          >
            {files.map((file, index) => (
              <FileMosaic key={file.id} {...file} onDelete={removeFile} />
            ))}
          </Dropzone>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={loadingCreate}
          >
            Cancel
          </Button>
          <Box sx={{ position: "relative", ml: 1 }}>
            <Button
              //sx={buttonSx}
              onClick={handleCreate}
              disabled={isDisabled() || loadingCreate}
              variant="contained"
              color="success"
            >
              Upload
            </Button>
            {loadingCreate && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
