import React from "react";
import { Chart as ChartJS, PointElement, LineElement } from "chart.js";
import { Line } from "react-chartjs-2";
import colors from "../../assets/variables.scss";
import { useState } from "react";
import { useEffect } from "react";
import { get_new } from "../../utils/httpClient";
import { Box } from "@mui/material";
import { numeralFormatter } from "../../utils/numeralFormatter";
import { useContext } from "react";
import ResultDashboardContext from "../../views/results/dashboard/context/resultDashboardContext";

ChartJS.register(PointElement, LineElement);

export default function VarVsTargetTimeseries({
  entity,
  periodObject,
  benchmark_id,
  metric,
  api,
  metricName,
  orientValue,
}) {
  const [segment, setSegment] = useState([]);
  const [overall, setOverall] = useState([]);

  console.log("segment data", segment);

  const { apply_segmentation } = useContext(ResultDashboardContext);

  const getSegment = () => {
    if (!api[0]?.assignment__cluster__cluster_cd && api[0]?.assignment__cluster__cluster_cd !== 0) return;
    get_new(
      `/project/benchmark/results/segment/metrics/?benchmark_id=${benchmark_id}&variable_detail__original_field_name=${metric}&assignment__cluster__cluster_cd=${api[0]?.assignment__cluster__cluster_cd}&orient=${orientValue}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setSegment(data.results);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
      });
  };

  const getOverall = () => {
    get_new(
      `/project/benchmark/results/metrics/?benchmark_id=${benchmark_id}&variable__variable_name=${metric}&orient=${orientValue}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setOverall(data.results);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
      });
  };

  useEffect(() => {
    getSegment();
    getOverall();
  }, [
    entity,
    periodObject,
    benchmark_id,
    metric,
    api[0]?.assignment__cluster__cluster_cd,
    orientValue,
  ]);

  segment?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  overall?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: `${metricName} - Progress Towards Target`,
        color: "#4c4c4d",
        font: {
          size: 14,
          weight: "bold",
          lineHeight: 1.2,
        },
      },
      datalabels: {
        display: false,
      },
      legend: { display: true },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Period",
          color: "#4c4c4d",
          font: {
            size: 10,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        grace: "10%",
        ticks: {
          beginAtZero: true,
        },
        title: {
          display: true,
          align: "end",
          text: "Progress Towards Target ( % )",
          color: "#4c4c4d",
          font: {
            size: 10,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };

  const labels = api?.map((value) => value.period);
  const data = {
    labels,
    datasets: [
      {
        label: api[0]?.dmu_id,
        data: api?.map((period) =>
          numeralFormatter(period.progress_current_target * 100)
        ),
        borderColor: colors.cluster1,
        backgroundColor: colors.cluster1,
        yAxisID: "y",
        datalabels: {
          display: true,
          color: "white",
          align: "center",
          borderRadius: 50,
          backgroundColor: colors.cluster1,
          labels: {
            title: {
              font: {
                size: 10,
              },
            },
          },
          formatter: (value) => {
            return `${value}%`;
          },
        },
      },
      {
        label: "Overall (Avg)",
        data: overall?.map((value) =>
          numeralFormatter(value.progress_current_target * 100)
        ),
        borderColor: colors.cluster2,
        backgroundColor: colors.cluster2,
        yAxisID: "y",
      },
      apply_segmentation
        ? {
            label: "Segment (Avg)",
            data: segment?.map((value) =>
              numeralFormatter(value.progress_current_target * 100)
            ),
            borderColor: colors.cluster3,
            backgroundColor: colors.cluster3,
            yAxisID: "y",
          }
        : null,
      {
        label: "Target",
        data: api?.map((value) => 100),
        borderColor: colors.extra4,
        backgroundColor: "transparent",
        borderWidth: 1,
        borderDash: [5],
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        yAxisID: "y",
      },
    ].filter(Boolean),
  };

  return (
    <Box sx={{ width: { xs: "100%", sm: "47%" }, height: "300px" }}>
      <Line options={options} data={data} />
    </Box>
  );
}
