import { get_new } from "../../../../../../utils/httpClient";

export const getAvgEfficiencyData = ({ benchmark_id, baseSegment, orientVaue }) => {
    const url =   baseSegment === "" ? 
    `/project/benchmark/results/efficiency/?benchmark_id=${benchmark_id}&orient=${orientVaue}` 
    :
    `/project/benchmark/results/segment/efficiency/?benchmark_id=${benchmark_id}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`

  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error fetching Avg Efficiency Data:', error);
    throw error;
  })
};

export const getEfficiencyDistrData = ({ benchmark_id, baseSegment, periodObject,orientVaue }) => {
    const url = `/project/benchmark/results/segment/efficiency-bins/?benchmark_id=${benchmark_id}&period=${periodObject.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error Efficiency Distribution Data:', error);
    throw error;
  })
};

export const getBoxplotData = ({ benchmark_id, baseSegment, periodObject,orientVaue }) => {
    const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error Boxplot Data:', error);
    throw error;
  })
};