import React from 'react'
import { MainCard, ShadowCardContentContainer } from '../../../../components/common/commonContainer'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import InputTargetGraphs from '../../../../graphs/individualResults/inputTargetGraphs'
import OutputTargetGraphs from '../../../../graphs/individualResults/outputTargetGraphs'
import IndividualResultsTable from './individualResultsTable'
import { useEffect } from 'react'
import { useState } from 'react'
import { getInputTargetData, getOutputTargetData } from './services/inputOutputTargetServices'

export default function InputOutputTargets({benchmark_id, periodObject, api, periodOptions, entity, orientValue} ) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);

  const [inputData, setInputData] = useState([])
  const [outputData, setOutputData] = useState([])

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //INPUT AND OUTPUT DATA FETCH
    Promise.all([
      getInputTargetData({ benchmark_id, periodObject, entity, orientValue }),
      getOutputTargetData({ benchmark_id, periodObject, entity, orientValue }),
    ])
    .then(([inputData, outputdata]) => {
      setInputData(inputData)
      setOutputData(outputdata)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setError(error)
    })
    .finally(() => {
      setLoading(false);
    });
  }, [benchmark_id, periodObject, entity, orientValue]);
  
  return (
    <MainCard >
      {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Box>
    ) : 
    error ? ( // Render error component when error exists
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
          Error: {error.message}
      </Box>
    ) :
    (
      <>
        <Typography variant="h5" sx={{padding: '10px', pl: '36px'}}>
          Input / Output Targets
        </Typography>
        <Divider variant="fullWidth" sx={{width: '-webkit-fill-available'}}/>
        <ShadowCardContentContainer sx={{ml: '36px', mr:'36px'}}>
            <Typography variant="h6" color="initial" sx={{width: '100%', display: 'flex', justifyContent: 'center', pt: 2}}>
              Input Targets
            </Typography>
            <InputTargetGraphs 
              api={inputData} 
              periodOptions={periodOptions}
            />
        </ShadowCardContentContainer>
        <ShadowCardContentContainer sx={{ml: '36px', mr:'36px'}} >
            <Typography variant="h6" color="initial" sx={{width: '100%', display: 'flex', justifyContent: 'center', pt: 2}}>
              Output Targets
            </Typography>
            <OutputTargetGraphs 
              api={outputData} 
              periodOptions={periodOptions}
            />
        </ShadowCardContentContainer>
        <IndividualResultsTable 
          api={api} 
          periodOptions={periodOptions} 
        />
      </>
      )}
    </MainCard>
  )
}
