import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DetailsContainer, DetailsTable } from '../../components/common/commonContainer';
import EmptyCardMessage from '../../components/common/emptyCardMessage';
import FilterSidebar from '../../components/common/filtersSidebar';
import MainUpperBar from '../../components/common/mainUpperBar';
import { get_new } from '../../utils/httpClient';
import DatasetsTable from './datasetsTable';

export default function DatasetsHome() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);


	  //DATASETS HANDLE TABLE PAGINATION
	  const isSmallScreen = useMediaQuery('(max-width:1536px)');

	  const [page, setPage] = useState(0);
	  const [rowsPerPage, setRowsPerPage] = useState(isSmallScreen ? 4 : 8);
	
	  const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };
	  
	  // //Search bar DATASETS Value handler
	  // const [searchDataset, setSearchDataset] = useState('')
	  // const searchHandler = (event) => {
	  //   setSearchDataset(event.target.value)
	  //   }
	
	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  useEffect(() => {
        const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
        setRowsPerPage(newDefaultRowsPerPage);
      }, [isSmallScreen]);
	
  

	const [filterFields, setFilterFields] = useState([
		{key: 'name', title: 'Search', label: 'Search Scenario', name: 'searchScenario', value: '', type: 'text' },
		{
			key: 'status',
			title: 'Status',
			label: 'Status',
			name: 'status',
			value: '',
			type: 'select',
			placeHolder: '',
			options: [
				{ label: 'All', value: '' },
				{ label: 'Loading', value: 'loading' },
				{ label: 'Processing', value: 'processing' },
				{ label: 'New', value: 'new' },
				{ label: 'Ready', value: 'ready' },
			],
		},
		// { key: 'creationDate', title: 'Creation Date', label: '', name: 'Creation Date', value: '', type: 'date' },
		// { key: 'LastUpdate', title: 'Last Update', label: '', name: 'Last Update', value: '', type: 'date' },
	]);

	const [delayedDatasetTerm, setDelayedDatasetTerm] = useState('');
    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        setDelayedDatasetTerm(filterFields[0].value);
      }, 1000);
  
      return () => clearTimeout(delayDebounceFn);
    }, [filterFields[0].value]);

	useEffect(() => {
		setLoading(true);
		setError(null); // Clear any previous errors

		get_new(`/datasets/?page_size=${rowsPerPage}&page=${page + 1}&search=${delayedDatasetTerm}&status=${filterFields[1].value}`)
		  .then((response) => {
			if (!response.ok) {
			  console.log('Could not retrieve API data');
			  console.log('Response Error' + response.status);
			  throw new Error(response.statusText);
			}
			return response.json();
		  })
		  .then((data) => {
			  setData(data)
			  setLoading(false)
			})
		  .catch((error) => {
			console.log('Error: ' + error);
			// TODO: Show user error that data couldn't be retrieved
			setError(error); // Set the error state
			setLoading(false)
	
		  });
	  }, [page, rowsPerPage, delayedDatasetTerm, filterFields[1].value]);  

	return (
		<Box>
			<MainUpperBar UpperTitle={'Datasets'} backLink={"/"} />
			<DetailsContainer>
            	<FilterSidebar filteredData={data.results} filterFields={filterFields} setFilterFields={setFilterFields}/>
				<DetailsTable sx={{ display: 'flex', justifyContent: 'center' }}>
					{loading ? (
					<CircularProgress /> 
					) : error ? ( // Render error component when error exists
					<div>Error: {error.message}</div>)
					:
					data && data.count > 0 ? 
					<DatasetsTable 
						filteredData={data.results}

						page={page}
						count={data.count}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
	  
					/> 
					: 
					<EmptyCardMessage text1={'Results'} text2={'Projects'} />}
				</DetailsTable>
			</DetailsContainer>
		</Box>
	);
}
