import React from 'react';
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { useContext } from 'react';
import ResultDashboardContext from '../../views/results/dashboard/context/resultDashboardContext';
import { useState } from 'react';
import EfficiencyFrontierModal from '../chartModals/efficiencyFrontierModal';

ChartJS.register( 
  PointElement, 
  LineElement
);



export default function EfficiencyFrontierGraph({api}) {
  const { setValue, setFrontierEntityValue } = useContext(ResultDashboardContext);

  const [open, setOpen] = useState(false);
  const[entityList, setEntityList] = useState([])

  /*  MODAL HANDLERS */
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {display: false},
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
          },
        },
        title: {
          display: true,
          text: 'Efficiency Frontier',
          color: '#4c4c4d',
          font: {
            size: 14,
            weight: 'bold',
            lineHeight: 1.2,
          },
    
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const efficiencyFrontierValues = [
                `Bin: ${context.dataset.label}`,
                `Entity: ${context.raw.dmu}`,
                `Inputs: ${context.parsed.x.toFixed(2)}`,
                `Outputs: ${context.parsed.y.toFixed(2)}`,
              ];
              return efficiencyFrontierValues
            }
          }
        },
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          grid: {display: false},
          title: {
            display: true,
            text: 'Inputs',
            color: '#4c4c4d',
            font: {
              size: 10,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: {top: 0, left: 0, right: 0, bottom: 0}
          }
        },
        y: {
        display: true,
        grid: {display: false},
        grace: "10%",
        beginAtZero: true,
        title: {
          display: true,
          align: 'end',
          text: 'Outputs',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      }
    },
    onClick: (e, el) => {
      // console.log(el.length);
      // console.log(api);
      if(el.length === 1) {
        setValue(1)
        setFrontierEntityValue(el[0].element.$context.raw.dmu)
      }
      if (el.length > 1) {
        handleOpen(true)
        const arr = el.map(v => v.element.$context.raw.dmu)
        
        setEntityList(arr)
      }
    },
  };

let Bin1 = api && api.filter(coordinate => (coordinate.bin === "0% - 60%" && coordinate.pc_input !== null && coordinate.pc_output !== null ))
let Bin2 = api && api.filter(coordinate => (coordinate.bin === "60% - 70%" && coordinate.pc_input !== null && coordinate.pc_output !== null ))
let Bin3 = api && api.filter(coordinate => (coordinate.bin === "70% - 80%" && coordinate.pc_input !== null && coordinate.pc_output !== null ))
let Bin4 = api && api.filter(coordinate => (coordinate.bin === "80% - 90%" && coordinate.pc_input !== null && coordinate.pc_output !== null ))
let Bin5 = api && api.filter(coordinate => (coordinate.bin === "90% - 100%" && coordinate.pc_input !== null && coordinate.pc_output !== null ))

const maxInputVal = api?.map(v=>v.pc_input)
  const data = {

    datasets: [
      {
        type: 'line',
        label: '100% Frontier',
        borderColor: '#1db505',
        backgroundColor: 'transparent',
        fill: false,
        data: [{x:0,y:0},{x:Math.max(...maxInputVal),y:Math.max(...maxInputVal)}],
        
        
        borderWidth: 1,
        pointRadius: 0,
        pointStyle: 'dash',
        borderDash: [5, 5], // set the borderDash property to display a dashed line
      },
      // {
      //   type: 'line',
      //   label: '60% Frontier',
      //   borderColor: '#993155',
      //   backgroundColor: 'transparent',
      //   fill: false,
      //   data: [{x:0,y:0},{x:6,y:3.54}],
        
        
      //   borderWidth: 1,
      //   pointRadius: 0,
      //   pointStyle: 'dash',
      //   borderDash: [5, 5], // set the borderDash property to display a dashed line
  
      // },
      {
        label: '0% - 60%',
        data: Bin1.map(coordinate => ({x:coordinate.pc_input , y:coordinate.pc_output, dmu: coordinate.dmu_id })),
        backgroundColor: colors.bin060
        // backgroundColor: '#FF548F',
      },
      {
          label: "60% - 70%",
          data: Bin2.map(coordinate =>({x:coordinate.pc_input, y: coordinate.pc_output, dmu: coordinate.dmu_id})),
          backgroundColor: colors.bin6070
          // backgroundColor: '#9061C2',
      },
      {
          label: "70% - 80%",
          data: Bin3.map(coordinate => ({ x: coordinate.pc_input, y: coordinate.pc_output, dmu: coordinate.dmu_id})),
          backgroundColor: colors.bin7080
          // backgroundColor: '#BE80FF',
      },
      {
          label: "80% - 90%",
          data: Bin4.map(coordinate => ({ x: coordinate.pc_input, y: coordinate.pc_output, dmu: coordinate.dmu_id })),
          backgroundColor: colors.bin8090
          // backgroundColor: '#63D3FF',
      },
      {
          label: "90% - 100%",
          data: Bin5.map(coordinate => ({ x: coordinate.pc_input, y: coordinate.pc_output, dmu: coordinate.dmu_id })),
          backgroundColor: colors.bin90100
          // backgroundColor: '#02779E',
      }
    ],
  };



  return <div style={{ width: '100%', height: '400px' }}>
    <Scatter options={options} data={data}  className="segmentation" />
    {
      <EfficiencyFrontierModal
        open={open}
        onClose={handleClose}
        entityList={entityList}
        setValue={setValue}
        setFrontierEntityValue={setFrontierEntityValue}
      />

    }
  </div>
}
