import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ShadowCardContentContainer } from "../../../../components/common/commonContainer";
import VariableTimeseries from "../../../../graphs/metricResults/variableTimeseries";
import VarVsTargetTimeseries from "../../../../graphs/metricResults/varVsTargetTimeseries";
import { get_new } from "../../../../utils/httpClient";
import InformationVariableTable from "./informationVariableTable.jsx/informationVariableTable";
import { numeralFormatter } from "../../../../utils/numeralFormatter";

export default function HighLevelMetricsAndVariables({
  entity,
  periodObject,
  benchmark_id,
  metric,
  orientValue,
}) {
  const [api, setApi] = useState([]);
  const [apiCurrentPeriod, setApiCurrentPeriod] = useState([]);

  useEffect(() => {
    if (!metric || !periodObject) return;
    get_new(
      `/project/benchmark/results/dmu/metrics/?benchmark_id=${benchmark_id}&dmu_id=${entity}&variable_detail__original_field_name=${metric}&orient=${orientValue}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setApi(data.results);
        setApiCurrentPeriod(
          data.results.filter((v) => v.period === periodObject.period)
        );
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
      });
  }, [entity, benchmark_id, metric, orientValue, periodObject]);

  api?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });
  const metricName = metric?.replace(/_/g, " ");

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-around",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexFlow: "row",
          borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography variant="h5" color="initial" sx={{ p: "10px", pl: "27px" }}>
          Variable: {metricName}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            mt: "20px",
            flexFlow: "row wrap",
            width: "-webkit-fill-available",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <ShadowCardContentContainer
            sx={{
              alignItems: "center",
              justifyContent: "unset",
              gap: "0px",
              width: { xs: "100%", lg: "44%" },
              flexGrow: "0",
              flexFlow: "row nowrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {numeralFormatter(
                  apiCurrentPeriod?.[0]?.actual_value -
                    apiCurrentPeriod?.[0]?.improv_benchmark_period
                )}
              </Typography>
              <Typography variant="body2" color="initial" nowrap="true">
                Baseline
              </Typography>
            </Box>

            <Divider variant="middle" orientation="vertical" flexItem />

            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {numeralFormatter(apiCurrentPeriod?.[0]?.actual_value)}
              </Typography>
              <Typography variant="body2" color="initial">
                Current
              </Typography>
            </Box>
            <Divider variant="middle" orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {numeralFormatter(apiCurrentPeriod?.[0]?.target)}
              </Typography>
              <Typography variant="body2" color="initial">
                Target
              </Typography>
            </Box>
          </ShadowCardContentContainer>
          <ShadowCardContentContainer
            sx={{
              alignItems: "center",
              justifyContent: "unset",
              gap: "0px",
              width: { xs: "100%", lg: "46%" },
              flexGrow: "0",
              flexFlow: "row nowrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "36%",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {numeralFormatter(
                  apiCurrentPeriod?.[0]?.progress_current_target
                )}
                %
              </Typography>
              <Typography variant="body2" color="initial">
                Progress to target
              </Typography>
            </Box>

            <Divider variant="middle" orientation="vertical" flexItem />

            <Box
              sx={{
                display: "flex",
                width: "32%",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {/*Need diff p/p api prop*/} {/* Doubs about this */}
                {numeralFormatter(
                  apiCurrentPeriod?.[0]?.pct_improv_benchmark_period
                )}
                %
              </Typography>
              <Typography variant="body2" color="initial" nowrap="true">
                %diff p/p
              </Typography>
            </Box>
            <Divider variant="middle" orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                width: "32%",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {numeralFormatter(
                  apiCurrentPeriod?.[0]?.change_current_benchmark
                )}
                %
              </Typography>
              <Typography variant="body2" color="initial">
                %diff p/b
              </Typography>
            </Box>
          </ShadowCardContentContainer>
        </Box>
      </Box>
      <ShadowCardContentContainer
        sx={{ flexFlow: "row wrap", width: "-webkit-fill-available" }}
      >
        <VariableTimeseries api={api} metricName={metricName} />
        <VarVsTargetTimeseries
          api={api}
          entity={entity}
          metric={metric}
          metricName={metricName}
          benchmark_id={benchmark_id}
          orientValue={orientValue}
        />
      </ShadowCardContentContainer>
      {/* <ShadowCardContentContainer sx={{ flexFlow: 'row wrap', width: '-webkit-fill-available'}}>
            <VariableHistogram/>
            <VariableHistogram/>
        </ShadowCardContentContainer> */}
      <Box
        sx={{
          width: "-webkit-fill-available",
          margin: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <InformationVariableTable api={api} />
      </Box>
    </Box>
  );
}
