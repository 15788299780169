import React, { useEffect, useState, useContext } from 'react';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
    DialogTitle,
    List,
    ListItemText,
    ListItemButton,
	Button,
	Box,
    Grid, Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { green } from '@mui/material/colors';   
import { enqueueSnackbar } from 'notistack';
import { getVariablesList } from './benchmarkWizardSteps/datasetAuxFunctions';
import { ProjectContext } from '../../../../../context/Project/ContextProject';
import { CreateBenchmarkContext } from './benchmarkWizardSteps/createBenchmarkContext';
import { BenchmarkScenariosContext } from '../../benchmarkScenarios';
import { get_new, post_json } from '../../../../../utils/httpClient';
//Import Wizard Steps
import PurposeWS from './benchmarkWizardSteps/purposeWS';
import DescriptionWS from './benchmarkWizardSteps/descriptionWS';
import ObjectiveWS from './benchmarkWizardSteps/objectiveWS';
import VariablesWS from './benchmarkWizardSteps/variablesWS';
import VarTreatmentWS from './benchmarkWizardSteps/varTreatmentWS';
import TargetsWS from './benchmarkWizardSteps/targetsWS';
import ScaleWS from './benchmarkWizardSteps/scaleWS';
import PeriodsWS from './benchmarkWizardSteps/periodsWS';
import BaselinePeriodsWS from './benchmarkWizardSteps/baselinePeriodsWS';
import TrackingPeriodsWS from './benchmarkWizardSteps/trackingPeriodsWS';
import SegmentationWS from './benchmarkWizardSteps/segmentationWS';
import SummaryWS from './benchmarkWizardSteps/summaryWS';
import { Link } from 'react-router-dom';


export default function AddBenchmarkWizard({ open = true, onClose = null }) {
    // COMPONENT STATE
    const [formData, setFormData] = useState({
        project_id: '',
        name: '', 
        description: '',
        purpose: 'benchmark', //[rank, benchmark]
        objective: 'input', //[input, output, input_output_independent, input_output_together]
        target_values_type: 'radial', //[radial, non_radial]
        scale_type: 'crs', //[crs, vrs, drs, irs]
        input_vars: [],
        output_vars: [],
        apply_restricted: false,
        restricted_vars: [],
        apply_undesirable: false,
        undesirable_vars: [],
        apply_weight_restrictions: false,
        multi_periods: '', //[all, single, na]
        apply_tracking_cycles: false,
        baseline_frequency: '', //[monthly, quarterly, yearly]
        // if tracking_cycles=false
        single_baseline_period: '',
        // if tracking_cycles=true
        first_baseline_period: null,
        tracking_frequency: '', //[monthly, quarterly, yearly]
        include_next_baseline: false,
        apply_segmentation: false,
        segmentation_scenario: null,
        dea_model: '', //NOTE: confirm with Simon
        user_weight_restrictions: []
    });
    const [variableList, setVariableList] = useState([]);
    //const [catVarList, setCatVarList] = useState([]); //Categorical variable list
    const [numVarList, setNumVarList] = useState([]); //Numerical variable list
    const [activeStep, setActiveStep] = useState(0); //[0-9]
    const [datasetDetails, setDatasetDetails] = useState({}); 
    const [periodData, setPeriodData] = useState({
        'has_periods': false,
        'period_type': '',
        'periods_list': []
    });
    const [disabledTabs, setDisabledTabs] = useState({
        'periodsWS':false,
        'baselinePeriodsWS':false,
        'trackingPeriodsWS':false,
        'segmentationWS':false
    }); 
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingRun, setLoadingRun] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState('');
        

    // CONTEXT STATE
    const { projectData, datasetData } = useContext(ProjectContext);
    const { setCreatedID } = useContext(BenchmarkScenariosContext);
    
    // GET DATASET CONFIG DETAILS
    /*
    This is an intermediate step while we fix the backend to return the complete
    dataset config details including period information. 
    Doing this in two steps:
    1. Get dataset config details
    2. Get dataset period info and periods list if dataset config details is retrieved successfully
    */
    
    // console.log('base formData', formData);

    function getDatasetDetails(dataset_id) {
        let targetURL = '/dataset/dataset_config/?dataset_id=' + dataset_id;
        // console.log(targetURL);
        get_new(targetURL)
            .then((response) => {
            if (!response.ok) {
                throw new Error(response.status+": "+response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log('dataset_details:', data.results[0]);
                setDatasetDetails(data.results[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    }   


    /* GET PROJECT AND DATASET INFO */
    useEffect(() => {
        // create deep copy of formData object and add project_id
        // let newFormData = JSON.parse(JSON.stringify(formData));
        let newFormData = formData;
        newFormData.project_id = projectData.project_id;
        // console.log('Project ID: ' + projectData.project_id);
        // console.log('newFormData', newFormData);
        setFormData(newFormData);
        
        getDatasetDetails(projectData.dataset_id); 
        setVariableList(getVariablesList(datasetData, 'all'));
        setNumVarList(getVariablesList(datasetData, 'numerical'));
        //setCatVarList(getVariablesList(datasetData, 'categorical'));
        
        return;
    }, [projectData, datasetData]);

    /* GET PERIODS INFO */
    useEffect(() => {
        // Stop if dataset details are not available
        if (!datasetDetails.dataset_id) return;
        
        // Don't get periods if no period date field is defined
        if (!datasetDetails.period_field) {
            console.log('No period field - disabling tabs');
            //Disable periods tabs
            setDisabledTabs(disabledTabs => {
                let newDisabledTabs = { ...disabledTabs };
                newDisabledTabs.periodsWS = true;
                newDisabledTabs.baselinePeriodsWS = true;
                newDisabledTabs.trackingPeriodsWS = true;
                return newDisabledTabs;
            });
            // Set multi_periods to 'na'
            setFormData(formData => {
                let newFormData = { ...formData };
                newFormData.multi_periods = 'na';
                return newFormData;
            });
            return; // end process 
        } ;
        
        // Identify periods type (monthly, quarterly, yearly)
        let periodField = datasetDetails.datasetvariable.filter((variable) => {
            return (variable.original_field_name === datasetDetails.period_field);
        })[0];
        if (!periodField.field_sub_type) return;
        let periodicity = periodField.field_sub_type.toLowerCase();

        // Get list of periods
        let targetURL = '/dataset/periods_list/?dataset_id=' + datasetDetails.dataset_id;
        // console.log(targetURL);
        let newPeriodData = {
            'has_periods': true,
            'period_type': periodicity,
            'periods_list': []
        }
        // console.log('newPeriodData', newPeriodData);
        get_new(targetURL)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                // console.log('Fetch periods succeeded');
                // console.log('periods data', data);
                newPeriodData.periods_list = data.results;
                console.log('newPeriodData', newPeriodData)
                setPeriodData(newPeriodData);
                return;
            })
            .catch((error) => {
                console.log('Fetch periods failed');
                console.log(error);
                console.log('newPeriodData', newPeriodData)
                setPeriodData(newPeriodData);
                return;
            })
    }, [datasetDetails]);



    /*Update form data*/
    /*TODO: Check if name format is valid*/
    /*TODO: Check if name already exists*/
    const changeFormData = (name, newValue) => {
        console.log('form field: ' + name +'='+ newValue);
		const newForm = {
			...formData,
			...{
				[name]: newValue,
			},
		};

        setFormData(newForm);
        console.log(newForm);
        
    };
    
    /*Handle close dialog*/
    const handleCloseDialog = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    /*Handle RUN and SAVE button spinners*/
    function handleButtonSpinners(action, enable) {
        if (action==='save' && enable) {
            setLoadingSave(true);        
        } else if (action==='save' && !enable) {
            setLoadingSave(false);
        } else if (action==='run' && enable) {
            setLoadingRun(true);
        } else if (action==='run' && !enable) {
            setLoadingRun(false);
        }
        
    }

    /*Handle create benchmark scenario */
    const handleCreate = async (action) => {
        if (!loadingSave || !loadingRun) {
            handleButtonSpinners(action, true);
        }
        
        //Make copy of formData
        let data = JSON.parse(JSON.stringify(formData));
        data.action = action; // 'save' or 'run'
        
        // rename certain fields
        data.benchmark_name = data.name.trim();
        data.benchmark_desc = data.description.trim()
        data.benchmark_freq = data.baseline_frequency;
        data.benchmark_start_period = data.first_baseline_period;
        data.tracking_freq = data.tracking_frequency;
        data.orient=data.objective;

        // Leave only IDs in list fields 
        data.input_vars = data.input_vars.map((item) => { return item.id; });
        data.output_vars = data.output_vars.map((item) => { return item.id; });
        data.restricted_vars = data.restricted_vars.map((item) => { return item.id; });
        data.undesirable_vars = data.undesirable_vars.map((item) => { return item.id; });
        data.segmentation_scenario_id = data.segmentation_scenario ? data.segmentation_scenario.id : null;
        
        // delete unnecessary fields
        delete data.name;
        delete data.description;
        delete data.baseline_frequency;
        delete data.first_baseline_period;
        delete data.tracking_frequency;
        delete data.segmentation_scenario;
        delete data.objective;

        console.log('Final Benchmark data', data);
        console.log('Final Benchmark FormData', formData);
            
        //Target URL for posting form

        const targetURL = '/project/benchmark/';
        post_json(targetURL, data)
            .then((response) => {
                console.log('response', response);
                if (!response.ok) {
                    throw new Error(response.status + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log('Benchmark Scenario created successfully');
                console.log(data);
                enqueueSnackbar('Successfully added scenario: ' + formData.name.trim(), { variant: 'success' });
                setCreatedID(data.benchmark_id);
                handleButtonSpinners(action, false);
                handleCloseDialog();
                return;
            })
            .catch((error)=>{
                console.log('Benchmark Scenario creation failed');
                console.log(error)
                handleButtonSpinners(action, false);
                //handleCloseDialog();
                return 
            })
        
      
    };
    

    async function checkName(){
        
        if (formData.name === '') return;
        // Check if name already exists
        
        const targetURL = '/project/benchmark/?project_id=' + formData.project_id +
            '&benchmark_name=' + formData.name.trim();
        
        //console.log(targetURL);

        let bad_name= await get_new(targetURL)
            .then((response) => {
                if (!response.ok) {
                    console.log('Error' + response.status );
                    throw new Error('Could not verify name');
                }
                return response.json();
            })
            .then((data) => {
                if (data.count > 0) {
                    console.log('Raise 409 error ');
                    console.log('Scenario name already exists');
                    setNameError(true);
                    setNameHelperText('Scenario name already exists');
                    return true;
                }
                console.log('Scenario name is valid');
                return false;

            })
            .catch((error) => {
                console.log('Name verification failed: ', error);
                return false;
            })

        //console.log("Bad name: " + bad_name);
        return bad_name;
    }; 

    
    const prevStep = () => {
        let step = 1;
        // if in SegmentationWS and no periods, reverse to PeriodsWS
        if (activeStep === 10 && periodData.has_periods === false) {
            step = 3;
        }
        // if in SegmentationWS and periods but no tracking cycles, reverse to PeriodsWS
        else if (activeStep === 10 && periodData.has_periods === true && formData.apply_tracking_cycles === false) {
            step = 3;
        }
		setActiveStep(activeStep - step);
	};

    const nextStep = () => {
        let step = 1;
        if (activeStep === 1) {
            checkName().then((result_check_name) => {
                if (result_check_name) {
                    return;
                }
                setActiveStep(activeStep + step);
            });
            return;
        }

        // if in PeriodsWS and no periods, skip BaselinePeriodsWS and TrackingPeriodsWS
        if (activeStep === 7 && periodData.has_periods === false) {
            step = 3;
        }
        // if in PeriodsWS and periods but no tracking cycles, skip BaselinePeriodsWS and TrackingPeriodsWS
        else if (activeStep === 7 && periodData.has_periods === true && formData.apply_tracking_cycles === false) {
            step = 3;
        }
		setActiveStep(activeStep + step);
    };
    
    const handleTabChange = (event, newValue) => {
        if (activeStep === 1) {
            checkName().then((result_check_name) => {
                if (result_check_name) {
                    return;
                }
                setActiveStep(newValue);
            });
            return;
        }
        setActiveStep(newValue);
    };

    //Manage disabled state of next button
    const isDisabled = () => {
        switch (activeStep) {
            case 0: // Purpose
                return formData.purpose === '';
            case 1: // Description
                return formData.name.trim() === '';
            case 2: // Objective
                return formData.objective === '';
            case 3: // Variables
                if (formData.input_vars.length === 0 && formData.output_vars.length === 0) return true;
                else if (formData.objective === 'input') return formData.input_vars.length === 0;
                else if (formData.objective === 'output') return formData.output_vars.length === 0;
                else if (formData.objective === 'input_output_independent' ||
                    formData.objective === 'input_output_together') {
                    return formData.input_vars.length === 0 || formData.output_vars.length === 0;
                };
                break;
            case 4: // Var Treatment
                if (formData.apply_restricted === false && formData.apply_undesirable === false) return false;
                else if (formData.apply_restricted === true && formData.apply_undesirable === false) {
                    return formData.restricted_vars.length === 0;
                }
                else if (formData.apply_restricted === false && formData.apply_undesirable === true) {
                    return formData.undesirable_vars.length === 0;
                }
                else if (formData.apply_restricted === true && formData.apply_undesirable === true) {
                    return formData.restricted_vars.length === 0 || formData.undesirable_vars.length === 0;
                }
                break;
            case 5: // Scale
                return formData.scale_type === '';
            case 6: // Targets
                return formData.target_values_type === '';
            case 7: // Periods
                if (periodData.has_periods === false) return false;
                else if (formData.multi_periods === '') return true;
                else if (formData.multi_periods === 'all') return false;
                else if (formData.multi_periods === 'single') return formData.single_baseline_period === '';
                break;
            case 8: // Baseline Periods
                return formData.baseline_frequency === '' || formData.first_baseline_period === '';
            case 9: // Tracking Periods
                return formData.tracking_frequency === '';
            case 10: // Segmentation
                if (formData.apply_segmentation === false) return false;
                else if (formData.apply_segmentation === true) return formData.segmentation_scenario === null;
                break;
            case 11: // Summary
                if (formData.name.trim() === '') return true;
                if (formData.objective === 'input' && formData.input_vars.length === 0) return true;
                if (formData.objective === 'output' && formData.output_vars.length === 0) return true;
                if ((formData.objective === 'input_output_independent' || formData.objective === 'input_output_together')
                    && (formData.input_vars.length === 0 || formData.output_vars.length === 0)) return true;
                if ((formData.multi_periods === 'single' && formData.single_baseline_period === '')) return true;
                if ((formData.multi_periods === 'all' && formData.apply_tracking_cycles &&
                    (formData.baseline_frequency === '' || formData.first_baseline_period === '' ||
                        formData.tracking_frequency === ''))) return true;
                if (formData.apply_segmentation && formData.segmentation_scenario === null) return true;
                return false;

            default:
                return false;

        } 
    };
    
    const stepsList = () => {
        return   [
            { 'step': 'Purpose', 'step_index': 0, 'disabled': false },
            { 'step': 'Description', 'step_index': 1, 'disabled': false },
            { 'step': 'Objective', 'step_index': 2, 'disabled': false },
            { 'step': 'Input/Output metrics', 'step_index': 3, 'disabled': false },
            { 'step': 'Variable Treatments', 'step_index': 4, 'disabled': false },
            { 'step': 'Scale', 'step_index': 5, 'disabled': false },
            { 'step': 'Target Values', 'step_index': 6, 'disabled': false },
            { 'step': 'Multi-periods', 'step_index': 7, 'disabled': false },
            { 'step': 'Baseline Cycle', 'step_index': 8, 'disabled': disabledTabs.baselinePeriodsWS },
            { 'step': 'Tracking cycles', 'step_index': 9, 'disabled': disabledTabs.trackingPeriodsWS },
            { 'step': 'Segmentation', 'step_index': 10, 'disabled': disabledTabs.segmentationWS },
            { 'step': 'Summary', 'step_index': 11, 'disabled': false },
        ]
    }

    return (
        <>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { mt: "50px" } }}
                maxWidth='lg'
                fullWidth={true}
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle style={{ background: '#47254b', color: 'white' }}>
                    Create Benchmark Scenario
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            //bgcolor: 'white',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
				<DialogContent style={{ marginTop: '0px' }} dividers>
                    <Grid container spacing={2}>
                       
                        {/* Left side Menu*/}

                        <Grid item xs={5} sm={4} md={3} lg={2}>
                            <List dense component="nav">
                                {stepsList().map((step) => {
                                    return (
                                        <ListItemButton
                                            //disablePadding
                                            key={"wizardStep-" + step.step_index}
                                            disableGutters
                                            disabled={step.disabled}
                                            sx={{ py: 0, minHeight: 30 }}
                                            selected={activeStep === step.step_index}
                                            onClick={(event) => handleTabChange(event, step.step_index)}
                                        >
                                            <ListItemText primary={step.step} />
                                        </ListItemButton>
                                    )
                                })}
                            </List>
                        </Grid>
                        
                        {/* Steps Content */}
                        <Grid item xs={7} sm={8} md={7} lg={8} >
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
                            > 
                                <CreateBenchmarkContext.Provider value={{
                                    formData, setFormData, changeFormData,
                                    variableList, numVarList,
                                    periodData, disabledTabs, setDisabledTabs,
                                    'dataset_id': projectData.dataset_id,
                                }}>
                                    
                                    <PurposeWS tabValue={activeStep} index={0} />
                                    <DescriptionWS tabValue={activeStep} index={1}
                                        nameError={nameError} nameHelperText={nameHelperText}
                                        setNameError={setNameError} setNameHelperText={setNameHelperText}/>
                                    <ObjectiveWS tabValue={activeStep} index={2} />
                                    <VariablesWS tabValue={activeStep} index={3} />
                                    <VarTreatmentWS tabValue={activeStep} index={4} />
                                    <ScaleWS tabValue={activeStep} index={5} />
                                    <TargetsWS tabValue={activeStep} index={6} />
                                    <PeriodsWS tabValue={activeStep} index={7} />
                                    <BaselinePeriodsWS tabValue={activeStep} index={8} />
                                    <TrackingPeriodsWS tabValue={activeStep} index={9} />
                                    <SegmentationWS tabValue={activeStep} index={10} />
                                    <SummaryWS tabValue={activeStep} index={11}
                                        nameError={nameError} nameHelperText={nameHelperText}/>
                            
                            </CreateBenchmarkContext.Provider>
                            </Box>
                        </Grid>
                        {/* Knowledge base column*/}
                        <Grid item md={2}>
                            {
                                activeStep === 0 ? 
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Benchmark Scenarios
                                        </Typography>
                                    </Link> 
                                </Box>
                                : 
                                activeStep === 1 ? 
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                    Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Benchmark Scenarios
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                activeStep === 2 ? 
                                    <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                        <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                            Learn more about:
                                        </Typography>
                                        <Link to="/knowledgeBase/inputOutputMetrics" target="_blank" style={{color: '#43224d'}}>
                                            <Typography variant="body2" color="#43224d">
                                               Benchmarking Input & Output metrics
                                            </Typography>
                                        </Link> 
                                        <Link to="/knowledgeBase/objective" target="_blank" style={{color: '#43224d'}}>
                                            <Typography variant="body2" color="#43224d">
                                                Benchmarking Objectives
                                            </Typography>
                                        </Link> 
                                    </Box>
                                :
                                activeStep === 3 ?
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/inputOutputMetrics" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Input and Output Metrics
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                activeStep === 4 ? 
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/variableTreatment" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Variable Treatment
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                activeStep === 5 ?
                                    <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                        <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                            Learn more about:
                                        </Typography>
                                        <Link to="/knowledgeBase/inputOutputMetrics" target="_blank" style={{color: '#43224d'}}>
                                            <Typography variant="body2" color="#43224d">
                                                Input/Output Metrics
                                            </Typography>
                                        </Link> 
                                        <Link to="/knowledgeBase/scale" target="_blank" style={{color: '#43224d'}}>
                                            <Typography variant="body2" color="#43224d">
                                                Scale
                                            </Typography>
                                        </Link> 
                                    </Box>
                                :
                                activeStep === 6 ?
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/targetValues" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Target Values
                                        </Typography>
                                    </Link>
                                </Box>
                                :
                                activeStep === 7 ?
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/trackingCycles" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Tracking cycles
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                activeStep === 8 ? 
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/trackingCycles" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Tracking cycles
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                activeStep === 9 ? 
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>
                                    <Link to="/knowledgeBase/trackingCycles" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            Tracking cycles
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                activeStep === 10 ? 
                                <Box sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                                    <Typography variant="body2" color="#43224d" sx={{fontWeight: 'bold'}}>
                                        Learn more about:
                                    </Typography>    
                                    <Link to="/knowledgeBase/about" target="_blank" style={{color: '#43224d'}}>
                                        <Typography variant="body2" color="#43224d">
                                            segmentation
                                        </Typography>
                                    </Link> 
                                </Box>
                                :
                                ''
                            }
                        </Grid>
                    </Grid>
				</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="text">
						Cancel
					</Button>
					{activeStep > 0 && (
						<Button onClick={prevStep} variant="outlined" color="primary">
							Prev
						</Button>
					)}
					{activeStep < 11 && (
						<Button onClick={nextStep} variant="contained" color="primary" disabled={isDisabled()}>
							Next
						</Button>
					)}
					
                    {activeStep === 11 && (
                        <Box sx={{position: 'relative', ml:1}}>
                        <Button
                            onClick={() => { handleCreate('save') }}
                            variant="contained"
                            color="primary"
                            disabled={isDisabled()}>
							Save
                            </Button>
                            {loadingSave && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }} />)}
                        </Box>
                    )}
                    {activeStep === 11 && (
                        <Box sx={{position: 'relative', ml:1}}>
                            <Button
                            onClick={() => { handleCreate('run') }}
                            variant="contained"
                            color="primary"
                            disabled={isDisabled()}>
							Run
                            </Button>
                            {loadingRun && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }} />)}
                        </Box>
					)}
				</DialogActions>
            </Dialog>
        </>
    )

}