import React from "react";
import { LabelGrid, ValueGrid } from "../manualSettings";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import { getVariablesList } from "../benchmarkWizardSteps/datasetAuxFunctions";
import { useState } from "react";
import { useEffect } from "react";

export default function VariablesEditManual({
  setFormBenchmarkData,
  formBenchmarkData,
  changeFormBenchmarkData,
  datasetData,
}) {
  const [numVarList, setNumVarList] = useState([]);
  const [inputOptions, setInputOptions] = useState([]);
  const [outputOptions, setOutputOptions] = useState([]);

  useEffect(() => {
    setNumVarList(getVariablesList(datasetData, "numerical"));
    return;
  }, [datasetData]);

  useEffect(() => {
    // Filter options based on default values of input_vars and output_vars
    const filteredInputOptions = removeMatchingItems(
      formBenchmarkData.output_vars,
      numVarList
    );
    const filteredOutputOptions = removeMatchingItems(
      formBenchmarkData.input_vars,
      numVarList
    );
    setInputOptions(filteredInputOptions);
    setOutputOptions(filteredOutputOptions);
  }, [numVarList, formBenchmarkData]);

  // Helper function to filter available options for input and output Autocompletes
  function removeMatchingItems(selectedOptions, options) {
    // Extract the IDs from array1 into a Set for efficient lookups
    const idSet = new Set(selectedOptions.map((item) => item.id));
    // Filter array2, keeping only items with IDs not present in array1
    const filteredOptions = options.filter((item) => !idSet.has(item.id));
    return filteredOptions;
  }

  return (
    <>
      <Grid container spacing={1} marginY={2} fontSize={12}>
        <LabelGrid>Input Variables:</LabelGrid>
        <ValueGrid type="list">
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="input-vars"
              size="small"
              //sx = {{width: '450px'}}
              required
              selectOnFocus
              clearOnBlur
              clearOnEscape
              autoComplete
              autoHighlight
              fullWidth
              options={inputOptions}
              getOptionLabel={(option) => option.label}
              value={formBenchmarkData.input_vars}
              onChange={(e, newValue) => {
                changeFormBenchmarkData({ input_vars: newValue });
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
            />
          </FormControl>
        </ValueGrid>
        <LabelGrid>Output Variables:</LabelGrid>
        <ValueGrid type="list">
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="output-vars"
              size="small"
              //sx = {{width: '450px'}}
              required
              selectOnFocus
              clearOnBlur
              clearOnEscape
              autoComplete
              autoHighlight
              fullWidth
              options={outputOptions}
              getOptionLabel={(option) => option.label}
              value={formBenchmarkData.output_vars}
              onChange={(e, newValue) => {
                changeFormBenchmarkData({ output_vars: newValue });
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
            />
          </FormControl>
        </ValueGrid>
      </Grid>
    </>
  );
}
