import { get_new } from "../../../../../utils/httpClient"

export const getEfficiencyApi = ({ project_id, benchmark_id, encodedSelectedOption, orientValue }) => {
  const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&dmu_id=${encodedSelectedOption}&orient=${orientValue}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => (prevData) => [...prevData,  data ])
  .catch((error) => {
    console.error('Error fetching Efficiency Api data:', error);
    throw error;
  })
};

export const getPeriodEfficiencyApi = ({ project_id, benchmark_id, encodedSelectedOption, periodObject, orientValue }) => {
    const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&dmu_id=${encodedSelectedOption}&period=${periodObject?.period}&orient=${orientValue}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => (prevData) => [...prevData,  data ])
    .catch((error) => {
      console.error('Error fetching Period Efficiency Api data:', error);
      throw error;
    })
  };

  export const getEfficiencyQuadrantsApi = ({ project_id, benchmark_id, periodObject, encodedSelectedOption, orientValue }) => {
    const url = `/project/benchmark/results/dmu/quadrant/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&dmu_id=${encodedSelectedOption}&orient=${orientValue}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => (prevData) => [...prevData,  data ])
    .catch((error) => {
      console.error('Error fetching Efficiency Quadrant data:', error);
      throw error;
    })
  };