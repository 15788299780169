import React from 'react';
import { Line } from 'react-chartjs-2';
import { options } from '../graphProps/segmentation'
// import { decToPer } from '../App';
import colors from '../../assets/variables.scss'
import { GraphContainer } from '../../components/common/commonContainer';
import { numeralFormatter } from '../../utils/numeralFormatter';
import TopEfficiencyTable from './tablesTopBottomEfficiency/topEfficiencyTable';


export default function EfficiencyChange({value, periodObject, previousPeriod, api}) {

  const title = {
    plugins: {
      title: {
        text: `Top 5 Entities - Efficiency Change (p/${value ? 'b' : 'p'})`,
        display: true,
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      subtitle: {
        display: false,
        text: 'Custom Chart Subtitle'
      },
      datalabels: {
        display: true,
        color: "white",
        align: 'center',
        borderRadius: 50,
        labels: {
          title: {
            font: {
              size: 10
            }
          },
        },
        formatter: (value) => {
          return `${numeralFormatter(value)} %`
        }
      },
    }
  }
  const option135 = Object.assign({}, options, title)
  const labels = api && api.results && [value ? periodObject?.benchmark_period : previousPeriod?.period, api?.results[0]?.period]
  const data = {
    labels,
    datasets: [
      {
        label: api.results && api.results[0]?.dmu_id,
        data: value === 0 ? api.results && api.results[0] && api.results[0].efficiency && 
          [((api.results[0].efficiency - api?.results[0]?.pct_improv_prev_period) * 100), ((api?.results[0]?.efficiency * 100))] 
          :
          api.results && api.results[0] && 
          [((api?.results[0]?.efficiency - api?.results[0]?.pct_improv_benchmark_period) * 100), ((api?.results[0]?.efficiency * 100))],
        borderColor: colors.entity1,
        backgroundColor: colors.entity1,
        yAxisID: 'y',
        datalabels: {
          backgroundColor:colors.entity1
        }
      },
      {
        label: api.results && api.results[1]?.dmu_id,
        data: value === 0 ? api.results && api.results[1] && 
          [((api?.results[1]?.efficiency - api?.results[1]?.pct_improv_prev_period) * 100), ((api?.results[1]?.efficiency * 100))] 
          :
          api.results && api.results[1] && 
          [((api?.results[1]?.efficiency - api?.results[1]?.pct_improv_benchmark_period) * 100), ((api?.results[1]?.efficiency * 100))],
        borderColor: colors.entity2,
        backgroundColor: colors.entity2,
        yAxisID: 'y',
        datalabels: {
          backgroundColor:colors.entity2
        }
  
      },
      {
        label: api.results && api.results[2]?.dmu_id,
        data: value === 0 ? api.results && api.results[2] && 
          [((api?.results[2]?.efficiency - api?.results[2]?.pct_improv_prev_period) * 100), ((api?.results[2]?.efficiency * 100))] 
          :
          api.results && api.results[2] && 
          [((api?.results[2]?.efficiency - api?.results[2]?.pct_improv_benchmark_period) * 100), ((api?.results[2]?.efficiency * 100))],
        borderColor: colors.entity3,
        backgroundColor: colors.entity3,
        yAxisID: 'y',
        datalabels: {
          backgroundColor:colors.entity3
        }
      },
      {
        label: api.results && api.results[3]?.dmu_id,
        data: value === 0 ? api.results && api.results[3] && 
        [((api?.results[3]?.efficiency - api?.results[3]?.pct_improv_prev_period) * 100), ((api?.results[3]?.efficiency * 100))] 
        :
        api.results && api.results[3] && 
        [((api?.results[3]?.efficiency - api?.results[3]?.pct_improv_benchmark_period) * 100), ((api?.results[3]?.efficiency * 100))],
      borderColor: colors.entity4,
        backgroundColor: colors.entity4,
        yAxisID: 'y',
        datalabels: {
          backgroundColor:colors.entity4
        }
      },
      {
        label: api.results && api.results[4]?.dmu_id,
        data: value === 0 ? api.results && api.results[4] && api.results[4].efficiency && 
          [((api.results[4].efficiency - api?.results[4]?.pct_improv_prev_period) * 100), ((api?.results[4]?.efficiency * 100))] 
          :
          api.results && api.results[4] && 
          [((api?.results[4]?.efficiency - api?.results[4]?.pct_improv_benchmark_period) * 100), ((api?.results[4]?.efficiency * 100))],
        borderColor: colors.entity5,
        backgroundColor: colors.entity5,
        yAxisID: 'y',
        datalabels: {
          backgroundColor:colors.entity5
        },
      }
    ],
  };

  return <GraphContainer sx={{alignItems: 'center', m: 0, p: '5px', width: '-webkit-fill-available', flexFlow: 'column'}}>
    <div style={{ height: "400px", width: '80%' }}>
      <Line options={option135} data={data} />
    </div>
    <TopEfficiencyTable api={api} value={value} periodObject={periodObject} previousPeriod={previousPeriod}/>
  </GraphContainer>
}