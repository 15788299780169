import { FormControl, Grid, OutlinedInput } from '@mui/material'
import React from 'react'
import { LabelGrid, ValueGrid } from '../manualSettings'

export default function NameDescManual({summaryData, setFormBenchmarkData, formBenchmarkData}) {
    const handleNameChange = (e) => {
        setFormBenchmarkData((prevData) => ({
            ...prevData,
            benchmark_name: e.target.value,
        }));
    };
    
    const handleDescriptionChange = (e) => {
        setFormBenchmarkData((prevData) => ({
            ...prevData,
            benchmark_desc: e.target.value,
        }));
    };

  return (
    <>
        <Grid container spacing={1} marginY={2} fontSize={12}>
            <LabelGrid>name: </LabelGrid>
            <ValueGrid  required={true}>
                <FormControl sx={{width: '70%'}}>
                    <OutlinedInput 
                        id="component-outlined"
                        defaultValue={formBenchmarkData?.benchmark_name || ''}
                        onChange={handleNameChange}
                        sx={{fontSize: '12px',
                            '& .MuiOutlinedInput-input': {p:'5px'}
                        }}
                    />
                </FormControl>                 
            </ValueGrid>
            <LabelGrid> Description: </LabelGrid>
            <ValueGrid>
                <FormControl sx={{width: '70%'}}>
                    <OutlinedInput 
                        id="component-outlined"
                        value={formBenchmarkData.benchmark_desc}
                        onChange={handleDescriptionChange}
                        sx={{fontSize: '12px',
                            '& .MuiOutlinedInput-input': {p:'5px'}
                        }}
                    />
                </FormControl>  
            </ValueGrid>
        </Grid>
    </>
  )
}
