import { Box, CircularProgress, Divider, Tabs, Typography } from '@mui/material'
import React from 'react'
import { SmallRoundTab } from '../../../../../components/common/tabsDesign'
import { GraphMainCard } from '../../../../../components/common/commonContainer'
import { useState } from 'react'
import NoPeriodAvgEfficiencyImprovementSection from './noPeriodAvgEfficiencyImprovement'
import AvgEfficiencyImprovementSection from './avgEfficiencyImprovement'
import { useEffect } from 'react'

export default function MainAvgEfficiencyImprovement({periodOptions, baseSegment, benchmark_id, periodObject, previousPeriod, orientVaue}) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if(periodObject !== [])
        return setLoading(false)
    }, [periodObject])


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
  return (
    <Box sx={{width: '100%', display: 'flex', flexFlow: 'column'}}>
        <Typography variant="h5" sx={{padding: '10px'}}>Average Efficiency Improvement</Typography>
        <Divider variant="fullWidth" />
        {
            loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                </Box>
            ) :
            periodOptions.length <= 1 ?
            <NoPeriodAvgEfficiencyImprovementSection baseSegment={baseSegment} benchmark_id={benchmark_id} orientVaue={orientVaue}/>
            :
            <>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    TabIndicatorProps= {{sx: {display: 'none'}}}
                    sx={{padding: '10px 20px', alignSelf: 'flex-end'}}
                >
                    <SmallRoundTab label="Period / Period" />
                    <SmallRoundTab label="Period / Baseline" />
                </Tabs>
                <GraphMainCard sx={{justifyContent: 'space-evenly', m: 0}} >
                    {selectedTab === 0 && (
                        <AvgEfficiencyImprovementSection value={0} periodObject={periodObject} previousPeriod={previousPeriod} baseSegment={baseSegment} benchmark_id={benchmark_id} orientVaue={orientVaue}/>
                        
                    )}
                    {selectedTab === 1 && (
                    <AvgEfficiencyImprovementSection value={1} periodObject={periodObject} previousPeriod={previousPeriod} baseSegment={baseSegment} benchmark_id={benchmark_id} orientVaue={orientVaue}/>
                    )}    
                </GraphMainCard>
            </>
        }
    </Box>
  )
}
