import { Box, DialogContent, Divider, Grid, Paper, Typography, styled } from '@mui/material'
import React, { useContext } from 'react'
import StatusBanner from '../../../../../../components/common/statusBanner'
import { ErrorOutlineRounded } from '@mui/icons-material'
import EditBenchmarkContext from '../../context/editBenchmarkContext';
import SegmentationSetupInfo from './setupInfo/segmentationSetupInfo';
import TrackingPeriodSetupInfo from './setupInfo/trackingPeriodSetupInfo';
import BenchmarkSetupInfo from './setupInfo/benchmarkSetupInfo';

export default function EditModalContent({ scenarioData = null }) {
    //CONTEXT PROVIDER 
    const { summaryData } = useContext(EditBenchmarkContext)

    const upperHeadText =
        {
            saved:     
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ErrorOutlineRounded fontSize='medium' sx={{ float: 'left', mr: 1, color: 'primary' }} />
                    <Typography variant="body1" fontWeight={700} color="initial">
                        You can modify all the scenario settings. <br /> When you feel ready click on run to generate the results.
                    </Typography>
                </Box>,

            published:
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ErrorOutlineRounded fontSize='medium' sx={{ mr: 1, color: 'primary' }} />
                    <Typography variant="body1" fontWeight={700} color="initial">
                        You can only DELETE or UNPUBLISH this scenario as it is currently published.
                    </Typography>
                </Box>,

            completed:
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ErrorOutlineRounded fontSize='medium' sx={{ mr: 1, color: 'primary' }} />
                    <Typography variant="body1" fontWeight={700} color="initial">
                        You can only edit the scenario description. <br/> Or you can also publish this Benchmark Scenario.
                    </Typography>
                </Box>,

            processing:
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ErrorOutlineRounded fontSize='medium' sx={{ float: 'left', mr: 1, color: 'orangered' }} />
                    <Typography variant="body1" fontWeight={700} color="initial">
                        You cannot modify any fields in this state, please wait for the process to complete.
                    </Typography>
                </Box>
        }

   return (
        <DialogContent sx={{display: 'flex', flexFlow: 'column nowrap', gap: '5px', pb: 0}}>
            <Grid container direction="row" justifyContent="center" spacing={1} mt={1}>
                <Grid item xs={12}>
                    {upperHeadText[summaryData?.status]}
                </Grid>
                <Grid item xs={12} sx={{fontSize:12}}>
                    <Grid container spacing={1} pt={1}>
                        <Grid item xs={6} sm={6} md={3} lg={3} sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                            <Typography variant="h6" color="initial">
                                Name
                            </Typography>
                            <Typography variant="body2" color="initial">
                                {summaryData?.benchmark_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3} sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                            <Typography variant="h6" color="initial">
                                ID
                            </Typography>
                            <Typography variant="body2" color="initial">
                                {summaryData?.benchmark_id}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2} sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                            <Typography variant="h6" color="initial">
                                Status
                            </Typography>
                            <StatusBanner status={summaryData?.status}/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2} sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                            <Typography variant="h6" color="initial">
                                Project Name
                            </Typography>
                            <Typography variant="body2" color="initial">
                                {scenarioData?.project_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2} sx={{display: 'flex', flexFlow: 'column nowrap', gap: '10px'}}>
                            <Typography variant="h6" color="initial">
                                Creation Date
                            </Typography>
                            <Typography variant="body2" color="initial">
                                {summaryData?.creation_date}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', flexFlow: 'column', gap: '10px'}}>
                        <Typography variant="h6"> 
                            Description
                        </ Typography>
                        <Typography variant="body2" color="initial" sx={{border: '1px solid #DDDDDD', borderRadius: '8px', p: 1}}>
                            {summaryData?.benchmark_desc}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', gap: '5px'}}>
                <Box sx={{minWidth: '200px', height: '160px', display: 'flex', flexFlow: 'column nowrap', gap: '5px'}}>
                    <Typography variant="h6" color="initial">Benchmark Config</Typography>
                    <BenchmarkSetupInfo  summaryData={summaryData}/>
                </Box>

                <Box sx={{minWidth: '200px', height: '160px', display: 'flex', flexFlow: 'column nowrap', gap: '5px'}}>
                    <Typography variant="h6" color="initial">Tracking Period</Typography>
                    { summaryData?.apply_tracking_cycles ?
                        <TrackingPeriodSetupInfo summaryData={summaryData}/>
                        :
                        <EditModalCards >
                            <Typography variant="body2" color="initial">
                                No Tracking Period Applied
                            </Typography>
                        </EditModalCards>
                    }
                </Box>
                <Box sx={{minWidth: '200px', height: '160px', display: 'flex', flexFlow: 'column nowrap', gap: '5px'}}>
                    <Typography variant="h6" color="initial">Segmentation Config</Typography>
                    {
                    summaryData?.apply_segmentation ?
                        <SegmentationSetupInfo summaryData={summaryData}/>
                        :
                        <EditModalCards >
                            <Typography variant="body2" color="initial">
                                No Segmentation Applied
                            </Typography>
                        </EditModalCards>
                    }
                </Box>
            </Box>

            <Divider sx={{pt: '10px'}} />
        </DialogContent>
  )
}

export const EditModalCards = styled(Paper)({
    display: 'flex',
    padding: 20,
    gap: 10,
    flexGrow: '1',
    flexFlow: 'column nowrap',
    width: 'fit-content',
    alignItems:'flex-start',
    justifyContent: 'flex-start'
})