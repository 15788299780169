import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GraphContainer } from '../../../../../components/common/commonContainer'
import AvgEfficiencyImprovement from '../../../../../graphs/aggregatedResults/avgEfficiencyImprovement'
import BottomEfficiencyChange from '../../../../../graphs/aggregatedResults/bottomEfficiencyChange'
import EfficiencyChange from '../../../../../graphs/aggregatedResults/topEfficiencyChange'
import { get_new } from '../../../../../utils/httpClient'

export default function AvgEfficiencyImprovementSection({value, periodObject, baseSegment, previousPeriod, benchmark_id, orientVaue}) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);

    //AVG EFFICIENCY IMPROVEMENT DATA
    const [efficiencyImprovementData, setEfficiencyImprovementData] = useState([])
     //TOP EFFICIENCY CHANGE DATA
     const [topChangeData, setTopChangeData] = useState([])
      //BOTTOM EFFICIENCY CHANGE DATA
    const [bottomChangeData, setBottomChangeData] = useState([])

    const setLoadingState = (isLoading) => {
        setLoading(isLoading);
      };
      
      // Usage: setLoadingState(true) to set loading state, setLoadingState(false) to clear loading state
      

    const fetchData = (url, successCallback) => {
      setLoadingState(true);
      setError(null); // Clear any previous errors
      get_new(url)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        successCallback(data);
        setLoadingState(false);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state
        setLoadingState(false);

      });
    };

    useEffect(() => {
      //AVG EFFICIENCY IMPROVEMENT DATA FETCH
      fetchData(`${baseSegment === "" ? 
        `/project/benchmark/results/efficiency/?benchmark_id=${benchmark_id}&orient=${orientVaue}` 
        :
        `/project/benchmark/results/segment/efficiency/?benchmark_id=${benchmark_id}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`}`,
        (data) => setEfficiencyImprovementData(data)
      )
      //TOP EFFICIENCY CHANGE DATA FETCH
      fetchData(
        `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}&ordering=${value === 0 ? 'row_no_in_period_vs_period' : 'row_no_in_benchmark_vs_period'}`,
        (data) => setTopChangeData(data)
      )
      //BOTTOM EFFICIENCY CHANGE DATA FETCH
      fetchData(
        `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}&ordering=-${value === 0 ? 'row_no_in_period_vs_period' : 'row_no_in_benchmark_vs_period'}`,
        (data) => setBottomChangeData(data)
      )
    }, [baseSegment, benchmark_id, periodObject.period, orientVaue, value])

    return (
    <Box sx={{gap: '10px', width: '100%', display: 'flex', flexFlow: 'column'}}>
        {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
            <CircularProgress />
        </Box>
        ) :
        error ? ( // Render error component when error exists
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
            Error: {error.message}
        </Box>
        ) :
         (
            <>
                <GraphContainer sx={{ height: 'fit-content', m: 0}}>
                    <AvgEfficiencyImprovement 
                        value={value} 
                        api={efficiencyImprovementData}
                    />
                </GraphContainer>
                <Box sx={{gap: '10px', display: 'flex', width: '100%', flexFlow: 'row wrap'}}>
                    <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-around', flexFlow: 'column nowrap'}}>
                        <EfficiencyChange value={value} api={topChangeData} periodObject={periodObject} previousPeriod={previousPeriod}/>
                    </Box>
                    <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-around', flexFlow: 'column nowrap'}}>
                        <BottomEfficiencyChange value={value} api={bottomChangeData} periodObject={periodObject} previousPeriod={previousPeriod}/>
                    </Box>
                </Box>
            </>
        )}
    </Box>
  )
}
