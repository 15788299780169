import React, { useEffect, useState } from "react";
import {
  MainCard,
  CardContentContainer,
} from "../../components/common/commonContainer";
import { PrimaryButton, SecondaryButton } from "../../components/common/button";
import {
  CircularProgress,
  TablePagination,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import EmptyCardMessage from "../../components/common/emptyCardMessage";
import DatasetsCards from "./datasetsCards";
import HomeProject from "./homeProject";
import { get_new } from "../../utils/httpClient";
import AddProjectDialog from "./menuOptions/addProject";
import AddDataset from "../datasets/menuOptions/addDataset";
import DatasetsUpperBar from "./homeDatasets/datasetsUpperBar";
import ProjectsUpperBar from "./homeProjects/projectsUpperBar";
import {
  useDataSetPageStore,
  useDataSetRowsPerPageStore,
  useHomeFiltersProjectsStore,
  useHomeSearchDatasetStore,
  useHomeSearchProjectStore,
  useProjectsPageStore,
  useProjectsRowsPerPageStore,
} from "../../store/store";

export default function Home({ userData }) {
  const isSmallScreen = useMediaQuery("(max-width:1536px)");
  const rowsPerPageOptions = isSmallScreen ? [4, 8, 12] : [4, 8, 12, 16];

  const [loadingProject, setLoadingProject] = useState(false);
  const [loadingDataset, setLoadingDataset] = useState(false);
  const [errorProject, setErrorProject] = useState(null);
  const [errorDataset, setErrorDataset] = useState(null);

  //DATA
  const [projects, setProjects] = useState([]);
  const [datasets, setDatasets] = useState({});

  const [deletedID, setDeletedID] = useState(null);
  const [createdID, setCreatedID] = useState(null);
  const [addDatasetDialog, setAddDatasetDialog] = useState(false);
  const [addProjectDialog, setAddProjectDialog] = useState(false);

  //PROJECTS FILTERS
  //   const [projectsCreationDate, setProjectsCreationDate] = useState("");
  const {
    projectsCreationDate,
    setProjectsCreationDate,
    projectsStatus,
    setProjectsStatus,
  } = useHomeFiltersProjectsStore();
  //   const [projectsStatus, setProjectsStatus] = useState("");

  const handleProjectsSelectValues = (value1, value2) => {
    setProjectsCreationDate(value1);
    setProjectsStatus(value2);
    // Do something with the selected values in the parent component
  };
  //DATASETS FILTERS
  //   const [datasetsCreationDate, setDatasetsCreationDate] = useState("");
  const {
    datasetsCreationDate,
    setDatasetsCreationDate,
    datasetsStatus,
    setDatasetsStatus,
  } = useHomeFiltersProjectsStore();
  //   const [datasetsStatus, setDatsetsStatus] = useState("");

  const handleDatasetsSelectValues = (value1, value2) => {
    setDatasetsCreationDate(value1);
    setDatasetsStatus(value2);
    // Do something with the selected values in the parent component
  };

  //Project HANDLE TABLE PAGINATION

  //   const [projectsPage, setProjectsPage] = React.useState(0);
  const { projectsPage, setProjectsPage } = useProjectsPageStore();
  const [prevSearchProjects, setPrevSearchProjects] = useState("");
  const { projectsRowsPerPage, setProjectsRowsPerPage } =
    useProjectsRowsPerPageStore();
  // const [projectsRowsPerPage, setProjectsRowsPerPage] = React.useState(
  //   isSmallScreen ? 4 : 8
  // );

  //SearchBar projects Value handler
  //   const [searchProjects, setSearchProjects] = useState("");
  const { searchProjects, setSearchProjects } = useHomeSearchProjectStore();
  const [delayedProjectsTerm, setDelayedProjectsTerm] = useState("");

  const searchProjectsHandler = (event) => {
    setSearchProjects(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Only reset pagination if the search term has changed
      if (searchProjects !== prevSearchProjects) {
        setProjectsPage(0);
        setPrevSearchProjects(searchProjects);
      }
      setDelayedProjectsTerm(searchProjects);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchProjects]);

  const handleProjectsChangePage = (event, newPage) => {
    setProjectsPage(newPage);
  };
  const handleProjectsChangeRowsPerPage = (event) => {
    setProjectsRowsPerPage(parseInt(event.target.value, 10));
    setProjectsPage(0);
  };

  // useEffect(() => {
  //   const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
  //   if (projectsRowsPerPage !== 4 && projectsRowsPerPage !== 8) {
  //     setProjectsRowsPerPage(newDefaultRowsPerPage);
  //   }
  // }, [isSmallScreen]);

  //DATASET HANDLE TABLE PAGINATION

  //   const [datasetsPage, setDatasetsPage] = React.useState(0);
  const { datasetsPage, setDatasetsPage } = useDataSetPageStore();
  const [prevSearchDataSets, setPrevSearchDataSets] = useState("");
  // const [datasetsRowsPerPage, setDatasetsRowsPerPage] = React.useState(4);
  const { datasetsRowsPerPage, setDatasetsRowsPerPage } =
    useDataSetRowsPerPageStore();
  const handleDatasetsChangePage = (event, newPage) => {
    setDatasetsPage(newPage);
  };

  //Search bar DATASETS Value handler
  //   const [searchDatasets, setSearchDatasets] = useState("");
  const { searchDatasets, setSearchDatasets } = useHomeSearchDatasetStore();
  const [delayedDatasetsTerm, setDelayedDatasetsTerm] = useState("");

  const searchDatasetsHandler = (event) => {
    setSearchDatasets(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchDatasets !== prevSearchDataSets) {
        setDatasetsPage(0);
        setPrevSearchDataSets(searchDatasets);
      }
      setDelayedDatasetsTerm(searchDatasets);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchDatasets]);

  const handleDatasetsChangeRowsPerPage = (event) => {
    setDatasetsRowsPerPage(parseInt(event.target.value, 10));
    setDatasetsPage(0);
  };

  // useEffect(() => {
  //   const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
  //   setDatasetsRowsPerPage(newDefaultRowsPerPage);
  // }, [isSmallScreen]);

  const getProjects = () => {
    setLoadingProject(true);
    get_new(
      `/projects/?page_size=${projectsRowsPerPage}&page=${
        projectsPage + 1
      }&search=${delayedProjectsTerm}&status=${projectsStatus}&creation_date=${projectsCreationDate}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve projects");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log('Projects List');
        // console.log(data);
        // console.log(data.results);
        setProjects(data);
        setLoadingProject(false);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
        setErrorProject(error); // Set the error state
        setLoadingProject(false);
      });
  };

  const getDatasets = () => {
    setLoadingDataset(true);
    get_new(
      `/datasets/?page_size=${datasetsRowsPerPage}&page=${
        datasetsPage + 1
      }&search=${delayedDatasetsTerm}&status=${datasetsStatus}&creation_date=${datasetsCreationDate}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve datasets");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log('Dataset List');
        // console.log(data);
        // console.log(data.results);
        setDatasets(data);
        setLoadingDataset(false);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
        setErrorDataset(error); // Set the error state
        setLoadingDataset(false);
      });
  };

  useEffect(() => {
    getProjects();
  }, [
    deletedID,
    projectsRowsPerPage,
    projectsPage,
    delayedProjectsTerm,
    projectsStatus,
    projectsCreationDate,
  ]);

  useEffect(() => {
    getDatasets();
  }, [
    deletedID,
    createdID,
    datasetsRowsPerPage,
    datasetsPage,
    delayedDatasetsTerm,
    datasetsStatus,
    datasetsCreationDate,
  ]);

  // OPEN DIALOG DATASET
  const openDatasetDialog = (data) => {
    setAddDatasetDialog(true);
  };

  // CLOSE DIALOG DATASET
  const closeDatasetDialog = () => {
    setAddDatasetDialog(false);
  };

  // APPLY DIALOG DATASET
  /*
	const applyDialogDataset = async () => {
		const data = new FormData();
		data.append('name', formDataset.name);
		data.append('file', formDataset.file);

		let res = await post('/csv/', data);
		if (res.status === 200) {
			setAddDatasetDialog(false);
			getDatasets();
		} else {
			res = await res.json();
			alert(res.Res);
		}
	};
	*/

  // OPEN DIALOG PROJECT
  const openAddProjectDialog = (data) => {
    setAddProjectDialog(true);
  };

  // CLOSE DIALOG PROJECT
  const closeAddProjectDialog = () => {
    setAddProjectDialog(false);
  };

  //filter dataset data
  // const filteredDataset = datasets.results && datasets.results.filter((row) =>
  // 	row.dataset_name.toLowerCase().includes(searchDataset && searchDataset.toLowerCase()) ||
  // 	row.dataset_id.toString().includes(searchDataset && searchDataset) ||
  // 	row.filename.toLowerCase().includes(searchDataset && searchDataset.toLowerCase())
  // );

  const [tab, setTab] = useState(1);

  const handleClick = (buttonValue) => {
    setTab(buttonValue);
  };

  const [projectsTab, setProjectsTab] = useState(1);

  const handleProjectsClick = (buttonValue) => {
    setProjectsTab(buttonValue);
  };
  return (
    <>
      {/* PROJECTS LIST */}
      <MainCard
        sx={
          projectsTab === 1
            ? { backgroundColor: "transparent", boxShadow: "none" }
            : {}
        }
      >
        <Box sx={{ display: "flex", m: "0px 0 12px 0", p: "0 40px" }}>
          <Typography variant="h1" color="initial">
            Hi {userData.first_name}
          </Typography>
        </Box>
        <ProjectsUpperBar
          text={"Projects"}
          search={true}
          value={searchProjects}
          onChange={searchProjectsHandler}
          setSearchProjects={setSearchProjects}
          onSelectValues={handleProjectsSelectValues}
          handleProjectsClick={handleProjectsClick}
          button={
            <PrimaryButton
              onClick={openAddProjectDialog}
              largeText={"Create Project"}
              smallText={"+"}
            />
          }
        />

        <CardContentContainer
          sx={
            projectsTab === 1
              ? {
                  paddingTop: "20px",
                  justifyContent: "center",
                  margin: "0 40px",
                }
              : { paddingTop: "20px", justifyContent: "center" }
          }
        >
          {loadingProject ? (
            <CircularProgress />
          ) : errorProject ? ( // Render errorProject component when error exists
            <div>Error: {errorProject.message}</div>
          ) : projects?.results?.length > 0 ? (
            projects &&
            projects.count !== undefined && (
              <HomeProject
                searchProject={delayedProjectsTerm}
                setDeletedID={setDeletedID}
                filteredData={projects?.results}
                projectsTab={projectsTab}
                section={true}
                page={projectsPage}
                count={projects?.count}
                rowsPerPage={projectsRowsPerPage}
                onPageChange={handleProjectsChangePage}
                onRowsPerPageChange={handleProjectsChangeRowsPerPage}
              />
            )
          ) : (
            <EmptyCardMessage text1="Projects" text2="Create Project" />
          )}
        </CardContentContainer>
      </MainCard>

      {/* DATASETS LIST */}
      <MainCard
        sx={
          tab === 1 ? { backgroundColor: "transparent", boxShadow: "none" } : {}
        }
      >
        {/* DATASET LIST HEADER */}
        <DatasetsUpperBar
          datasets={datasets}
          text={"Datasets"}
          search={true}
          value={searchDatasets}
          setSearchDatasets={setSearchDatasets}
          onChange={searchDatasetsHandler}
          button={
            <SecondaryButton
              onClick={openDatasetDialog}
              largeText={"Add Dataset"}
              smallText={"+"}
            />
          }
          onSelectValues={handleDatasetsSelectValues}
          handleClick={handleClick}
        />

        {/* DATASET LIST CONTENT*/}
        <CardContentContainer
          sx={
            tab === 1
              ? {
                  paddingTop: "20px",
                  margin: "0 40px",
                  justifyContent: "center",
                }
              : { paddingTop: "20px", justifyContent: "center" }
          }
        >
          {loadingDataset ? (
            <CircularProgress />
          ) : errorDataset ? ( // Render error component when error exists
            <div>Error: {errorDataset.message}</div>
          ) : datasets?.results?.length > 0 ? (
            <DatasetsCards
              datasets={datasets}
              tab={tab}
              filteredDataset={datasets.results}
              rowsPerPageOptions={rowsPerPageOptions}
              count={datasets?.count}
              rowsPerPage={datasetsRowsPerPage}
              page={datasetsPage}
              onPageChange={handleDatasetsChangePage}
              onRowsPerPageChange={handleDatasetsChangeRowsPerPage}
            />
          ) : (
            <EmptyCardMessage text1={"Results"} text2={"Projects"} />
          )}
        </CardContentContainer>
      </MainCard>

      {/* ----- ADD DATASET ----- */}
      {addDatasetDialog && (
        <AddDataset
          open={addDatasetDialog}
          onClose={closeDatasetDialog}
          setCreatedID={setCreatedID}
        />
      )}

      {/* ----- ADD PROJECTS ----- */}
      {addProjectDialog && (
        <AddProjectDialog
          open={addProjectDialog}
          onClose={closeAddProjectDialog}
        />
      )}
    </>
  );
}
