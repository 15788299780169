import { Box, Grid, Typography, styled } from "@mui/material";
import StatusBanner from "../../../components/common/statusBanner";
import { BaseMiniCard } from "../../../components/common/commonContainer";
import { Link } from "react-router-dom";
import { EventAvailableRounded } from "@mui/icons-material";
import ProjectKebabMenu from "../projectKebabMenu";
import { DatasetInfoBox } from "../datasetsCards";

const DatasetBoxLayout = styled(Box)({
  display: "flex",
  flexFlow: "row wrap",
});

const DatasetContentBox = styled(DatasetBoxLayout)({
  flexFlow: "column",
  alignItems: "flex-start",
});

export default function HomeProjetsCards({
  page,
  rowsPerPage,
  filteredData,
  setDeletedID,
}) {
  return (
    <Grid container spacing={1}>
      {/*Slice datasets to show only the ones in the current page*/
      /* Create one card pr record in slice */}
      {filteredData
        ?.slice(0, page * rowsPerPage + rowsPerPage)
        .map((content, index) => (
          <Grid item xs={12} sm={6} md={3} xl={3} key={index}>
            <BaseMiniCard key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <StatusBanner status={content.status} />
                <ProjectKebabMenu
                  project_id={content.project_id}
                  setDeletedID={setDeletedID}
                />
              </Box>
              <DatasetContentBox
                sx={{
                  gap: "0.7rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {/*DISPLAY FILE NAME */}
                <DatasetInfoBox sx={{ flexFlow: "column nowrap" }}>
                  <Link
                    to={"/projectDetails?project_id=" + content.project_id}
                    style={{ color: "initial", textDecoration: "none" }}
                  >
                    <Typography variant="subtitle1">
                      {content.project_name}
                    </Typography>
                  </Link>
                </DatasetInfoBox>
                {/*DISPLAY CREATION DATE TEXT */}
                <DatasetInfoBox
                  sx={{ flexFlow: "column nowrap", justifyContent: "flex-end" }}
                >
                  <Typography variant="caption" color="#8993A3">
                    {" "}
                    Project ID {content.project_id}
                  </Typography>
                  <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
                    <EventAvailableRounded
                      fontSize="small"
                      sx={{ color: "#8993A3" }}
                    />
                    <Typography variant="caption" color="#8993A3">
                      {content.creation_date}
                    </Typography>
                  </Box>
                </DatasetInfoBox>
              </DatasetContentBox>
            </BaseMiniCard>
          </Grid>
        ))}
    </Grid>
  );
}
