export const benchmarkScenariosHeadCells = [
    {
      id: 'nameId',
      numeric: false,
      disablePadding: true,
      label: 'Name / ID',
    },
    {
      id: 'useSegmentation',
      numeric: true,
      disablePadding: false,
      label: 'Use Segmentation',
      customDisplay: {xs: 'none', sml: 'table-cell'},
    },
    {
      id: 'type',
      numeric: true,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'lastUpdate',
      numeric: true,
      disablePadding: false,
      label: 'Last Update',
      customDisplay: {xs: 'none', md: 'table-cell'},
    },
    {    
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {    
      id: 'Kebab',
      numeric: true,
      disablePadding: false,
    },
  ];
