import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { numeralFormatter } from '../../../utils/numeralFormatter';


const BottomEfficiencyTable = ({value, api, periodObject, previousPeriod, previousApi}) => {
    // const selectedProps = ["dmu_id",`${ value ? "base_period_value" : "last_period_value"}`,"output_value", `${value ? 'pct_impr_benchmark_vs_period' : 'pct_impr_period_vs_period'}`,];
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Entity</TableCell>
          <TableCell>{value ? periodObject?.benchmark_period : previousPeriod?.period}</TableCell>
          <TableCell>{periodObject?.period}</TableCell>
          <TableCell>{`Change p/${value ? 'b' : 'p'}`}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {api?.results?.slice(0, 5).map((row, index) => (
          <TableRow key={index}>
            <TableCell sx={{width: '25%'}}>{row.dmu_id}</TableCell>
            <TableCell sx={{width: '25%'}}>{numeralFormatter((row.efficiency - (value ? row.pct_improv_benchmark_period : row.pct_improv_prev_period) ) * 100)} %</TableCell>
            <TableCell sx={{width: '25%'}}>{numeralFormatter(row.efficiency * 100)} %</TableCell>
            <TableCell sx={{width: '25%'}}>{numeralFormatter((value ? row.pct_improv_benchmark_period : row.pct_improv_prev_period) * 100)} %</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BottomEfficiencyTable;
