import {
  Chart as ChartJS,
  PointElement,
  LineElement,
} from 'chart.js';




ChartJS.register(
    PointElement,
    LineElement,
);


export const options = {
    animation: false,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            text: 'Error',
            display: true,
            color: '#4c4c4d',
            font: {
                size: 14,
                weight: 'bold',
                lineHeight: 1.2,
            },
        },
        datalabels: {
            display: true,
            color: "red",
            align: 'center',
            borderRadius: 50,
            labels: {
              title: {
                font: {
                  size: 6
                }
              },
            }
          },
      
    },
    scales: {
        x: {
            display: true,
            title: {
                display: true,
                text: '',
                color: '#4c4c4d',
                font: {
                family: "Arial",
                size: 10,
                weight: 'bold',
                lineHeight: 1.2,
                },
                padding: {top: 0, left: 0, right: 0, bottom: 0},
            }
        },

        y: {
            grace: "10%",
            type: 'linear',
            display: false,
            position: 'left',
            title: {
                display: true,
                align: 'end',
                text: 'Efficiency',
                color: '#4c4c4d',
                font: {
                family: "Arial",
                size: 10,
                weight: 'bold',
                lineHeight: 1.2
                },
                padding: {top: 0, left: 0, right: 0, bottom: 0}
            }
        }
    },
};