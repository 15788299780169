import { createTheme } from "@mui/material";

export const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sml: 600,
        sm: 700,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: '0.5rem 0.3125rem',
            '&:first-of-type': {
              paddingLeft: '2.5rem'
            },
            '&:last-of-type': {
              paddingRight: '2.5rem'
            }
          },
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            minHeight: 0
          },
        },
      },
    },

    palette: {
      primary: {
        main: '#47254B',
      },
      secondary: {
        main: '#f44336',
      },
    },
});

theme.typography.h1 = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  // '@media (min-width:600px)': {
  //   fontSize: '3.5rem',
  // },
  // [theme.breakpoints.up('md')]: {
  //   fontSize: '2.4rem',
  // },
};

theme.typography.subtitle1 = {
  fontSize: '1.125rem',
  fontWeight: 'bold'
};
theme.typography.subtitle2 = {
  fontSize: '1rem',
  fontWeight: 'bold'
};

theme.typography.h5 = {
  fontSize: '1.3rem',
  fontWeight: 'normal'
};

//h6 === subtitle3
theme.typography.h6 = {
  fontSize: '0.875rem',
  fontWeight: 'bold'
};
//h7 === subtitle4
theme.typography.h7 = {
  fontSize: '0.75rem',
  fontWeight: 'bold'
};
theme.typography.body1 = {
  fontSize: '1rem',
  fontWeight: 'normal'
};
theme.typography.body2 = {
  fontSize: '0.875rem',
  fontWeight: 'normal'
};
theme.typography.caption = {
  fontSize: '0.75rem',
  fontWeight: 'normal'
};
theme.typography.overline = {
  fontSize: '0.75rem',
  fontWeight: 'normal'
};

