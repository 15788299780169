import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { numeralFormatter } from '../../utils/numeralFormatter';

ChartJS.register(
  BarElement,
);




export default function VariableHistogramComparison({metrics, plots}) {
  const options = {
    layout: {
      padding: {
        top: 25, // Adjust the value as per your desired padding
      },
    },
    plugins: {
      title: {
        display: false,
        text: '',
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => {
          return numeralFormatter(value)
        }
      },
  
    },
    responsive: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        grace: '30%',
        display: false,
        stacked: true,
      },
    },
  };
  const variables = Object.values(metrics);

  const labels = variables[0].map( value => value.dmu_id);
  const data = {
    labels,
    datasets: [
      {
        label: "Segments",
        data: variables[0].map( value => (
          plots === 'current' ? value.actual_value 
          : plots === 'target' ? value.target 
          : plots === 'change_current_benchmark' ? value.change_current_benchmark 
          : value.progress_current_target 
        )),
        backgroundColor: [colors.segment1, colors.segment2,colors.segment3, colors.segment4, colors.segment5]
      },
    ],
  };
  return <Bar options={options} data={data} height={200} />;
}
