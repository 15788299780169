import { Button, DialogActions } from '@mui/material'
import React from 'react'

export default function EditProcessing({ handleClose }) {
    
  return (
    <DialogActions>
        <Button onClick={handleClose} color='primary' variant='contained' autoFocus>OK</Button>
    </DialogActions>
  )
}
