import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { numeralFormatter } from '../../../utils/numeralFormatter';


const NoPeriodTopEfficiencyTable = ({api}) => {
    // const selectedProps = ["dmu_id",`${ value ? "base_period_value" : "last_period_value"}`,"output_value", `${value ? 'pct_impr_benchmark_vs_period' : 'pct_impr_period_vs_period'}`,];
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Entity</TableCell>
          <TableCell>Efficiency</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {api?.results?.slice(0, 5).map((row, index) => (
          <TableRow key={index}>
            <TableCell sx={{width: '25%'}}>{row.dmu_id}</TableCell>
            <TableCell sx={{width: '25%'}}>{numeralFormatter(row.efficiency * 100)} %</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default NoPeriodTopEfficiencyTable;
