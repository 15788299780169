import { Box, Divider, Grid, IconButton, styled, Table, TablePagination, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import { BaseMiniCard } from '../../components/common/commonContainer'
import StatusBanner from '../../components/common/statusBanner';
import { ReactComponent as QuestionCircle }  from "../../assets/icons/datasets/question_Circle.svg"
import { ReactComponent as BlueIcon }  from "../../assets/icons/datasets/Blue_Icon.svg"
import HomeDatasetsTable from './homeDatasets/homeDatasetsTable';
import { EnhancedTableHead } from '../../components/common/table/tableHead';
import { datasetsHeadCells } from '../datasets/datasetsHeadcells';
import { useState } from 'react';
import { ProjectContainer } from './homeProject';



export const DatasetBoxLayout = styled(Box) ({
    display: 'flex',
    flexFlow: 'row wrap',
})

export const DatasetContentBox = styled(DatasetBoxLayout) ({
    flexFlow: 'column'
});

export const DatasetInfoBox = styled(DatasetBoxLayout) ({
    alignItems: 'center',
});


export const DividerBox = styled(DatasetBoxLayout) ({
    flexFlow: 'column wrap',
});

export default function DatasetsCards(props) {
    const {datasets, tab, page, rowsPerPage, rowsPerPageOptions, filteredDataset, count, onPageChange, onRowsPerPageChange} = props
    const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

    
	const [selected, setSelected] = useState([]);

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};



	const isSelected = (name) => selected.indexOf(name) !== -1;



    return (
        <ProjectContainer>
            {tab === 1 ? 
                <Grid container spacing={1}>
                
                    {/*Slice datasets to show only the ones in the current page*/
                    /* Create one card pr record in slice */}
                {filteredDataset?.slice(0, page * rowsPerPage + rowsPerPage).map((content, index) => (
                    <Grid item xs={12} sm={6} md={3} xl={3} key={index}>
                    <BaseMiniCard key={index}>
                        <BlueIcon/>
                        <DatasetContentBox sx={{gap: '0.7rem'}}>
                            {/*DISPLAY FILE NAME */}
                                <DatasetInfoBox sx={{flexFlow: 'nowrap', maxHeight: '50px', marginTop: '15px', overflow: "hidden", textOverflow: "ellipsis"}}>
                                    <Link to={'/datasetDetails?dataset_id=' + content.dataset_id} style={{ color: 'initial', textDecoration: 'none' }}>
                                        <Typography variant="subtitle1"  sx={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                            {content.dataset_name}
                                        </Typography>
                                    </Link>
                                    {/* <Box sx={{ overflow: "hidden", textOverflow: "ellipsis"}}>
                                        <Typography variant="h6" color="initial" sx={{ overflow: "hidden", textOverflow: "ellipsis"}}>{content.filename}</Typography>
                                    </Box> */}
                                </DatasetInfoBox>
                                <Box sx={{display: 'flex', flexFlow: 'row'}}>
                                    <StatusBanner status={content.status}/>
                                    <Tooltip title="Help Menu">
                                        <IconButton
                                            size="small"
                                            sx={{ ml: 1, p: '0 4px' }}
                                        >
                                            <QuestionCircle/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Box sx={{ display: 'flex', gap: '2rem', textOverflow: "ellipsis", overflow: 'hidden' }}>
                                    {/*DISPLAY NUM OF RECORDS */}
                                    < DatasetInfoBox>
                                        <Box>
                                            <Typography variant="subtitle1" color="initial">{content.rows}</Typography>
                                            <Typography variant="body2" color="#8993A3">Records</Typography>
                                        </Box>
                                    </DatasetInfoBox>
                                    <Divider variant="fullWidth" orientation="vertical" flexItem/>
                                    {/*DISPLAY NUM OF COLUMNS */}
                                    <DatasetInfoBox>
                                        <Box>
                                            <Typography variant="subtitle1" color="initial">{content.columns}</Typography>
                                            <Typography variant="body2" color="#8993A3">Variables</Typography>
                                        </Box>
                                    </DatasetInfoBox>
                                </Box>
                        </DatasetContentBox>
                    </BaseMiniCard>
                    </Grid>
                )
                )}
                </Grid>
                :

            
                <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        headCells={datasetsHeadCells}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={filteredDataset.length}
                    />
                    <HomeDatasetsTable
                        isSelected={isSelected}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        filteredData={filteredDataset}
                    />
                </Table>
            }
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '20px'}}>
                {/* DATASET LIST PAGINATION */}
                {datasets && datasets.count !== undefined && <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />}
            </Box>
        </ProjectContainer>
    )
}