import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  MainCard,
  ShadowCardContentContainer,
} from "../../../components/common/commonContainer";
import EmptyCardMessage from "../../../components/common/emptyCardMessage";
import ComparisonResultsContent from "./comparisonResults/comparisonResultsContent";
import { useState } from "react";
import { useEffect } from "react";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import FilterSidebar from "../../../components/common/filtersSidebar";
import {
  getEntitiesOptions,
  getPeriodObject,
  getPeriodOptions,
} from "./comparisonResults/services/comparisonResultsDashboardServices";
import { useComparisonResultsDashboardStore } from "../../../store/store";

export default function ComparisonResultsDashboard({
  benchmark_id,
  project_id,
  projectType,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const [entities, setEntities] = useState();
  // const [selectedValues, setSelectedValues] = useState([]);
  const [periodObject, setPeriodObject] = useState([]);
  const { selectedValues, setSelectedValues } =
    useComparisonResultsDashboardStore();

  const selectedEntities =
    entities?.results?.filter((entity) =>
      selectedValues.includes(entity.dmu_id)
    ) || [];

  const [filterFields, setFilterFields] = useState([
    {
      key: "period",
      title: "Period",
      label: "",
      name: "Period",
      value: "",
      type: "select",
      options: [{ label: "", value: "" }],
    },
    {
      key: "orient",
      title: "Orient",
      label: "",
      name: "Orient",
      value: "",
      type: "",
      options: [
        { label: "Input", value: "input" },
        { label: "Output", value: "output" },
      ],
    },
  ]);
  //Filter Values
  const periodValue = filterFields[0].value;
  const orientValue = filterFields[1].value;

  useEffect(() => {
    if (projectType === "input_output_independent") {
      filterFields[1].type = "select";
      filterFields[1].label = "Input";
      filterFields[1].value = "input";
    }
  }, [projectType]);

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //API AND RANK DATA FETCH
    Promise.all([
      getPeriodOptions({ project_id, benchmark_id }),
      getEntitiesOptions({ benchmark_id }),
    ])
      .then(([periodOptions, entitiesOptions]) => {
        setFilterFields((prevFields) => {
          return prevFields.map((field) => {
            if (field.key === "period") {
              const defaultOption =
                periodValue && periodValue !== ""
                  ? periodValue
                  : periodOptions[periodOptions?.length - 1]?.value;
              return {
                ...field,
                options: [...periodOptions],
                value: defaultOption,
              };
            }
            return field;
          });
        });
        setEntities(entitiesOptions);

        return getPeriodObject({ project_id, benchmark_id, periodValue });
      })
      .then((periodObjectData) => {
        if (periodValue === "All") {
          setPeriodObject({ period: "All" });
        } else {
          setPeriodObject(periodObjectData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [project_id, benchmark_id, periodValue, orientValue]);

  const periodOptions = filterFields[0].options;

  const handleChange = (event, values) => {
    if (values.length <= 5) {
      const newSelectedValues = values.map((option) => option.dmu_id);
      setSelectedValues(newSelectedValues);
    }
  };

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <MainCard
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </MainCard>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: "100%" }}>
        <MainCard
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          Error: {error.message}
        </MainCard>
      </Box>
    );
  }

  return (
    <MainCard>
      <ShadowCardContentContainer
        sx={{ display: "flex", flexFlow: "row wrap" }}
      >
        {filterFields[0]?.options?.length <= 1 ? (
          <FilterSidebar
            filterFields={filterFields}
            setFilterFields={setFilterFields}
          />
        ) : (
          <FilterSidebar
            filterFields={filterFields}
            setFilterFields={setFilterFields}
          />
        )}
        <Box sx={{ width: '30%', maxWidth: '700px', minWidth: '200px' }}>
          <Typography variant="h6" color="initial">
            Select entities to compare:
          </Typography>
          <Autocomplete
            multiple
            options={entities?.results || []}
            limitTags={5}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.dmu_id}
            onChange={handleChange}
            value={selectedEntities}
            renderOption={(props, option, { selected }, index) => {
              return (
                <li {...props}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`autocomplete-checkbox-${option?.dmu_id}`}
                        name={`autocomplete-checkbox-${option?.dmu_id}`}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        disabled={selectedValues.length >= 5 && !selected}
                      />
                    }
                    label={option?.dmu_id}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  />
                </li>
              );
            }}
            
            renderInput={(params) => (
              <TextField
                id="autocomplete-input"
                name="autocomplete-input"
                {...params}
                placeholder="You can select a maximum of 5 entities:"
              />
            )}
          />
        </Box>
        {periodObject?.period === "All" ? null : (
          <>
            <Divider orientation="vertical" flexItem />
            {filterFields[0]?.options?.length <= 1 ? null : (
              <Box sx={{ display: "flex", flexFlow: "column" }}>
                <Typography variant="h5" color="initial">
                  {periodObject?.period}
                </Typography>
                <Typography variant="body2" color="initial">
                  Current Period
                </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <Typography variant="h5" color="initial">
                {periodObject?.benchmark_period}
              </Typography>
              <Typography variant="body2" color="initial">
                Benchmark Period
              </Typography>
            </Box>
          </>
        )}
      </ShadowCardContentContainer>
      <ShadowCardContentContainer>
        {selectedValues.length !== 0 ? (
          <ComparisonResultsContent
            selectedValues={selectedValues}
            periodObject={periodObject}
            benchmark_id={benchmark_id}
            periodOptions={periodOptions}
            orientValue={orientValue}
          />
        ) : (
          <EmptyCardMessage />
        )}
      </ShadowCardContentContainer>
    </MainCard>
  );
}
