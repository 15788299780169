import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { TableLink } from '../../../../components/common/styledLinks';
import SegmentationKebabMenu from './segmentationKebabMenu';
import StatusBanner from '../../../../components/common/statusBanner';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
  export default function ProjectHomeSegmentationTableContent(props) {

    const { order, orderBy, handleClick, isSelected, page, rowsPerPage, filteredData, setDeletedID } =
    props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length);

    return (
      <TableBody>
        {stableSort(filteredData, getComparator(order, orderBy))
        .slice(0, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.segmentation_name);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleClick(event, row.segmentation_name)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              //key={row.title}
              selected={isItemSelected}
            >
              <TableCell
              id={labelId}
              scope="row"
              sx={{width: '30%'}}
              >
                {
                  row.status === 'processing' || row.status === 'new' || row.status === 'saved' ?
                  <Box sx={{display: 'flex', flexFlow: 'column'}}>
                    <Typography variant="body2" color="initial"> {row.segmentation_name}</Typography>
                    <Typography variant="body2" color="gray">ID: {row.segmentation_scenario_id}</Typography>
                  </Box> 
                  :
                  <TableLink to={'/segmentationScenarioDetails?project_id=' + row.project_id + '&segmentation_scenario_id=' + row.segmentation_scenario_id}>
                    <Box sx={{display: 'flex', flexFlow: 'column'}}>
                      <Typography variant="body2" color="initial"> {row.segmentation_name}</Typography>
                      <Typography variant="body2" color="gray">ID: {row.segmentation_scenario_id}</Typography>
                    </Box> 
                  </TableLink>
                }
              </TableCell>
              <TableCell align="left" sx={{width: '15%'}}>
                {row.number_of_clusters}
              </TableCell>
              <TableCell align="left" sx={{display: {xs: 'none', md: 'table-cell'}, width: '15%'}}>{row.segmentation_method}</TableCell>
              <TableCell align="left" sx={{display: {xs: 'none', md: 'table-cell'}, width: '15%'}}>{row.last_update}</TableCell>
              <TableCell align="left"><StatusBanner status={row.status}/></TableCell>
              <TableCell align="right">
                <SegmentationKebabMenu
                  scenario_id={row.segmentation_scenario_id}
                  setDeletedID={setDeletedID}
                  status={row.status}
                /></TableCell>

            </TableRow>
          );
        })}
        {emptyRows > 0 && (
        <TableRow
          style={{
          height: (props.dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={7} />
        </TableRow>
      )}
    </TableBody>
  )
}
