import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { get_new } from "../../utils/httpClient";
import ResultsTable from "../../views/results/resultsTable";
import {
  DetailsContainer,
  DetailsTable,
} from "../../components/common/commonContainer";
import FilterSidebar from "../../components/common/filtersSidebar";
import MainUpperBar from "../../components/common/mainUpperBar";
import EmptyCardMessage from "../../components/common/emptyCardMessage";

export const headCells = [
  {
    id: "publishResults",
    numeric: false,
    disablePadding: true,
    label: "Publish Results ID",
  },
  {
    id: "projectName",
    numeric: true,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "lastUpdate",
    numeric: true,
    disablePadding: false,
    label: "Last Update Date",
  },
  {
    id: "publishDate",
    numeric: true,
    disablePadding: false,
    label: "Publish Date",
  },
];

export default function ResultHome(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  //PUBLISHED RESULTS HANDLE TABLE PAGINATION
  const isSmallScreen = useMediaQuery("(max-width:1536px)");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(isSmallScreen ? 4 : 8);

  const rowsPerPageOptions = isSmallScreen ? [4, 8, 12] : [4, 8, 12, 16];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // //Search bar PUBLISHED RESULTS Value handler
  // const [searchSegmentation, setSearchSegmentation] = useState('')
  // const searchHandler = (event) => {
  //   setSearchSegmentation(event.target.value)
  //   }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
    setRowsPerPage(newDefaultRowsPerPage);
  }, [isSmallScreen]);

  const [filterFields, setFilterFields] = useState([
    {
      key: "searchScenario",
      title: "Search",
      label: "Search Scenario",
      name: "searchScenario",
      value: "",
      type: "text",
    },
    {
      key: "objective",
      title: "Objective",
      label: "objective",
      name: "Objective",
      value: "",
      type: "select",
      placeHolder: "",
      options: [
        { label: "All", value: "" },
        { label: "Input Based", value: "input" },
        { label: "Output Based", value: "output" },
        {
          label: "Input & Output independently",
          value: "input_output_independent",
        },
        {
          label: "Input & Output simultaneously",
          value: "input_output_together",
        },
      ],
    },
  ]);

  const [delayedSearchTerm, setDelayedSearchTerm] = useState("");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDelayedSearchTerm(filterFields[0].value);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterFields[0].value]);

  const searchValue = delayedSearchTerm;
  const objectiveOption = filterFields[1].value;

  useEffect(() => {
    setLoading(true);
    setError(null); // Clear any previous errors
    get_new(
      `/project/benchmarks/?status=published&page_size=${rowsPerPage}&page=${
        page + 1
      }&search=${searchValue}&orient=${objectiveOption}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve API data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state
        setLoading(false);
      });
  }, [page, rowsPerPage, searchValue, objectiveOption]);

  console.log("data results", data.results);

  return (
    <Box>
      <MainUpperBar UpperTitle={"Published Results"} backLink={"/"} />
      <DetailsContainer>
        <FilterSidebar
          filteredData={data.results}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
        />
        <DetailsTable sx={{ display: "flex", justifyContent: "center" }}>
          {loading ? (
            <CircularProgress />
          ) : error ? ( // Render error component when error exists
            <div>Error: {error.message}</div>
          ) : data && data.count > 0 ? (
            <ResultsTable
              filteredData={data.results}
              page={page}
              count={data.count}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <EmptyCardMessage text1={"Results"} text2={"Projects"} />
          )}
        </DetailsTable>
      </DetailsContainer>
    </Box>
  );
}
