import React from 'react'
import { Box, CardContent } from '@mui/material'
import { styled } from '@mui/system'
import About from '../about'
import InputOutputMetrics from '../inputOutputMetrics'
import Objective from '../objective'
import VariableTreatment from '../variableTreatment'
import TargetValues from '../targetValues'
import Scale from '../scale'
import { Route, Routes } from 'react-router-dom'


export const KnowledgeCardsContent = styled(CardContent)({
  display: 'flex', 
  flexFlow: 'column nowrap', 
  gap: '15px',
  padding: '25px'
})


export default function KnowledgeBaseContent() {
  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '75%'}}>
      <Routes>
        <Route index element ={<About/>}/>
        <Route path="about" element={<About/>} />
        <Route path="inputOutputMetrics" element={<InputOutputMetrics/>} />
        <Route path="objective" element={<Objective/>} />
        <Route path="variableTreatment" element={<VariableTreatment/>} />
        <Route path="scale" element={<Scale/>} />
        <Route path="targetValues" element={<TargetValues/>} />
        <Route path="*" element={<p>This page doesn't exist</p>} />
      </Routes>
    </Box>
  )
}
