import { Box, CircularProgress, Divider, Tabs, Typography } from '@mui/material'
import React from 'react'
import { GraphContainer, GraphMainCard } from '../../../../components/common/commonContainer'
import EfficiencyDistributionPerBinGraph from '../../../../graphs/aggregatedResults/efficiencyDistributionPerBinGraph'
import EfficiencyFrontierGraph from '../../../../graphs/aggregatedResults/efficiencyFrontierGraph'
import { useEffect } from 'react'
import { get_new } from '../../../../utils/httpClient'
import { useState } from 'react'
import { SmallRoundTab } from '../../../../components/common/tabsDesign'

export default function EfficiencyFrontier({periodObject, baseSegment, benchmark_id, orientVaue}) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);


  //EFFICIENCY FRONTIER DATA
  const [efficiencyFrontierData, setEfficiencyFrontierData] = useState([])

  //EFFICIENCY DISTRIBUTION PER BIN DATA
  const [efficiencyDistrData, setEfficiencyDistrData] = useState([])

  const setLoadingState = (isLoading) => {
    setLoading(isLoading);
  };
  
  // Usage: setLoadingState(true) to set loading state, setLoadingState(false) to clear loading state


  const fetchData = (url, successCallback) => {
    setLoadingState(true);
    setError(null); // Clear any previous errors
    get_new(url)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        successCallback(data);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state

      })
      .finally(() => {
        setLoadingState(false);
      });
    };

    useEffect(() => {
      fetchData(
        `/project/benchmark/results/dmu/frontier/?benchmark_id=${benchmark_id}&period=${periodObject.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`,
        (data) => setEfficiencyFrontierData(data.results)
      );
      fetchData(
        `/project/benchmark/results/segment/efficiency-bins/?benchmark_id=${benchmark_id}&period=${periodObject.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`,
        (data) => setEfficiencyDistrData(data.results)
      )
    }, [benchmark_id, periodObject.period, baseSegment, orientVaue])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
};

  return (
    <Box sx={{width: '100%'}}>
      {loading ?
        (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
            <CircularProgress />
        </Box>
        ) : error ? ( // Render error component when error exists
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
              Error: {error.message}
            </Box>
        ) :
          <GraphMainCard >
            <GraphContainer sx={{ width: '100%'}}>
              <EfficiencyFrontierGraph api={efficiencyFrontierData} />
            </GraphContainer>
            <GraphContainer sx={{ width: '100%', height: '300px', flexFlow: 'column nowrap'}} >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps= {{sx: {display: 'none'}}}
                sx={{alignSelf: 'flex-end', p: '10px'}}
              >
                <SmallRoundTab label={<Typography variant="caption" >Value</Typography>}/>
                <SmallRoundTab label={<Typography variant="caption" >Percent</Typography>} />
              </Tabs>

              {selectedTab === 0 && (
                <EfficiencyDistributionPerBinGraph value={0} api={efficiencyDistrData}/>
              )}
              {selectedTab === 1 && (
                <EfficiencyDistributionPerBinGraph value={1} api={efficiencyDistrData}/>
              )}  
            </GraphContainer>
          </GraphMainCard>
      }
    </Box>
  )
}
