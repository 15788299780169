import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

const ProgressBar = styled(LinearProgress) ({
    height: '9px',
    borderRadius: '50px',
    backgroundColor: '#DDDDDD',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#1AB759',
    },
})

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end', flexFlow: 'column', justifyContent: 'center'}}>
      <Box sx={{ width: '100%' }}>
        <ProgressBar variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${ isNaN(props.value) ? 0 : Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel(props) {

  return (
    <Box sx={{ width: '60%', display: {xs: 'none', md: 'block'} }}>
      <LinearProgressWithLabel value={props.value} />
    </Box>
  );
}
