import React from 'react';
import { Line } from 'react-chartjs-2';
import { options } from '../graphProps/segmentation'
// import { decToPer } from '../App';
import colors from '../../assets/variables.scss'
import { numeralFormatter } from '../../utils/numeralFormatter';



export default function AvgEfficiencyBySegmentPP({tabIndex, periodObject, previousPeriod, api }) {

  const tittle = {
    plugins: {
      title: {
        text: `Avg. Efficiency By Segment (p/${tabIndex ? "b" :"p"})`,
        display: true,
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      datalabels: {
        display: true,
        color: "white",
        align: 'center',
        borderRadius: 50,
        labels: {
          title: {
            font: {
              size: 10
            }
          },
        },
        formatter: (value) => {
          return `${numeralFormatter(value)} %`
        }
      },
    }
  }

  const options151 = Object.assign({}, options, tittle)
  const labels = api && [(tabIndex === 0 ? previousPeriod.period : periodObject.benchmark_period), periodObject.period];
  const data = {
    labels,
    datasets: api?.map((value, index) => ({
      label: `segment ${value.assignment__cluster__cluster_cd}`,
      data: tabIndex === 0 ?
      [((value?.avg_efficiency - (value?.pct_improv_prev_period) ) * 100), (value?.avg_efficiency * 100)]
      :
      [((value?.avg_efficiency - (value?.pct_improv_benchmark_period) ) * 100), (value?.avg_efficiency * 100)],
      borderColor: colors[`segment${index + 1}`],
      backgroundColor: colors[`segment${index + 1}`],
      yAxisID: 'y',
      datalabels: {
        backgroundColor: colors[`segment${index + 1}`]
      }
    }))
   };



  return <div style={{ height: "85%", width: '90%' }}>
    <Line options={options151} data={data} />
  </div>
}
