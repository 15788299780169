import { get_new } from "../../../../../utils/httpClient"

export const getInputData = ({ benchmark_id, periodObject, orientValue }) => {
  const url = `/project/benchmark/results/metrics/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&orient=${orientValue}&benchmark_var__variable_type=input`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Input data:', error);
    throw error;
  })
};

export const getOutputData = ({ benchmark_id, periodObject, orientValue }) => {
    const url = `/project/benchmark/results/metrics/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&orient=${orientValue}&benchmark_var__variable_type=output`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data.results)
    .catch((error) => {
      console.error('Error fetching Output data:', error);
      throw error;
    })
  };

  export const getTotalMetricsData = ({ benchmark_id, periodObject, orientValue }) => {
    const url = `/project/benchmark/results/metrics/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&orient=${orientValue}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data.results)
    .catch((error) => {
      console.error('Error fetching Total Metrics data:', error);
      throw error;
    })
  };