import React, { useEffect } from 'react';
import {  styled, Table, TableContainer, TablePagination } from '@mui/material';
import { EnhancedTableHead } from '../../../../components/common/table/tableHead';
import { benchmarkScenariosHeadCells } from './benchmarkProjecthomeHeadcells';
import ProjectHomeBenchmarkTableContent from './benchmarkProjectHomeTableContent';


export const ProjectContainer = styled(TableContainer) ({
   flex: 1
});


export default function BenchmarkProjectHomeTable(props) {
  const { filteredData, setDeletedID, page, rowsPerPage, onPageChange, onRowsPerPageChange, count } = props
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        setSelected(newSelected);
      };


    
    const isSelected = (name) => selected.indexOf(name) !== -1;

  //   useEffect(() => {
  //     setPage(0); // Reset page to 0 when filtered data is empty
  // }, [filteredData]);
  
  return (
    <ProjectContainer>
        <Table
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headCells={benchmarkScenariosHeadCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredData.length}
          />
          <ProjectHomeBenchmarkTableContent
            orderBy={orderBy}
            handleClick={handleClick}
            isSelected={isSelected}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredData={filteredData}
            setDeletedID={setDeletedID}
          />
        </Table>
        <TablePagination
          rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />

    </ProjectContainer>
  )
}
