import { CloseRounded } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, OutlinedInput } from '@mui/material'
import React, { useState } from 'react'
import { LabelGrid, ValueGrid } from './benchmarkScenarios/menuOptions/manualSettings'
import { patch_json } from '../../../utils/httpClient'
import { enqueueSnackbar } from 'notistack'

export default function EditProjectDetails({open, onClose, projectData, setProjectData, setFieldChange}) {

    //intitialize formProjectData with actualData
    const [formProjectData, setFormProjectData] = useState({...projectData, })
  
    //Create an object with summaryData adapted to the props that api needs to receive
    const inititalSummaryData = {...projectData}

    //Initialize empty object to store only data values that changes between sumaryData and formBenchmarkData
    const editedData = {}

    // Compare props values between obj1 and obj2 and generate 2 arrays with props names with equal props values 
    // and another with differents props values
    const compareProps = (obj1, obj2) => {
        const samePropsValues = [];
        const differentPropsValues = [];
        for (const prop in obj1) {
            if (prop in obj2) {
                if(typeof obj1[prop] === 'object' && typeof obj2[prop] === 'object') {
                    JSON.stringify(obj1[prop] ) === JSON.stringify(obj2[prop]) ?
                    samePropsValues.push(prop)
                    :
                    differentPropsValues.push(prop)
                } else if (obj1[prop] === obj2[prop]) {
                    samePropsValues.push(prop);
                } else {
                    differentPropsValues.push(prop);
                }
            }
        }
      
        return {
          samePropsValues,
          differentPropsValues,
        };
    };
    const comparisonResult = compareProps(inititalSummaryData, formProjectData);

    //set differnt props to editedData object and execute action
    const compareForm = (action, defaultValues) => {
        if(action !== 'edit') return;

        if(comparisonResult.differentPropsValues.length > 0) {
            comparisonResult.differentPropsValues.forEach((prop) => {
                editedData[prop] = formProjectData[prop];
            });
            handleAction(action, editedData)
        } else {
            console.log("No changes to save");
        }        
        onClose()
    }

        /*Handle create benchmark scenario */
        const handleAction = async (action, editObject) => {
            //Target URL for posting form
            const targetURL = '/project/';
                    let edit = {
                    action: 'edit',
                    project_id: projectData.project_id,
                    ...editObject
                }
                setFieldChange(false)
    
                patch_json(targetURL, edit)
                .then((response) => {
                    console.log('response', response);
                    if (!response.ok) {
                        throw new Error(response.status + response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setFieldChange(true)
                    console.log('Your changes have been successfully saved');
                    enqueueSnackbar('Your changes have been successfully saved for PROJECT ID: ' + projectData.project_id, { variant: 'info' }); 
                    // handleCloseDialog();
                })
                .catch((error)=>{
                    console.log('Project Details action failed');
                    console.log(error)
                    if(error.message.includes('409')){
                        enqueueSnackbar('Sorry, this name has already been taken.', { variant: 'error' }); 
                    } else {
                        enqueueSnackbar('Error Saving your data',{ variant: 'error' }); 

                    }
 
                })
        };
        const changeFormBenchmarkData = (name, newValue) => {
            const newForm = {
                ...formProjectData,
                ...{
                    [name]: newValue,
                },
            };
            setFormProjectData(newForm);        
        };
  return (
    <Dialog 
    open={open} 
    onClose={onClose}
    lg="true"
    fullWidth={true}
    >
        <DialogTitle
            sx={{ display: 'flex', background: '#47254b', color: 'white', justifyContent: 'space-between', alignItems: 'center'}}
        >
            Edit Project
            <IconButton onClick={onClose} sx={{color: 'white'}}>
                <CloseRounded color='white'/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={1} marginY={2} fontSize={12}>
            <LabelGrid>Name: </LabelGrid>
            <ValueGrid  required={true}>
                <FormControl sx={{width: '70%'}}>
                    <OutlinedInput 
                        id="component-outlined"
                        defaultValue={formProjectData?.project_name || ''}
                        placeholder='Please Write a Project Name'
                        onChange={(event) => changeFormBenchmarkData('project_name', event.target.value)}
                        sx={{fontSize: '12px',
                            '& .MuiOutlinedInput-input': {p:'5px'}
                        }}
                    />
                </FormControl>                 
            </ValueGrid>
            <LabelGrid> Description: </LabelGrid>
            <ValueGrid>
                <FormControl sx={{width: '70%'}}>
                    <OutlinedInput 
                        id="component-outlined"
                        value={formProjectData.project_desc}
                        rows={5}
                        multiline
                        onChange={(event) => changeFormBenchmarkData('project_desc', event.target.value)}
                        sx={{fontSize: '12px', 
                            p: '5px'
                        }}
                    />
                </FormControl>  
            </ValueGrid>
        </Grid>
        </DialogContent>
        <DialogActions>
            {/* <Button color='primary' variant='text' autoFocus onClick={handleClose} >
                Cancel
            </Button> */}
            <Button disabled={formProjectData.project_name === ''} onClick={() => { compareForm('edit', projectData) }} color='primary' variant='text' autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog>
  )
}
