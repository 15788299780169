import React from 'react';
import { Bar } from 'react-chartjs-2';
import { options } from '../../graphProps/segmentation'
// import { decToPer } from '../../App';
import colors from '../../../assets/variables.scss'
import { GraphContainer } from '../../../components/common/commonContainer';
import { numeralFormatter } from '../../../utils/numeralFormatter';
import NoPeriodTopEfficiencyTable from './topEfficiencyTable';


export default function NoPeriodTopEfficiencyChange({ api}) {

  const title = {
    plugins: {
      title: {
        text: `Top 5 Entities - Efficiency`,
        display: true,
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      subtitle: {
        display: false,
        text: 'Custom Chart Subtitle'
      },
      datalabels: {
        display: true,
        color: "white",
        align: 'center',
        borderRadius: 50,
        labels: {
          title: {
            font: {
              size: 10
            }
          },
        },
        formatter: (value) => {
          return `${numeralFormatter(value)} %`
        }
      },
      legend: {display: false}
    }
  }

  const topResults = api?.results?.slice(0,5)
  const option135 = Object.assign({}, options, title)
  const labels = topResults?.map(value => value.dmu_id)

  const data = {
    labels,
    datasets: [{
        label: '',
        data: topResults?.map(value => value.efficiency * 100),
        borderColor: [colors.entity1, colors.entity2, colors.entity3, colors.entity4, colors.entity5],
        backgroundColor: [colors.entity1, colors.entity2, colors.entity3, colors.entity4, colors.entity5],
    },]
  };

  return <GraphContainer sx={{alignItems: 'center', m: 0, p: '5px', width: '-webkit-fill-available', flexFlow: 'column'}}>
    <div style={{ height: "250px", width: '80%' }}>
      <Bar options={option135} data={data} />
    </div>
    <NoPeriodTopEfficiencyTable api={api} />
  </GraphContainer>
}