import { get_new } from "../../../../../utils/httpClient"

export const getMetricsApi = ({ benchmark_id, periodObject, encodedSelectedOption, type, orientValue }) => {
  const url = `/project/benchmark/results/dmu/metrics/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&dmu_id=${encodedSelectedOption}&benchmark_var__variable_type=${type}&orient=${orientValue}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => (prevData) => [...prevData,  data ])
  .catch((error) => {
    console.error('Error fetching Metrics Api data:', error);
    throw error;
  })
};
