import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import NoPeriodTopEfficiencyChange from '../../../../../graphs/aggregatedResults/noPeriodAvgEfficiencyImprovement/topEfficiency'
import NoPeriodBottomEfficiency from '../../../../../graphs/aggregatedResults/noPeriodAvgEfficiencyImprovement/bottomEfficiency'
import { useEffect } from 'react'
import { useState } from 'react'
import { getBottomEfficiencyData, getTopEfficiencyData } from './services/noPeriodAvgEfficiencyImprovementServices'

export default function NoPeriodAvgEfficiencyImprovementSection({baseSegment, benchmark_id, orientVaue}) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);

    //TOP EFFICIENCY CHANGE DATA
    const [topEfficiencyData, setTopEfficiencyData] = useState([])
    //BOTTOM EFFICIENCY CHANGE DATA
    const [bottomEfficiencyData, setBottomEfficiencyData] = useState([])
    
    //FETCH FUNCTION
    useEffect(() => {
        setLoading(true);
        //SCENARIO AND DMU DATA FETCH
        Promise.all([
          getTopEfficiencyData({ benchmark_id, baseSegment, orientVaue }),
          getBottomEfficiencyData({ benchmark_id, baseSegment, orientVaue }),
        ])
        .then(([topEfficiency, bottomEfficiency]) => {
          setTopEfficiencyData(topEfficiency);
          setBottomEfficiencyData(bottomEfficiency);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setError(error)
        })
        .finally(() => {
            setLoading(false);
        });
    }, [benchmark_id, baseSegment, orientVaue]);

  
  return (
    <Box sx={{gap: '10px', width: '100%', display: 'flex', flexFlow: 'column'}}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
        ) : error ? ( // Render error component when error exists
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
              Error: {error.message}
          </Box>
        ) :
        (
        <Box sx={{gap: '10px', display: 'flex', width: '100%', flexFlow: {xs: 'column nowrap', md: 'row nowrap'}, mt: '20px'}}>
          <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-around', flexFlow: 'column nowrap'}}>
            <NoPeriodTopEfficiencyChange api={topEfficiencyData}/>
          </Box>
          <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-around', flexFlow: 'column nowrap'}}>
            <NoPeriodBottomEfficiency api={bottomEfficiencyData}/>
          </Box>
        </Box>
      )}
    </Box>
  )
}
