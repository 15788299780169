import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { get_new } from '../../utils/httpClient';
import EfficiencyQuadrantTable from '../../views/results/dashboard/aggregatedResultsTabs/tables/efficiencyQuadrantTable';



export const options = {
    maintainAspectRatio: false,
    plugins: {
        datalabels: {display: false},
        legend: {
          position: 'top',
          display: false
        },
        title: {
          display: true,
          text: 'Efficiency Quadrants',
          color: '#4c4c4d',
          font: {
            size: 14,
            weight: 'bold',
            lineHeight: 1.2,
          },    
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const efficiencyFrontierValues = [
                `Quadrant: ${context.raw.quadrant}`,
                `Entity: ${context.raw.dmu_id}`,
                `Efficiency: ${context.parsed.x.toFixed(2)}%`,
                `Outputs: ${context.parsed.y.toFixed(2)}`,
              ];
              return efficiencyFrontierValues
            }
          }
        },
      },
  scales: {
    x: {
      display: true,
      grid: {display: false},
      beginAtZero: true,
      max: 110,
      grace: '1%',
      title: {
        display: true,
        text: 'Efficiency %',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2,
        },
        padding: {top: 0, left: 0, right: 0, bottom: 0}
      }
    },
    y: {
      display: true,
      beginAtZero: true,
      grid: {display: false},
      afterFit: (ctx) => {
        ctx.width = 70
      },


      grace: '1%',
      title: {
        display: true,
        align: 'end',
        text: 'Aggregated Outputs',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2
        },
        padding: {top: 0, left: 0, right: 0, bottom: 0}
      }
    }
  }
};

export default function EfficiencyQuadrantsGraph({benchmark_id, periodObject, baseSegment, orientVaue}) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);

  const [api, setApi] = useState([])
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    setError(null); // Clear any previous errors

    get_new(`/project/benchmark/results/dmu/quadrant/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&assignment__cluster__cluster_cd=${baseSegment}&orient=${orientVaue}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve API data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const filteredData =  value === 1 ? data.results.filter(data => data.quadrant === 'Sluggish') 
        : value === 2 ? data.results.filter(data => data.quadrant === 'Leaders') 
        : value === 3 ? data.results.filter(data => data.quadrant === 'Laggards') 
        : value === 4 ?  data.results.filter(data => data.quadrant === 'Lean') 
        : data.results;
        setApi(filteredData.filter(value => value.pc_output !== null && value.pc_output !== undefined && value.efficiency !== null && value.efficiency !== undefined))
        setLoading(false); 
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state
        setLoading(false);

      });
  }, [value, periodObject?.period, benchmark_id, baseSegment, orientVaue]);

  function getColor(value) {
    if (value.x < 80 && value.y < 2) {
      return 'violet';
    } else if (value.x >= 80 && value.y < 2) {
      return '#4CB4E6';
    } else if (value.x >= 80 && value.y >= 2) {
      return '#8e0fa8';
    } else {
      return '#3265aa'
    }
  }

  const data = {
    datasets: [
      {
        label: 'Entity',
        data : api?.map( data => ({x: data.efficiency * 100, y: data.pc_output, dmu_id: data.dmu_id, quadrant: data.quadrant})),
        backgroundColor: (ctx) => {
          const value = {x: ctx?.parsed?.x, y: ctx?.parsed?.y};
          return getColor(value);
        },
      },
      {
        type:'line',
        label:'Horizontal line',
        data: [{x:0,y:2}, {x:100,y:2}],
        borderColor: colors.cluster1,
        backgroundColor: colors.cluster1,
        yAxisID: 'y',
        borderWidth: 1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
  
  
      },
      {
        type:'line',
        label:'Vertical line',
        data: [{x:80,y:0}, {x:80,y:12}],
        borderColor: colors.cluster1,
        backgroundColor: colors.cluster1,
        yAxisID: 'y',
        borderWidth: 1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
  
  
      }
  
    ],
  };

return <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexFlow: 'column'}}>
  {
    loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress/>
      </Box>
      ) : error ? ( // Render error component when error exists
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
            Error: {error.message}
        </Box>
      ) :
      <>
        <div style={{ width: '100%', height: '400px' }}>
          <Scatter options={options} data={data} height={300} width={500} className="segmentation" />
        </div> 
        <Tabs value={value} onChange={handleChange} sx={{mt: '20px'}}>
          <Tab label="All" />
          <Tab label="Sluggish" />
          <Tab label="Leaders" />
          <Tab label="Laggards" />
          <Tab label="Lean" />
        </Tabs>
        <div style={{ width: '80%', height: '400px', padding: '40px 0'}}>
          <EfficiencyQuadrantTable api={api} />
        </div>
      </>
    }
  </div>
}