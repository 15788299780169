import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import React from "react";
import { numeralFormatter } from "../../../../../utils/numeralFormatter";

export default function InformationVariableTable({ api }) {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          width: "100%",
          backgroundColor: "#F8F8FA",
          height: "100%",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <TableHead>
          <TableRow sx={{verticalAlign: 'bottom'}}>
            <TableCell>Period</TableCell>
            <TableCell>Base</TableCell>
            <TableCell>Current</TableCell>
            <TableCell>Target</TableCell>
            <TableCell colSpan={2} style={{ textAlign: "center" }}>
              Target vs base
              <TableRow
                sx={{
                  display: "flex",
                  justifyContent: 'space-around'
                }}
              >
                <TableCell
                  sx={{
                    border: "none",
                    '&:first-of-type': {
                      paddingLeft: '8px',
                      paddingBottom: '0'
                    }
                  }}
                >
                  Difference
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    whiteSpace: 'nowrap',
                    '&:last-of-type': {
                      paddingRight: '8px',
                      paddingBottom: '0'
                    }
                  }}
                >
                  % Change
                </TableCell>
              </TableRow>
            </TableCell>

            <TableCell colSpan={2} style={{ textAlign: "center" }}>
              Current vs base
              <TableRow
                sx={{
                  display: "flex",
                  justifyContent: 'space-around'
                }}
              >
                <TableCell
                  sx={{
                    border: "none",
                    '&:first-of-type': {
                      paddingLeft: '8px',
                      paddingBottom: '0'
                    }
                  }}
                >
                  Difference
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    whiteSpace: 'nowrap',
                    '&:last-of-type': {
                      paddingRight: '8px',
                      paddingBottom: '0'
                    }
                  }}
                >
                  % Change
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {api?.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.period}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {numeralFormatter(
                  row.actual_value - row.improv_benchmark_period
                )}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {numeralFormatter(row.actual_value)}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {numeralFormatter(row.target)}
              </TableCell>
              <TableCell
                sx={{
                  width: "12.5%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {numeralFormatter(row.diff_target_benchmark)}
              </TableCell>
              <TableCell
                sx={{
                  width: "12.5%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {numeralFormatter(row?.change_target_benchmark.toFixed(5) * 100)}%
              </TableCell>
              <TableCell
                sx={{
                  width: "12.5%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {numeralFormatter(row.diff_current_benchmark)}
              </TableCell>
              <TableCell
                sx={{
                  width: "12.5%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {numeralFormatter(row?.progress_current_target.toFixed(5) * 100)}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
