import React from "react";
import { Box, FormControl, MenuItem, Select, styled, TextField, Typography } from "@mui/material";
import { FilterBarContainer } from "./commonContainer";

export const FormControlStyle = styled(FormControl) ({
    margin: '10px',
    minWidth: '120px',
    maxWidth: '300px',
    width: '140px'
})


const MAX_HEIGHT = 10;

const styles = {
  menuPaper: {
    maxHeight: MAX_HEIGHT * 48 + 8, // 48 is the height of a MenuItem, plus 8px for padding
  },
  menuItem: {
    height: 48,
  },
};

export default function FilterSidebar ({ noMarginBottom, filterFields, setFilterFields, handleSubmit }) {

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterFields((prevFields) => {
      const updatedFields = prevFields.map((field) =>
        field.key === name ? { ...field, value } : field
      );
      return updatedFields;
    });
  };
  

  return (
    <FilterBarContainer noMarginBottom={noMarginBottom} onSubmit={handleSubmit}>
    <Box sx={{ display: 'flex', gap: '10px', flexFlow: 'row wrap' }}>
      {filterFields.map((field, index) => {
        if (field.type === "text") {
          return (
            <FormControlStyle key={field.key} sx={{ width: '240px' }}>
              <Typography variant="h6">{field.title}</Typography>
              <TextField
                name={field.key}
                value={field.value}
                onChange={handleFilterChange}
                variant="standard"
              />
            </FormControlStyle>
          );
        } else if (field.type === "select") {
          return (
            <FormControlStyle key={field.key}>
              <Typography variant="h6">{field.title}</Typography>
              <Select
                displayEmpty
                labelId={`${field.key}-label`}
                id={field.key}
                label={field.label}
                name={field.key}
                value={field.value}
                onChange={handleFilterChange}
                variant="standard"
                MenuProps={{
                  PaperProps: {
                    style: styles.menuPaper,
                  },
                }}
              >
                {field.options.map((option, optionIndex) => (
                  <MenuItem key={optionIndex} value={option.value} style={styles.menuItem}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControlStyle>
          );
        } else if (field.type === "date") {
          return (
            <FormControlStyle key={field.key} sx={{ width: '150px' }}>
              <Typography variant="h6">{field.title}</Typography>
              <TextField
                label={field.label}
                name={field.key}
                type="date"
                value={field.value}
                onChange={handleFilterChange}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </FormControlStyle>
          );
        } else {
          return null; // default to null if type doesn't match
        }
      })}
    </Box>
  </FilterBarContainer>
  );
};