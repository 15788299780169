import React, {useContext } from 'react';
import {
    TextField,
    Box,
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';


export default function DescriptionWS(props) {
   const  {
        tabValue, index, nameError = false, nameHelperText = '',
        setNameError = () => { },
        setNameHelperText = () => { }
    } = props;
    
    // Context state
    const { formData, changeFormData } = useContext(CreateBenchmarkContext);


    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <Box>
            <div><b>Name and Description</b></div>
            <br />
            <Box>Add a short name and description representing the objective and unique considerations of this 
            scenario. This will help you identify this scenario in the future.
             </Box>
            <br />
            <br />
            <TextField
                        id='name'
                        label="Name"
                        size="small"
                        sx={{ width:'450px' }}
                        variant="outlined"
                        required
                        error={nameError}
                        helperText={nameHelperText}
                inputProps={{ maxLength: 50 }} 
                        value={formData.name}
                        onChange={(event) => {
                                    if (nameError) {
                                        setNameError(false);
                                        setNameHelperText('');
                                    }
                                    changeFormData('name', event.target.value)
                                }}
                        />
                
                    <br />
                    <br />

                    <TextField
                        id='description'
                        label="Description"
                        size="small"
                        inputProps={{maxLength:500}}
                        variant="outlined"
                        fullWidth
                multiline rows={4}
                value={formData.description}
                        onChange={(event) => changeFormData('description', event.target.value)}
                />
                </Box>
                </TabPanel>
        </>
    )
}