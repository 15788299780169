import { Box, Typography, styled } from '@mui/material'

export const FilterBarContainer = styled('form')((props) => ({
    backgroundColor: '#fff',
    padding: '0px 40px',
    display: 'flex',
    alignItems:'center',
    flexFlow: 'row wrap',
    marginBottom: '15px',
    borderRadius: '0.25rem',
    border: '1px solid rgba(244, 244, 244, 1)',
    justifyContent: 'flex-start'
  }))

export const BaseCard = styled(Box) ({
    backgroundColor: "#FFFFFF",
    align: 'center',
    fontSize: '0.875',
    lineHeight: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25rem',
    paddingBottom: '1.5rem',
    boxShadow: '0px 4px 4px rgba(153, 153, 153, 0.15)',
})

export const MainCard = styled(BaseCard)(({theme}) => ({
    display: 'flex',
    margin: '0 0 15px 0',
    flexFlow:'column wrap',
    paddingTop: '1rem',
    width: '100%',
    minHeight: '15.5rem',
    paddingBottom: '0.5rem'
}));

export const BaseMiniCard = styled(BaseCard)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    filter: 'drop-shadow(6px 6px 5px #eae4e4)',
    border: '0.5px solid #dddddd',
    boxSizing: 'border-box',
    padding: '1rem',
    height: '100%',
    // height: '12.75rem',

    [theme.breakpoints.up("xl")]: {
        maxWidth: '25rem'
      },
}));

export const CardUpperContainer = styled(Box) ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    margin: '0px 0 12px 0',
    alignItems: 'center'
})

export const CardContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexFlow: 'row wrap',
    gap: '10px',
    width: '-webkit-fill-available',
    flexGrow: '1',

    [theme.breakpoints.down("md")]: {
        justifyContent: 'center',
    },
}))


export const ShadowCardContentContainer = styled(CardContentContainer) ({
    boxShadow: "4px 4px 8px rgba(151, 151, 151, 0.25)",
    border: '1px solid #E8E8E8',
    margin: '0.5rem 1rem',
    borderRadius: '6px',
    padding: '5px'
})

export const GraphContainer = styled(ShadowCardContentContainer) ({
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    alignItems: 'center',
})

export const GraphMainCard = styled(Box) ({
    display: 'flex', 
    justifyContent: 'space-around',
    margin: '20px 0',
    flexFlow: 'row wrap',
})

export const GrayBlock = styled(Box)({
    backgroundColor: '#F5F5F5',
    padding: '10px',
    borderRadius: '8px',
    margin: '0 1rem',
    width: '-webkit-fill-available'
})

export const PurpleBox = styled(Typography)({
    backgroundColor: 'rgba(124, 108, 170, 1)',
    borderRadius: '20px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 10px'
})

//Categories Home Sections
export const DetailsTable = styled(MainCard)({
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    width: '-webkit-fill-available',
})

export const DetailsContainer = styled(Box)({
    display: 'flex',
    flexFlow: 'column nowrap',
})