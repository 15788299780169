import React, { useEffect, useState, useContext } from 'react';
import {
	Box,
    Grid,
    Chip,
    Divider
} from '@mui/material';
//import Cookies from 'js-cookie';
import TabPanel from './tabPanel';
//import { ProjectContext } from '../../../../context/Project/ContextProject';
import { CreateBenchmarkContext } from './createBenchmarkContext';
import { post_json } from '../../../../../../utils/httpClient';
//import { red } from '@mui/material/colors';



export default function SummaryWS({ tabValue, index }) {
    //local state 
    
    // Context state
    const { formData, changeFormData, periodData } = useContext(CreateBenchmarkContext);
    
    // Human readable Purpose options 
    function purposeOptions(purpose) {
        switch (purpose) {
            case 'benchmark':
                return 'Benchmark + Improvement Plan';
            case 'rank':
                return 'Rank';
            default:
                return '';
        }
    }

    // Human readable Objective options

    function objectiveOptions(objective) {
        switch (objective) {
            case 'input':
                return 'Input Based';
            case 'output':
                return 'Output Based';
            case 'input_output_independent':
                return 'Input and Output based independent';
            case 'input_output_together':
                return 'Input and Output based simultaneously';
            default:
                return '';
        }
    }

    function scaleOptions(scale) {
        switch (scale) {
            case 'crs':
                return 'Constant returns to scale';
            case 'vrs':
                return 'Variable returns to scale';
            case 'irs':
                return 'increasing returns to scale';
            case 'drs':
                return 'Decreasing returns to scale';
            default:
                return '';
        }
    }

    function targetOptions(target) {
        switch (target) {
            case 'radial':
                return 'Equal proportion (Radial)';
            case 'non_radial':
                return 'Individual proportion (Non-radial)';
            default:
                return '';
        }
    }


    // Get benchmark model if entering last step
    useEffect(() => {
        if (tabValue !== index) {
            return;
        }

        // Return if any of these fields are empty
        if (
            formData.project_id === '' ||
            formData.name.trim() === '' ||
            formData.objective === '' ||
            (formData.input_vars.length === 0 && formData.output_vars.length === 0) ||
            formData.target_values_type === '' ||
            formData.apply_weight_restriction === ''
        ) {
            console.log('SummaryWS useEffect: empty fields')
            console.log('formData', formData)
            changeFormData('dea_model', '');
            return;
        }

        console.log('Get Benchmark Model');
        let data = JSON.parse(JSON.stringify(formData));
        data.action = 'model';
        
        // rename certain fields
        data.benchmark_name = data.name.trim();
        data.benchmark_desc = data.description;
        data.benchmark_freq = data.baseline_frequency;
        data.benchmark_start_period = data.first_baseline_period;
        data.tracking_freq = data.tracking_frequency;

        delete data.name;
        delete data.description;
        delete data.baseline_frequency;
        delete data.first_baseline_period;
        delete data.tracking_frequency;
        delete data.include_next_baseline;
        delete data.apply_tracking_cycles;
        
        // Clean list field
        data.input_vars = data.input_vars.map((item) => { return item.id; });
        data.output_vars = data.output_vars.map((item) => { return item.id; });
        data.restricted_vars = data.restricted_vars.map((item) => { return item.id; });
        data.undesirable_vars = data.undesirable_vars.map((item) => { return item.id; });
        data.segmentation_scenario_id = data.segmentation_scenario ? data.segmentation_scenario.id : null;
        
        delete data.segmentation_scenario;

        console.log('SummaryWS data', data);
        // console.log('SummaryWS formData', formData);

        const targetURL = '/project/benchmark/'
        // console.log('targetURL: '+targetURL)
        post_json(targetURL, data)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status + ' : ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log('Suggested Scenario: ', data.dea_model);
                changeFormData('dea_model', data.dea_model);
                return;
            })
            .catch(error => {
                console.log('Error: ', error);
                changeFormData('dea_model', 'could not compute');
                return;
            });
        return;
       
    }, [tabValue]);

    return (
        <>
            <TabPanel value={tabValue} index={index}>
                <div><b>Summary</b></div>
                <Divider />
                <Grid container spacing={1} marginY={2} fontSize={12}>
                    <LabelGrid>name: </LabelGrid>
                    <ValueGrid value={formData.name.trim()} required={true}/>
                    <LabelGrid> Description: </LabelGrid>
                    <ValueGrid>{formData.description}</ValueGrid>
                </Grid>
                <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Benchmark Setup</b> </Box>
                <Divider />
                <Grid container spacing={1} marginY={2} fontSize={12}>
                    <LabelGrid> Purpose: </LabelGrid>
                    <ValueGrid>{purposeOptions(formData.purpose)}</ValueGrid>
                    <LabelGrid> Objective: </LabelGrid>
                    <ValueGrid>{objectiveOptions(formData.objective)}</ValueGrid>
                    <LabelGrid> Scale:</LabelGrid>
                    <ValueGrid>{scaleOptions(formData.scale_type)}</ValueGrid>
                    <LabelGrid> Target Values Type: </LabelGrid>
                    <ValueGrid>{targetOptions(formData.target_values_type)}</ValueGrid>
                    <LabelGrid>Input Variables:</LabelGrid>
                    <ValueGrid
                        value={formData.input_vars}
                        required={
                            formData.objective === 'input' ||
                            formData.objective === 'input_output_independent' ||
                            formData.objective === 'input_output_together'}
                        type='list'>
                        <ChipsList list={formData.input_vars} />
                    </ValueGrid>
                    <LabelGrid>Output Variables:</LabelGrid>
                    <ValueGrid
                        value={formData.output_vars}
                        required={
                            formData.objective === 'output' ||
                            formData.objective === 'input_output_independent' ||
                            formData.objective === 'input_output_together'}
                        type='list'>
                        <ChipsList list={formData.output_vars} />
                    </ValueGrid>
                </Grid>
                <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Variable treatments</b> </Box>
                <Divider />
                <Grid container spacing={1} marginY={2} fontSize={12}>
                    <LabelGrid> Restricted Variables:</LabelGrid>
                    <ValueGrid> {formData.apply_restricted ?
                        <ChipsList list={formData.restricted_vars} />
                        : 'Not applied'}
                    </ValueGrid>
                    <LabelGrid> Undesirable Effects Variables:</LabelGrid>
                    <ValueGrid> {formData.apply_undesirable ?
                        <ChipsList list={formData.undesirable_vars} />
                        : 'Not applied'}
                    </ValueGrid>
                </Grid>

                <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Period Information</b></Box>
                <Divider />
                <Grid container spacing={1} marginY={2} fontSize={12}>
                    <LabelGrid> Single or Multiple periods: </LabelGrid>
                    {formData.multi_periods === 'na'
                        ? <ValueGrid value="Not applicable" required={false} />
                        : <ValueGrid value={formData.multi_periods} required={true} />
                    }
                    {(formData.multi_periods === 'single')?(
                        <>
                            {console.log('Got to single period')}
                            <LabelGrid> Period: </LabelGrid>
                            <ValueGrid
                                value={periodData.periods_list.filter((period) => {
                                    return period.period_id === formData.single_baseline_period
                                })[0]?.date_string}
                                required={formData.multi_periods === 'single'} />
                        </>
                    ) : (null)}
                    {(formData.multi_periods === 'all') ? (
                        <>
                            {console.log('Got to  All period')}
                            <LabelGrid> Apply Tracking Cycles: </LabelGrid>
                            <ValueGrid>{formData.apply_tracking_cycles?'Yes':'No'}</ValueGrid>
                        </>
                    ) : (null)}
                    {formData.apply_tracking_cycles ? (
                        <>
                            {console.log('Got to tracking cycles')}
                            <LabelGrid> Baseline Frequency </LabelGrid>
                            <ValueGrid
                                value={formData.baseline_frequency}
                                required={formData.apply_tracking_cycles}
                            />
                            <LabelGrid> Tracking Frequency: </LabelGrid>
                            <ValueGrid
                                value={formData.tracking_frequency}
                                required={formData.apply_tracking_cycles}
                            />
                            <LabelGrid> First Baseline Period: </LabelGrid>
                            <ValueGrid
                                value={periodData.periods_list.filter((period) => {
                                    return period.period_id === formData.first_baseline_period
                                })[0]?.date_string}
                                required={formData.apply_tracking_cycles}
                            />
                            <LabelGrid> Include Next Baseline: </LabelGrid>
                            <ValueGrid>{formData.include_next_baseline?'Yes':'No'}</ValueGrid>
                        </>
                    ) : (null)}


                </Grid>
                
                <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Segmentation</b></Box>
                <Divider/>
                <Grid container spacing={1} marginY={2} fontSize={12}>
                    <LabelGrid> Segmentation Scenario: </LabelGrid>
                    <ValueGrid
                        value={!formData.apply_segmentation ?
                            'Not applied':
                            (formData.segmentation_scenario ? formData.segmentation_scenario.label : '')}
                        required={formData.apply_segmentation}
                    />

                </Grid>
                <Box width={'100%'} sx={{ bgcolor: '#E1E5F2' }}><b>Model</b></Box>
                <Divider />
                <Grid container spacing={1} marginY={2} fontSize={12}>
                    <LabelGrid> Suggested Model: </LabelGrid>
                    <ValueGrid value={formData.dea_model}/> 

                </Grid>


         </TabPanel>                   
        </>
    )
}

// Create Chips from a list of objects
function ChipsList({ list }) {
    return (
        <>
            {list.map((item) => {
                return (
                    <Chip size='small' key={item.id} label={item.label} />
                )
            })}
        </>
    )
}

function LabelGrid({ children, label }) {
    return (
        <Grid item xs={12} sm={4} sx={{}}>
             <Box sx={{minWidth:100}}>
                 {children}
            </Box>
        </Grid>
    )
}

function ValueGrid({ children, value=null, type='text', required=false }) {
    return (
        <Grid item xs={12} sm={8} sx={{}}>
            <Box sx={{ minWidth: 100 }}>
                {required && type==='text'? 
                    (value || value !== '' ? capitalizeFirstLetter(value) : <div style={{ color: 'red' }}>Missing required*</div>)
                    : null}
                {!required && type === 'text' && value !=='' && value?capitalizeFirstLetter(value):null}
                {required && type === 'list' ?
                    (value.length === 0 ? <div style={{ color: 'red' }}>Missing required*</div> : null)
                    : null}
                {children}
            </Box>
        </Grid>
    )
}

/*Capitalizes first letter of a string*/
function capitalizeFirstLetter(string) {
    // console.log('capitalizeFirstLetter', string)
    if (!string || string=='') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}
