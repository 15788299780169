import { get_new } from "../../../utils/httpClient";

export const getData = ({ rowsPerPage, page, searchValue, statusOption, objectiveOption, useSegmentationOption }) => {
    const url = `/project/benchmarks/?page_size=${rowsPerPage}&page=${page + 1}&search=${searchValue}&status=${statusOption}&orient=${objectiveOption}&apply_segmentation=${useSegmentationOption}`;
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error fetching data:', error);
      throw error;
    })
  };

  export const getDatasetData = ({projectData}) => {
    const url = `/dataset/?dataset_id=${projectData.dataset_id}`

    return get_new(url)
    .then((response) => {
        if(!response.ok) {
            console.log('Could not retrieve data');
            console.log('Response Error' + response.status);
            throw new Error(response.statusText);      
        }
        return response.json()
    })
    .then((data) => data.results)
    .catch((error) => {
        console.error('Error fetching dataset data:', error);
        throw error;
    })
}