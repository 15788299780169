import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { MainCard, ShadowCardContentContainer } from '../../../components/common/commonContainer'
import FilterSidebar from '../../../components/common/filtersSidebar';
import { RoundTab, TabsContainer } from '../../../components/common/tabsDesign';
import Efficiency from './aggregatedResultsTabs/efficiency';
import EfficiencyFrontier from './aggregatedResultsTabs/efficiencyFrontier';
import EfficiencyPerSegment from './aggregatedResultsTabs/efficiencyPerSegment';
import EfficiencyQuadrants from './aggregatedResultsTabs/efficiencyQuadrants';
import { get_new } from '../../../utils/httpClient';
import { useEffect } from 'react';
import Summary from './aggregatedResultsTabs/summary';
import { getBenchmarkData, getPeriodOptions, getSegmentOptions } from './aggregatedResultsTabs/services/aggregatedResultDashboardServices';
import TotalMetrics from './aggregatedResultsTabs/totalMetrics';


export const GridContent = styled(Box)({
  padding: '5px 0px',
  display:'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

export default function AggregatedResultsDashboard({benchmark_id, project_id, projectType}) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);

  const [value, setValue] = useState(0);
  const [previousPeriod, setPreviousPeriod] = useState([])
  const [benchmarkData, setBenchmarkData] = useState([])
  const [efficiency, setEfficiency] = useState([])

  const [filterFields, setFilterFields] = useState([
    {
      key: 'period',
      title: "Period",
      label: "",
      name: "Period",
      value: "",
      type: "select",
      options: [
        { label: "", value: "" },
      ]
    },
      {
        key: 'segment',
        title: "Segment",
        label: "",
        name: "Segment",
        value: "",
        type: "select",
        options: [
          { label: "", value: "" },
        ]
      },    
      {
        key: 'orient',
        title: "Orient",
        label: "",
        name: "Orient",
        value: "",
        type: "",
        options: [
          { label: "Input", value: "input" },
          { label: "Output", value: "output" },
        ]
      },    

  
    ])

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
  //BENCHMARK DATA FETCH
    Promise.all([
      getBenchmarkData({ benchmark_id }),
      getPeriodOptions({project_id, benchmark_id}),
      getSegmentOptions({ project_id, benchmark_id })
    ])
      .then(([benchmarkData, periodOptionsData, segmentOptionsData]) => {
        setBenchmarkData(benchmarkData);
        setFilterFields((prevFields => {
          const periodDefaultValue = periodOptionsData[periodOptionsData?.length - 1]?.value;
          const segmentDefaultValue = segmentOptionsData?.[0]?.value; // set default value to first object of fetched data
          return prevFields.map(field => {
            if (field.key === 'period') {
              return {
                ...field,
                options: [...periodOptionsData],
                value: periodDefaultValue
              };
            }
            if(field.key === 'segment') {
              return {
                ...field,
                options: [...segmentOptionsData],
                value: segmentDefaultValue
              }; 
              
            }
            return field;
          });
        }))
        // return Promise.all([])
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error)
      })
      .finally(() => {
        setLoading(false);
      });
  }, [project_id, benchmark_id]);

  //Filter Values
  const periodValue = filterFields[0].value
  const segmentValue = filterFields[1].value
  const orientValue = filterFields[2].value

  //If project type is input output independent, set values and input type
  useEffect(() => {
    if(projectType === 'input_output_independent'){
      filterFields[2].type = 'select'
      filterFields[2].label = 'Input'
      filterFields[2].value = 'input'
    }  
  }, [projectType])

  //return previous period option
  useEffect(() => {
    if(!periodValue) return ;
    get_new(`/project/dataset-periods/?benchmark__project_id=${project_id}&benchmark_id=${benchmark_id}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve dataset periods');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if(periodValue === 'All'){
          setPeriodObject({period: 'All'})
        } else {
          const prevIndex = data.results.findIndex(item => item.period === periodValue);
          setPreviousPeriod(prevIndex === 0 ? data.results[prevIndex] : data.results[prevIndex - 1]);
        }

      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error)
      })
      .finally(() => {
        setLoading(false); 
      });
  }, [benchmark_id, periodValue, project_id]);

  //Check if not necessary then you can delete this as it was replaced by 'efficiency' state
  // //efficiency api
  // const [api, setApi] = useState([])
  // useEffect(() => {
  //   get_new(`/project/benchmark/results/efficiency/?benchmark_id=${benchmark_id}&period=${periodValue}&orient=${orientValue}`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         console.log('Could not retrieve API data');
  //         console.log('Response Error' + response.status);
  //         throw new Error(response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setApi(data.results);
  //     })
  //     .catch((error) => {
  //       console.log('Error: ' + error);
  //       // TODO: Show user error that data couldn't be retrieved
  //       setError(error)
  //     })
  //     .finally(() => {
  //       setLoading(false); 
  //     });
  // }, [filterFields, benchmark_id, orientValue]);

  //entities count and dmu efficiency api
  const [entities, setEntities] = useState([])
  useEffect(() => {
    if(!periodValue && !segmentValue) return ;
    get_new(`/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodValue}&assignment__cluster__cluster_cd=${segmentValue}&orient=${orientValue}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve API data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setEntities(data);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error)
      })
      .finally(() => {
        setLoading(false); 
      });
  }, [filterFields, benchmark_id, orientValue]);
  //Create Period Object
  const [periodObject, setPeriodObject] = useState([])
  useEffect(() => {
    if(!periodValue) return ;
    get_new(`/project/dataset-periods/?benchmark__project_id=${project_id}&period=${periodValue}&benchmark_id=${benchmark_id}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve dataset periods');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if(periodValue === 'All'){
          setPeriodObject({period: 'All'})
        } else {
          const index = data?.results?.findIndex(item => item.period === periodValue);
          setPeriodObject(data?.results[index]);
        }
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error)
      })
      .finally(() => {
        setLoading(false); 
      });
  }, [periodValue, benchmark_id, project_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    setFilterFields(prevFields => {
			const updatedFields = prevFields.map(field => {
			  if (field.key === 'segment') {
				if (newValue === 1) {
				  // If the new value is 0, set the type to an empty string
				  return { ...field, type: '' };
				} else if (newValue !== 1) {
				  // If the new value is 1, change the type to "select"
				  return { ...field, type: 'select' };
				}
			  }
			  return field;
			});
		
			return updatedFields;
		  });
  };


  if(loading) {
    return <Box sx={{width: '100%'}}>
      <MainCard sx={{justifyContent: 'center', alignItems: 'center', height: '300px'}}>
        <CircularProgress/>
      </MainCard>
    </Box>
  }

  if(error) {
    return <Box sx={{width: '100%'}}>
      <MainCard sx={{justifyContent: 'center', alignItems: 'center', height: '300px'}}>
        Error: {error.message}
      </MainCard>
    </Box>
  }
  return (
    <Box sx={{width: '100%'}}>
        <FilterSidebar filterFields={filterFields} setFilterFields={setFilterFields} />
        <MainCard> 
          <Summary
            periodOptions={filterFields[0]}
            segmentOptions={filterFields[1]}
            periodObject={periodObject}
            entities={entities}
            // api={api}
            efficiencySummary={efficiency.results}
          />

          <TabsContainer
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
          variant="fullWidth"
          TabIndicatorProps= {{sx: {display: 'none'}}}
          >
            <RoundTab iconPosition="start" label="Efficiency" />
            <RoundTab iconPosition="start" label="Efficiency per Segment" disabled={filterFields[1].options.length <= 1 ? true : false}/>
            <RoundTab iconPosition="start" label="Efficiency Frontier" />
            <RoundTab iconPosition="start" label="Efficiency Quadrants" />
            <RoundTab iconPosition="start" label="Metrics" />
          </TabsContainer>

          <ShadowCardContentContainer sx={{    boxShadow: "none", border: 'none'}} >
            {value === 0 && (
              <Efficiency periodObject={periodObject} previousPeriod={previousPeriod} baseSegment={segmentValue} benchmark_id={benchmark_id} periodOptions={filterFields[0].options} orientVaue={orientValue} setEfficiency={setEfficiency}/>
              )}
            {value === 1 && (
              <EfficiencyPerSegment periodObject={periodObject} previousPeriod={previousPeriod} baseSegment={segmentValue} benchmark_id={benchmark_id} orientVaue={orientValue} />
            )}
            {value === 2 && (
              <EfficiencyFrontier periodObject={periodObject} baseSegment={segmentValue} benchmark_id={benchmark_id} orientVaue={orientValue} />
            )}
            {value === 3 && (
              <EfficiencyQuadrants periodObject={periodObject} baseSegment={segmentValue} benchmark_id={benchmark_id} orientVaue={orientValue} />
            )}
            {value === 4 && (
              <TotalMetrics periodObject={periodObject} periodOptions={filterFields[0].options} benchmark_id={benchmark_id} orientValue={orientValue} />
            )}
          </ShadowCardContentContainer>
        </MainCard>
    </Box>
  )
}
