import { Box, Chip, Typography, Grid, Select, MenuItem, styled, Tabs, Tab, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from '../../../utils/httpClient';

const TabsContainer = styled(Tabs)({
	backgroundColor: '#F5F5F5',
	border: '1px solid #E8E8E8;',
	width: '100%',
	borderRadius: '11px 11px 0 0',
});

const CustomTab = styled(Tab)({
	color: '#626262',
	'&.Mui-selected': {
		color: '#FFFFFF',
		backgroundColor: '#47254B',
		borderRadius: '10px 10px 0 0',
		boxShadow: '4px 8px 4px rgba(0, 0, 0, 0.25)',
	},
});

export default function CorrelationMatrix() {
	const [searchParams, setSearchParams] = useSearchParams();
	const dataset_id = searchParams.get('dataset_id');

	// USE STATE
	const [state, setState] = useState({
		tab: 0,
		filter_variable: [],
		filter_period: 'All',
		variables: [],
		periods: [],
	});

	// CHANGE STATE
	const changeState = async (name, value) => {
		const newState = {
			...state,
			...{
				[name]: value,
			},
		};

		setState(newState);
	};

	useEffect(() => {
		(async () => {
			const newState = {};

			await (async () => {
				const endpoint = `?dataset_id=${dataset_id}`;
				const res = await get(`/dataset/${endpoint}`);
				newState.variables = res.results.filter((row) => row.var_type === 'numerical');
			})();

			await (async () => {
				const endpoint = `?dataset_id=${dataset_id}`;
				const res = await get(`/dataset/periods_list/${endpoint}`);
				newState.periods = res.results.filter((row) => row.period !== 'All');
			})();

			setState({
				...state,
				...newState,
			});
		})();
	}, [dataset_id]);

	// TAB PANEL
	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
				{value === index && <Box sx={{ px: 3 }}>{children}</Box>}
			</div>
		);
	}

	return (
		<>
			<Typography fontSize="1.2rem" fontWeight="bold" marginBottom="20px">
				Exploratory Data Analysis
			</Typography>

			<Grid container spacing={2} marginBottom="20px">
				<Grid item>
					<Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
						Variable
					</Typography>
					<Select
						style={{ maxWidth: '400px' }}
						multiple
                        displayEmpty
						value={state.filter_variable}
						size="small"
						onChange={(event) => changeState('filter_variable', event.target.value)}
						renderValue={(selected) => {
							if (selected.length === 0) {
								return <em>Select Variables</em>;
							}

							return (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((value) => (
										<Chip size="small" key={value} label={value} />
									))}
								</Box>
							);
						}}
					>
						{state.variables.map((row, index) => (
							<MenuItem key={index} value={row.original_field_name}>
								{row.original_field_name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item>
					<Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
						Period
					</Typography>
					<Select value={state.filter_period} size="small" onChange={(event) => changeState('filter_period', event.target.value)}>
						<MenuItem value="All">All</MenuItem>

						{state.periods.map((row, index) => (
							<MenuItem key={index} value={row.period}>
								{row.period}
							</MenuItem>
						))}
					</Select>
				</Grid>
			</Grid>

			<TabsContainer value={state.tab} onChange={(event, value) => changeState('tab', value)}>
				<CustomTab label="High Positive Correlation" />
				<CustomTab label="High Negative Correlation" />
			</TabsContainer>
			<TabPanel value={state.tab} index={0}>
				<Grid container spacing={2} marginBottom="20px">
					<Grid item>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Var A</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										<b>Store Area</b>
									</TableCell>
									<TableCell>vs</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<b>Shelf Area</b>
									</TableCell>
									<TableCell>vs</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
					<Grid item>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Var B</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										<b>Store Area</b>
									</TableCell>
									<TableCell>0.94</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<b>Shelf Area</b>
									</TableCell>
									<TableCell>0.98</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel value={state.tab} index={1}>
				<Grid container spacing={2} marginBottom="20px">
					<Grid item>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Var A</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										<b>Store Area</b>
									</TableCell>
									<TableCell>vs</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<b>Shelf Area</b>
									</TableCell>
									<TableCell>vs</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
					<Grid item>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Var B</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										<b>Store Area</b>
									</TableCell>
									<TableCell>-0.92</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<b>Shelf Area</b>
									</TableCell>
									<TableCell>-0.91</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</TabPanel>
		</>
	);
}
