import "chart.js/auto";
import { Pie } from "react-chartjs-2";

export default function ChartVariableTypes({ data }) {
  const chartOptions = {
    width: "60%",
    plugins: {
      datalabels: {
        color: "white",
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
      },
    },
  };

  const chartData = {
    labels: ["Numerical", "Categorical", "Datetime"],
    datasets: [
      {
        data: [data.numerical, data.categorical, data.datetime],
        backgroundColor: ["#776ea9ff", "#740f88ff", "#47254Bff"],
        borderAlign: "center",
      },
    ],
  };

  return (
    <>
      <Pie options={chartOptions} data={chartData} />
    </>
  );
}
