import React from "react";
import { CardUpperContainer } from "../../../components/common/commonContainer";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/icons/sidebar/filterBarSearchIcon.svg";
import {
  ClearRounded,
  DensityMediumRounded,
  GridViewRounded,
} from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get_new } from "../../../utils/httpClient";
import { useHomeFiltersProjectsStore } from "../../../store/store";

const Search = styled("div")({
  borderRadius: "0.375rem",
  border:
    "1px solid var(--forms-eui-form-border-color, rgba(19, 34, 149, 0.10))",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  height: "35px",
  lineHeight: "14px",
  padding: "2px 10px",
  "&:hover": {
    backgroundColor: "white",
    opacity: 0.7,
  },
});

const FormControlStyle = styled(FormControl)({
  margin: "10px",
  minWidth: "120px",
  maxWidth: "300px",
  width: "140px",
  alignItems: "center",
  gap: "5px",
});

export default function DatasetsUpperBar({
  handleClick,
  button,
  value,
  onChange,
  onSelectValues,
  setSearchDatasets,
}) {
  const [creationOptions, setCreationOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [api, setApi] = useState([]);

  useEffect(() => {
    get_new(`/datasets/?page_size=${api.count}`)
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve datasets");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setApi(data);
        setCreationOptions([
          ...new Set(data?.results?.map((value) => value.creation_date)),
        ]);
        setStatusOptions([
          ...new Set(data?.results?.map((value) => value.status)),
        ]);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
      });
  }, [api.count]);

  const { datasetsCreationDate, datasetsStatus } =
    useHomeFiltersProjectsStore();
  //   const [datasetsStatus, setDatse
  const [selectedCreationValue, setSelectedCreationValue] =
    useState(datasetsCreationDate);
  const [selectedStatusValue, setSelectedStatusValue] =
    useState(datasetsStatus);

  const handleFilterChange1 = (event) => {
    const value = event.target.value;
    setSelectedCreationValue(value);
    onSelectValues(value, selectedStatusValue);
  };

  const handleFilterChange2 = (event) => {
    const value = event.target.value;
    setSelectedStatusValue(value);
    onSelectValues(selectedCreationValue, value);
  };

  const handleClearClick = () => {
    setSearchDatasets("");
  };

  return (
    <Box sx={{ display: "flex", flexFlow: "column nowrap" }}>
      <Box sx={{ display: "flex", m: "20px 0 10px 0", p: "0 40px" }}>
        <Typography variant="h1" color="initial">
          Datasets
        </Typography>
      </Box>
      <CardUpperContainer sx={{ marginBottom: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Search>
            <SearchIcon fill="#A28DA5" />
            <TextField
              placeholder="Search"
              InputProps={{
                "aria-label": "search",
                endAdornment: (
                  <IconButton
                    sx={{ visibility: value ? "visible" : "hidden" }}
                    onClick={handleClearClick}
                  >
                    <ClearRounded />
                  </IconButton>
                ),
              }}
              value={value}
              onChange={onChange}
              variant="outlined"
              sx={{
                color: "inherit",
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: 1,
                  // vertical padding + font size from searchIcon
                  width: "100%",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
          </Search>
          <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
            {/* <FormControlStyle
              key={1}
              sx={{ display: "flex", flexFlow: "row", width: "fit-content" }}
            >
              <Typography variant="h6" color="#7F7F7F">
                Creation Date
              </Typography>
              <Select
                displayEmpty
                disableUnderline
                labelId={`${1}-label`}
                id="1"
                label={"Creation Date"}
                name="creationDateFilter"
                value={selectedCreationValue}
                variant="standard"
                onChange={handleFilterChange1}
                sx={{ color: "#002D9C" }}
              >
                <MenuItem key={"All"} value={""} sx={{ color: "#002D9C" }}>
                  All
                </MenuItem>
                {creationOptions.map((option, optionIndex) => (
                  <MenuItem
                    key={optionIndex}
                    value={option}
                    sx={{ color: "#002D9C" }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControlStyle> */}

            <FormControlStyle
              key={2}
              sx={{ display: "flex", flexFlow: "row", width: "fit-content" }}
            >
              <Typography variant="h6" color="#7F7F7F">
                Status
              </Typography>
              <Select
                displayEmpty
                disableUnderline
                labelId={`${2}-label`}
                id="2"
                label={"Status"}
                name="statusFilter"
                value={selectedStatusValue}
                variant="standard"
                onChange={handleFilterChange2}
                sx={{ color: "#002D9C" }}
              >
                <MenuItem key={"All"} value={""} sx={{ color: "#002D9C" }}>
                  All
                </MenuItem>
                {statusOptions.map((option, optionIndex) => (
                  <MenuItem
                    key={optionIndex}
                    value={option}
                    sx={{ color: "#002D9C" }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControlStyle>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <IconButton onClick={() => handleClick(0)}>
            <DensityMediumRounded />
          </IconButton>
          <IconButton onClick={() => handleClick(1)}>
            <GridViewRounded />
          </IconButton>
          {button}
        </Box>
      </CardUpperContainer>
    </Box>
  );
}
