import { Box, CircularProgress, Divider, Tabs, Typography } from '@mui/material'
import React from 'react'
import { GraphContainer, GraphMainCard } from '../../../../components/common/commonContainer'
import { EfficiencyBySegmentsBoxplot } from '../../../../graphs/aggregatedResults/efficiencyBySegmentsBoxPlot'
import EfficiencyDistributionBySegment from '../../../../graphs/aggregatedResults/efficiencyDistributionBySegment'
import AvgEfficiencyBySegmentPP from '../../../../graphs/aggregatedResults/avgEfficiencyBySegmentPP'
import { useState } from 'react'
import { SmallRoundTab } from '../../../../components/common/tabsDesign'
import { useEffect } from 'react'
import { get_new } from '../../../../utils/httpClient'

export default function EfficiencyPerSegment({periodObject, previousPeriod, benchmark_id, orientVaue}) {
  const [selectedTab1, setSelectedTab1] = useState(0);
  const [selectedTab2, setSelectedTab2] = useState(0);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
 
  const [barChartData, setBarChartData] = useState([])
  const [lineChartData, setlineChartData] = useState([])
  const [boxplotData, setBoxplotData] = useState([])


  const handleTabChange1 = (event, newValue) => {
    setSelectedTab1(newValue);
  };
  
  const handleTabChange2 = (event, newValue) => {
    setSelectedTab2(newValue);
  };


  //BAR CHART DATA
  useEffect(() => {
    setLoading(true)
    get_new(`/project/benchmark/results/segment/efficiency-bins/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&orient=${orientVaue}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve dataset periods');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {

        setBarChartData(data.results);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error)
      })
      .finally(() => {
        setLoading(false);
      });
  }, [periodObject, benchmark_id, orientVaue]);

  //LINE CHART DATA
  useEffect(() => {
    setLoading(true)
    get_new(`/project/benchmark/results/segment/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject.period}&orient=${orientVaue}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve dataset periods');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setlineChartData(data.results);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error)
      })
      .finally(() => {
        setLoading(false);
      });

  }, [benchmark_id, periodObject, orientVaue]);

  //BOXPLOT CHART DATA
  useEffect(() => {
    setLoading(true)
    get_new(`/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&orient=${orientVaue}`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve dataset periods');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {

        setBoxplotData(data.results);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
        setError(error)
      })
      .finally(() => {
        setLoading(false);
      });

  }, [periodObject, benchmark_id, orientVaue]);



  if(loading) {
    return <Box sx={{width: '100%',  height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress/>
    </Box>
  }

  if(error) {
    return <Box sx={{width: '100%',  height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {error.message}
    </Box>
  }

  return (
    <Box sx={{width: '100%'}}>
        <Typography variant="h5" sx={{padding: '10px'}}>Average Efficiency</Typography>
        <Divider variant="fullWidth" />
        <GraphMainCard  sx={{gap: '10px'}}>
          <GraphContainer sx={{ width: '30%', m:0, gap: 0, flex: 1,  alignItems: 'flex-end',justifyContent: 'space-evenly', p: '10px', flexFlow: 'column nowrap'}}>
            <Tabs
              value={selectedTab1}
              onChange={handleTabChange1}
              TabIndicatorProps= {{sx: {display: 'none'}}}
            >
              <SmallRoundTab label={<Typography variant="caption" >Value</Typography>}/>
              <SmallRoundTab label={<Typography variant="caption" >Percent</Typography>} />
            </Tabs>

            {selectedTab1 === 0 && (
                <EfficiencyDistributionBySegment api={barChartData} value={0}/>
            )}
            {selectedTab1 === 1 && (
                <EfficiencyDistributionBySegment api={barChartData} value={1}/>
            )}               
          </GraphContainer>
          <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '10px', flexFlow: 'column nowrap', width: {xs: '100%',lg:'48%'}}}>
              <GraphContainer sx={{alignItems: 'center', m: 0, p: '5px', width: '-webkit-fill-available', height: '300px ', flexFlow: 'column'}}>
                <Tabs
                  value={selectedTab2}
                  onChange={handleTabChange2}
                  TabIndicatorProps= {{sx: {display: 'none'}}}
                  sx={{alignSelf:'flex-end'}}
                >
                  <SmallRoundTab label={<Typography variant="caption" >Period / Period</Typography>}/>
                  <SmallRoundTab label={<Typography variant="caption" >Period / Baseline</Typography>} />
                </Tabs>
                {selectedTab2 === 0 && (
                  <AvgEfficiencyBySegmentPP api={lineChartData} periodObject={periodObject} previousPeriod={previousPeriod} tabIndex={0}/>
                )}
                {selectedTab2 === 1 && (
                  <AvgEfficiencyBySegmentPP api={lineChartData} periodObject={periodObject} previousPeriod={previousPeriod} tabIndex={1}/>
                )}               
              </GraphContainer>
              <GraphContainer sx={{ m: 0, height: '200px'}}>
                <EfficiencyBySegmentsBoxplot api={boxplotData} />
              </GraphContainer>
          </Box>
        </GraphMainCard>
    </Box>
  )
}
