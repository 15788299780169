import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { numeralFormatter } from "../../../../utils/numeralFormatter";
import colors from "../../../../assets/variables.scss";

export default function VariableTable({ metrics }) {
  const variables = Object.values(metrics);
  const data = variables[0].map((value) => {
    return {
      entity: value.dmu_id,
      segment: value.assignment__cluster__cluster_cd,
      base: numeralFormatter(value.actual_value - value.diff_current_benchmark),
      current: numeralFormatter(value.actual_value),
      target: numeralFormatter(value.target),
      diffTarBase: numeralFormatter(value.diff_target_benchmark),
      changeTarBase: numeralFormatter(value.change_target_benchmark),
      diffCurrBase: `${numeralFormatter(value.diff_current_benchmark)}`,
      diffCurrTar: `${numeralFormatter(value.progress_current_target * 100)}%`,
      progressTowardTarget: `${numeralFormatter(
        value.progress_current_target * 100
      )}%`,
    };
  });
  return (
    <TableContainer component={Paper} sx={{ height: "fit-content", mt: "8px" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: colors.entity1 }}>
            <TableCell
              sx={{ borderBottom: "none", color: "white" }}
              colSpan={9}
            >
              <Typography variant="h6" color="white">
                {variables[0][0]?.variable__variable_name?.replace(/_/g, " ")}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ verticalAlign: "bottom" }}>
            <TableCell>Entity</TableCell>
            <TableCell>Base</TableCell>
            <TableCell>Current</TableCell>
            <TableCell>Target</TableCell>
            <TableCell colSpan={2} sx={{ textAlign: "center" }}>
              Target vs base
              <TableRow
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <TableCell
                  sx={{
                    border: "none",
                    "&:first-of-type": {
                      paddingLeft: "8px",
                      paddingBottom: "0",
                    },
                  }}
                >
                  Difference
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    whiteSpace: "nowrap",
                    "&:last-of-type": {
                      paddingRight: "8px",
                      paddingBottom: "0",
                    },
                  }}
                >
                  % Change
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell>Difference Current vs Base</TableCell>
            <TableCell>Progress to Target</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.entity}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.base}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.current}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.target}
              </TableCell>
              <TableCell
                sx={{
                  width: "12.5%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {row.diffTarBase}
              </TableCell>
              <TableCell
                sx={{
                  width: "12.5%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {row.changeTarBase}%
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.diffCurrBase}
              </TableCell>
              <TableCell sx={{ width: "12.5%", whiteSpace: "nowrap" }}>
                {row.progressTowardTarget}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
