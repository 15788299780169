import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import NameDescManual from "./benchmarkManualConfig/nameDescManual";
import BenchmarkSetupManual from "./benchmarkManualConfig/benchmarkSetupManual";
import VariableTreatmentsManual from "./benchmarkManualConfig/variableTreatmentsManual";
import PeriodInformationManual from "./benchmarkManualConfig/periodInformationManual";
import SegmentationModelManual from "./benchmarkManualConfig/SegmentationModelManual";
import { useContext } from "react";
import EditBenchmarkContext from "../context/editBenchmarkContext";
import { useState } from "react";
import VariablesEditManual from "./benchmarkManualConfig/variablesEditManual";
import MultiPeriodManual from "./benchmarkManualConfig/multiPeriodManual";
import TrackingCyclesManual from "./benchmarkManualConfig/trackingCyclesManual";
import { post_json } from "../../../../../utils/httpClient";
// import ProjectContext from "../../../../../context/Project/ContextProject";
import { useDataSetStore } from "../../../../../store/store";
import { enqueueSnackbar } from "notistack";

export default function ManualSettings({ open, onClose, handleAction }) {
  const [saveButtonState, setSaveButtonState] = useState(true);
  //CONTEXT PROVIDER
  //Summary data is the actual data
  const { summaryData, setEditedSummaryData } =
    useContext(EditBenchmarkContext);
  //   const { datasetData } = useContext(ProjectContext);
  const { dataSetValuesData } = useDataSetStore();

  //intitialize formBenchmarkData with actualData
  const [formBenchmarkData, setFormBenchmarkData] = useState({
    ...summaryData,
    input_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.variable_type === "input")
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    output_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.variable_type === "output")
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    restricted_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.restricted_var === true)
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    undesirable_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.undesirable_metrics === true)
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    multi_periods:
      summaryData?.benchmarkperiod?.length === 1
        ? "single"
        : summaryData?.benchmarkperiod?.length > 1
        ? "all"
        : "",
    single_baseline_period:
      summaryData?.benchmarkperiod?.length === 1
        ? summaryData?.benchmarkperiod?.[0]?.period_id
        : "",

    //this prop is temporary while it is being added into summaryData api
    include_next_baseline: false,
  });

  //Create an object with summaryData adapted to the props that api needs to receive
  const inititalSummaryData = {
    ...summaryData,
    input_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.variable_type === "input")
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    output_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.variable_type === "output")
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    restricted_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.restricted_var === true)
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    undesirable_vars: (summaryData?.benchmarkvars || [])
      .filter((v) => v.undesirable_metrics === true)
      .map((v) => ({ label: v.variable_name, id: v.variable_detail })),
    multi_periods:
      summaryData?.benchmarkperiod?.length === 1
        ? "single"
        : summaryData?.benchmarkperiod?.length > 1
        ? "all"
        : "",
    single_baseline_period:
      summaryData?.benchmarkperiod?.length === 1
        ? summaryData?.benchmarkperiod?.[0]?.period_id
        : "",

    //this prop is temporary while it is being added into summaryData api
    include_next_baseline: false,
  };

  //Initialize empty object to store only data values that changes between sumaryData and formBenchmarkData
  const editedData = {};

  // Compare props values between obj1 and obj2 and generate 2 arrays with props names with equal props values
  // and another with differents props values
  const compareProps = (obj1, obj2) => {
    const samePropsValues = [];
    const differentPropsValues = [];
    for (const prop in obj1) {
      if (prop in obj2) {
        if (typeof obj1[prop] === "object" && typeof obj2[prop] === "object") {
          JSON.stringify(obj1[prop]) === JSON.stringify(obj2[prop])
            ? samePropsValues.push(prop)
            : differentPropsValues.push(prop);
        } else if (obj1[prop] === obj2[prop]) {
          samePropsValues.push(prop);
        } else {
          differentPropsValues.push(prop);
        }
      }
    }

    return {
      samePropsValues,
      differentPropsValues,
    };
  };
  const comparisonResult = compareProps(inititalSummaryData, formBenchmarkData);

  //set differnt props to editedData object and execute action
  const compareForm = (action, defaultValues) => {
    if (action !== "edit") return;

    // console.log('editedData', editedData);
    // console.log('formBenchmarkData', formBenchmarkData);
    if (comparisonResult.differentPropsValues.length > 0) {
      comparisonResult.differentPropsValues.forEach((prop) => {
        editedData[prop] = formBenchmarkData[prop];
      });
      setEditedSummaryData(editedData);
      handleAction(action, editedData);
    } else {
      // console.log(`There are no changes detected at the props: ${comparisonResult.samePropsValues.join(', ')}`);
      enqueueSnackbar('No changes to save', { variant: 'info' });
      console.log("No changes to save");
    }
    onClose();
  };

  const changeFormBenchmarkData = (updatedData) => {
    // console.log('form field: ' + name +'='+ newValue);
    // Merge updatedValues into formBenchmarkData using object spread
    const newForm = {
      ...formBenchmarkData,
      ...updatedData,
    };
    // Set the updated formBenchmarkData
    setFormBenchmarkData(newForm);
  };

  // Get benchmark model if entering last step
  function getSuggestedModel(formbenchmarkData) {
    // Return if any of these fields are empty
    if (
      formBenchmarkData.project_id === "" ||
      formBenchmarkData.benchmark_name.trim() === "" ||
      formBenchmarkData.orient === "" ||
      (formBenchmarkData.input_vars.length === 0 &&
        formBenchmarkData.output_vars.length === 0) ||
      formBenchmarkData.target_values_type === "" ||
      formBenchmarkData.apply_weight_restriction === ""
    ) {
      console.log("SummaryWS useEffect: empty fields");
      console.log("formBenchmarkData", formBenchmarkData);
      changeFormBenchmarkData("dea_model", "");
      return;
    }

    console.log("Get Benchmark Model");
    let data = JSON.parse(JSON.stringify(formBenchmarkData));
    data.action = "model";

    // rename certain fields
    data.objective = data.orient;

    // Clean list field
    data.input_vars = data.input_vars.map((item) => {
      return item.id;
    });
    data.output_vars = data.output_vars.map((item) => {
      return item.id;
    });
    data.restricted_vars = data.restricted_vars.map((item) => {
      return item.id;
    });
    data.undesirable_vars = data.undesirable_vars.map((item) => {
      return item.id;
    });
    data.segmentation_scenario_id = data.segmentation_scenario
      ? data.segmentation_scenario.id
      : null;

    delete data.segmentation_scenario;
    //TO DO: remove this line when the API is fixed
    data.user_weight_restrictions = []
    console.log("SummaryWS data", data);
    // console.log('SummaryWS formData', formData);

    const targetURL = "/project/benchmark/";
    // console.log('targetURL: '+targetURL)
    post_json(targetURL, data)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + " : " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Suggested Scenario: ", data.dea_model);
        changeFormBenchmarkData({ dea_model: data.dea_model });
        setSaveButtonState(false);
        return;
      })
      .catch((error) => {
        console.log("Error: ", error);
        changeFormBenchmarkData({ dea_model: "could not compute" });
        setSaveButtonState(true);
        return;
      });
    return;
  }
  useEffect(() => {
    getSuggestedModel(formBenchmarkData);
  }, [formBenchmarkData.restricted_vars, formBenchmarkData.undesirable_vars, formBenchmarkData.benchmarkvars, formBenchmarkData.orient, formBenchmarkData.target_values_type]);
  return (
    <Dialog open={open} onClose={onClose} fullScreen fullWidth={true}>
      <DialogTitle
        sx={{
          display: "flex",
          background: "#47254b",
          color: "white",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseRounded color="white" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div style={{ marginTop: "10px" }}>
          <b>Summary</b>
        </div>
        <Divider />
        <NameDescManual
          summaryData={summaryData}
          formBenchmarkData={formBenchmarkData}
          setFormBenchmarkData={setFormBenchmarkData}
        />
        {summaryData?.status === "completed" ? null : (
          <>
            <BenchmarkSetupManual
              summaryData={summaryData}
              formBenchmarkData={formBenchmarkData}
              setFormBenchmarkData={setFormBenchmarkData}
              changeFormBenchmarkData={changeFormBenchmarkData}
            />

            <VariablesEditManual
              summaryData={summaryData}
              formBenchmarkData={formBenchmarkData}
              setFormBenchmarkData={setFormBenchmarkData}
              changeFormBenchmarkData={changeFormBenchmarkData}
              datasetData={dataSetValuesData}
            />

            <VariableTreatmentsManual
              summaryData={summaryData}
              formBenchmarkData={formBenchmarkData}
              setFormBenchmarkData={setFormBenchmarkData}
              changeFormBenchmarkData={changeFormBenchmarkData}
            />
            <MultiPeriodManual
              summaryData={summaryData}
              formBenchmarkData={formBenchmarkData}
              setFormBenchmarkData={setFormBenchmarkData}
              changeFormBenchmarkData={changeFormBenchmarkData}
            />
            {formBenchmarkData.apply_tracking_cycles ? (
              <>
                <PeriodInformationManual
                  summaryData={summaryData}
                  formBenchmarkData={formBenchmarkData}
                  setFormBenchmarkData={setFormBenchmarkData}
                  changeFormBenchmarkData={changeFormBenchmarkData}
                />
                <TrackingCyclesManual
                  summaryData={summaryData}
                  formBenchmarkData={formBenchmarkData}
                  setFormBenchmarkData={setFormBenchmarkData}
                  changeFormBenchmarkData={changeFormBenchmarkData}
                />
              </>
            ) : null}
            <SegmentationModelManual
              summaryData={summaryData}
              formBenchmarkData={formBenchmarkData}
              setFormBenchmarkData={setFormBenchmarkData}
              changeFormBenchmarkData={changeFormBenchmarkData}
              datasetData={dataSetValuesData}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {/* <Button
          onClick={() => {
            getSuggestedModel(formBenchmarkData);
          }}
          color="primary"
          variant="text"
          autoFocus
        >
          Apply
        </Button> */}
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={saveButtonState}
          onClick={() => {
            compareForm("edit", summaryData);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

//EXPORTED TO EACH CHILD SECTION
export function LabelGrid({ children, label }) {
  return (
    <Grid item xs={12} sm={3} sx={{}}>
      <Box sx={{ minWidth: 100 }}>{children}</Box>
    </Grid>
  );
}

export function ValueGrid({
  children,
  value = null,
  type = "text",
  required = false,
}) {
  return (
    <Grid item xs={12} sm={9} sx={{}}>
      <Box sx={{ minWidth: 100 }}>
        {required && type === "text" ? (
          value || value !== "" ? (
            capitalizeFirstLetter(value)
          ) : (
            <div style={{ color: "red" }}>Missing required*</div>
          )
        ) : null}
        {!required && type === "text" && value !== "" && value
          ? capitalizeFirstLetter(value)
          : null}
        {required && type === "list" ? (
          value.length === 0 ? (
            <div style={{ color: "red" }}>Missing required*</div>
          ) : null
        ) : null}
        {children}
      </Box>
    </Grid>
  );
}

/*Capitalizes first letter of a string*/
export function capitalizeFirstLetter(string) {
  // console.log('capitalizeFirstLetter', string)
  if (!string || string === "") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
