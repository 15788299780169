import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Box,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useContext, useCallback } from "react";
import { get_new } from "../../../utils/httpClient";
import ChartBoxPlot from "./chart_boxplot";
import ChartHistogram from "./chart_histogram";
import ChartTimeSeries from "./chart_time_series";
import { DatasetContext } from "../datasetDetails";
import { numFormatFloat } from "../../../utils/numeralFormatter";

export default function VariableDetails() {
  //const contextDataset = useContext(ContextDataset);
  const { dataset, state, setState } = useContext(DatasetContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // USE STATE
  const [varDetailState, setVarDetailState] = useState({
    tab: 0,
    variable_quantile: {},
    variable_descriptive: {},
    data_boxplot: [],
    data_histogram: [],
    data_time_series: [],
  });

  // CHANGE FILTER
  const changeFilter = useCallback(
    (name, value) => {
      const newFilter = {
        ...state.filters,
        ...{
          [name]: value,
        },
      };
      setState((prevState) => {
        return {
          ...prevState,
          ...{
            filters: newFilter,
          },
        };
      });
    },
    [state.filters, setState]
  );

  // CHANGE LOCAL STATE
  const handleChangeLocalState = (name, value) => {
    console.log("handleChangeLocalState", name, value);
    setVarDetailState((prevState) => {
      return {
        ...prevState,
        ...{
          [name]: value,
        },
      };
    });
  };

  useEffect(() => {
    //if no variable is selected, select first on the list
    if (!state.filters.variable && dataset.variables.length > 0) {
      const numericalVariable = dataset.variables.filter((variable) => {
        return variable.var_type?.toLowerCase() === "numerical";
      })[0];

      if (numericalVariable) {
        changeFilter("variable", numericalVariable.variable_detail_id);
      }
    }
  }, [state.filters.variable, dataset.variables, changeFilter]);

  // GET VARIABLE STATISTICS WHEN FILTERS CHANGE
  useEffect(() => {
    setLoading(true);
    // stop if no dataset_id
    if (!dataset.dataset_id) {
      console.log("Statistics exit 1");
      return;
    }

    // if no variable is selected select, stop
    if (!state.filters.variable && dataset.variables.length > 0) {
      console.log("Statistics exit 2");
      return;
    }

    // stop if multiple periods but no period is selected
    if (dataset.periods.length > 0 && !state.filters.period) {
      console.log("Statistics exit 3");
      return;
    }

    console.log("Getting variable Statistics");
    const newVarDetailState = {};

    let targetURL = `/dataset/statistics/?dataset_id=${dataset.dataset_id}`;
    targetURL += "&period=" + state.filters.period;

    // Get original field name until endpoint includes variable_id
    let variable_original_field_name = dataset.variables.filter((row) => {
      return row.variable_detail_id === state.filters.variable;
    })[0].original_field_name;
    targetURL +=
      "&variable_detail__original_field_name=" + variable_original_field_name;
    console.log("targetURL", targetURL);

    get_new(targetURL)
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve datasets");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("var detail data", data);
        let varData = data.results[0];

        // Quantile statistics
        /* 
				TODO: create a function to format depending on the var type and apply it
				to the render and not here
				*/
        newVarDetailState.variable_quantile = {
          Minimum: varData.min ? numFormatFloat(varData.min) : "N/A",
          "5-th percentile": varData.percentile_5_th
            ? numFormatFloat(varData.percentile_5_th)
            : "N/A",
          q1: varData.q1 ? numFormatFloat(varData.q1) : "N/A",
          median: varData.median ? numFormatFloat(varData.median) : "N/A",
          q3: varData.q3 ? numFormatFloat(varData.q3) : "N/A",
          "95-th percentile": varData.percentile_95_th
            ? numFormatFloat(varData.percentile_95_th)
            : "N/A",
          maximum: varData.max ? numFormatFloat(varData.max) : "N/A",
          range: varData.range ? numFormatFloat(varData.range) : "N/A",
          "interquartile range (IQR)": varData.iqr
            ? numFormatFloat(varData.iqr)
            : "N/A",
        };

        // Descriptive statistics
        newVarDetailState.variable_descriptive = {
          Type: varData.var_type,
          "Standard deviation": varData.std
            ? numFormatFloat(varData.std)
            : "N/A",
          "Coefficient of  variation (CV)": varData.cv
            ? numFormatFloat(varData.cv)
            : "N/A",
          Kurtosis: varData.kurtosis ? numFormatFloat(varData.kurtosis) : "N/A",
          Mean: varData.mean ? numFormatFloat(varData.mean) : "N/A",
          "Median Absolute Deviation (MAD)": varData.mad
            ? numFormatFloat(varData.mad)
            : "N/A",
          Skewness: varData.skewness ? numFormatFloat(varData.skewness) : "N/A",
          Sum: varData.sum ? numFormatFloat(varData.sum) : "N/A",
          Variance: varData.variance ? numFormatFloat(varData.variance) : "N/A",
          Monotonicity: varData.monotonicity,
        };
        console.log("Variable statistics", newVarDetailState);
        setVarDetailState((prevState) => {
          return {
            ...prevState,
            ...newVarDetailState,
          };
        });
        return;
      })
      .catch((error) => {
        console.log("Error: " + error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state.filters, dataset]);

  // GET CHART DATA
  useEffect(() => {
    // if no dataset_id or variable filters, stop
    if (!dataset.dataset_id || !state.filters.variable) {
      console.log("Charts exit 1");
      return;
    }

    // if multiple periods but none selected, stop
    if (dataset.periods.length > 0 && !state.filters.period) {
      console.log("Charts exit 2");
      return;
    }

    // Get Boxplot Data
    if (varDetailState.tab === 0) {
      console.log("Getting boxplot data");

      let targetURL = "/dataset/rawlong/?dataset_id=" + dataset.dataset_id;
      targetURL += "&period=" + state.filters.period;
      // get variable name as temp step until endpoint includes variable_id
      let variable_original_field_name = dataset.variables.filter((row) => {
        return row.variable_detail_id === state.filters.variable;
      })[0].original_field_name;
      targetURL += "&metric=" + variable_original_field_name;

      console.log("boxplot targetURL", targetURL);

      get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            console.log("Could not retrieve datasets");
            console.log("Response Error" + response.status);
            throw new Error(response.status + " : " + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          console.log("boxplot data", data.results);
          setVarDetailState((prevState) => {
            return {
              ...prevState,
              ...{
                data_boxplot: data.results,
              },
            };
          });
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
          setError(error);
        });
    }

    // Get Histogram Data
    if (varDetailState.tab === 1) {
      console.log("Getting Histogram Data");

      let targetURL = "/dataset/score_bin/?dataset_id=" + dataset.dataset_id;
      targetURL += "&period=" + state.filters.period;
      // get variable name as temp step until endpoint includes variable_id
      let variableName = dataset.variables.filter((row) => {
        return row.variable_detail_id === state.filters.variable;
      })[0].original_field_name;

      targetURL += "&metric=" + variableName;
      console.log("histogram targetURL", targetURL);

      get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.status + " : " + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          console.log("histogram data", data.results);
          setVarDetailState((prevState) => {
            return {
              ...prevState,
              ...{
                data_histogram: data.results,
              },
            };
          });
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    }

    // Get Time Series Data
    if (varDetailState.tab === 2) {
      // exit if dataset doesn't have multiple periods
      if (dataset.periods.length === 0) {
        console.log("Time series exit 1");
        return;
      }
      console.log("Getting time series data");

      let targetURL = "/dataset/statistics/?dataset_id=" + dataset.dataset_id;
      // get variable name as temp step until endpoint includes variable_id
      let variableName = dataset.variables.filter((row) => {
        return row.variable_detail_id === state.filters.variable;
      })[0].original_field_name;
      targetURL += "&variable_detail__original_field_name=" + variableName;
      console.log("time series targetURL", targetURL);

      get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.status + " : " + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          console.log("time series data", data.results);
          setVarDetailState((prevState) => {
            return {
              ...prevState,
              ...{
                data_time_series: data.results.filter((row) => {
                  return row.period.toLowerCase() !== "all";
                }),
              },
            };
          });
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
          setError(error);
        });
    }
  }, [state.filters, varDetailState.tab, dataset]);

  // Helper function to get variable name from variable ID
  function getVariableName(variable_id) {
    let variable_name = dataset.variables.filter((row) => {
      return row.variable_detail_id === variable_id;
    })[0].original_field_name;

    console.log("variable_name");
    return variable_name;
  }

  return (
    <>
      <Typography fontSize="1.2rem" fontWeight="bold" marginBottom="20px">
        Exploratory Data Analysis
      </Typography>

      <Grid container spacing={2} marginBottom="20px">
        <Grid item>
          <Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
            Variable
          </Typography>
          <Select
            displayEmpty
            value={state.filters.variable}
            size="small"
            onChange={(event) => changeFilter("variable", event.target.value)}
            /*renderValue={(selected) => (!selected ? <em>Select Variable</em> : selected)}*/
          >
            {dataset.variables
              .filter((variable) => {
                return variable.var_type?.toLowerCase() === "numerical";
              })
              .map((row, index) => (
                <MenuItem key={index} value={row.variable_detail_id}>
                  {row.alternative_field_name ?? row.original_field_name}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        {dataset.periods.length > 0 ? (
          <Grid item>
            <Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
              Period
            </Typography>
            <Select
              value={state.filters.period}
              size="small"
              onChange={(event) => changeFilter("period", event.target.value)}
              renderValue={(selected) =>
                !selected ? <em>Select Period</em> : selected
              }
            >
              {dataset.periods.map((period, index) => (
                <MenuItem key={index} value={period.date_string}>
                  {period.date_string}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        ) : null}
      </Grid>

      {loading ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </DialogContent>
      ) : error ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          Error: {error.message}
        </DialogContent>
      ) : (
        state.filters.variable && (
          <>
            <TableContainer component={Paper}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Quantile Statistics
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {console.log("varDetailState", varDetailState)}
                      {Object.keys(varDetailState.variable_quantile).map(
                        (field, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <b>{field}</b>
                            </TableCell>
                            <TableCell>
                              {numFormatFloat(
                                varDetailState.variable_quantile[field]
                              ) ?? ""}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Descriptive Statistics
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(varDetailState.variable_descriptive).map(
                        (field, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <b>{field}</b>
                            </TableCell>
                            <TableCell>
                              {varDetailState.variable_descriptive[field]}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </TableContainer>

            <br />

            <Tabs
              value={varDetailState.tab}
              onChange={(event, value) => handleChangeLocalState("tab", value)}
            >
              <Tab label="Boxplot" value={0} />
              <Tab label="Histogram" value={1} />
              {dataset.periods.length > 0 && (
                <Tab label="Time Series" value={2} />
              )}
            </Tabs>
            <br />

            <Box>
              {varDetailState.tab === 0 && (
                <ChartBoxPlot
                  data={varDetailState.data_boxplot}
                  y={getVariableName(state.filters.variable)}
                />
              )}
            </Box>
            <Box>
              {varDetailState.tab === 1 && (
                <ChartHistogram
                  data={varDetailState.data_histogram}
                  x={getVariableName(state.filters.variable)}
                />
              )}
            </Box>
            <Box>
              {varDetailState.tab === 2 && (
                <ChartTimeSeries
                  data={varDetailState.data_time_series}
                  y={`${getVariableName(state.filters.variable)} (Means)`}
                />
              )}
            </Box>
          </>
        )
      )}
    </>
  );
}
