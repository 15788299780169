import { Button, styled, useMediaQuery } from '@mui/material'

export const ButtonLayout = styled(Button) (({ theme }) => ({
  color: 'white',
  borderRadius: 5,
  width: 179, 
  height: 40,
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 700,
  align: 'center',
  borderWidth: 0,
  textTransform: 'none',

  [theme.breakpoints.down("md")]: {
    width: 40,
    minWidth: '0px',
    borderRadius: '50px',
    height: 40,
    minHeight: 0
  },
}))
export const GreenButton = styled(ButtonLayout) (({ theme }) => ({
  backgroundColor: "#1AB759",
  color:'#FFF',

  '&:hover': {
    backgroundColor:'#018938'
  }
}));

export const BlueButton = styled(ButtonLayout) ({
  backgroundColor: "#0994FF",
  '&:hover': {
    backgroundColor:'#3265AA'
  }
})


export function PrimaryButton(props) {
  const isSmallScreen = useMediaQuery('(max-width: 900px)');
  return (
    <GreenButton onClick={props.onClick} >
      {isSmallScreen ? props.smallText : props.largeText}
    </GreenButton>
  )
}

export function SecondaryButton(props) {
  const isSmallScreen = useMediaQuery('(max-width: 900px)');
  return (
    <BlueButton onClick={props.onClick} sx={{isSmallScreen}}>
      {isSmallScreen ? props.smallText : props.largeText}
    </BlueButton>
  )
}