import React from "react";
import {
  Chart as ChartJS,
} from "chart.js";
import {
  BoxPlotController,
  BoxAndWiskers
} from "@sgratzl/chartjs-chart-boxplot";
import colors from '../../assets/variables.scss'
import { useEffect, useRef } from "react";

ChartJS.register(
  BoxPlotController,
  BoxAndWiskers,
);


export function EfficiencyScoresBoxPlot({api}) {
  const canvasRef = useRef(undefined);
  const chartRef = useRef(undefined);


  const data = React.useMemo(() => {
    const labels = [
      ""
    ];
    return {
      labels,
      datasets: [
        {
          label: "Dataset 1",
          data: [api && api.map( data => (data.efficiency * 100))],
          borderColor: colors.extra4,
          backgroundColor: '#BE80FF',
          itemRadius: 1
        }
      ]
    };
  }, [api]);

  useEffect(() => {
    chartRef.current = new ChartJS(canvasRef.current, {
      type: "boxplot",
      data: data,
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Efficiency Scores',
            color: '#4c4c4d',
            font: {
              size: 14,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const boxplotValues = [
                  `Min: ${context.parsed.min.toFixed(2)}`,
                  `25% Quartile: ${context.parsed.q1.toFixed(2)}`,
                  `Median: ${context.parsed.median.toFixed(2)}`,
                  `Mean: ${context.parsed.mean.toFixed(2)}`,
                  `75% Quartile: ${context.parsed.q3.toFixed(2)}`,
                  `Max: ${context.parsed.max}`
                ];
                return boxplotValues
              }
            }
          },
          datalabels: {display: false},
          legend: {display: false}
        },
        scales: {
          x: {
            grid:{display:false},
            display: true,
            title: {
              display: true,
              text: 'Efficiency ( % )',
              color: '#4c4c4d',
              font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2,
              },
              padding: {top: 0, left: 0, right: 0, bottom: 0}
            }
          },
          y: {
            display: false,
            beginAtZero: false,
            grace: "10%",
            title: {
              display: true,
              align: 'end',
              text: '',
              color: '#4c4c4d',
              font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2
              },
            }
          }
        }
      }
    });

    return () => {
      chartRef.current.destroy();
    };
  }, [data]);
  return <canvas ref={canvasRef} />;
}
