import { get_new } from "../../../../../utils/httpClient";

export const getPeriodFilterData = ({ benchmark_id, project_id }) => {
  const url = `/project/dataset-periods/?benchmark__project_id=${project_id}&benchmark_id=${benchmark_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    const options = data.count > 0
      ? data.results.map(p => ({ label: p.period, value: p.period }))
      : [{ label: 'All', value: 'All' }];
    return options;
  })
  .catch((error) => {
    console.error('Error fetching Period Filter data:', error);
    throw error;
  })
};

export const getEntityFilterData = ({ benchmark_id }) => {
  const url = `/project/benchmark/dmu/?benchmark_id=${benchmark_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    const options = data.results.map(p => ({label: p.dmu_id, value: p.dmu_id}))
    return options
  })
  .catch((error) => {
    console.error('Error fetching Entity Filter data:', error);
    throw error;
  })
};

export const getPeriodObjectData = ({ benchmark_id, project_id, periodValue}) => {
  const url = `/project/dataset-periods/?benchmark__project_id=${project_id}&period=${periodValue}&benchmark_id=${benchmark_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Period Object data:', error);
    throw error;
  })
};

export const getApiData = ({ benchmark_id, dmuValue, periodObject, orientValue}) => {
  const url = `/project/benchmark/results/dmu/metrics/?benchmark_id=${benchmark_id}&dmu_id=${dmuValue}&period=${periodObject?.period}&orient=${orientValue}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Period Object data:', error);
    throw error;
  })
};