export const SegmentationScenariosHeadCells = [
    {
      id: 'nameId',
      numeric: false,
      disablePadding: true,
      label: 'Name / ID',
    },
    {
      id: 'segments',
      numeric: true,
      disablePadding: false,
      label: 'Segments',
    },
    {
      id: 'method',
      numeric: true,
      disablePadding: false,
      label: 'Method',
      customDisplay: {xs: 'none', md: 'table-cell'},
    },
    {
      id: 'lastUpdate',
      numeric: true,
      disablePadding: false,
      label: 'Last Update',
      customDisplay: {xs: 'none', md: 'table-cell'},
    },
    {    
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {    
      id: 'Kebab',
      numeric: true,
      disablePadding: false,
    },
  ];