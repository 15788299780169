import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import { numeralFormatter } from '../../../../../utils/numeralFormatter';


const EfficiencyQuadrantTable = ({api}) => {

  return (
    <TableContainer component={Paper} sx={{width: 'auto', backgroundColor: '#F8F8FA', height: '100%', overflowY: 'auto'}}>
        <Table >
            <TableHead style={{ position: 'sticky', top: 0, backgroundColor: '#F8F8FA', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TableRow>
                <TableCell sx={{ '&:first-of-type': { paddingleft: '20px' }, paddingRight: '30px' }}></TableCell>
                <TableCell sx={{  paddingRight: '30px' }}>Entity</TableCell>
                <TableCell sx={{  paddingRight: '30px' }}>Outputs</TableCell>
                <TableCell sx={{ '&:last-of-type': { paddingRight: '20px' },  paddingRight: '30px' }}>Efficiency</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {api.map((row, index) => (
                <TableRow 
                    key={index}
                    sx={{ '&:last-child td': { border: 0 } }}
                >
                    <TableCell>{index}</TableCell>
                    <TableCell>{row.dmu_id}</TableCell>
                    <TableCell>{numeralFormatter(row.pc_output)}</TableCell>
                    <TableCell>{numeralFormatter(row.efficiency * 100)} %</TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>

  );
};

export default EfficiencyQuadrantTable;
