import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import AggregatedResultsDashboard from "./aggregatedResultsDashboard";
import IndividualResultsDashboard from "./individualResultsDashboard";
import MetricsDashboard from "./metricsDashboard";
import ComparisonResultsDashboard from "./comparisonResultsDashboard";
import MainUpperBar from "../../../components/common/mainUpperBar";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  AssessmentRounded,
  BubbleChartRounded,
  PieChartRounded,
  TripOriginRounded,
} from "@mui/icons-material";
import {
  getApiData,
  getPeriodData,
} from "../services/resultsDashboardServices";
import ResultDashboardContext from "./context/resultDashboardContext";
import BenchmarkConfiguration from "../../benchmarkScenarios/benchmarkConfiguration";
import { handlePublishAction } from "../../home/projectDetails/benchmarkScenarios/menuOptions/services/publishActionPatch";
import { getSummaryData } from "../../benchmarkScenarios/services/benchmarkConfigurationServices";
import StatusBanner from "../../../components/common/statusBanner";

const DashboardContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexFlow: "column wrap",
});

const TabsContainer = styled(Tabs)({
  backgroundColor: "#F5F5F5",
  border: "1px solid #E8E8E8;",
  width: "100%",
});

const CustomTab = styled(Tab)({
  color: "#626262",
  minHeight: 0,
  height: 50,
  "&.Mui-selected": {
    color: "#FFFFFF",
    backgroundColor: "#47254B",
    borderRadius: "10px 10px 0 0",
    boxShadow: "4px 8px 4px rgba(0, 0, 0, 0.25)",
  },
});

export default function ResultsDashboard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // URL STATE
  const [searchParams] = useSearchParams();
  const benchmark_id = searchParams.get("benchmark_id");
  const project_id = searchParams.get("project_id");
  const [api, setApi] = useState([]);
  const [periods, setPeriods] = useState([]);
  const storedTabValue = sessionStorage.getItem("benchmarkstabValue");
  const [value, setValue] = useState(
    storedTabValue ? Number(storedTabValue) : 0
  );
  const [frontierEntityValue, setFrontierEntityValue] = useState("");
  const [openConfig, setOpenConfig] = useState(false);
  const [summaryData, setSummaryData] = useState({'benchmark_name': '','benchmark_id': '',});

  /* VIEW OPTION HANDLERS */
  const handleOpenConfig = () => {
    setOpenConfig(true);
  };

  const handleCloseConfig = () => {
    setOpenConfig(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("benchmarkstabValue", newValue);
    sessionStorage.setItem("storedBenchmarkId", benchmark_id);
  };
  //To charge the componente check if the bechmark_id is the same as the stored one
  useEffect(() => {
    const storedBenchmarkId = sessionStorage.getItem("storedBenchmarkId");
    if (storedBenchmarkId !== benchmark_id) {
      setValue(0);
      sessionStorage.setItem("benchmarkstabValue", 0);
    }
  }, [benchmark_id]);

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //API AND RANK DATA FETCH
    Promise.all([
      getApiData({ benchmark_id }),
      getPeriodData({ project_id, benchmark_id }),
      getSummaryData({ project_id, scenario_id: benchmark_id })
    ])
      .then(([apidata, rankData, summaryData]) => {
        setApi(apidata);
        setPeriods(rankData);
        setSummaryData(summaryData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [benchmark_id, project_id]);

  return (
    <ResultDashboardContext.Provider
      value={{
        apply_segmentation: api?.[0]?.apply_segmentation,
        setValue: setValue,
        setFrontierEntityValue: setFrontierEntityValue,
        frontierEntityValue: frontierEntityValue,
      }}
    >
      <DashboardContainer>
        <MainUpperBar
          UpperTitle={
            api &&
            api[0] && (
              <span>
                <Link
                  to={`/projectDetails?project_id=${api[0]?.project_id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Project
                </Link>{" "}
                / Results
              </span>
            )
          }
        />
        <Grid
          container
          sx={{
            flexFlow: "column nowrap",
            justifyContent: "space-between",
            pb: "20px",
            gap: "10px",
          }}
        >
          <Grid item>
            <Typography fontSize="1.5rem" fontWeight="bold">
              {api && api[0]?.benchmark_name}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container sx={{ flexFlow: "row nowrap" }}>
              <Grid container sx={{ gap: "20px", alignItems: 'center' }}>
                <Grid item>
                  <Typography fontSize="0.9rem" fontWeight="bold">
                    Scenario Objective
                  </Typography>
                  <Typography fontSize="0.8rem">{api[0]?.type}</Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize="0.9rem" fontWeight="bold">
                    Project Name
                  </Typography>
                  <Link
                    to={"/projectDetails?project_id=" + project_id}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography fontSize="0.8rem">
                      {api[0]?.project_name}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Typography fontSize="0.9rem" fontWeight="bold">
                    Project ID
                  </Typography>
                  <Link
                    to={"/projectDetails?project_id=" + project_id}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography fontSize="0.8rem">
                      {api[0]?.project_id}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  {
                    api[0]?.status &&
                    <StatusBanner status={api[0]?.status}/>
                  }
                </Grid>
              </Grid>
              <Grid container sx={{ gap: "20px", justifyContent: 'flex-end', width: 'fit-content', flexFlow: 'row nowrap' }}>
                <Grid item>
                  <Button
                    onClick={() => handleOpenConfig()}
                    sx={{
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      padding: "5px 10px",
                    }}
                    size="small"
                    color="primary"
                    variant="outlined"
                    autoFocus
                  >
                    Scenario Details
                  </Button>
                </Grid>
                {
                  api[0]?.status === 'completed' ?
                  <Grid item>
                    <Button
                      onClick={() => { handlePublishAction('publish', summaryData) }}
                      variant='contained' 
                      autoFocus
                      sx={{
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        padding: "5px 10px",
                      }}
                    >
                      Publish Scenario
                    </Button>
                  </Grid>
                  :
                  null
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TabsContainer
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
          TabIndicatorProps={{ sx: { display: "none" } }}
          variant="fullWidth"
        >
          <CustomTab
            icon={<AssessmentRounded />}
            iconPosition="start"
            label="Aggregated"
          />
          <CustomTab
            icon={<TripOriginRounded fontSize="small" />}
            iconPosition="start"
            label="Individual"
          />
          <CustomTab
            icon={<PieChartRounded fontSize="small" />}
            iconPosition="start"
            label="Metrics"
            disabled={periods.length <= 1 ? true : false}
          />
          <CustomTab
            icon={<BubbleChartRounded />}
            iconPosition="start"
            label="Compare"
          />
        </TabsContainer>
        <Box sx={{ display: "flex", width: "100%" }}>
          {value === 0 && (
            <AggregatedResultsDashboard
              benchmark_id={benchmark_id}
              project_id={project_id}
              projectType={api?.[0]?.type}
            />
          )}
          {value === 1 && (
            <IndividualResultsDashboard
              benchmark_id={benchmark_id}
              project_id={project_id}
              apply_segmentation={api?.[0]?.apply_segmentation}
              projectType={api?.[0]?.type}
            />
          )}
          {value === 2 && (
            <MetricsDashboard
              benchmark_id={benchmark_id}
              project_id={project_id}
              apply_segmentation={api?.[0]?.apply_segmentation}
              projectType={api?.[0]?.type}
            />
          )}
          {value === 3 && (
            <ComparisonResultsDashboard
              benchmark_id={benchmark_id}
              project_id={project_id}
              projectType={api?.[0]?.type}
            />
          )}
        </Box>
        {
          <BenchmarkConfiguration
            open={openConfig}
            onClose={handleCloseConfig}
            project_id={project_id}
            scenario_id={benchmark_id}
          />
        }
      </DashboardContainer>
    </ResultDashboardContext.Provider>
  );
}
