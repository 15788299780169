import React from 'react';
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { numeralFormatter } from '../../utils/numeralFormatter';


ChartJS.register(
  PointElement,
  LineElement,
);

export const options = {
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  layout: {
    padding: {
      right: 25
    }
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Efficiency By Period',
      color: '#4c4c4d',
      font: {
        size: 14,
        weight: 'bold',
        lineHeight: 1.2,
      },
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Period',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2,
        },
        padding: {top: 0, left: 0, right: 0, bottom: 0}
      }
    },
    y: {
      display: true,
      grace: '5%',
      title: {
        display: true,
        align: 'end',
        text: 'Efficiency ( % )',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2
        },
        padding: {top: 0, left: 0, right: 0, bottom: 0}
      }
    }
  }
};


export default function IndividualEfficiencyByPeriod({dmu, segment, overall}) {
  overall?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  dmu?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  segment?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

    const labels = overall?.map(segment => segment.period);
    const data = {
    labels,
    datasets: [
        {
      label: dmu[0]?.dmu_id,
      data: dmu?.map(segment => (segment.efficiency * 100)),
      borderColor: colors.entity1,
      backgroundColor: colors.entity1,
      yAxisID: 'y',
      datalabels: {
        display: true,
        color: "white",
        align: 'center',
        borderRadius: 50,
        backgroundColor: colors.entity1,
        labels: {
          title: {
            font: {
              size: 10
            }
          },
        },
        formatter: (value) => {
          return `${numeralFormatter(value)}%`
        }
      },
    },
    segment ?
    {
        label: 'Segment',
        data: segment?.map(data => (data.avg_efficiency * 100)),
        borderColor: colors.entity2,
        backgroundColor: colors.entity2,
        yAxisID: 'y',
    } : null,
    {
        label: 'Overall',
        data: overall?.map(segment => (segment.avg_efficiency * 100)),
        borderColor: colors.entity3,
        backgroundColor: colors.entity3,
        yAxisID: 'y',
    }
  ].filter(Boolean),
};


  return <div style={{ width: '100%', height: '300px' }}>
    <Line options={options} data={data} />
  </div>
}
