import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { numeralFormatter } from '../../utils/numeralFormatter';

ChartJS.register(LinearScale, PointElement);

export const options = {
    maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Inter Cluster Distance Plot',
      color: '#4c4c4d',
      padding: {
        bottom: 30
    },
      font: {
        size: 14,
        weight: 'bold',
        lineHeight: 1.2,
      },
    },
    datalabels: {
      color: 'white',
      align: 'center',
      formatter: (value) => {
       return numeralFormatter(value.cluster_size)
      }
      
    },
    legend: {
      display: false
    },
  },
  scales: {
    x:{
      title: {
        display: true,
        align: 'center',
        text: 'MDS 2',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2
        },
      } 
    },
    y: {
      beginAtZero: true,
       title: {
        display: true,
        align: 'center',
        text: 'MDS 1',
        color: '#4c4c4d',
        font: {
          size: 10,
          weight: 'bold',
          lineHeight: 1.2
        },
      } 
    },
  }
};



export function InterClusterScatter({api, segment}) {
  
    const data = {
        datasets: [
        {
            label: 'dataset',
            data: api?.results?.[0]?.clusters?.map(coordinate => ({ 
              x: coordinate?.cluster_center_x_axe, 
              y: coordinate?.cluster_center_y_axe, 
              r: coordinate?.cluster_size >= 30 ? 30 : coordinate?.cluster_size <= 5 ? 5 : coordinate.cluster_size,
              cluster_size: coordinate?.cluster_size
            })),

            backgroundColor: (context) => {
              const value = segment !== "" ? segment : false
      
                return value === false ?  [colors.segment5, colors.segment2,colors.segment3,colors.segment4] :  
                value === 0 ? colors.segment5 : 
                value === 1 ? colors.segment2 : 
                value === 2 ? colors.segment3 : 
                colors.segment4
              }
        },
        ],
    };
  
  return <>
    <div style={{ width: '100%', height: '300px' }}>
      <Bubble options={options} data={data} />
    </div>
    <TableContainer component={Paper}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell>Segment</TableCell>
            <TableCell>MDS 1</TableCell>
            <TableCell>MDS 2</TableCell>
            <TableCell>Distance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {api?.results?.[0]?.clusters?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.cluster_cd}</TableCell>
              <TableCell>{numeralFormatter(row.cluster_center_y_axe)}</TableCell>
              <TableCell>{numeralFormatter(row.cluster_center_x_axe)}</TableCell>
              <TableCell>{numeralFormatter(row.cluster_size)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>  </>
}
