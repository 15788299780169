import React, {useState} from 'react';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as ViewIcon } from '../../assets/icons/tableKebab/viewLogo.svg';
import { IconButton } from '@mui/material';
import { DeleteOutline, EditOutlined} from '@mui/icons-material';
import { StyledKebabMenu } from '../../components/common/styledKebabMenu';
import DeleteProject from './menuOptions/deleteProject';
//import DeleteSegmentation from './menuOptions/deleteSegmentation';

export default function ProjectKebabMenu({project_id, setDeletedID=()=>{}}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  
  const open = Boolean(anchorEl);

  /* HANDLES FOR SCENARIO MENU */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* HANDLES FOR DELETE OPTION */
  const handleDelete = () => {
    console.log('delete: ', project_id);
    setOpenDelete(true);
    handleClose();

  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  }


  return (
    <>
      {/* -----KEBAB MENU----- */ }
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledKebabMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <ViewIcon style={{marginRight: 12}} />
          View
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <EditOutlined sx={{fill: 'rgba(146, 147, 147, 1)'}} />
          Edit
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleDelete} disableRipple>
          <DeleteOutline sx={{fill: 'rgba(146, 147, 147, 1)'}} />
          Delete
        </MenuItem>
      </StyledKebabMenu>
      </div>
      
      {/* -------- MENU ITEM COMPONENTS -------- */}
      {/* Specific actions for each menu item are being handled inside the menu item component  */}
      {openDelete && <DeleteProject
        open={openDelete}
        onClose={handleCloseDelete}
        project_id={project_id}
        setDeletedID={setDeletedID}
        />
      }
      
  </>

    
  );
}