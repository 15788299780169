import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import React from 'react'
import CircularProgressWithLabel from '../../../../graphs/efficiencyBarChart'
import { numeralFormatter } from '../../../../utils/numeralFormatter'
import { ShadowCardContentContainer } from '../../../../components/common/commonContainer'
import { GridContent } from '../aggregatedResultsDashboard'
import { useState } from 'react'
import { useEffect } from 'react'



export default function Summary({periodOptions, periodObject, entities, segmentOptions, efficiencySummary}) {
  const [loading, setLoading] = useState(true)
  const [currentPeriod, setCurrentPeriod] = useState([])
  const [baselinePeriod, setBaselinePeriod] = useState([])

  useEffect(() => {
    if(efficiencySummary?.length > 0) {
      const current = efficiencySummary.find(item => item.period === periodObject?.period)
      const baseline = efficiencySummary.find(item => item.period === periodObject?.benchmark_period)

      setCurrentPeriod(current)
      setBaselinePeriod(baseline)
    }
  },[periodOptions, periodObject, entities, segmentOptions, efficiencySummary])

  useEffect(() => {
    if( (efficiencySummary?.length > 0 && entities.count > 0) || Object.keys(periodObject).length > 0 ){
      setLoading(false)
    }
  },[periodOptions, periodObject, efficiencySummary, entities, segmentOptions])

  if(loading) {
    return (
      <Box sx={{display: 'flex', flexFlow: {xs: 'row wrap', md:'row'}, justifyContent: 'space-around'}}>
        <ShadowCardContentContainer sx={{
          width: {xs: '100%',lg:'100%'},
          flexFlow: 'column',
          alignItems: 'flex-start',
          flexGrow: '1',
          justifyContent: 'space-evenly',
          mb: '1rem'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}>
            <CircularProgress />
          </Box>
        </ShadowCardContentContainer>
      </Box>
    )
  }

  return (
    <Box sx={{display: 'flex', flexFlow: {xs: 'row wrap', md:'row'}, justifyContent: 'space-around'}}>
    <ShadowCardContentContainer sx={{
      width: {xs: '100%',lg:'100%'},
      flexFlow: 'column',
      alignItems: 'flex-start',
      flexGrow: '1',
      justifyContent: 'space-evenly',
      mb: '1rem'
    }}>
      <Box sx={{display: 'flex', flexFlow: 'row wrap', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', width: '100%', gap: '5px'}}>
        {
          periodOptions.options.length <= 1 ?
          <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" color="initial">{numeralFormatter(currentPeriod?.avg_efficiency * 100)}%</Typography>
            <Typography variant="body2" component="div">
              Avg Efficiency
            </Typography>
          </Box>
          : 
          <Box sx={{display: 'flex', gap: '10px', flexFlow: 'row wrap'}}>
            <GridContent>
              <Typography variant="h5" color="initial">{ numeralFormatter(currentPeriod?.avg_efficiency * 100)}%</Typography>
              <Typography variant="body2" color="initial">Avg. Efficiency</Typography>
            </GridContent>
            <GridContent>
              <Typography variant="h5" color="initial">{ numeralFormatter(((baselinePeriod?.avg_efficiency) * 100))}%</Typography>
              <Typography variant="body2" color="initial">Baseline</Typography>
            </GridContent>
            <GridContent>
              <Typography variant="h5" color="initial">{numeralFormatter(currentPeriod?.pct_improv_prev_period?.toFixed(5) * 100)}%</Typography>
              <Typography variant="body2" color="initial">P/P Improvement</Typography>
            </GridContent>
            <GridContent>
              <Typography variant="h5" color="initial">{numeralFormatter(currentPeriod?.pct_improv_benchmark_period?.toFixed(5) * 100)}%</Typography>
              <Typography variant="body2" color="initial">P/B Improvement</Typography>
            </GridContent>
          </Box>
        }
        <Divider variant="fullWidth" orientation="vertical" flexItem/>
        <Box sx={{display: 'flex', gap: '10px'}}>
          {
             periodOptions.options.length <= 1 ?
             null
             :
            <Box sx={{ borderRadius: '8px', margin: '0 1rem'}}>
              <Typography variant="h5" color="initial">{periodObject?.period}</Typography>
              <Typography variant="body2" color="initial">Current Period</Typography>
            </Box>
          }
          {
            periodObject.period === 'All' ?
            null
            :
            <Box sx={{ borderRadius: '8px'}}>
              <Typography variant="h5" color="initial">{periodObject?.benchmark_period}</Typography>
              <Typography variant="body2" color="initial">Baseline Period</Typography>
            </Box>
          }
        </Box>
        {
          periodObject.period === 'All' ?
          null
          :
          <Divider variant="fullWidth" orientation="vertical" flexItem/>
        }
        <Box sx={{display: 'flex', gap: '10px'}}>
          <Box sx={{ borderRadius: '8px', margin: '10px'}}>
            <Typography variant="h5" color="initial">{entities.count}</Typography>
            <Typography variant="body2" color="initial">Entities</Typography>
          </Box>
          {
            segmentOptions.options.length <= 1  || segmentOptions.options.some(item => Object.values(item).some(value => value === null)) ?
            null
            :
          <Box sx={{ borderRadius: '8px', margin: '10px'}}>
            <Typography variant="h5" color="initial">{segmentOptions.value === "" ? "All" : segmentOptions.value}</Typography>
            <Typography variant="body2" color="initial">Segment</Typography>
          </Box>
          }
        </Box>                  
        </Box>
    </ShadowCardContentContainer>
  </Box>
  )
}
