import React from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { numeralFormatter } from '../../../utils/numeralFormatter';

export default function VariableTable({api}) {

  return (
    <Box sx={{ width: '80%', mt: '1rem', pb: '15px', display: 'table', tableLayout: 'fixed', overflow: 'auto'}}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Segment</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Mean</TableCell>
                <TableCell>Std</TableCell>
                <TableCell>Min</TableCell>
                <TableCell>25%</TableCell>
                <TableCell>50%</TableCell>
                <TableCell>75%</TableCell>
                <TableCell>Max</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {api?.results?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.cluster_cd}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{numeralFormatter(row.mean)}</TableCell>
                  <TableCell>{numeralFormatter(row.std)}</TableCell>
                  <TableCell>{numeralFormatter(row.min)}</TableCell>
                  <TableCell>{numeralFormatter(row.number_25_pct)}</TableCell>
                  <TableCell>{numeralFormatter(row.number_50_pct)}</TableCell>
                  <TableCell>{numeralFormatter(row.number_75_pct)}</TableCell>
                  <TableCell>{numeralFormatter(row.max)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
    )
}
