import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { numeralFormatter } from '../../utils/numeralFormatter';

ChartJS.register(
  BarElement,
);

export default function EfficiencyDistributionPerBinGraph({ value, api}) {

  const options = {
    maintainAspectRatio: false,
    responsive: true,
      plugins: {
      title: {
        display: true,
        text: 'Efficiency Distribution Per Bin',
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (ctx) => {
          return value === 0 ? ctx : `${numeralFormatter(ctx * 100)} %`
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {display: false},
        display: true,
        stacked: false,
        title: {
          display: true,
          text: 'Efficiency Bins',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      },
      y: {
        display: true,
        stacked: false,
        font:{size: 20},
        afterFit: (ctx) => {
          ctx.width = 40 // Set the desired width for the y-axis labels
        },
        grace: '20%',
        title: {
          align: 'end',
          display: true,
          text: 'Entities (n)',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
        },
        ticks: {
          display: false
        },
      },
    }
  };
  
  const summationsMap = api?.reduce((map, obj) => {
    const { bin, bin_count } = obj;
    const currentCount = map.get(bin) || 0;
    map.set(bin, currentCount + bin_count);
    return map;
  }, new Map());

  const sortedSummations = Array.from(summationsMap?.entries()).sort((a, b) => {
    const binRanges = ["0% - 60%", "60% - 70%", "70% - 80%", "80% - 90%", "90% - 100%"];
    return binRanges.indexOf(a[0]) - binRanges.indexOf(b[0]);
  });
  
  const summationValues = sortedSummations?.map(([_, bin_count]) => bin_count);
  

  const totalEntities = api.reduce( (accumulator, curr) => accumulator + curr.bin_count, 0)
  const labels = Array.from(new Set(api.map(obj => obj.bin)))
  .map(prop => api.find(obj => obj.bin === prop).bin);
  const data = {
  labels: labels,
  datasets: [
    {
      label: "bins",
      data: value === 0 ? summationValues?.map( d => d ) : summationValues?.map( d => d / totalEntities),
      backgroundColor: [colors.bin060, colors.bin6070, colors.bin7080, colors.bin8090, colors.bin90100],
    },    
  ],
};

  return <div style={{ width: '100%', height: '90%'}}>
    <Bar options={options} data={data}/>
  </div>
}
