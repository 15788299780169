import React from 'react';
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { Box } from '@mui/material';
import { numeralFormatter } from '../../utils/numeralFormatter';

ChartJS.register(
  PointElement,
  LineElement,
);



export default function VariableTimeseries({metricName, api}) {

  const options = {
    animation: false,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: metricName,
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      legend: {display: true}
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Period',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      },
      y: {
        display: true,
        grace: "10%",
        ticks: {
          beginAtZero: true
        },
        title: {
          display: true,
          align: 'end',
          text: metricName,
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      }
    }
  };
  
  
  const labels = api?.map(period => period.period);
  
  const data = {
    labels,
    datasets: [
      {
        label: 'Actual',
        data: api.map(period => period.actual_value),
        borderColor: colors.cluster1,
        backgroundColor: colors.cluster1,
        yAxisID: 'y',
        datalabels: {
          display: true,
          color: "white",
          align: 'center',
          borderRadius: 50,
          backgroundColor: colors.cluster1,
          labels: {
            title: {
              font: {
                size: 10
              }
            },
          },
          formatter: (value) => {
            return `${numeralFormatter(value)}`
          }
        },
      },
  
      {
        label: 'Target',
        data: api?.map(period => period.target),
        borderColor: colors.cluster2,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderDash: [5],
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        yAxisID: 'y',
        datalabels: {
          display: false,
          formatter: (value) => {
            return `${numeralFormatter(value)}`
          }
        },
      },
      
    ],
  };
  return <Box sx={{ width: {xs:'100%', sm: '48%'}, height: '300px'}}>
    <Line options={options} data={data} />
   
  </Box>
}
