import { CloseRounded } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { useState } from 'react';

export default function EfficiencyFrontierModal({open, onClose, entityList, setValue, setFrontierEntityValue}) {
    const [selectedEntity, setSelectedEntity] = useState('')
    const handleEntitySelector = () => {
        setValue(1)
        setFrontierEntityValue(selectedEntity)
        onClose()
      };
  return (
    <Dialog
        open={open} 
        onClose={onClose}
        maxWidth='xs'
        fullWidth={true}
    >
        <DialogTitle
            sx={{ display: 'flex', background: '#47254b', color: 'white', justifyContent: 'flex-end'}}
        >
            <IconButton onClick={onClose} sx={{color: 'white'}}>
                <CloseRounded color='white'/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
        <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Select an entity for more information:</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={selectedEntity}
        onChange={(event) => setSelectedEntity(event.target.value)}
      >
        {
            entityList.map(entity => {
                return <FormControlLabel value={entity} control={<Radio />} label={entity} />
            })
        }
      </RadioGroup>
    </FormControl>
        </DialogContent>
        <DialogActions>
            <Button disabled={selectedEntity === '' ? true : false} onClick={() => handleEntitySelector()} color='primary' variant='text' autoFocus>
                Apply
            </Button>
        </DialogActions>
    </Dialog>
  )
}
