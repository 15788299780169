import { get_new } from "../../../../../../utils/httpClient";

export const getScenarioData = ({ project_id, scenario_id }) => {
    const url = `/project/benchmarks/?project_id=${project_id}&benchmark_id=${scenario_id}`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching Benchmark Scenario data:', error);
    throw error;
  })
};

export const getSummaryData = ({ project_id, scenario_id }) => {
  const url = `/project/benchmark/?project_id=${project_id}&benchmark_id=${scenario_id}`;
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching Summary data:', error);
    throw error;
  })
};

export const getProjectData = ({ project_id }) => {
  const url = `/project/?project_id=${project_id}`;

  return get_new(url)
  .then((response) => {
      if(!response.ok) {
          console.log('Could not retrieve data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);      
      }
      return response.json()
  })
  .then((data) => data.results[0])
  .catch((error) => {
      console.error('Error fetching project data:', error);
      throw error;
  })
}

export const getDatasetDetails = ({ projectData }) => {
  const url = `/dataset/dataset_config/?dataset_id=${projectData.dataset_id}`;
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching datasets details data:', error);
    throw error;
  })
};

export const getDatasetInfo = ({ projectData }) => {
  const url = `/datasets/?dataset_id=${projectData.dataset_id}`;
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching datasets details data:', error);
    throw error;
  })
};



export const getDatasetPeriodData = ({ datasetDetails, periodicity }) => {
  const url = `/dataset/periods_list/?dataset_id=${datasetDetails.dataset_id}`;

  let newPeriodData = {
    'has_periods': true,
    'period_type': periodicity,
    'periods_list': []
}
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    newPeriodData.periods_list = data.results;
    return newPeriodData
  })
  .catch((error) => {
    console.error('Error fetching boxplot data:', error);
    throw error;
  })
};