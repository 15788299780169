import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import React from 'react'
import { MainCard, ShadowCardContentContainer } from '../../../../components/common/commonContainer'
import EntitiesBySegment from '../../../../graphs/aggregatedResults/entitiesBySegment'
import { GridContent } from '../aggregatedResultsDashboard'
import { useState, useEffect } from 'react'
import { numeralFormatter } from '../../../../utils/numeralFormatter'
import { getApiData, getRankData } from './services/highLevelAndEntitiesServices'

export default function IndividualhighLevelAndEntities({entity, periodObject, benchmark_id, periodOptions, orientValue}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)
  const [api, setApi] = useState([])
  const [rank, setRank] = useState([])

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //API AND RANK DATA FETCH
    Promise.all([
      getApiData({ benchmark_id, periodObject, entity, orientValue }),
      getRankData({ benchmark_id, periodObject, orientValue }),
    ])
    .then(([apidata, rankData]) => {
      setApi(apidata)
      setRank(rankData)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setError(error)
    })
    .finally(() => {
      setLoading(false);
    });
  }, [benchmark_id, periodObject, entity, orientValue]);
  
  if(loading) {
    return     <MainCard sx={{display: 'flex',flexFlow: 'row wrap', justifyContent: 'space-around'}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Box>
    </MainCard>
  }

  if(error) {
    return     <MainCard sx={{display: 'flex',flexFlow: 'row wrap', justifyContent: 'space-around'}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        Error: {error.message}
      </Box>
    </MainCard>

  }

  return (
    <MainCard sx={{display: 'flex',flexFlow: 'row wrap', justifyContent: 'space-around', minHeight: 0}}>
      <>
      {
        periodOptions.length <= 1 ?
        <ShadowCardContentContainer sx={{
          width: '100%',
          flexFlow: 'column',
          alignItems: 'baseline',
          flexGrow: '0',
          height: 'fit-content'
          }}>
            <Box sx={{display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
              <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5">{numeralFormatter(api[0]?.efficiency * 100)}%</Typography>
                <Typography variant="body2" component="div">
                 Efficiency
                </Typography>
              </Box>
              <Divider variant="fullWidth" orientation="vertical" flexItem/>
              {
                periodObject?.period === 'All' ?
                null
                :
                <>
                  <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h6" color="initial">Benchmark Period</Typography>
                    <Typography variant="caption" color="initial">{periodObject?.benchmark_period}</Typography>
                  </Box>

                  <Divider variant="fullWidth" orientation="vertical" flexItem/>
                </>
                
              }
                <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="h6" color="initial">Entities</Typography>
                  <Typography variant="caption" color="initial">{rank}</Typography>
                </Box>
                {
                  api[0]?.cluster_cd ?
                  <>
                    <Divider variant="fullWidth" orientation="vertical" flexItem/>

                    <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" color="initial">Segment</Typography>
                      <Typography variant="caption" color="initial">{api[0]?.cluster_cd}</Typography>
                    </Box>              
                  </>
                  :
                  null
                }
            </Box>
          </ShadowCardContentContainer>
        :
        <ShadowCardContentContainer sx={{
            width: {xs: '100%',lg:  periodOptions.length > 1 && (api?.[0]?.cluster_cd || api?.[0]?.cluster_cd === 0) ? '50%' : '100%'},
            flexFlow: 'row',
            alignItems: 'center',
            flexGrow: '0',
            }}>
              <Box 
                sx={{    
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-evenly',
                  flexFlow: 'row wrap',
                  width: '-webkit-fill-available',
                  flexGrow: '1',
              
                  gap: '0px',
                  padding: 0,
                }}
              >
                <Box sx={{display: 'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                  <GridContent>
                    <Typography variant="h5" color="initial">{numeralFormatter(api[0]?.efficiency * 100)}%</Typography>
                    <Typography variant="body2" color="initial">Efficiency</Typography>
                  </GridContent>
                  <Divider variant="middle" flexItem />
                  <GridContent>
                    <Typography variant="h5" color="initial">{numeralFormatter((api[0]?.efficiency - api[0]?.pct_improv_benchmark_period) * 100)}%</Typography>
                    <Typography variant="body2" color="initial">Baseline Efficiency</Typography>
                  </GridContent>
                </Box>
                
                <Divider variant="fullWidth" orientation="vertical" flexItem/>
                
                <Box sx={{display:'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                  <GridContent>
                    <Typography variant="h5" color="initial">{numeralFormatter(api?.[0]?.pct_improv_prev_period.toFixed(5) * 100)}%</Typography>
                    <Typography variant="body2" color="initial">P/P Improvement</Typography>
                  </GridContent>
                  <Divider variant="middle" flexItem />
                  <GridContent>
                    <Typography variant="h5" color="initial">{isNaN(api[0]?.row_no_in_period_vs_period) ? 0 : api[0]?.row_no_in_period_vs_period}/{rank}</Typography>
                    <Typography variant="body2" color="initial">Rank</Typography>
                    <Typography variant="body2" color="initial">P/P Improvement</Typography>
                  </GridContent>
                </Box>
                <Box sx={{display:'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                  <GridContent>
                    <Typography variant="h5" color="initial">{numeralFormatter(api?.[0]?.pct_improv_benchmark_period.toFixed(5) * 100)}%</Typography>
                    <Typography variant="body2" color="initial">P/B Improvement</Typography>
                  </GridContent>
                  <Divider variant="middle" flexItem />
                  <GridContent>
                    <Typography variant="h5" color="initial">{isNaN(api[0]?.row_no_in_benchmark_vs_period) ? 0 : api[0]?.row_no_in_benchmark_vs_period}/{rank}</Typography>
                    <Typography variant="body2" color="initial">Rank</Typography>
                    <Typography variant="body2" color="initial">P/B Improvement</Typography>
                  </GridContent>
                </Box>

                <Divider variant="fullWidth" orientation="vertical" flexItem/>
                
                <Box sx={{display:'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                  <GridContent>
                    <Typography variant="h5" color="initial">{periodObject?.period}</Typography>
                    <Typography variant="body2" color="initial">Current Period</Typography>
                  </GridContent>
                  <Divider variant="middle" flexItem />
                  <GridContent>
                    <Typography variant="h5" color="initial">{periodObject?.benchmark_period}</Typography>
                    <Typography variant="body2" color="initial">Baseline Period</Typography>
                  </GridContent>
                </Box>
              </Box>
          </ShadowCardContentContainer>
      }

      {
        periodOptions.length > 1 && (api?.[0]?.cluster_cd || api?.[0]?.cluster_cd === 0) ?
            <ShadowCardContentContainer  
            sx={{
              width: {xs: '100%',lg:'41%'},
              flexFlow: 'column',
              alignItems: 'flex-start',
              flexGrow: '0',
              }}>
                <Box sx={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', width: '100%'}}>
                    <Typography variant="h5" sx={{padding: '10px'}}>Entities & Segments</Typography>
                </Box>
            <Divider variant="fullWidth" sx={{width: '-webkit-fill-available'}}/>
            <Box sx={{display: 'flex', flex: 1, alignItems: 'center', width: '90%', flexFlow: 'row wrap', justifyContent: 'center'}}>
                <EntitiesBySegment benchmark_id={benchmark_id} entity={entity} periodObject={periodObject}/>
              <Box sx={{display: 'flex', flexFlow: 'column', width: {xs: '90%',md:'30%'}}}>
                  <Box sx={{backgroundColor: '#F5F5F5', padding: '10px', borderRadius: '8px', margin: '10px', width: '-webkit-fill-available'}}>
                    <Typography variant="h6" color="initial">Entities</Typography>
                    <Typography variant="caption" color="initial">{rank}</Typography>
                  </Box>
                  <Box sx={{backgroundColor: '#F5F5F5', padding: '10px', borderRadius: '8px', margin: '10px', width: '-webkit-fill-available'}}>
                    <Typography variant="h6" color="initial">Segment</Typography>
                    <Typography variant="caption" color="initial">{api[0]?.cluster_cd}</Typography>
                  </Box>
                </Box>
            </Box>
        </ShadowCardContentContainer>
        :
        null
      }
      </>
    </MainCard>
  )
}