import React from "react";
import {
  Chart as ChartJS,
} from "chart.js";
import {
  BoxPlotController,
  BoxAndWiskers
} from "@sgratzl/chartjs-chart-boxplot";
import colors from '../../assets/variables.scss'
import { useRef } from "react";
import { useMemo } from "react";


ChartJS.register(
  BoxPlotController,
  BoxAndWiskers,
);


export function EfficiencyBySegmentsBoxplot({api}) {
  const canvasRef = useRef(undefined);
  const chartRef = useRef(undefined);

  const groupedArray = Object.values(api.reduce((acc, obj) => {
    const { cluster_cd } = obj;
    acc[cluster_cd] = [...(acc[cluster_cd] || []), obj];
    return acc;
  }, {}));
  const segmentsArray = Array.from(new Set(groupedArray?.map(group => group[0]?.cluster_cd)));
  const data = useMemo(() => {
    const labels = api?.[0]?.cluster_cd === null ? [''] : segmentsArray;

    return {
      labels,
      datasets: [
        {
          label: "Dataset 1",
          data: groupedArray?.map( value => value.map(val=>val?.efficiency * 100))
        ,
          borderColor: colors.extra4,
          backgroundColor: ['#FF548F','#9061C2','#BE80FF','#63D3FF']
        },
      ]
    };
  }, [groupedArray, segmentsArray, api]);

  React.useEffect(() => {
    chartRef.current = new ChartJS(canvasRef.current, {
      type: "boxplot",
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Efficiency By Segment',
            color: '#4c4c4d',
            font: {
              size: 14,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          datalabels: {display: false},
          legend: {display: false},

          tooltip: {
            callbacks: {
              label: (context) => {
                const boxplotValues = [
                  `Min: ${context.parsed.min.toFixed(2)}`,
                  `25% Quartile: ${context.parsed.q1.toFixed(2)}`,
                  `Median: ${context.parsed.median.toFixed(2)}`,
                  `Mean: ${context.parsed.mean.toFixed(2)}`,
                  `75% Quartile: ${context.parsed.q3.toFixed(2)}`,
                  `Max: ${context.parsed.max.toFixed(2)}`
                ];
                return boxplotValues
              }
            }
          },
        },

        scales: {
          x: {
            display: true,
            grid: {display: false},

            title: {
              display: true,
              text: 'Segments',
              color: '#4c4c4d',
              font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2,
              },
              padding: {top: 0, left: 0, right: 0, bottom: 0}
            }
          },
          y: {
            display: true,
            beginAtZero: false,
            grid: {display: false},

            title: {
              display: true,
              align: 'end',
              text: 'Efficiency ( % )',
              color: '#4c4c4d',
              font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2
              },
            }
          }
        }
      }
    });

    return () => {
      chartRef.current.destroy();
    };
  }, [data]);
  return <canvas ref={canvasRef} />;
}
