import React, { useEffect } from "react";
import {
  MainCard,
  ShadowCardContentContainer,
} from "../../../../components/common/commonContainer";
import { Box, CircularProgress, Tabs } from "@mui/material";
import Metrics from "./metrics";
import ComparisonEfficiencyTimeseries from "../../../../graphs/comparisonResults/efficiencyTimeseries";
import EfficiencyQuadrantsComparison from "../../../../graphs/comparisonResults/quadrantsComparison";
import EfficiencyTable from "./efficiencyTable";
import EfficiencyHistogramComparison from "../../../../graphs/comparisonResults/variables-comparison/efficiencyHistogram";
import { useState } from "react";
import { SmallRoundTab } from "../../../../components/common/tabsDesign";
import {
  getEfficiencyApi,
  getEfficiencyQuadrantsApi,
  getPeriodEfficiencyApi,
} from "./services/comparisonResultsContentServices";

export default function ComparisonResultsContent({
  selectedValues,
  periodObject,
  benchmark_id,
  periodOptions,
  orientValue,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [efficiencyApi, setEfficiencyApi] = useState([]);
  const [periodEfficiencyApi, setPeriodEfficiencyApi] = useState([]);
  const [efficiencyQuadrantsApi, setEfficiencyQuadrantsApi] = useState([]);

  //TABS HANDLER
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("value", value);
  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);

    const fetchSelectedValuesData = () => {
      // Clear previous data
      setEfficiencyApi([]);
      setPeriodEfficiencyApi([]);
      setEfficiencyQuadrantsApi([]);
      const selectedValuesCopy = [...selectedValues];
      for (const selectedValue of selectedValuesCopy) {
        // const controller = new AbortController();
        const encodedSelectedOption = encodeURIComponent(selectedValue);
        Promise.all([
          getEfficiencyApi({
            benchmark_id,
            encodedSelectedOption,
            orientValue,
          }),
          getPeriodEfficiencyApi({
            benchmark_id,
            encodedSelectedOption,
            periodObject,
            orientValue,
          }),
          getEfficiencyQuadrantsApi({
            benchmark_id,
            periodObject,
            encodedSelectedOption,
            orientValue,
          }),
        ])
          .then(
            ([
              efficiencyApiData,
              periodEfficiencyApiData,
              efficiencyQuadrantsApiData,
            ]) => {
              setEfficiencyApi(efficiencyApiData);
              setPeriodEfficiencyApi(periodEfficiencyApiData);
              setEfficiencyQuadrantsApi(efficiencyQuadrantsApiData);
            }
          )
          .catch((error) => {
            console.error("Error fetching data:", error);
            setError(error);
          })
          .finally(() => {
            setLoading(false);
          });
        // return () => controller.abort()
      }
    };

    // Use a timeout to delay fetching data after selectedValues change
    const delay = 3000;
    const timeoutId = setTimeout(() => {
      fetchSelectedValuesData();
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [benchmark_id, orientValue, selectedValues, periodObject]);

  useEffect(() => {
    // Remove unselected option data from the array
    setEfficiencyApi((prevData) =>
      prevData.filter((item) => selectedValues.includes(item.selectedOption))
    );
  }, [selectedValues, benchmark_id]);

  useEffect(() => {
    // Remove unselected option data from the array
    setPeriodEfficiencyApi((prevData) =>
      prevData.filter((item) => selectedValues.includes(item.selectedOption))
    );
    setEfficiencyQuadrantsApi((prevData) =>
      prevData.filter((item) => selectedValues.includes(item.selectedOption))
    );
  }, [selectedValues, periodObject, benchmark_id, orientValue]);

  return (
    <Box sx={{ width: "inherit" }}>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <MainCard
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress />
          </MainCard>
        </Box>
      ) : error ? (
        <Box sx={{ width: "100%" }}>
          <MainCard
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            Error: {error.message}
          </MainCard>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: { xs: "column", lg: "row wrap" },
              width: "100%",
            }}
          >
            {periodOptions.length <= 1 ? (
              <ShadowCardContentContainer
                sx={{ width: "inherit", flexFlow: "row wrap" }}
              >
                <EfficiencyHistogramComparison
                  periodEfficiencyApi={periodEfficiencyApi.filter(
                    (obj) => obj.count !== 0
                  )}
                />
                <EfficiencyTable
                  periodEfficiencyApi={periodEfficiencyApi.filter(
                    (obj) => obj.count !== 0
                  )}
                />
              </ShadowCardContentContainer>
            ) : (
              <ShadowCardContentContainer
                sx={{
                  width: { xs: "inherit", lg: "35%" },
                  flexFlow: "column",
                  pt: "2rem",
                  pb: "2.5rem",
                  height: { xs: "250px", lg: "350px" },
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ sx: { display: "none" } }}
                  sx={{ minHeight: 50 }}
                >
                  <SmallRoundTab iconPosition="start" label="Efficiency" />
                  <SmallRoundTab iconPosition="start" label="Improvement p/p" />
                  <SmallRoundTab iconPosition="start" label="Improvement p/b" />
                </Tabs>
                {value === 0 && (
                  <ComparisonEfficiencyTimeseries
                    efficiencyApi={efficiencyApi}
                    value={0}
                  />
                )}
                {value === 1 && (
                  <ComparisonEfficiencyTimeseries
                    efficiencyApi={efficiencyApi}
                    value={1}
                  />
                )}
                {value === 2 && (
                  <ComparisonEfficiencyTimeseries
                    efficiencyApi={efficiencyApi}
                    value={2}
                  />
                )}
              </ShadowCardContentContainer>
            )}
            <ShadowCardContentContainer
              sx={{
                width: { xs: "inherit", lg: "35%" },
                pt: "2rem",
                pb: "2.5rem",
                height: { xs: "250px", lg: "350px" },
              }}
            >
              <EfficiencyQuadrantsComparison
                efficiencyQuadrantsApi={efficiencyQuadrantsApi}
              />
            </ShadowCardContentContainer>
          </Box>
          {periodOptions.length <= 1 ? null : (
            <ShadowCardContentContainer
              sx={{
                flexFlow: "row nowrap",
                width: { xs: "inherit", lg: "inherit" },
              }}
            >
              <EfficiencyHistogramComparison
                periodEfficiencyApi={periodEfficiencyApi.filter(
                  (obj) => obj.count !== 0
                )}
              />
              <EfficiencyTable
                periodEfficiencyApi={periodEfficiencyApi.filter(
                  (obj) => obj.count !== 0
                )}
              />
            </ShadowCardContentContainer>
          )}
        </>
      )}

      <Metrics
        benchmark_id={benchmark_id}
        selectedValues={selectedValues}
        periodObject={periodObject}
        orientValue={orientValue}
      />
    </Box>
  );
}
