import { get_new } from "../../../../../../../utils/httpClient";

export const getSegmentOptions = ({ formBenchmarkData }) => {
    const url = `/project/segmentation/?project_id=${formBenchmarkData.project_id}&status=completed`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    let options = data.results.map((scenario) => {
        return {
            label: scenario.segmentation_name,
            id: scenario.segmentation_scenario_id
        }
    })
    return options
  })
  .catch((error) => {
    console.error('Error fetching segmentation options:', error);
    throw error;
  })
};

export const getSegmentationData = ({ formBenchmarkData }) => {
    const url = `/project/segmentation/?segmentation_scenario_id=${formBenchmarkData.segmentation_scenario_id}`;
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching segmentation data:', error);
    throw error;
  })
};