import React, { useEffect, useState} from 'react';
import {
    CircularProgress,
	Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import EditProcessing from './editStatusModals/editProcessing';
import EditSaved from './editStatusModals/editSaved';
import EditCompleted from './editStatusModals/editCompleted';
import EditPublished from './editStatusModals/editPublished';
import { getDatasetDetails, getDatasetPeriodData, getProjectData, getScenarioData, getSummaryData } from './services/editBenchmarkServices';
import EditBenchmarkContext from '../context/editBenchmarkContext';
import EditModalContent from './editStatusModals/editModalContent';


export default function EditBenchmark({
    open = true,
    onClose = null,
    scenario_id = null,
    project_id = null,
    setDeletedID = () => {},
}) {
    // COMPONENT STATE
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [scenario, setScenario] = useState({'benchmark_name': '','benchmark_id': '',});
    const [summaryData, setSummaryData] = useState({'benchmark_name': '','benchmark_id': '',});
    const [editedSummaryData, setEditedSummaryData] = useState({})
    const [projectData, setProjectData] = useState([])
    const [datasetDetails, setDatasetDetails] = useState({}); 
    const [periodData, setPeriodData] = useState({
        'has_periods': false,
        'period_type': '',
        'periods_list': []
    });
    // HANDLE CLOSE
    const handleClose = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };
    //FETCH FUNCTION
    useEffect(() => {
        setLoading(true);
        //SCENARIO AND DMU DATA FETCH
        Promise.all([
            getScenarioData({ project_id, scenario_id }),
            getSummaryData({ project_id, scenario_id }),
            getProjectData({ project_id })
        ])
        .then(([scenarioData, summaryData, projectData]) => {
            setScenario(scenarioData);
            setSummaryData(summaryData);
            setProjectData(projectData);

            return  getDatasetDetails({ projectData })
        })
        .then((datasetDetails) => {
            setDatasetDetails(datasetDetails)
            // Identify periods type (monthly, quarterly, yearly)
            let periodField = datasetDetails.datasetvariable.filter((variable) => {
                return (variable.original_field_name === datasetDetails.period_field);
            })[0];
            if (!periodField || !periodField.field_sub_type) return;
            let periodicity = periodField.field_sub_type.toLowerCase();

            return getDatasetPeriodData({ datasetDetails, periodicity })
        })
        .then((datasetPeriodData) => {
            setPeriodData(datasetPeriodData);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setError(error)
        })
        .finally(() => {
            setLoading(false);
        });
    }, [project_id, scenario_id, editedSummaryData]);

    if(loading) {
        return <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-benchmark-title"
        aria-describedby='edit-benchmark-description'
        fullWidth
        PaperProps={{ sx: {height: '480px'}}}
    >
        <DialogTitle id="edit-benchmark-title" sx={{ display: 'flex', background: '#47254b', color: 'white', justifyContent: 'flex-end'}}>
            <IconButton onClick={handleClose} sx={{color: 'white', display: 'flex'}}>
                <CloseRounded color='white'/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
        }}>
            <CircularProgress/>
        </DialogContent>
    </Dialog>
    }

    if(error) {
        return <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-benchmark-title"
        aria-describedby='edit-benchmark-description'
        fullWidth
        PaperProps={{ sx: {height: '480px'}}}
    >
        <DialogTitle id="edit-benchmark-title" sx={{ display: 'flex', background: '#47254b', color: 'white', justifyContent: 'flex-end'}}>
            <IconButton onClick={handleClose} sx={{color: 'white', display: 'flex'}}>
                <CloseRounded color='white'/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
        }}>
            Error: {error.message}
        </DialogContent>
    </Dialog>
    }

    return (
        <>
            <Dialog
                maxWidth='md'
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-benchmark-title"
                aria-describedby='edit-benchmark-description'
            >
                <DialogTitle id="edit-benchmark-title" sx={{ display: 'flex', background: '#47254b', color: 'white', justifyContent: 'flex-end', padding: '8px 24px'}}>
                    <IconButton onClick={handleClose} sx={{color: 'white'}}>
                        <CloseRounded color='white'/>
                    </IconButton>
                </DialogTitle>
                <EditBenchmarkContext.Provider 
                    value={{
                        summaryData, 
                        editedSummaryData, 
                        setEditedSummaryData,
                        periodData
                        }}
                    >
                        <EditModalContent scenarioData={scenario}/>
                    {
                        scenario.status === 'saved' && 
                        <EditSaved
                            handleClose={handleClose}
                            scenarioData={scenario}
                            setDeletedID={setDeletedID}
                        />
                    }
                    {
                        scenario.status === 'processing' && 
                        <EditProcessing
                            handleClose={handleClose}
                        />
                    }
                    {
                        scenario.status === 'completed' && 
                        <EditCompleted
                            handleClose={handleClose}
                            scenarioData={scenario}
                            setDeletedID={setDeletedID}
                        />
                    }
                    {
                        scenario.status === 'published' && 
                        <EditPublished
                            scenarioData={scenario}
                            setDeletedID={setDeletedID}
                        />
                    }
                </EditBenchmarkContext.Provider>
            </Dialog>
        </>
    );
}