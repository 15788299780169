import { Autocomplete, Box, Card, CardContent, CardHeader, Chip, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Switch, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LabelGrid, ValueGrid } from '../manualSettings'
import { getSegmentOptions, getSegmentationData } from './services/SegmentationModelManualServices';
import { getVariablesList } from '../benchmarkWizardSteps/datasetAuxFunctions';
import { HelpOutlineRounded } from '@mui/icons-material';

export default function SegmentationModelManual({summaryData, formBenchmarkData, changeFormBenchmarkData, datasetData}) {
    //local state 
    const [segmentOptions, setSegmentOptions] = useState([]);
    const [segmentationData, setSegmentationData] = useState(null);
    const [variableList, setVariableList] = useState([]);

    /* get list of segmentation scenarios 
    label=segmentationScenarios.segmentation_name
      id=segmentationScenarios.segmentation_scenario_id
      */

    //FETCH FUNCTION
		useEffect(() => {
			Promise.all([
				getSegmentOptions({ formBenchmarkData }),
			])
			.then(([segmentOptions]) => {
				setSegmentOptions(segmentOptions);
        
        setVariableList(getVariablesList(datasetData, 'all'));
        if(formBenchmarkData.segmentation_scenario_id){
          // Get segmentation scenario information to display after selecting a scenario
          return  getSegmentationData({ formBenchmarkData })
        }
			})
      .then((segmentation) => {
        setSegmentationData(segmentation)
      })
			.catch(error => {
				console.error('Error fetching data:', error);
				// setError(error)
			})
		}, [formBenchmarkData.segmentation_scenario_id]);

  return (
    <>
      <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Segmentation</b></Box>
      <Divider/>
      <Grid container spacing={1} marginY={2} fontSize={12}>
        <LabelGrid> Segmentation Scenario: </LabelGrid>
        <ValueGrid>
          <Grid container spacing={2} sx={{display: 'flex', flexFlow: 'row'}}>
            <Grid item xs={6} >
              <Box >
                {/*Disable if Project doesn't have segmentation scenarios*/}
                <FormControl>
                  <FormControlLabel
                    value={formBenchmarkData.apply_restricted}
                    label='Apply segmentation'
                    labelPlacement='end'
                    componentsProps={{typography: { fontSize: '12px' }}}
                    control={
                    <Switch
                      disabled={segmentOptions.length === 0}
                      checked={formBenchmarkData.apply_segmentation}
                      onChange={(event) => {
                          changeFormBenchmarkData({apply_segmentation: event.target.checked});
                      }}
                    />}
                  />
                </FormControl>
              </Box>
              </Grid>
              <Grid item xs={6}>
                <Box >
                  <FormControl fullWidth>
                    <FormLabel htmlFor='segmentation-scenario' sx={{fontSize: '12px'}} >Segmentation Scenario </FormLabel>
                    <Autocomplete
                      id='segmentation-scenario'
                      size='small'
                      //sx = {{width: '450px'}}
                      required
                      selectOnFocus
                      clearOnBlur
                      clearOnEscape
                      autoComplete
                      autoHighlight
                      fullWidth
                      disabled={!formBenchmarkData.apply_segmentation}
                      options={segmentOptions}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={formBenchmarkData.segmentation_scenario}
                      onChange={(event, newValue) => {
                          changeFormBenchmarkData(
                            {
                            segmentation_scenario_id: newValue.id
                          }
                        );
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth required />}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            {formBenchmarkData.apply_segmentation && segmentationData && (
                    <Grid>
                        <Card sx={{ mt: 2 }}>
                            <CardHeader size='small' disableTypography sx={{ fontSize: 14, m: 0, p: 1 }}
                                title={<b>{'Scenario: ' + segmentationData.segmentation_name} </b>} />
                            <CardContent sx={{ fontSize: 12, px: 1, pt: 0, pb: 1 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={5} md={4} lg={3}>
                                                <Box>Segmentation Method:</Box>
                                            </Grid>
                                            <Grid item xs={6} sm={7} md={8} lg={9}>
                                                <Box>{segmentationData.segmentation_method}</Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={5} md={4} lg={3}>
                                                <Box>Segments:</Box>
                                            </Grid>
                                            <Grid item xs={6} sm={7} md={8} lg={9}>
                                                <Box>{segmentationData.number_of_clusters}</Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={3}>
                                                <Box>Segmentation Variables:</Box>
                                            </Grid>
                                            <Grid item xs={12} lg={9}>
                                                <Box>
                                                {/*Create chips from segmentation variables*/}
                                                {console.log('variableList: ', variableList)}
                                                {segmentationData.segmentationvariable.map((variable) => {
                                                    return (
                                                        <Chip
                                                            key={variable.variable_detail}
                                                            label={variableList.filter((row) => {
                                                                return row.id === variable.variable_detail;
                                                            })[0].label
                                                            }
                                                            variant='outlined'
                                                            size='small'
                                                            sx={{ margin: '2px' }}
                                                        />
                                                    )
                                                })}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        </Grid>
                )}
        </ValueGrid>
      </Grid>

      <Box width={'100%'} sx={{ bgcolor: '#E1E5F2' }}>
        <b>Model</b>
        <Tooltip title="Click Below for Suggested Model" placement="right">
          <IconButton
            size="small"
            sx={{ ml: 2, mr: '10px' }}
            aria-haspopup="true"
          >
            <HelpOutlineRounded/>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <Grid container spacing={1} marginY={2} fontSize={12} sx={{alignItems: 'baseline'}}>
        <LabelGrid> 
            Suggested Model:
        </LabelGrid>
        <ValueGrid>
          {formBenchmarkData.dea_model}
        </ValueGrid>
      </Grid>
    </>
  )
}
