import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import StatusBanner from '../../components/common/statusBanner';
import { TableLink } from '../../components/common/styledLinks';
import BenchmarkKebabMenu from '../home/projectDetails/benchmarkScenarios/benchmarkKebabMenu';
import { useContext } from 'react';
import { BenchmarkScenariosContext } from '../home/projectDetails/benchmarkScenarios';
//import KebabMenu from '../home/kebabMenu';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
  export default function BenchmarkScenarioTableContent(props) {
    const { setDeletedID } = useContext(BenchmarkScenariosContext)
    const { order, orderBy, handleClick, isSelected, page, rowsPerPage, filteredData } =
    props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length);
    return (
      <TableBody>
        {stableSort(filteredData, getComparator(order, orderBy))
        .slice(0, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.benchmark_name);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleClick(event, row.benchmark_name)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              //key={row.title}
              selected={isItemSelected}
            >
              <TableCell
              id={labelId}
              scope="row"
              >
                {
                  row.status === 'processing' || row.status === 'new' || row.status === 'saved' ?
                  <Box sx={{display: 'flex', flexFlow: 'column'}}>
                    <Typography variant="body2" color="initial">{row.benchmark_name}</Typography>
                    <Typography variant="body2" color="gray">ID: {row.benchmark_id}</Typography>
                  </Box>
                  :
                  <TableLink to={"/dashboard?project_id=" + row.project_id + "&benchmark_id=" + row.benchmark_id}>
                    <Box sx={{display: 'flex', flexFlow: 'column'}}>
                      <Typography variant="body2" color="initial">{row.benchmark_name}</Typography>
                      <Typography variant="body2" color="gray">ID: {row.benchmark_id}</Typography>
                    </Box>
                  </TableLink>
                }
              </TableCell>
              <TableCell align="left" sx={{display: {xs: 'none', sml: 'table-cell'}}}>
                <Box sx={{display: 'flex', flexFlow: 'column'}}>
                  {/* Need Api data */}
                 <Typography variant="body2" color="initial">{row.apply_segmentation ? 'Yes' : 'No'}</Typography>
                </Box>
              </TableCell>
              <TableCell align="left">
                {row.type}
              </TableCell>   
              <TableCell align="left" sx={{display: {xs: 'none', md: 'table-cell'}}}>
                <Typography variant="body2" color="gray">{row.last_update}</Typography>
              </TableCell>
              <TableCell align="left" sx={{width: '15%'}} ><StatusBanner status={row.status}/></TableCell>
              <TableCell align="right">
                <BenchmarkKebabMenu 
                  project_id={row.project_id} 
                  scenario_id={row.benchmark_id}
                  status={row.status}
                  toEnpoint={'/benchmarkScenarios'}
                  setDeletedID={setDeletedID}
                />
              </TableCell>
            </TableRow>
          );
        })}
        {emptyRows > 0 && (
        <TableRow
          style={{
          height: (props.dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={7} />
        </TableRow>
      )}
    </TableBody>
  )
}
