import React, { useState, useEffect } from "react";
import {
  //CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  Box,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { get_new, post } from "../../../utils/httpClient";
import { numFormatInt } from "../../../utils/numeralFormatter";
import {
  GridInformationContainer,
  GridInformationItem,
} from "../../datasetDetails/datasetDetails";

import iconFileName from "../../../assets/icons/datasets/Icon_File_Name.svg";
import iconRecords from "../../../assets/icons/datasets/Icon_Records.svg";
import iconColumns from "../../../assets/icons/datasets/Icon_Columns.svg";

export default function AddProjectDialog({ open, onClose }) {
  // LOCAL STATE
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    dataset_id: "",
  });

  const [datasets, setDatasets] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [nameHelperText, setNameHelperText] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [formErrors, setFormErrors] = useState({ error: false, message: "" });

  const buttonSx = {
    ...(successCreate && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  /*Load datasets*/
  /*TODO: See if we can replace this with dynamic load on autocomplete
    to avoid loading all datasets at once*/
  useEffect(() => {
    /*Get dataset list only when open=true*/
    if (!open) return;

    const datasetList = [];
    Promise.all([
      get_new("/datasets/?status=ready"),
      get_new("/api/dataset/periods/"),
    ])
      .then(([response1, response2]) => {
        if (!response1.ok || !response2.ok) {
          throw new Error("Could not load data");
        }
        return Promise.all([response1.json(), response2.json()]);
      })
      .then(([data1, data2]) => {
        if (data1.results.length === 0) {
          console.log("No datasets available");
          return;
        }
        data1.results.forEach((dataset) => {
          let matchingPeriod = data2.results.find(
            (period) => Number(period.dataset_id) === Number(dataset.dataset_id)
          );

          let numberOfPeriods = matchingPeriod
            ? matchingPeriod.number_of_periods
            : null;

          datasetList.push({
            label: dataset.dataset_name,
            id: dataset.dataset_id,
            columns: dataset.columns,
            rows: dataset.rows,
            entities: dataset.entities,
            number_of_periods: numberOfPeriods,
          });
        });
        setDatasets(datasetList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [open]);

  /*Update form data*/
  const changeFormData = (name, newValue) => {
    /*TODO: Check name format is valid*/
    const newForm = {
      ...formData,
      ...{
        [name]: newValue,
      },
    };

    setFormData(newForm);
  };

  /*Close dialog*/
  const handleCloseDialog = (event, reason) => {
    /* Disable backdrop close on click*/
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleCreate = () => {
    if (!loadingCreate) {
      setSuccessCreate(false);
      setLoadingCreate(true);

      const data = new FormData();
      data.append("project_name", formData.name);
      data.append("project_desc", formData.description);
      data.append("dataset", formData.dataset_id);

      /*Target URL for posting form*/
      const targetURL = "/project/";

      /*Post form data*/
      post(targetURL, data)
        .then((response) => {
          //console.log(response);
          if (response.status === 409) {
            console.log("Raise 409 error ");
            setNameHelperText("Project name already exists");
            setNameError(true);
            throw new Error("Project name already exists");
          } else if (response.status === 500) {
            alert("Server Error. Please try again later.");
            throw new Error("Server Error: 500");
          } else if (!response.ok) {
            response.json().then((data) => {
              setFormErrors({
                error: true,
                message: data.Res,
              });
            });
            throw new Error(
              "Error: " + response.status + " : " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log("Project created successfully");
          setSuccessCreate(true);
          setLoadingCreate(false);
          console.log("New project id: " + data.project_id);
          handleCloseDialog();
          //redirect to project page
          window.location.href =
            "/projectDetails?project_id=" + data.project_id;
        })
        .catch((error) => {
          console.log("Create project failed");
          console.log(error);
          setSuccessCreate(false);
          setLoadingCreate(false);
        });
    }
  };

  /*Disable button if dataset and name are not completed*/
  const isDisabled = () => {
    return formData.dataset_id === "" || formData.name === "";
  };

  {
    /* To show the selected dataset */
  }
  let selectedDataset = datasets.find(
    (dataset) => dataset.id === formData.dataset_id
  );

  const infoItems = [
    { label: "ID", value: selectedDataset?.id },
    {
      label: "Records",
      value: numFormatInt(selectedDataset?.rows),
    },
    {
      label: "Columns",
      value: numFormatInt(selectedDataset?.columns),
    },
    {
      label: "Entities",
      value: numFormatInt(selectedDataset?.entities),
    },
    {
      label: "Periods",
      value: numFormatInt(selectedDataset?.number_of_periods),
    },
  ];

  return (
    <>
      <Dialog fullWidth={true} open={open} onClose={handleCloseDialog}>
        <DialogTitle style={{ background: "#47254b", color: "white" }}>
          Create New Project
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ marginTop: "0px" }} dividers>
          <br />
          <Box mb={2}>
            Select a Dataset and add a short name and description .<br />
            <div style={{ fontSize: 12 }}>
              Adding some details of the project's purpose and objective to the
              description could help identify it in the future.
            </div>
          </Box>
          {formErrors.error ? (
            <Box mb={2} sx={{ color: "red", fontSize: 12 }}>
              <ul>
                <li> {formErrors.message}</li>
              </ul>
            </Box>
          ) : (
            ""
          )}

          <Autocomplete
            id="dataset_id"
            size="small"
            sx={{ width: "450px" }}
            required
            selectOnFocus
            clearOnBlur
            clearOnEscape
            autoComplete
            autoHighlight
            options={datasets}
            getOptionLabel={(option) => option.label}
            value={
              datasets.find((obj) => obj.id === formData.dataset_id) || null
            }
            onChange={(event, newValue) => {
              let dataset_id = newValue === null ? "" : newValue.id;
              changeFormData("dataset_id", dataset_id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Dataset"
                disabled={datasets.length === 0}
                helperText={
                  datasets.length === 0 ? "No datasets available" : null
                }
                required
              />
            )}
          />
          <br />
          {selectedDataset && (
            <GridInformationContainer
              container
              component={Paper}
              sx={{
                mt: -1,
              }}
            >
              {infoItems.map((item) => (
                <GridInformationItem item key={item.label}>
                  <Grid container sx={{ gap: "6px" }}>
                    <Grid item>
                      <Typography fontSize="0.8rem" marginBottom="2px">
                        {item.label}
                      </Typography>
                      <Typography fontSize="0.9rem">{item.value}</Typography>
                    </Grid>
                  </Grid>
                </GridInformationItem>
              ))}
            </GridInformationContainer>
          )}

          <TextField
            id="name"
            label="Name"
            size="small"
            sx={{ width: "450px" }}
            variant="outlined"
            required
            error={nameError}
            helperText={nameHelperText}
            inputProps={{ maxLength: 50 }}
            onChange={(event) => {
              if (nameError) {
                setNameError(false);
                setNameHelperText(null);
              }
              changeFormData("name", event.target.value);
            }}
          />

          <br />
          <br />

          <TextField
            id="description"
            label="Description"
            size="small"
            inputProps={{ maxLength: 500 }}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            onChange={(event) =>
              changeFormData("description", event.target.value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="outlined">
            Cancel
          </Button>
          <Box sx={{ position: "relative", ml: 1 }}>
            <Button
              sx={buttonSx}
              onClick={handleCreate}
              disabled={isDisabled() || loadingCreate}
              variant="contained"
              color="success"
            >
              Create
            </Button>
            {loadingCreate && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
