import React, { useEffect, useState} from 'react';
import {
    CircularProgress,
	Dialog,
	DialogActions,
    DialogContent,
    DialogTitle,
	Button,
    Box,
    Grid,
    
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSnackbar } from 'notistack';
import {api_delete, get_new } from '../../../utils/httpClient';
import { red } from '@mui/material/colors';



export default function DeleteProject({
    open = true,
    onClose = null,
    project_id = null,
    setDeletedID = () => {},
}) {

    // COMPONENT STATE
    const [project, setProject] = useState({'project_name': '','project_id': '',});
    const [loadingDelete, setLoadingDelete] = useState(false);
    

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();
    
    
    // GET PROJECT INFO
    /* query API using project_id and set project state */
    useEffect(() => {
        //console.log("Get project info");
        const targetURL = '/projects/?project_id=' + project_id;
        get_new(targetURL)
            .then((response) => {
                //console.log("Response: ", response);
                if (!response.ok) {
                    console.log("Error");
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                //console.log("Data: ", data.results[0]);
                setProject(data.results[0]);
                return;
            })
            .catch((error) => {
                console.log("Error: ", error);
                return;
            });
    }, [project_id]);

    // HANDLE CLOSE
    const handleClose = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    // HANDLE CLICK ON DELETE
    /* send DELETE request to API, close modal and initiate toast confirmation to indicate fail/success */
    const handleDelete = () => {
        if (!loadingDelete) {setLoadingDelete(true);}
        console.log("Deleting project: ", project.project_id);
        // enqueueSnackbar('Successfully deleted project: '+project.project_name, { variant: 'error' });
        // enqueueSnackbar('Failed deleting project: ' + project.project_name, { variant: 'info' });
        // handleClose();
        const data = { project_id: project.project_id };
        const targetURL = '/project/?project_id=' + project.project_id;
        api_delete(targetURL, data)
            .then((response) => {
                console.log("Response: ", response);
                if (!response.ok) {
                    console.log("Error");
                    throw new Error(response.statusText);
                }
                // Delete doesn't return anything
                return;
                //return response.json();
            })
            .then(() => {
                console.log("Delete Succeeded")
                enqueueSnackbar('Successfully deleted project: ' + project.project_name, { variant: 'success' });
                setDeletedID(project.project_id);
                setLoadingDelete(false);
                handleClose();
            }).catch((error) => {
                console.log("Delete Failed")
                console.log("Error: ", error);
                enqueueSnackbar('Failed deleting project: ' + project.project_name, { variant: 'error' });
                setLoadingDelete(false);
                handleClose();
                return;
            }); 
    }

    return (
        <>
            <Dialog
                maxWidth='sm'
        open={open}
        onClose={handleClose}
                aria-labelledby="delete-title"
                aria-describedby='delete-description'
      >
        <DialogTitle id="delete-title" sx={{background: '#47254b', color: 'white'}}>
          Delete Project?
        </DialogTitle>
                <DialogContent>
                    <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                        <Grid item xs={12}>
                            <div id='delete-benchmark-description'>
                                  <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                        <Box sx={{ }}>
                                            <WarningIcon fontSize='large' color='error' sx={{ float: 'left', mr: 1 }} />
                                        </Box>
                                        <Box sx={{ textAlign:'center', }}>
                                            Caution! You are about to delete project
                                            "<b>{project.project_name}</b>" 
                                            ID: {project.project_id}
                                        </Box>
                                    </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="center" spacing={2}>
                                <Box sx={{mt:1 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box width={180} sx={{ mr: 1 }}>Benchmark Scenarios:</Box>
                                    <Box>{project.benchmarks_total}</Box>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box width={180} sx={{ mr: 1 }}>Segmentation Scenarios:</Box>
                                    <Box>{project.segmentations_total}</Box>
                                </Box>
                                </Box>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width:'100%', textAlign:'center'}}>
                                <b>Deleting a scenario will permanently remove its configuration 
                                and all associated results. </b>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{fontSize:12}}>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Box sx={{}}>
                                    <ul>
                                        {project.status === 'published' && (
                                <li>
                                    This project is publishing results visible to other users.
                                    If removed, users will no longer have access to them.
                                </li>
                            )}
                            <li>
                                All segmentation and benchmark scenarios associated with this project
                                will be deleted.
                            </li>
                            {project.status === 'processing' && (
                                <li>
                                    Scenarios currently being processed will be stopped and any
                                    progress will be lost.
                                </li>
                            )}
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
          </Grid>
                   
                        
                            
                            
                            

                        
                    
          
        </DialogContent>
        <DialogActions>
          <Box sx={{mx:1}}>
                        <Button
                            disabled={loadingDelete}
                            onClick={handleDelete}
                            color='error'
                            variant='text' >
            Yes, Delete </Button>
            {loadingDelete && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: red[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                            />
                        )}
            </Box>
            <Button onClick={handleClose} color='primary' variant='contained' autoFocus>Cancel, Keep</Button>
        </DialogActions>
      </Dialog>
        </>
    );
}