import { get_new } from "../../../../../utils/httpClient"

export const getPeriodOptions = ({ project_id, benchmark_id }) => {
  const url = `/project/dataset-periods/?benchmark__project_id=${project_id}&benchmark_id=${benchmark_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    const options = data.count > 0 ? data?.results?.map(p => 
      ({ label: p.period, value: p.period }))
      : 
      [{ label: 'All', value: 'All' }];
      return options
  })
  .catch((error) => {
    console.error('Error fetching benchmark data:', error);
    throw error;
  })
};

export const getEntitiesOptions = ({ benchmark_id }) => {
    const url = `/project/benchmark/dmu/?benchmark_id=${benchmark_id}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error fetching benchmark data:', error);
      throw error;
    })
  };

  export const getPeriodObject = ({ project_id, benchmark_id,periodValue }) => {
    const url = `/project/dataset-periods/?benchmark__project_id=${project_id}&period=${periodValue}&benchmark_id=${benchmark_id}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
        const index = data?.results?.findIndex((item) => item.period === periodValue);
        return data?.results[index]
    })
    .catch((error) => {
      console.error('Error fetching benchmark data:', error);
      throw error;
    })
  };