import React, { useEffect, useState, useContext, createContext } from 'react'
import { CircularProgress} from '@mui/material'
import { PrimaryButton } from '../../../components/common/button'
import { CardContentContainer, MainCard } from '../../../components/common/commonContainer'
import EmptyCardMessage from '../../../components/common/emptyCardMessage'
import { UpperBarComponent } from '../../../components/common/upperBarComponent'
import AddBenchmark from './benchmarkScenarios/menuOptions/addBenchmark'
import AddBenchmarkWizard from './benchmarkScenarios/menuOptions/addBenchmarkWizard'
import ProjectContext from '../../../context/Project/ContextProject'
import BenchmarkProjectHomeTable from './benchmarkScenarios/benchmarkProjectHomeTable'
import ManualSettings from './benchmarkScenarios/menuOptions/manualSettings'
import { GetBenchmarkScenarios } from './services/benchmarkScenariosServices'

export const BenchmarkScenariosContext = createContext();
export default function BenchmarkScenarios() {
  
  // COMPONENT STATE
  const [benchmarkScenarios, setBenchmarkScenarios] = useState([])
  const [benchmarkDialog, setBenchmarkDialog] = useState(false);
  const [createScenario, setCreateScenario] = useState(false);
  const [createMethod, setCreateMethod] = useState('wizard');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [createdID, setCreatedID] = useState('');
	const [deletedID, setDeletedID] = useState('');
  const [fieldChange, setFieldChange] = useState(false);
  const [editSaved, setEditSaved] = useState(false);
  const [statusChange, setStatusChange] = useState(false)


  // BENCHMARK HANDLE TABLE PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };  
  


  // CONTEXT STATE
   const {projectData} = useContext(ProjectContext);

  //Search bar Benchmark table Value handler
	const [searchBenchmark, setSearchBenchmark] = useState('')
  const [delayedBenchmarkTerm, setDelayedBenchmarkTerm] = useState('');

	const searchBenchmarkHandler = (event) => {
		setSearchBenchmark(event.target.value)
	}

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDelayedBenchmarkTerm(searchBenchmark);
      setPage(0)
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchBenchmark]);

  // //filter Benchmark table data
  // const filteredBenchmark = benchmarkScenarios.results && benchmarkScenarios.results.filter((row) =>
  //   row.benchmark_name.toLowerCase().includes(searchBenchmark && searchBenchmark.toLowerCase()) ||
  //   row.benchmark_id.toString().includes(searchBenchmark && searchBenchmark)
  // );

      //FETCH FUNCTION
      useEffect(() => {
        setLoading(true);
        if(!projectData.project_id) return;
        Promise.all([
          GetBenchmarkScenarios({ projectData, rowsPerPage, page, delayedBenchmarkTerm }),
        ])
        .then(([fetchedData]) => {
          setBenchmarkScenarios(fetchedData)
          const processingBenchmarks = fetchedData?.results?.filter( v => v.status === 'processing')
          setStatusChange(processingBenchmarks)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setError(error)
        })
        .finally(() => {
            setLoading(false);
        });
    }, [projectData, createdID, deletedID, rowsPerPage, page, delayedBenchmarkTerm, fieldChange]);



  //This Check if there are processing status scenarios and if so check every 20 seconds the status of the scenario.
  useEffect(() => {
    let intervalId;

    if (statusChange.length !== 0) {
      // Define a function that will be called every 2 minutes
      intervalId = setInterval(() => {
        Promise.all([
          GetBenchmarkScenarios({ projectData, rowsPerPage, page, delayedBenchmarkTerm }),
        ])
        .then(([fetchedData]) => {
          setBenchmarkScenarios(fetchedData)
          const processingBenchmarks = fetchedData?.results?.filter( v => v.status === 'processing')
          setStatusChange(processingBenchmarks)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setError(error)
        })
        .finally(() => {
            setLoading(false);
        });
        // Place your code here that you want to run every 2 minutes
      }, 20 * 1000); // 20 seconds in milliseconds
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [statusChange?.length, projectData, rowsPerPage, page, delayedBenchmarkTerm]);

  // OPEN/CLOSE CREATE BENCHMARK SCENARIO DIALOG
	const toggleBenchmarkDialog = () => {
		setBenchmarkDialog(!benchmarkDialog);
	};
  
  const toggleCreateScenario = () => {
    benchmarkDialog && setBenchmarkDialog(!benchmarkDialog);
    setCreateScenario(!createScenario);
  };
  
  if (createScenario && createMethod === 'manual') {
    // No option for manual yet. Revert back to wizard and close create Scenario
    setCreateMethod('wizard');
    setCreateScenario(!createScenario);
  }

  return (
    <>
      <BenchmarkScenariosContext.Provider value={{setCreatedID, setDeletedID, setFieldChange, setEditSaved, editSaved }}>
        <MainCard>
          {/* TABLE HEADER*/}
          <UpperBarComponent
            text={'Benchmark Scenarios'}
            search={true}
            value={searchBenchmark}
            onChange={searchBenchmarkHandler}
            button={
              <PrimaryButton
                onClick={toggleBenchmarkDialog}
                largeText={'+ Create Scenario'}
                smallText={'+'} />
            } />
          
          {/* TABLE WITH BENCHMARK SCENARIOS */}
          <CardContentContainer>
            {
            loading ? (
              <CircularProgress />
              ) :
              benchmarkScenarios.results && benchmarkScenarios.results.length > 0 ? 
                  <BenchmarkProjectHomeTable
                    filteredData={benchmarkScenarios.results}
                    setDeletedID={setDeletedID}
                    page={page}
                    count={benchmarkScenarios.count}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> 
              : 
              <EmptyCardMessage text1='Benchmark Scenario' text2='Create Scenario'/>
            }
          </CardContentContainer>
        </MainCard>

    {/* CREATE NEW BENCHMARK SCENARIO METHOD MODAL/DIALOG */}
			{ benchmarkDialog && (
        <AddBenchmark
          open={benchmarkDialog}
          onClose={toggleBenchmarkDialog}
          toggleCreateScenario={toggleCreateScenario}
          createMethod={createMethod}
          setCreateMethod={setCreateMethod}
        />
      )}

      {/* CREATE SCENARIO WIZARD MODAL/DIALOG */}
      {createScenario && createMethod==='wizard' && (
        <AddBenchmarkWizard
          open={createScenario}
          onClose={toggleCreateScenario}
        />
        )}

      {/* CREATE SCENARIO WIZARD MODAL/DIALOG */}
      {createScenario && createMethod==='manual' && (
        <ManualSettings
          open={createScenario}
          onClose={toggleCreateScenario}
        />
      )}
      </BenchmarkScenariosContext.Provider>
    </>
  )
}
