import React from "react";
import { Chart as ChartJS, PointElement, LineElement } from "chart.js";
import { Line } from "react-chartjs-2";
import colors from "../../assets/variables.scss";
import { numeralFormatter } from "../../utils/numeralFormatter";

ChartJS.register(PointElement, LineElement);

export const options = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 25,
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "Efficiency",
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y + "%";
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Period",
        color: "#4c4c4d",
        font: {
          family: "Arial",
          size: 10,
          weight: "bold",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 0 },
      },
    },
    y: {
      grace: "20%",
      display: true,
      title: {
        display: true,
        align: "end",
        text: "Efficiency ( % )",
        color: "#4c4c4d",
        font: {
          family: "Arial",
          size: 10,
          weight: "bold",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 0 },
      },
    },
  },
};

export default function ComparisonEfficiencyTimeseries({
  efficiencyApi,
  value,
}) {
  const dateToData = efficiencyApi.reduce((acc, api) => {
    api.results.forEach((value) => {
      acc[new Date(value.period)] = value;
    });
    return acc;
  }, {});

  const labels = Object.keys(dateToData);
  labels.sort((a, b) => new Date(a) - new Date(b));

  const sortedLabels = labels.map((date) =>
    new Date(date).toLocaleDateString()
  );

  const data = {
    labels: sortedLabels,
    datasets: efficiencyApi?.map((api, index) => {
      return {
        label: api?.results?.[0]?.dmu_id,
        data: sortedLabels.map((label) => {
          const item = api.results.find((result) => result.period === label);
          return item
            ? numeralFormatter(
                value === 0
                  ? item.efficiency * 100
                  : value === 1
                  ? item.pct_improv_prev_period * 100
                  : item.pct_improv_benchmark_period * 100
              )
            : 0;
        }),
        borderColor: colors[`entity${index + 1}`],
        backgroundColor: colors[`entity${index + 1}`],
        yAxisID: "y",
        datalabels: {
          display: false,
          color: "white",
          align: "center",
          borderRadius: 50,
          backgroundColor: colors[`entity${index + 1}`],
          labels: {
            title: {
              font: {
                size: 10,
              },
            },
          },
          formatter: (value) => {
            return `${value}%`;
          },
        },
      };
    }),
  };
  return <Line options={options} data={data} className="segmentation" />;
}
