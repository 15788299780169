import React, { useEffect, useState, useContext } from "react";
import {
  styled,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
  Link,
  CircularProgress,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import iconPen from "../../../assets/icons/datasets/Icon_Pen.svg";
import ChartVariableTypes from "./chartVariableTypes";
import { get_new } from "../../../utils/httpClient";
import { tableHeadCellsDatasets } from "./tableHeadCellsDatasets";
import { tableHeadCellsSummary } from "./tableHeadCellsSummary";
import { EnhancedTableHead } from "../../../components/common/table/tableHead";
import AnnotateDataset from "../menuOptions/annotate";
import ContextDataset from "../../../context/Dataset/ContextDataset";
import { DatasetContext } from "../datasetDetails";

import { numFormatInt, numFormatPct } from "../../../utils/numeralFormatter";

// STYLED
const TextFieldRounded = styled(TextField)({
  width: "100%",
  "> div": {
    borderRadius: "100px",
  },
});

const SelectRounded = styled(Select)({
  width: "100%",
  borderRadius: "100px",
});

export default function Overview(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // LOCAL STATE
  const [detail, setDetail] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [varTypes, setVarTypes] = useState({});
  const [addAnnotate, setAddAnnotate] = useState(false);

  const [filter, setFilter] = useState({
    name: "",
    variableType: "-",
  });

  // CONTEXT STATE
  const contextDataset = useContext(ContextDataset);
  const { dataset, annotatedID, setAnnotatedID } = useContext(DatasetContext);

  // Update Summary data
  //--- creating here just to order the data----
  //--- Should probably be done one level up ----
  useEffect(() => {
    if (dataset.dataset_id == null) return;

    // SET SUMMARY DATA
    const newSummaryData = [
      {
        id: 1,
        name: "Variables",
        description: numFormatInt(dataset.info.columns),
      },
      {
        id: 2,
        name: "Observations",
        description: numFormatInt(dataset.info.rows),
      },
      {
        id: 3,
        name: "Missing cell (%)",
        description: numFormatPct(dataset.info.p_cells_missing),
      },
      {
        id: 4,
        name: "Duplicate Rows",
        description: numFormatInt(dataset.info.duplicates),
      },
      {
        id: 5,
        name: "Duplicate Rows (%)",
        description: numFormatPct(dataset.info.p_duplicates),
      },
      // need to get unique entities only if entity_id annotation is present
      { id: 6, name: "Unique entities", description: numFormatInt(dataset.info.entities) },
    ];
    // SET PERIOD DATA
    if (dataset.periods.length > 0) {
      //NOTE: NEED BACKEND TO UPDATE ENDPOINT WITH DATA
      // GET PERIOD SUB-TYPE
      const periodSubType = dataset.variables.filter((variable) => {
        return variable.field_type?.toLowerCase() === "period";
      })[0].field_sub_type;

      newSummaryData.push(
        { id: 7, name: "Multiple Periods", description: "Yes" },
        {
          id: 8,
          name: "Period Frequency",
          description: periodSubType ?? "N/A",
        },
        {
          id: 9,
          name: "First Period",
          description: dataset.periods[0].period_date,
          indent: true,
        },
        {
          id: 10,
          name: "Last Period",
          description: dataset.periods[dataset.periods.length - 1].period_date,
          indent: true,
        }
      );
    }
    
    setSummaryData(newSummaryData);

    // GET COUNTS PER VARIABLE TYPE
    const newVarTypes = {
      categorical: dataset.variables.filter((variable) => {
        return variable.var_type.toLowerCase() === "categorical";
      }).length,
      numerical: dataset.variables.filter((variable) => {
        return variable.var_type.toLowerCase() === "numerical";
      }).length,
      datetime: dataset.variables.filter((variable) => {
        return variable.var_type.toLowerCase() === "datetime";
      }).length,
    };
    // console.log('newVarTypes', newVarTypes);
    setVarTypes(newVarTypes);
  }, [dataset]);

  // Get filtered variable list
  useEffect(() => {
    setLoading(true);
    if (dataset.dataset_id == null) return;
    console.log("getting filtered data");

    let targetURL = `/dataset/?dataset_id=${dataset.info.dataset_id}`;
    if (filter.name) targetURL += `&search=${filter.name}`;
    if (filter.variableType !== "-")
      targetURL += `&var_type=${filter.variableType}`;

    get_new(targetURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + " : " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setDetail(data.results);
      })
      .catch((error) => {
        console.log("Error: " + error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataset, filter]);

  // CHANGE FILTERS OF VARIABLES LIST
  const changeFilter = (name, event) => {
    const newFilter = {
      ...filter,
      ...{
        [name]: event.target.value,
      },
    };

    setFilter(newFilter);
  };

  // OPEN ANNOTATION DIALOG
  const openAnnotate = () => {
    setAddAnnotate(true);
  };

  // CLOSE ANNOTATION DIALOG
  const closeAnnotate = () => {
    setAddAnnotate(false);
  };

  // GO DETAIL
  const goDetail = (row) => {
    contextDataset.setState((prevState) => {
      return {
        ...prevState,
        ...{
          tabMain: 1,
          tabEDA: 1,
          variable: row.original_field_name,
        },
      };
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        Error: {error.message}
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <TableContainer component={Paper}>
            <Grid container spacing={5} padding="1.5rem 2.5rem 0 2.5rem">
              {/*Variable Search Field*/}
              <Grid item xs={6}>
                <Typography
                  fontSize="0.85rem"
                  fontWeight="bold"
                  marginBottom="10px"
                >
                  Name or Alternative Name:
                </Typography>

                <TextFieldRounded
                  value={filter.name}
                  onInput={(event) => changeFilter("name", event)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Variable Category Filter */}
              <Grid item xs={5}>
                <Typography
                  fontSize="0.85rem"
                  fontWeight="bold"
                  marginBottom="10px"
                >
                  Variable Type:
                </Typography>

                <SelectRounded
                  value={filter.variableType}
                  onChange={(event) => changeFilter("variableType", event)}
                  size="small"
                >
                  <MenuItem value="-">All</MenuItem>
                  <MenuItem value="categorical">Categorical</MenuItem>
                  <MenuItem value="datetime">Datetime</MenuItem>
                  <MenuItem value="numerical">Numerical</MenuItem>
                </SelectRounded>
              </Grid>

              {/* Variable Annotation Button */}
              <Grid item xs={1}>
                <Button
                  sx={{ marginTop: "40px", minWidth: "auto", padding: 0 }}
                  onClick={openAnnotate}
                >
                  <Box
                    component="img"
                    sx={{ display: "block" }}
                    src={iconPen}
                  />
                </Button>
              </Grid>
            </Grid>

            {/* Variable Table */}
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={tableHeadCellsDatasets} />
              <TableBody>
                {detail.map((row, index) => {
                  return (
                    <TableRow hover key={row.original_field_name}>
                      <TableCell align="left">
                        <Link
                          component="button"
                          style={{ textAlign: "left" }}
                          onClick={() => {
                            goDetail(row);
                          }}
                        >
                          {row.original_field_name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        {row.alternative_field_name}
                      </TableCell>
                      <TableCell align="left">{row.data_type}</TableCell>
                      <TableCell align="left">{row.var_type}</TableCell>
                      <TableCell align="left">{row.field_type}</TableCell>
                      <TableCell align="left">{row.field_sub_type}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* DATASET SUMMARY */}
        <Grid item xs={4}>
          {/* SUMMARY */}
          <TableContainer component={Paper}>
            <Typography
              padding="1.5rem 0 0 2.5rem"
              fontSize="0.95rem"
              fontWeight="bold"
            >
              Dataset Summary
            </Typography>
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={tableHeadCellsSummary} />
              <TableBody>
                {summaryData.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell
                        align="left"
                        style={{ textIndent: row.indent ? "25px" : 0 }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {/* VARIABLE TYPES */}
          <TableContainer component={Paper}>
            <Box sx={{ padding: "1.5rem 2.5rem 1.5rem 2.5rem" }}>
              <Typography fontSize="0.95rem" fontWeight="bold">
                Variable Types
              </Typography>

              <ChartVariableTypes data={varTypes} />
            </Box>
            <Table aria-labelledby="tableTitle">
              <TableBody>
                <TableRow hover>
                  <TableCell align="left">Categorical</TableCell>
                  <TableCell align="left">
                    {numFormatInt(varTypes.categorical)}
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell align="left">Numerical</TableCell>
                  <TableCell align="left">
                    {numFormatInt(varTypes.numerical)}
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell align="left">DateTime</TableCell>
                  <TableCell align="left">
                    {numFormatInt(varTypes.datetime)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* DIALOG ANNOTATE */}
      {addAnnotate && (
        <AnnotateDataset
          open={addAnnotate}
          onClose={closeAnnotate}
          annotatedID={annotatedID}
          setAnnotatedID={setAnnotatedID}
        />
      )}
    </>
  );
}
