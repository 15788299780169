import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { ProjectContext } from "../../../context/Project/ContextProject";
import { CardUpperContainer } from "../../../components/common/commonContainer";
import { ReactComponent as PencilIcon } from "../../../assets/icons/home/projectDetails/pencilIcon.svg";
import EditProjectDetails from "./editProjectDetails";

export const ProjectDetailsContainer = styled(Box)({
  display: "flex",
  width: "100%",
  flexFlow: "column wrap",
  height: "auto",
  paddingBottom: "1rem",
});

export default function ProjectDetailsForm({ datasetNameData }) {
  const [open, setOpen] = useState(false);
  /* HANDLES DIALOG */
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // CONTEXT STATE
  const { projectData, setProjectData, setFieldChange } =
    useContext(ProjectContext);

  return (
    <ProjectDetailsContainer sx={{ pt: "16px", pb: "16px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <CardUpperContainer
          sx={{
            marginBottom: 0,
            alignItems: "flex-start",
            gap: "20px",
            flexFlow: { xs: "column", sm: "row" },
          }}
        >
          <Typography variant="subtitle1">
            {projectData?.project_name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                Dataset
              </Typography>
              <Typography variant="caption" color="initial">
                {datasetNameData?.[0]?.dataset_name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                Last Update
              </Typography>
              <Typography variant="caption" color="initial">
                {projectData?.last_update}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                Project ID
              </Typography>
              <Typography variant="caption" color="initial">
                {projectData?.project_id}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                Dataset ID
              </Typography>
              <Typography variant="caption" color="initial">
                {projectData?.dataset_id}
              </Typography>
            </Box>
            <IconButton onClick={handleOpen}>
              <PencilIcon />
            </IconButton>
          </Box>
        </CardUpperContainer>
      </div>
      {projectData.project_desc ? (
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            p: "0px 40px",
            gap: "10px",
          }}
        >
          <Typography variant="h6">Description</Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{ border: "1px solid #DDDDDD", borderRadius: "8px", p: 1 }}
          >
            {projectData.project_desc}
          </Typography>
        </Box>
      ) : null}
      {open && (
        <EditProjectDetails
          open={open}
          onClose={handleClose}
          projectData={projectData}
          setProjectData={setProjectData}
          setFieldChange={setFieldChange}
        />
      )}
    </ProjectDetailsContainer>
  );
}
