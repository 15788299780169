export const tableHeadCellsDatasets = [
	{
		id: 'field',
		numeric: false,
		disablePadding: true,
		label: 'Field',
	},
	{
		id: 'alternative_name',
		numeric: false,
		disablePadding: true,
		label: 'Alternative Name',
	},
	{
		id: 'data_type',
		numeric: false,
		disablePadding: true,
		label: 'Data Type',
	},
	{
		id: 'variable_type',
		numeric: false,
		disablePadding: true,
		label: 'Variable Type',
	},
	{
		id: 'field_type',
		numeric: false,
		disablePadding: true,
		label: 'Field Type',
	},
	{
		id: 'field_subtype',
		numeric: false,
		disablePadding: true,
		label: 'Field Subtype',
	},
];
