import { useContext } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import Summary from './summary';
import VariableDetails from './variable_details';
import BivariateDistribution from './bivariate_distribution';
import CorrelationMatrix from './correlation_matrix';
import { DatasetContext } from '../datasetDetails';

// TAB PANEL
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index &&
				<Box sx={{ px: 3 }}>
					{children}
				</Box>
			}
		</div>
	);
}


export default function EDA() {
	// LOCAL STATE


	// CONTEXT STATE
	const { dataset, state, setState } = useContext(DatasetContext);
	
	// PRESERVING TAB STATE IN DATASET CONTEXT SO WE CAN 
	// KEEP THE LAST DEA TAB SELECTED EVEN IF THEY GO BACK TO SUMMARY
	const changeTabEDA = (event, newValue) => {
		setState((state) => {
			return {
				...state,
				...{
					tabEDA: newValue,
				},
			};
		});
	};

	return (
		<>
			<Tabs orientation="vertical" value={state.tabEDA} onChange={changeTabEDA}>
				<Tab value={0} label="Summary" wrapped />
				<Tab value={1} label="Variable Details" />
				<Tab value={2} label="Bivariate Distribution" />
				{/*<Tab value={3} label="Correlation Matrix" />*/}
			</Tabs>
			<TabPanel value={state.tabEDA} index={0}>
				<Summary />
			</TabPanel>
			<TabPanel value={state.tabEDA} index={1}>
				<VariableDetails />
			</TabPanel>
			<TabPanel value={state.tabEDA} index={2}>
				<BivariateDistribution />
			</TabPanel>
			{/*<TabPanel value={contextDataset.tabEDA} index={3}>
				<CorrelationMatrix />
			</TabPanel>*/}
		</>
	);
}
