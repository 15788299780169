import { Box, styled, Typography } from '@mui/material';
import React from 'react'
import emptyContainerIcon from '../../assets/icons/commons/emptyContainerIcon.svg'
export const EmptyContainer = styled(Box) ({
    width:'263px',
    height:'263px',
    borderRadius: '600px',
    backgroundColor: '#FBFBFB',
    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column wrap',
    fontSize: '12px',
});


export default function EmptyCardMessage(props) {
  return (
    <EmptyContainer>
        <img src={emptyContainerIcon} alt=''/>
        <Typography variant="body1" color="initial" sx={{padding: '0 35px'}} align='center'>
            You don´t have {props.text1} created yet
        </Typography>
        <Typography variant="body2" color="initial" align='center' sx={{padding: '0 35px'}}>
            Click on “{props.text2}” to generate a new one. 
        </Typography>
    </EmptyContainer>
  )
}
