import { get_new } from "../../../utils/httpClient";

export const getSummaryData = ({ project_id, scenario_id }) => {
    const url = `/project/benchmark/?project_id=${project_id}&benchmark_id=${scenario_id}`;
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data.results[0])
    .catch((error) => {
      console.error('Error fetching boxplot data:', error);
      throw error;
    })
  };