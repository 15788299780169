import React, {useContext } from 'react';
import {
	FormControlLabel,
	Radio,
    RadioGroup,
	Box,
    Grid
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';
import crsImage from '../../../../../../assets/images/crs.png';
import vrsImage from '../../../../../../assets/images/vrs.png';
import irsImage from '../../../../../../assets/images/irs.png';
import drsImage from '../../../../../../assets/images/drs.png';



export default function ScaleWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData } = useContext(CreateBenchmarkContext);

    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Select Input/Output scale relationship:</b></div>
            <br />
            <div>
                    Scale indicates the type of relationship between the input and output metrics as the values increase.
                    Usually this is better represented in a production process where a certain amount of input produces a
                    certain amount of output.
            </div>
            <RadioGroup
                value={formData.scale_type}
                onChange={(event) => changeFormData('scale_type', event.target.value)}
            >
                <Box sx={{ width: '100%', flexGrow: 1}}>
                    <Grid
                        container
                        rowSpacing={2}
                        margin={0}
                        padding={0}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                       
                        
                    {/*Options CRS*/}
                        <Grid item
                            xs={12}
                            sx={{
                                '&:hover': {
                                backgroundColor: 'lightblue',
                            },
                            }}
                            padding={1}
                        >
                            <Box>
                                <FormControlLabel value='crs' control={<Radio />}
                                        label={
                                            <Box>
                                                <Grid container>
                                                    
                                                    <Grid item xs={12}>
                                                        <b>Constant Returns </b>
                                                    </Grid>
                                                    <Grid item sm={10} md={11}>
                                                        <div style={{fontSize: 12}}>
                                                                When all inputs are increased by a certain proportion, the output
                                                                increases by the same proportion. For example, doubling the amount
                                                                of workers and machines results in a doubling of production.
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={2} md={1}>
                                                        <img width={70} src={crsImage} alt='Constant Returns to Scale Image'/>
                                                    </Grid>
                                            </Grid>
                                            </Box>
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
                            {/*Option VRS*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                    sx={{
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel value='vrs' control={<Radio />}
                                        label={
                                            <Box>
                                                <Grid container>
                                                    
                                                    <Grid item xs={12}>
                                                        <b>Variable Returns </b>
                                                    </Grid>
                                                    <Grid item sm={10} md={11}>
                                                        <div style={{fontSize: 12}}>
                                                                When all inputs are increased (scaled up) by a certain proportion,
                                                    the outputs vary,  by a different proportion. For example, doubling the amount of workers
                                                    and machines may result in more than or less than double the production depending on the scale reached.
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={2} md={1}>
                                                        <img width={70} src={vrsImage} alt='Variable Returns to Scale Image'/>
                                                    </Grid>
                                            </Grid>
                                            </Box>
                                         
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
                            {/*irs*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                    sx={{
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel value='irs' control={<Radio />}
                                        label={
                                            <Box>
                                                <Grid container>
                                                    
                                                    <Grid item xs={12}>
                                                        <b>Increasing Returns </b>
                                                    </Grid>
                                                    <Grid item sm={10} md={11}>
                                                        <div style={{fontSize: 12}}>
                                                            Increasing all inputs by a certain proportion results
                                                            in a higher increase in output. Increasing returns assumes a consistent output
                                                            improvement with scale. For example, doubling the inputs might more than double
                                                            the output.
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={2} md={1}>
                                                        <img width={70} src={irsImage} alt='Increasing Returns to Scale Image'/>
                                                    </Grid>
                                            </Grid>
                                            </Box>
                                          
                                        } />
                                    <br />
                                </Box>
                            </Grid>
                        {/*Option DRS*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                   sx={{
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                        }}

                                >
                                <Box>
                                    <FormControlLabel value='drs'
                                        control={<Radio />}
                                        label={
                                            <Box>
                                                <Grid container>
                                                    
                                                    <Grid item xs={12}>
                                                        <b>Decreasing Returns </b>
                                                    </Grid>
                                                    <Grid item sm={10} md={11}>
                                                        <div style={{ fontSize: 12 }}>
                                                            Increasing all inputs by a certain proportion results in a smaller
                                                            proportionate increase in output. Decreasing returns assumes that
                                                            increasing inputs leads to a diminishing proportionate increase in
                                                            output, implying that the production process becomes less efficient
                                                            as scale increases. For example, doubling the inputs might less than
                                                            double the output.
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={2} md={1}>
                                                        <img width={70} src={drsImage} alt='Decreasing Returns to Scale Image'/>
                                                    </Grid>
                                            </Grid>
                                            </Box>
                                          
                                        } />
                                    <br />
                                </Box>
                            </Grid>
                        </Grid>
                        </Box>
                </RadioGroup>
                </TabPanel>
        </>
    )
}