import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { numeralFormatter } from '../../../utils/numeralFormatter';


const NoPeriodBottomEfficiencyTable = ({ api }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Entity</TableCell>
          <TableCell>Efficiency</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {api?.results?.slice(0, 5).map((row, index) => (
          <TableRow key={index}>
            <TableCell sx={{width: '50%'}}>{row.dmu_id}</TableCell>
            <TableCell sx={{width: '50%'}}>{numeralFormatter(row.efficiency * 100)} %</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default NoPeriodBottomEfficiencyTable;
