import {
	CircularProgress,
} from '@mui/material';
import React, { useEffect, useState, useContext, createContext } from 'react';
import { PrimaryButton } from '../../../components/common/button';
import { CardContentContainer, MainCard } from '../../../components/common/commonContainer';
import EmptyCardMessage from '../../../components/common/emptyCardMessage';
import { UpperBarComponent } from '../../../components/common/upperBarComponent';
import { get_new } from '../../../utils/httpClient';
import ProjectContext from '../../../context/Project/ContextProject';
import AddSegmentation from './segmentationScenarios/menuOptions/addSegmentation';
import SegmentationProjectHomeTable from './segmentationScenarios/segmentationProjectHomeTable';

export const SegmentationScenariosContext = createContext();
export default function SegmentationScenarios() {

	// COMPONENT STATE
	const [loading, setLoading] = useState(true);
	const [segmentationDialog, setSegmentationDialog] = useState(false);
	const [createdID, setCreatedID] = useState('');
	const [deletedID, setDeletedID] = useState('');

	// SEGMENTATION HANDLE TABLE PAGINATION
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);

	const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};  

	// CONTEXT STATE
	const {projectData, segmentationScenarios, setSegmentationScenarios} = useContext(ProjectContext);

	//Search bar Segmentation table Value handler
	const [searchSegmentation, setSearchSegmentation] = useState('')
	const [delayedSegmentationTerm, setDelayedSegmentationTerm] = useState('');

	const searchSegmentationHandler = (event) => {
		setSearchSegmentation(event.target.value)
	}

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setDelayedSegmentationTerm(searchSegmentation);
			setPage(0)
		}, 1000);
	
		return () => clearTimeout(delayDebounceFn);
	  }, [searchSegmentation]);
	

	// GET LIST OF SEGMENTATION SCENARIOS
	useEffect(() => {
		if (!projectData.project_id) return;
		
		console.log('Refresh Segmentation Scenarios');
		//getSegmentations();
		setLoading(true);
		const target_url = `/project/segmentations/?project_id=${projectData.project_id}&page_size=${rowsPerPage}&page=${page + 1}&search=${delayedSegmentationTerm}`;
		get_new(target_url)
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status +" : "+ response.statusText);
				}
				return response.json();
			})
			.then((data) => { 
				console.log('Segmentation Scenarios: ', data.results.length);
				setSegmentationScenarios(data);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error:', error);
				setLoading(false);
			});
		
		//eslint-disable-next-line
		}, [projectData, createdID, deletedID, rowsPerPage, page, delayedSegmentationTerm]);
	
	// OPEN/CLOSE CREATE SEGMENTATION SCENARIO DIALOG
	const toggleSegmentationDialog = () => {
		setSegmentationDialog(!segmentationDialog);
	};

	return (
		<>
			<SegmentationScenariosContext.Provider value={{segmentationScenarios, setCreatedID, setDeletedID}}>
			<MainCard>
				{/* TABLE HEADER*/}
				<UpperBarComponent
					text={'Segmentation Scenarios'}
					search={true}
					value={searchSegmentation}
					onChange={searchSegmentationHandler}
	
					button={
						<PrimaryButton
							onClick={toggleSegmentationDialog}
							largeText={'+ Create Scenario'}
							SmallText={'+'} />
					} />
				
				{/* TABLE WITH SEGMENTATION SCENARIOS */}
				<CardContentContainer>
					{loading ? (
						<CircularProgress />
					) : segmentationScenarios.results && segmentationScenarios.results.length > 0 ? (
								<SegmentationProjectHomeTable
									segmentationScenarios={segmentationScenarios.results}
									setDeletedID={setDeletedID}
									page={page}
									count={segmentationScenarios.count}
									rowsPerPage={rowsPerPage}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
					) : (
						<EmptyCardMessage text1="Segmentation Scenario" text2="Create Scenario" />
					)}
				</CardContentContainer>
			</MainCard>

			{/* CREATE NEW SEGMENTATION SCENARIO MODAL/DIALOG */}
			{segmentationDialog && (
				<AddSegmentation
					open={segmentationDialog}
					onClose={toggleSegmentationDialog}
				/>
			)}
			</SegmentationScenariosContext.Provider>
		</>
	);
}
