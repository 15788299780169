import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const BaseLink = styled(Link) ({
    textDecoration: 'none',
})


export const BackLink = styled(BaseLink) ({
    color: '#47254B'
})

export const SideBarLink =styled(BaseLink) ({
    color: '#B4a0b7',
    fill: '#B4a0b7',
    '&:hover': {
        fill: '#FFFFFF',
        color: '#FFFFFF'
    }, 
})

export const TableLink = styled(BaseLink) ({
    color: '#47254B',
    '&:hover': {
        color: '#8C6D90'
    }, 
})

export const HelperLink = styled(BaseLink) ({
    color: '#000000',
    '&:hover': {
        textDecoration: 'underline'
    }, 
})