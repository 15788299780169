import { enqueueSnackbar } from "notistack";
import { patch_json } from "../../../../../../utils/httpClient";

/*Handle Unpublish benchmark scenario */
export const handleUnpublishAction = async (action, summaryData) => {
    //Make copy of summaryData
    let data = JSON.parse(JSON.stringify(summaryData));
    data.action = action; // 'save' or 'publish'            
    console.log('action', action)
    console.log('Final Benchmark data', data);
    console.log('Final Benchmark Summary Data', summaryData);

    const unpublish ={
        action: action,
        benchmark_id: summaryData.benchmark_id,
    }
    console.log(unpublish) 
    //Target URL for posting form
    const targetURL = '/project/benchmark/';
    patch_json(targetURL, unpublish)
    .then((response) => {
        console.log('response', response);
        if (!response.ok) {
            throw new Error(response.status + response.statusText);
        }
        return response.json();
    })
    .then((data) => {
        console.log('Benchmark Scenario unpublished');
        console.log(data);
        enqueueSnackbar('Successfully Unpublished scenario: ' + summaryData.benchmark_name.trim(), { variant: 'success' });
        // setCreatedID(data.benchmark_id);
        // handleCloseDialog();
        if(action === 'unpublish') {
            setTimeout(() => {
            window.location.reload();
            }, 2000); // 2000 milliseconds = 2 seconds
        }

        return;
    })
    .catch((error)=>{
        enqueueSnackbar('Failed to unpublish Benchmark Scenario: ' + summaryData.benchmark_name.trim(), { variant: 'error' });
        console.log('Benchmark Scenario unpublish failed');
        console.log(error)
        // handleButtonSpinners(action, false);
        //handleCloseDialog();
        return 
    })
};