import React from "react";
import { Chart as ChartJS } from "chart.js";
import {
  BoxPlotController,
  BoxAndWiskers,
} from "@sgratzl/chartjs-chart-boxplot";
import colors from "../../assets/variables.scss";
import { useRef } from "react";
import { useMemo } from "react";
import { numeralFormatter } from "../../utils/numeralFormatter";

ChartJS.register(BoxPlotController, BoxAndWiskers);

export function VariableBoxplot({ api, segment }) {
  const canvasRef = useRef(undefined);
  const chartRef = useRef(undefined);

  const data = useMemo(() => {
    const labels = api?.results?.map((data) => data.cluster_cd);
    return {
      labels,
      datasets: [
        {
          label: "Datasets",
          data: api?.results?.map((data) => ({
            min: data.min,
            max: data.max,
            q1: data.number_25_pct,
            q3: data.number_75_pct,
            median: data.number_50_pct,
            mean: data.mean,
          })),

          borderColor: colors.extra4,
          backgroundColor: (context) => {
            const value = segment ? segment : false;
            return value === false
              ? [
                  colors.segment5,
                  colors.segment2,
                  colors.segment3,
                  colors.segment4,
                ]
              : value === 0
              ? colors.segment5
              : value === 1
              ? colors.segment2
              : value === 2
              ? colors.segment3
              : colors.segment4;
          },
        },
      ],
    };
  }, [api, segment]);

  React.useEffect(() => {
    chartRef.current = new ChartJS(canvasRef.current, {
      type: "boxplot",
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: `${api?.results?.[0]?.variable} By Segment`,
            color: "#4c4c4d",
            font: {
              size: 14,
              weight: "bold",
              lineHeight: 1.2,
            },
          },
          datalabels: { display: false },
          legend: { display: false },

          tooltip: {
            callbacks: {
              label: (context) => {
                const boxplotValues = [
                  `Min: ${numeralFormatter(context.parsed.min)}`,
                  `25% Quartile: ${numeralFormatter(context.parsed.q1)}`,
                  `Median: ${numeralFormatter(context.parsed.median)}`,
                  `Mean: ${numeralFormatter(context.parsed.mean)}`,
                  `75% Quartile: ${numeralFormatter(context.parsed.q3)}`,
                  `Max: ${numeralFormatter(context.parsed.max)}`,
                ];
                return boxplotValues;
              },
            },
          },
        },

        scales: {
          x: {
            display: true,
            grid: { display: false },

            title: {
              display: true,
              text: "Segments",
              color: "#4c4c4d",
              font: {
                size: 10,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
            },
          },
          y: {
            display: true,
            beginAtZero: false,
            grid: { display: false },

            grace: "10%",
            title: {
              display: true,
              align: "end",
              text: "Efficiency ( % )",
              color: "#4c4c4d",
              font: {
                size: 10,
                weight: "bold",
                lineHeight: 1.2,
              },
            },
          },
        },
      },
    });

    return () => {
      chartRef.current.destroy();
    };
  }, [data, api?.results]);
  return <canvas ref={canvasRef} height={400} />;
}
