import React, {useContext } from 'react';
import {
	
	FormControlLabel,
	Radio,
    RadioGroup,
	Box,
    Grid
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';
import { useEffect } from 'react';


export default function TargetsWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData } = useContext(CreateBenchmarkContext);

    useEffect(() => {
        if (formData.objective === 'input_output_together' &&
            formData.target_values_type === 'radial') {
                changeFormData('target_values_type', 'non_radial');
            }
    }, [formData.objective]);

            
    
    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Metrics target value</b></div>
            <br />
            <div>
                How should targets be set?
            </div>
            <RadioGroup
                value={formData.target_values_type}
                onChange={(event) => changeFormData('target_values_type', event.target.value)}
            >
                <Box sx={{ width: '100%', flexGrow: 1}}>
                    <Grid
                        container
                        rowSpacing={2}
                        margin={0}
                        padding={0}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                       
                        
                    {/*Option radial*/}
                        <Grid item
                            xs={12}
                            sx={{
                                '&:hover': {
                                backgroundColor: 'lightblue',
                            },
                            }}
                            padding={1}
                        >
                            <Box>
                                    <FormControlLabel
                                        value='radial'
                                        disabled={formData.objective === 'input_output_together'}
                                        control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Equal proportion (Radial) </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    All improvement targets for inputs and/or output metrics
                                                    are minimized or maximized accordingly in the same proportion.
                                                    For example, if the objective is to minimize resource usage (Input based)
                                                    all input target values will be minimized by the same proportion (e.g. 10%).


                                            </div>  
                                        </div>   
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
                            {/*Option Non-radial*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                    sx={{
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel value='non_radial' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Individual proportion (Non-radial) </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    All improvement targets for inputs and/or output metrics are minimized
                                                    or maximized accordingly and independently for each metric. For example, if the
                                                    objective is to minimize resource usage (Input based) all input target values will
                                                    be minimized independently (e.g. 10% for input1, 6% for another input2, etc.).'
                                            </div>
                                        </div>   
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
                        </Grid>
                        </Box>
                </RadioGroup>
                </TabPanel>
        </>
    )
}