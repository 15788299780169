import React, { useContext } from 'react';
import {
	FormControlLabel,
	Radio,
    RadioGroup,
	Box,
    Grid
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';


export default function ObjectiveWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData } = useContext(CreateBenchmarkContext);
    
    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Select benchmarking Objective:</b></div>
            <br />
            <div>
                The objective determine the orientation that the algorithm will use to optimize, please select one of the options below:
            </div>
            <RadioGroup
                value={formData.objective}
                onChange={(event) => changeFormData('objective', event.target.value)}
            >
                <Box sx={{ width: '100%', flexGrow: 1 }}>
                    <Grid
                        container
                        rowSpacing={2}
                        margin={0}
                        padding={0}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                       
                        
                    {/*Options Rank*/}
                        <Grid item
                            xs={12}
                            sx={{
                                
                                '&:hover': {
                                backgroundColor: 'lightblue',
                            },
                            }}
                            padding={1}
                        >
                            <Box>
                                <FormControlLabel value='input' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Input Based </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    Analyze entities by assessing their input metric values and keeping
                                                    output metrics fixed. Creates improvement plans by identifying target values for
                                                    the 'input' metric. A common example is 'How much resources (input) should be used to
                                                    produce a given amount of output?'
                                            </div>
                                        </div>   
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
                            {/*Option Benchmark*/}
                                <Grid
                                    item
                                xs={12}

                                    padding={1}
                                    sx={{
                                        
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel value='output' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Output Based </b></div> 
                                            <div style={{fontSize: 12}}>
                                                Benchmark and rank entities by assessing their output metric values and keeping
                                                input metrics fixed. Creates improvement plans by identifying target values for
                                                the 'output' metric.A common example for this is 'Given a certain amount of resources,
                                                how much output should we be producing?'
                                            </div>
                                        </div>   
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
{/*Option input_output_independent*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                    sx={{
                                        
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel value='input_output_independent' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Both Input & Output based independently </b></div> 
                                            <div style={{fontSize: 12}}>
                                                Benchmark and rank entities by assessing input metrics while keeping output
                                                metrics fixed. And output metric values and keeping
                                                    input metrics fixed. This is the same as running "Input Based" and "Output Based"
                                                    simulations separately. 
                                                
                                            </div>
                                        </div>   
                                        } />
                                    <br />
                                </Box>
                            </Grid>
                        {/*Option input_output_together*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={1}
                                    sx={{
                                        
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel value='input_output_together' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Both Input & Output based simultaneously </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    Benchmark and rank entities by assessing input & output metric values simultaneously.
                                                    Creates improvement plans by identifying target values at a balanced point to simultaneously
                                                    minimize input and maximize output metrics. 
                                                    A common example for this is 'What are the metric target values that produce the best trade-off
                                                    between minimizing resource usage (inputs) while still increasing outputs?'
                                                
                                            </div>
                                        </div>   
                                        } />
                                    <br />
                                </Box>
                            </Grid>
                        </Grid>
                        </Box>
                </RadioGroup>
                </TabPanel>
        </>
    )
}