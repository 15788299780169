import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { Box } from '@mui/material';

ChartJS.register(
  BarElement,
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Entities By Segment (n)',
      color: '#4c4c4d',
      font: {
        size: 14,
        weight: 'bold',
        lineHeight: 1.2,
      },
    },
    legend: {
      display: false
    },
    datalabels: {
      align: 'center',
      color: 'white',
      weight: 'bold',

      // anchor: 'end',
      // align: 'center'
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      grace: '30%',
      display: false,
      stacked: true,
    },
  },
};



export default function SegmentationEntitiesBySegment({ api, segment }) {

  const labels = api?.results?.[0]?.clusters.sort((a, b) => a.cluster_cd - b.cluster_cd).map(d=>`Segment ${d.cluster_cd}`)
  const data = {
    labels,
    datasets: [
      {
        label: "Segments",
        data: api?.results?.[0]?.clusters.sort((a, b) => a.cluster_cd - b.cluster_cd).map( d => d.cluster_count ),
        backgroundColor: (context) => {
          const value = segment !== "" ? segment : false
  
            return value === false ?  [colors.segment5, colors.segment2,colors.segment3,colors.segment4] :  
            value === 0 ? colors.segment5 : 
            value === 1 ? colors.segment2 : 
            value === 2 ? colors.segment3 : 
            colors.segment4
          }
      },
    ],
  };

  return  <Box sx={{ width: {xs:'100%', md:'70%'}, height: '200px' }}>
    <Bar options={options} data={data} className="segmentation" />
  </Box>
}
