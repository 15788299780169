import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { TargetContainer } from '../../views/results/dashboard/individualResultsDashboard';
import { Box, Typography } from '@mui/material';
import { numeralFormatter } from '../../utils/numeralFormatter';


ChartJS.register(
  BarElement,
);

export default function OutputTargetGraphs({api, periodOptions}) {

const labels = periodOptions?.length <= 1 ? ["current", "target"] : ["base", "current", "target"];
const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
        display: false
    },
    title: {
      display: false,
      text: 'Output Target',
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      formatter: (value) => {
        return `${numeralFormatter(value)}`
      }
    },
  },
  scales: {
    x: {
      offset: true,
      stacked: true,
    },
    y: {
      grid: {display: false},
      ticks: { display: false},
      title: {
        display: false,
        align: 'end',
        text: '',
        color: '#4c4c4d',
        font: {
          family: "Arial",
          size: 10,
          weight: 'bold',
          lineHeight: 1.2
        },
        padding: {top: 0, left: 0, right: 0, bottom: 0}
      },
      grace: '30%',
      display:true,
      stacked: true,
    },
  },
};

return     <Box sx={{display: 'flex', flexFlow: 'row wrap', width: '100%', justifyContent: {xs: 'center', lg:'flex-start'}, gap: '20px', p: '0 20px'}}>
{api?.map((chartData, index) => (
  <TargetContainer key={index} sx={{width: {xs:'80%',lg:'27%'}}}>
    <TargetContainer sx={{padding: '10px', maxHeight: '20px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', border: 'none'}}>
      <Typography variant="h6" color="initial">{chartData.variable__variable_name.replace(/_/g, " ")}</Typography>
    </TargetContainer>
    <div style={{width: '100%', height: '190px'}}>
      <Bar 
        data={
          {
            labels, 
            datasets: [
              {
                label: 'metric',
                data: periodOptions?.length <= 1 ? [chartData?.actual_value, chartData?.target] : [(chartData?.actual_value - chartData?.improv_benchmark_period), chartData?.actual_value, chartData?.target],
                backgroundColor: [colors.cluster1, colors.cluster2, colors.cluster3],
                yTitle: "In Units",
              },
            ],
          }
        } 
        options={options}
      />
    </div>
  </TargetContainer>
))}
</Box>    
}
