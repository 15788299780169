import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { get_new } from "../../../utils/httpClient";
import ChartScatter from "./chart_scatter";
import ContextDataset from "../../../context/Dataset/ContextDataset";
import { DatasetContext } from "../datasetDetails";

export default function BivariateDistribution() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // LOCAL STATE
  const [xyOptions, setXYOptions] = useState({ x: [], y: [] });
  const [bivariateState, setBivariateState] = useState({
    data: [],
    correlation: "",
    mean_a: "",
    mean_b: "",
    std_a: "",
    std_b: "",
    varName_a: "",
    varName_b: "",
  });

  // CONTEXT STATE
  const { dataset, state, setState } = useContext(DatasetContext);

  const contextDataset = useContext(ContextDataset);

  // Define filter options
  useEffect(() => {
    let newFilterOptions = {
      x: [
        {
          value: "",
          label: "",
        },
      ],
      y: [
        {
          value: "",
          label: "",
        },
      ],
    };

    // get list of numerical variables that are not equal to filter.multivariate_vars.y
    newFilterOptions.x = dataset.variables
      .filter((variable) => {
        return (
          variable.variable_detail_id !== state.filters.bivariate_vars.y &&
          variable.var_type === "numerical"
        );
      })
      .map((variable) => {
        return {
          value: variable.variable_detail_id,
          label: variable.original_field_name,
        };
      });

    // get list of numerical variables that are not equal to filter.multivariate_vars.x
    newFilterOptions.y = dataset.variables
      .filter((variable) => {
        return (
          variable.variable_detail_id !== state.filters.bivariate_vars.x &&
          variable.var_type === "numerical"
        );
      })
      .map((variable) => {
        return {
          value: variable.variable_detail_id,
          label: variable.original_field_name,
        };
      });
    setXYOptions(newFilterOptions);
  }, [state.filters.bivariate_vars]);

  // GET DATA
  useEffect(() => {
    setLoading(true);
    // exit if no variables in filter
    if (!state.filters.bivariate_vars.x || !state.filters.bivariate_vars.y)
      return setLoading(false);

    // get variable names for filter
    let x = dataset.variables.filter((variable) => {
      return variable.variable_detail_id === state.filters.bivariate_vars.x;
    })[0].original_field_name;

    let y = dataset.variables.filter((variable) => {
      return variable.variable_detail_id === state.filters.bivariate_vars.y;
    })[0].original_field_name;

    let newState = {};

    let targetURL = `/dataset/bivariable/?dataset_id=${dataset.dataset_id}`;
    targetURL += `&period=${state.filters.period}`;
    targetURL += `&metric_a=${x}&metric_b=${y}`;

    console.log("targetURL: ", targetURL);

    get_new(targetURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + ": " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("data: ", data);
        newState.data = data.bivariable;
        newState.correlation = data.corr[0].correlation
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        newState.mean_a = data.table_a[0].mean_a
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        newState.mean_b = data.table_b[0].mean_b
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        newState.std_a = data.table_a[0].std_a
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        newState.std_b = data.table_b[0].std_b
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        newState.varName_a = x;
        newState.varName_b = y;
        console.log("newState: ", newState);

        setBivariateState((prevState) => {
          return {
            ...prevState,
            ...newState,
          };
        });
      })
      .catch((error) => {
        console.log("Error: " + error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    state.filters.bivariate_vars,
    dataset.dataset_id,
    state.filters.period,
    dataset.variables,
  ]);

  // CHANGE BIVARIATE XY FILTER STATE
  const changeXYFilter = async (name, value) => {
    let newBiVariateVars = {
      ...state.filters.bivariate_vars,
      ...{
        [name]: value,
      },
    };
    console.log("Change Bivariate Filter: ", newBiVariateVars);
    setState((prevState) => {
      return {
        ...prevState,
        ...{
          filters: {
            ...prevState.filters,
            bivariate_vars: newBiVariateVars,
          },
        },
      };
    });
  };

  // HANDLE CHANGE FILTER (PERIOD)
  function changeFilter(name, value) {
    const newFilter = {
      ...state.filters,
      ...{
        [name]: value,
      },
    };
    console.log("Change Filter: ", newFilter);
    setState((prevState) => {
      return {
        ...prevState,
        ...{
          filters: newFilter,
        },
      };
    });
  }

  return (
    <>
      <Typography fontSize="1.2rem" fontWeight="bold" marginBottom="20px">
        Exploratory Data Analysis
      </Typography>
      {console.log("filters: ", state.filters)}

      <Grid container spacing={2} marginBottom="20px">
        <Grid item>
          <Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
            X Axis
          </Typography>
          <Select
            id="x-axis"
            displayEmpty
            value={xyOptions.x.length > 0 ? state.filters.bivariate_vars.x : ""}
            size="small"
            onChange={(event) => changeXYFilter("x", event.target.value)}
            //renderValue={(selected) => (!selected ? <em>Select Variable</em> : selected)}
          >
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            {xyOptions.x.map((row, index) => (
              <MenuItem key={index} value={row.value}>
                {row.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
            Y Axis
          </Typography>
          <Select
            id="y-axis"
            displayEmpty
            value={state.filters.bivariate_vars.y}
            size="small"
            onChange={(event) => changeXYFilter("y", event.target.value)}
            //renderValue={(selected) => (!selected ? <em>Select Variable</em> : selected)}
          >
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            {xyOptions.y.map((row, index) => (
              <MenuItem key={index} value={row.value}>
                {row.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {dataset.periods.length > 0 ? (
          <Grid item>
            <Typography fontSize="0.9rem" fontWeight="bold" marginBottom="5px">
              Period
            </Typography>
            <Select
              id="period"
              value={state.filters.period}
              size="small"
              onChange={(event) => changeFilter("period", event.target.value)}
              //renderValue={(selected) => (!selected ? <em>Select Period</em> : selected)}
            >
              {dataset.periods.map((period, index) => (
                <MenuItem key={index} value={period.date_string}>
                  {period.date_string}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        ) : null}
      </Grid>

      {loading ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </DialogContent>
      ) : error ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          Error: {error.message}
        </DialogContent>
      ) : !state.filters.bivariate_vars.x || !state.filters.bivariate_vars.y ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <Typography variant="subtitle1" color="initial">
            Please Select an Option for X Axis and Y Axis
          </Typography>
        </DialogContent>
      ) : (
        <>
          <ChartScatter
            data={bivariateState.data}
            x={bivariateState.varName_a}
            y={bivariateState.varName_b}
          />
          <br />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>X Axis</TableCell>
                  <TableCell>Y Axis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Variable</b>
                  </TableCell>
                  <TableCell>{bivariateState.varName_a}</TableCell>
                  <TableCell>{bivariateState.varName_b}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Mean</b>
                  </TableCell>
                  <TableCell>{bivariateState.mean_a}</TableCell>
                  <TableCell>{bivariateState.mean_b}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Standard Deviation</b>
                  </TableCell>
                  <TableCell>{bivariateState.std_a}</TableCell>
                  <TableCell>{bivariateState.std_b}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Correlation</b>
                  </TableCell>
                  <TableCell>{bivariateState.correlation}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
