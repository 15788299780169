import React, { useState, useContext } from 'react';
import {
    FormControl,
	FormControlLabel,
	Radio,
    RadioGroup,
    Switch,
	Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';
import { useEffect } from 'react';
import { post_json } from '../../../../../../utils/httpClient';

export default function TrackingPeriodsWS({ tabValue, index }) {
    // Local state
    const [trackingCycles, setTrackingCycles] = useState([]);
    
    // Context state
    const { formData, changeFormData, periodData } = useContext(CreateBenchmarkContext);

    // Query backend to calculate tracking cycles 
    useEffect(() => {
        if (formData.tracking_frequency === '') return;
        
        const targetURL = '/tracking-periods/';
        console.log(targetURL);
        /*
        let data = {
            'baseline_frequency': formData.baseline_frequency,
            'first_baseline_period': formData.first_baseline_period,
            'tracking_frequency': formData.tracking_frequency,
            'periods': periodData.periods_list.map((period) => { return period.period; }),
            'include_next_baseline': formData.include_next_baseline,
        };
        */
        let data = {
            'benchmark_freq': formData.baseline_frequency,
            'benchmark_start_period': periodData.periods_list.filter((period) => {
                return period.period_id === formData.first_baseline_period;
            })[0].date_string,
            'tracking_freq': formData.tracking_frequency,
            'period_list': periodData.periods_list.map((period) => { return period.date_string; }),
            'include_next_baseline': formData.include_next_baseline,
        };
        console.log('Tracking Post data', data);
        
        post_json(targetURL, data)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status + ' : ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log('tracking cycles: ', data.dict[1]);
                setTrackingCycles(data.dict[1]);
            })
            .catch(error => {
                console.log(error);
            });
            
    }, [
        formData.baseline_frequency, formData.first_baseline_period,
        formData.tracking_frequency, formData.include_next_baseline, periodData.periods_list]);

    //{ const firstCycle = trackingCycles.filter((cycle) => { return cycle.cycle_id === 1; }); }

    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Tracking Cycles Tracking Frequency</b></div>
            <br /> 
                <div>How often should progress be assessed against the baseline?</div>
                <div>All periods between baseline periods that match the tracking 
                    frequency will be compared against the most immediate baseline
                    before it. Periods that don't match the tracking frequency will be
                    omitted.
                </div>
            <br/>
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4} md={3}>
                        <Box >
                            
                            {/*Disable if Dataset doesn't have periods*/}
                            <RadioGroup
                                value={(formData.tracking_frequency)}
                                onChange={(event) => changeFormData('tracking_frequency', event.target.value)}
                            >
                                {/*Disable if baseline periods are Quarterly or monthly*/}
                                <FormControlLabel
                                    value='yearly'
                                    control={<Radio />}
                                    disabled={['quarterly','monthly'].includes(formData.baseline_frequency) 
                                    || !periodData.has_periods
                                    || formData.baseline_frequency===''
                                    || formData.first_baseline_period===''
                                    || formData.first_baseline_period===null
                                }
                                    label='Yearly'
                                />
                                
                                {/*Disable if baseline periods are monthly*/}
                                {/*Disable if Dataset has yearly periods*/}
                                <FormControlLabel value='quarterly' control={<Radio />}
                                    disabled={
                                        ['monthly'].includes(formData.baseline_frequency) 
                                        || periodData.periodicity === 'yearly' 
                                        || !periodData.has_periods
                                        || formData.baseline_frequency===''
                                        || formData.first_baseline_period===''
                                        || formData.first_baseline_period===null
                                    }
                                    label='quarterly'
                                />
                                {/*Disable if Dataset has anything other than Monthly periods*/}
                                <FormControlLabel
                                    disabled={
                                        periodData.periodicity !== 'monthly' ||
                                        !periodData.has_periods}
                                    value='monthly'
                                    control={<Radio />}
                                    label='Monthly'
                                />

                            </RadioGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                    <Grid container spacing={2}>
                        {/* Include next baseline option*/}
                        <Grid item xs={12}>
                                <Box >
                                    Include next baseline as last period in cycle?<br/>
                             <FormControl>
                            <FormControlLabel
                                //value={formData.apply_restricted}
                                //label='Include next baseline period'
                                 //   labelPlacement='right'
                                    disabled={formData.tracking_frequency === formData.baseline_frequency}
                                control={
                                    <Switch
                                        checked={formData.include_next_baseline}
                                        onChange={(event) => {
                                            changeFormData('include_next_baseline', event.target.checked);
                                        }}
                                    />}
                            />
                        </FormControl>
                        </Box>
                        </Grid>
                        {/* Tracking Cycles Table*/}
                        <Grid item xs={12}>
                            {(formData.baseline_frequency===''||formData.first_baseline_period==='')&& (
                                < Box color='red'>
                                        Baseline frequency information is missing. <br/>
                                        Please go back to the previous step and fill in the required information.
                                </Box>
                            )}
                            {trackingCycles.length > 0 && (
                                
                                    <Box >
                                        <TableContainer sx={{minWidth: 300, maxWidth:400}}>
                                            <div style={{textAlign:'center'}}>
                                                Period setup for the First Cycle
                                            </div>
                                            <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Baseline Period</TableCell>
                                                <TableCell>Tracking Period</TableCell>
                                            </TableRow>
                                        </TableHead>
                                                <TableBody>
                                                    {console.log()}
                                                    {trackingCycles
                                                        .filter((cycle) => { return cycle.cycle_id === 1; })
                                                        .map((row) => (
                                                        <TableRow key={"trackingPeriod"+row.id}>
                                                            <TableCell>{row.baseline_period}</TableCell>
                                                            <TableCell>{row.tracking_period}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                            </Box>
                            )}

                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            
                                    
         </TabPanel>                   
        </>
    )
}