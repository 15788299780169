import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useComparisonResultsDashboardStore = create(
  persist(
    (set) => ({
      selectedValues: [],
      setSelectedValues: (selectedValues) => set({ selectedValues }),
    }),
    {
      name: "comparisonResultsDashboard-store",
      getStorage: () => sessionStorage,
    }
  )
);

export const useScreenSizeStore = create((set) => ({
  isSmallScreen: window.innerWidth < 1536,
  setScreenSize: () => set({ isSmallScreen: window.innerWidth < 1536 }),
}));

export const useSearchProjectsStore = create(
  persist(
    (set) => ({
      filterFields: [
        {
          key: "searchProject",
          title: "Search Project",
          value: "",
          label: "Search Project",
          name: "searchProject",
          type: "text",
        },
        {
          key: "status",
          title: "Status",
          label: "Status",
          name: "status",
          type: "select",
          placeHolder: "",
          value: "",
          options: [
            { label: "All", value: "" },
            { label: "New", value: "new" },
            { label: "Setup", value: "setup" },
            { label: "Processing", value: "processing" },
            { label: "Completed", value: "completed" },
            { label: "Published", value: "published" },
          ],
        },
      ],
      setFilterFields: (filterFields) => set({ filterFields }),
    }),
    {
      name: "searchProject-store",
      getStorage: () => sessionStorage,
    }
  )
);

export const useDataSetStore = create((set) => ({
  dataSetValuesData: [],
  setDataSetValuesData: (dataSetValuesData) => set({ dataSetValuesData }),
}));

export const useProjectsPageStore = create(
  persist(
    (set) => ({
      projectsPage: 0,
      setProjectsPage: (projectsPage) => set({ projectsPage }),
    }),
    {
      name: "projectsPage-store",
      getStorage: () => sessionStorage,
    }
  )
);

export const useProjectsRowsPerPageStore = create(
  persist(
    (set) => ({
      projectsRowsPerPage: 8,
      setProjectsRowsPerPage: (value) => set({ projectsRowsPerPage: value }),
    }),
    { name: "projectRowsPerPage-store", getStorage: () => sessionStorage }
  )
);

export const useDataSetPageStore = create(
  persist(
    (set) => ({
      datasetsPage: 0,
      setDatasetsPage: (datasetsPage) => set({ datasetsPage }),
    }),
    {
      name: "dataSetPage-store",
      getStorage: () => sessionStorage,
    }
  )
);
export const useDataSetRowsPerPageStore = create(
  persist(
    (set) => ({
      datasetsRowsPerPage: 8,
      setDatasetsRowsPerPage: (value) => set({ datasetsRowsPerPage: value }),
    }),
    { name: "datasetsRowsPerPage-store", getStorage: () => sessionStorage }
  )
);

export const useHomeSearchProjectStore = create(
  persist(
    (set) => ({
      searchProjects: "",
      setSearchProjects: (searchProjects) => set({ searchProjects }),
    }),
    {
      name: "searchProject-store",
      getStorage: () => sessionStorage,
    }
  )
);

export const useHomeSearchDatasetStore = create(
  persist(
    (set) => ({
      searchDatasets: "",
      setSearchDatasets: (searchDatasets) => set({ searchDatasets }),
    }),
    {
      name: "searchDataset-store",
      getStorage: () => sessionStorage,
    }
  )
);

export const useHomeFiltersProjectsStore = create(
  persist(
    (set) => ({
      projectsCreationDate: "",
      projectsStatus: "",
      setProjectsCreationDate: (projectsCreationDate) =>
        set({ projectsCreationDate }),
      setProjectsStatus: (projectsStatus) => set({ projectsStatus }),
      datasetsCreationDate: "",
      datasetsStatus: "",
      setDatasetsCreationDate: (datasetsCreationDate) =>
        set({ datasetsCreationDate }),
      setDatasetsStatus: (datasetsStatus) => set({ datasetsStatus }),
    }),
    {
      name: "homeFiltersProjects-store",
      getStorage: () => sessionStorage,
    }
  )
);
