import { get_new } from "../../../utils/httpClient";

export const getSegmentOptions = ({ project_id, segmentation_scenario_id, periodField }) => {
  const url = `/project/segmentation/results/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}&period_id=${periodField.value}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
      // Remove duplicate values using Set
      const options = [...new Set(data.results[0].clusters
        .sort((a, b) => a.cluster_cd - b.cluster_cd)
        .map((cluster) => cluster.cluster_cd))
      ];	
      return options
  })
  .catch((error) => {
    console.error('Error fetching Segment Options data:', error);
    throw error;
  })
};

export const getPeriodOptions = ({ project_id, segmentation_scenario_id }) => {
  const url = `/project/segmentation/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    const options = data.results[0].segmentationsegperiod.map(p => ({ label: p.date_string, value: p.period_id }));
    return options
  })
  .catch((error) => {
    console.error('Error fetching Segment Options data:', error);
    throw error;
  })
};

export const getMetricOptions = ({ project_id, segmentation_scenario_id, segmentField }) => {
  const url = `/project/segmentation/results/metrics/variable/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}&cluster_cd=${segmentField.value}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => {
    // Map and extract the 'name' property values
    const metricValues = [...new Set(data?.results?.map(value => value.variable))];
    const metricOptions = metricValues.map(value => ({ label: value, value: value }))
    return metricOptions
  })
  .catch((error) => {
    console.error('Error fetching Segment Options data:', error);
    throw error;
  })
};

export const getProject = ({ project_id }) => {
  const url = `/project/?project_id=${project_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error fetching Api data:', error);
    throw error;
  })
};

export const getSegmentationScenario = ({ project_id, segmentation_scenario_id }) => {
  const url = `/project/segmentations/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error fetching Api data:', error);
    throw error;
  })
};

export const getSegmentVariableId = ({ project_id, segmentation_scenario_id }) => {
  const url = `/api/project/segmentation/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching Api data:', error);
    throw error;
  })
};

export const getDatasetId = ({ project_id }) => {
    const url = `/project/?project_id=${project_id}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data.results[0].dataset_id)
    .catch((error) => {
      console.error('Error fetching Api data:', error);
      throw error;
    })
  };

export const getVariables = ({ datasetId }) => {
    const url = `/dataset/dataset_config2/?dataset_id=${datasetId}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data.results[0].datasetvariable)
    .catch((error) => {
      console.error('Error fetching Api data:', error);
      throw error;
    })
  };