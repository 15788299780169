import { Autocomplete, Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Switch, TextField } from '@mui/material'
import React from 'react'
import { LabelGrid, ValueGrid } from '../manualSettings'
import { useState } from 'react';
import { useEffect } from 'react';

export default function VariableTreatmentsManual({setFormBenchmarkData, formBenchmarkData, changeFormBenchmarkData}) {
  const [restrictedOptions, setRestrictedOptions] = useState([]);
  const [undesirableOptions, setUndesirableOptions] = useState([]);
    // Set restricted options if objective or input/output variables change
    useEffect(() => {
      let varOptions = [];
      if (formBenchmarkData.orient === 'input') {
          varOptions = formBenchmarkData.input_vars;
      }
      if (formBenchmarkData.orient === 'output') {
          varOptions = formBenchmarkData.output_vars;
      }
      if (formBenchmarkData.orient === 'input_output_independent') {
          varOptions = formBenchmarkData.input_vars.concat(formBenchmarkData.output_vars);
      }
      if (formBenchmarkData.orient === 'input_output_together') {
          varOptions = [];
          let newFormBenchmarkData = formBenchmarkData;
          newFormBenchmarkData.apply_restricted = false;
          newFormBenchmarkData.restricted_vars = [];
          setFormBenchmarkData(newFormBenchmarkData);
      }
      setRestrictedOptions(varOptions);
      // console.log('Re-calculated restricted Options:', varOptions);
  }, [formBenchmarkData.orient, formBenchmarkData.input_vars, formBenchmarkData.output_vars]);
  
  // Set undesirable options if objective or input/output variables change
  useEffect(() => { 
      let options=formBenchmarkData.input_vars.concat(formBenchmarkData.output_vars)
      setUndesirableOptions(options);
      // console.log('Re-calculated Undesirable Options:', options);
  }, [formBenchmarkData.objective, formBenchmarkData.input_vars, formBenchmarkData.output_vars]);

return (
    <>
      <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Variable treatments</b> </Box>
      <Divider />
      <Grid container spacing={1} marginY={2} fontSize={12}>
        <LabelGrid> Restricted Variables:</LabelGrid>
        <ValueGrid> 
          <Grid item xs={9} md={12} lg={12}>
            <Grid container spacing={1}>
              <Grid item xs={5} md={3} lg={3}>
                <FormControl>
                  <FormControlLabel
                    value={formBenchmarkData.apply_restricted}
                    label='Restrict variables'
                    labelPlacement='end'
                    componentsProps={{typography: { fontSize: '12px' }}}
                    disabled={formBenchmarkData.objective === 'input_output_together'}
                    control={
                    <Switch 
                      checked={formBenchmarkData.apply_restricted}
                      onChange={(event) => {
                        !event.target.checked ?
                        changeFormBenchmarkData({restricted_vars: [], apply_restricted: event.target.checked})
                        :
                        changeFormBenchmarkData({apply_restricted: event.target.checked});
                      }}
                    />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7} md={9} lg={9}>
                <FormControl fullWidth>
                  <FormLabel htmlFor='restricted-vars' sx={{fontSize: '12px'}}>Restricted Metrics </FormLabel>
                    <Autocomplete
                      multiple
                      id='restricted-vars'
                      size='small'
                      //sx = {{width: '450px'}}
                      disabled={!formBenchmarkData.apply_restricted}
                      selectOnFocus
                      clearOnBlur
                      clearOnEscape
                      autoComplete
                      autoHighlight
                      fullWidth
                      options={restrictedOptions}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={!formBenchmarkData.apply_restricted ? [] : formBenchmarkData.restricted_vars}
                      onChange={(event, newValue) => {
                        changeFormBenchmarkData({restricted_vars: newValue});
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth required 
                    />}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </ValueGrid>
        <LabelGrid > Undesirable Effects Variables:</LabelGrid>
        <ValueGrid> 
          <Grid item xs={9} md={12} lg={12}>
            <Grid container spacing={1}>
              <Grid item xs={5} md={3} lg={3}>
                <FormControl>
                  <FormControlLabel
                    //value={formData.apply_restricted}
                    label='Control Undesirable effects variables'
                    labelPlacement='end'
                    componentsProps={{ typography: { fontSize: '12px' } }}
                    control={
                      <Switch
                          checked={formBenchmarkData.apply_undesirable}
                          onChange={(event) => {
                              !event.target.checked ? 
                              changeFormBenchmarkData({undesirable_vars: [], apply_undesirable: event.target.checked})
                              :
                              changeFormBenchmarkData({apply_undesirable: event.target.checked})
                          }}
                        />
                      }
                  />
                  </FormControl>
                </Grid>
                <Grid item xs={7} md={9} lg={9}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor='undesirable_vars-vars' sx={{fontSize: '12px'}} >Undesirable effect metrics  </FormLabel>
                    <Autocomplete
                      multiple
                      id='undesirable_vars-vars'
                      size='small'
                      //sx = {{width: '450px'}}
                      disabled={!formBenchmarkData.apply_undesirable}
                      selectOnFocus
                      clearOnBlur
                      clearOnEscape
                      autoComplete
                      autoHighlight
                      fullWidth
                      options={undesirableOptions}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={!formBenchmarkData.apply_undesirable ? [] : formBenchmarkData.undesirable_vars}
                      onChange={(event, newValue) => {
                        changeFormBenchmarkData({undesirable_vars: newValue});
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth required />}
                    />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </ValueGrid>
      </Grid>
    </>
  )
}
