import { useState, useEffect } from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import colors from '../../../assets/variables.scss';

export default function ChartTimeSeries(props) {
	const [newData, setNewData] = useState(false);

	const options = {
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				display: false,
			},
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				display: true,
				grid: { display: false },
				title: {
					display: true,
					text: 'Period',
					color: '#4c4c4d',
					font: {
						size: 10,
						weight: 'bold',
						lineHeight: 1.2,
					},
				},
			},
			y: {
				display: true,
				grid: { display: false },
				grace: '10%',
				title: {
					display: true,
					text: props.y,
					color: '#4c4c4d',
					font: {
						size: 10,
						weight: 'bold',
						lineHeight: 1.2,
					},
				},
			},
		},
	};

	useEffect(() => {
		if (props.data.length) {
			const newData = {
				datasets: [
					{
						data: props.data.map((row) => ({ x: row.period, y: row.mean })),
						borderColor: colors.cluster1,
						backgroundColor: colors.cluster1,
					},
				],
			};

			setNewData(newData);
		} else {
			setNewData(false);
		}
	}, [props.data]);

	return (
		<>
			<div style={{ width: '600px', height: '400px' }}>{newData && <Line options={options} data={newData} />}</div>
		</>
	);
}
