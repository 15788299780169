import { get_new } from "../../../../../utils/httpClient"

export const getBoxplotData = ({ benchmark_id, periodObject, orientValue }) => {
  const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject?.period}&orient=${orientValue}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error fetching boxplot data:', error);
    throw error;
  })
};

export const getDmuData = ({benchmark_id, entityField, orientValue}) => {
  const url = `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&dmu_id=${entityField?.value}&orient=${orientValue}`

  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Dmu data:', error);
    throw error;
  })
};

export const getSegmentData = ({ benchmark_id, dmuData, orientValue }) => {
  const cluster_cd = dmuData[0]?.cluster_cd || '';
  const url = `/project/benchmark/results/segment/efficiency/?assignment__cluster__cluster_cd=${cluster_cd}&benchmark_id=${benchmark_id}&orient=${orientValue}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching segment data:', error);
    throw error;
  })
};

export const getOverallData = ({ benchmark_id, orientValue }) => {
  const url = `/project/benchmark/results/efficiency/?benchmark_id=${benchmark_id}&orient=${orientValue}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Overall data:', error);
    throw error;
  })
};