import Cookies from 'js-cookie';

const API = process.env.REACT_APP_API_URL; //Base API URL

export function get(endpoint) {
	const token = Cookies.get('token');
	return fetch(API + endpoint, {
		method: 'GET',
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/json',
			Authorization: `token ${token}`,
		},
	}).then((response) => response.json());
}

export function put(endpoint, data) {
	const token = Cookies.get('token');
	const csrftoken = Cookies.get('csrftoken');
	if (csrftoken) data.csrfmiddlewaretoken = csrftoken;
	
	return fetch(API + endpoint, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRFToken': (csrftoken?csrftoken:token),
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(data),
	});
}

export function post(endpoint, data) {
	const token = Cookies.get('token');
	//using csrftoken for django sessions
	const csrftoken = Cookies.get('csrftoken');
	if(csrftoken) data.append('csrfmiddlewaretoken', csrftoken);

	return fetch(API + endpoint, {
		method: 'POST',
		headers: {
			'X-CSRFToken': (csrftoken?csrftoken:token),
			//'Content-Type': 'application/json',
			Authorization: `token ${token}`,
		},
		body: data,
	});
}



export function get_new(endpoint) {
	const token = Cookies.get('token');
	return fetch(API + endpoint, {
		method: 'GET',
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/json',
			Authorization: `token ${token}`,
		},
	});
}



export function post_json(endpoint, data) {
	const token = Cookies.get('token');
	//using csrftoken for django sessions
	const csrftoken = Cookies.get('csrftoken');
	console.log('csrftoken', csrftoken);
	if (csrftoken) data.csrfmiddlewaretoken = csrftoken;
	
	return fetch(API + endpoint, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRFToken': (csrftoken ? csrftoken : token),
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(data),
	});

}

export function api_delete(endpoint, data) {
	const token = Cookies.get('token');
	//using csrftoken for django sessions
	const csrftoken = Cookies.get('csrftoken');
	if (csrftoken) data.csrfmiddlewaretoken = csrftoken;
	
	return fetch(API + endpoint, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRFToken': (csrftoken ? csrftoken : token),
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(data),
	});
	
}
export function patch_json(endpoint, data) {
	const token = Cookies.get('token');
	//using csrftoken for django sessions
	const csrftoken = Cookies.get('csrftoken');
	console.log('csrftoken', csrftoken);
	if (csrftoken) data.csrfmiddlewaretoken = csrftoken;

	return fetch(API + endpoint, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRFToken': (csrftoken ? csrftoken : token),
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(data),
	});
}

