import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { numeralFormatter } from '../../../../../utils/numeralFormatter';

export default function TotalMetricsTable({ api, periodOptions }) {

    const sortedApi = [...api].sort((a, b) => {
        if (a.variable__variable_type === 'input' && b.variable__variable_type === 'output') {
          return -1; // 'input' comes before 'output'
        }
        if (a.variable__variable_type === 'output' && b.variable__variable_type === 'input') {
          return 1; // 'output' comes after 'input'
        }
        return 0; // No change in order for other cases
      });
      return (
        <TableContainer component={Paper} sx={{ m: '1rem 36px', width: '-webkit-fill-available'}}>
          <Table>
            <TableHead>
              <TableRow sx={{verticalAlign: 'bottom'}}>
                <TableCell>Variable Name</TableCell>
                <TableCell>Orient</TableCell>
                {
                  periodOptions.length <= 1 ?
                  null
                  :
                  <TableCell>Base</TableCell>
                }
                <TableCell>Current</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Different Target vs Base</TableCell>
                {
                  periodOptions.length <= 1 ?
                  null
                  :
                  <>
                    <TableCell>Different Current vs Base</TableCell>
                    <TableCell>Progress To Target</TableCell>
                  </>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedApi?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{row.variable__variable_name}</TableCell>
                  <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{row.variable__variable_type}</TableCell>
                  {
                    periodOptions.length <= 1 ?
                    null
                    :
                    <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{numeralFormatter(row.tot_base)}</TableCell>
                  }
                  <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{numeralFormatter(row.tot_value)}</TableCell>
                  <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{numeralFormatter(row.tot_target)}</TableCell>
                  <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{numeralFormatter(row.tot_diff_target_benchmark)}</TableCell>
                  {
                    periodOptions.length <= 1 ?
                    null
                    :
                    <>
                      <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{numeralFormatter(row.tot_diff_current_benchmark)}</TableCell>
                      <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}}>{numeralFormatter(row.tot_progress_current_target * 100)} %</TableCell>
                    </>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
    