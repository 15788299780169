import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import {
  MainCard,
  ShadowCardContentContainer,
} from "../../../components/common/commonContainer";
import EmptyCardMessage from "../../../components/common/emptyCardMessage";
import FilterSidebar from "../../../components/common/filtersSidebar";
import MetricsHighLevelMetrics from "./metricResults/highLevelMetrics";
import HighLevelMetricsAndVariables from "./metricResults/highLevelMetricsAndVariables";
import { useEffect } from "react";
import { get_new } from "../../../utils/httpClient";

export default function MetricsDashboard({
  benchmark_id,
  project_id,
  projectType,
}) {
  const [loading, setLoading] = useState(true);

  const [filteredData, setFilteredData] = useState([]);
  // const [previousPeriod, setPreviousPeriod] = useState([])
  const [periodObject, setPeriodObject] = useState([]);

  const [filterFields, setFilterFields] = useState([
    {
      key: "period",
      title: "Period",
      label: "",
      name: "Period",
      value: "",
      type: "select",
      options: [{ label: "", value: "" }],
    },
    {
      key: "entity",
      title: "Entity",
      label: "Entity",
      name: "entity",
      value: "",
      type: "select",
      options: [{ label: "", value: "" }],
    },
    {
      key: "metric",
      title: "Metric",
      label: "Metric",
      name: "metric",
      value: "",
      type: "select",
      placeHolder: "",
      options: [{ label: "", value: "" }],
    },
    {
      key: "orient",
      title: "Orient",
      label: "",
      name: "Orient",
      value: "",
      type: "",
      options: [
        { label: "Input", value: "input" },
        { label: "Output", value: "output" },
      ],
    },
  ]);

  //Filter Values
  const periodValue = filterFields[0].value;
  const entityValue = filterFields[1].value;
  const metricValue = filterFields[2].value;
  const orientValue = filterFields[3].value;

  //If project type is input output independent, set values and input type
  useEffect(() => {
    if (projectType === "input_output_independent") {
      filterFields[3].type = "select";
      filterFields[3].label = "Input";
      filterFields[3].value = "input";
    }
  }, [projectType]);

  //SET PERIOD FILTER OPTIONS
  useEffect(() => {
    get_new(
      `/project/dataset-periods/?benchmark__project_id=${project_id}&benchmark_id=${benchmark_id}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const options = data.results.map((p) => ({
          label: p.period,
          value: p.period,
        }));
        const defaultValue = options[options.length - 1].value; // set default value to last object of fetched data
        setFilterFields((prevFields) => {
          return prevFields.map((field) => {
            if (field.key === "period") {
              return {
                ...field,
                options: [...options],
                value: defaultValue, // set default value to last object of fetched data
              };
            }
            return field;
          });
        });
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
      });
  }, [project_id, benchmark_id]);
  //SET PERIOD OBJECT AND PREVIOUS PERIOD
  useEffect(() => {
    get_new(
      `/project/dataset-periods/?benchmark__project_id=${project_id}&period=${periodValue}&benchmark_id=${benchmark_id}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const index = data.results.findIndex(
          (item) => item.period === periodValue
        );
        // setPreviousPeriod(index === 0 ? data.results[index] : data.results[index - 1]);
        setPeriodObject(data.results[index]);
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
      });
  }, [periodValue, project_id, benchmark_id]);

  //SET DMU FILTER OPTIONS
  useEffect(() => {
    get_new(`/project/benchmark/dmu/?benchmark_id=${benchmark_id}`)
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const options = data.results.map((p) => ({
          label: p.dmu_id,
          value: p.dmu_id,
        }));
        const defaultEntity = data.results[0]; // Get the first object from the array
        setFilterFields((prevFields) => {
          return prevFields.map((field) => {
            if (field.key === "entity") {
              return {
                ...field,
                options: [...options],
                value: defaultEntity.dmu_id, // Set the default value
              };
            }
            return field;
          });
        });
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
      });
  }, [periodObject?.period, benchmark_id]);

  useEffect(() => {
    setLoading(true);
    get_new(
      `/project/benchmark/results/dmu/metrics/?benchmark_id=${benchmark_id}&dmu_id=${entityValue}&orient=${orientValue}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const uniqueResults = [
          ...new Map(
            data.results.map((item) => [item?.variable__variable_name, item])
          ).values(),
        ];

        const options = uniqueResults.map((p) => ({
          label: p?.variable__variable_name,
          value: p?.variable__variable_name,
        }));
        const defaultVariable = data.results[0]; // Get the first object from the array
        setFilterFields((prevFields) => {
          return prevFields.map((field) => {
            if (field.key === "metric") {
              return {
                ...field,
                options: [...options],
                value: defaultVariable?.variable__variable_name, // Set the default value
              };
            }
            return field;
          });
        });
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entityValue, benchmark_id, orientValue]);

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <FilterSidebar
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
        />
        <MainCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </MainCard>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <FilterSidebar
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        filterFields={filterFields}
        setFilterFields={setFilterFields}
      />
      <MainCard>
        <MetricsHighLevelMetrics
          entity={entityValue}
          periodObject={periodObject}
          benchmark_id={benchmark_id}
          orientValue={orientValue}
        />
        <ShadowCardContentContainer
          sx={{ padding: entityValue !== null ? "0" : "4rem" }}
        >
          {entityValue !== null ? (
            <HighLevelMetricsAndVariables
              entity={entityValue}
              periodObject={periodObject}
              metric={metricValue}
              benchmark_id={benchmark_id}
              orientValue={orientValue}
            />
          ) : (
            <EmptyCardMessage text1="" text2="" />
          )}
        </ShadowCardContentContainer>
      </MainCard>
    </Box>
  );
}
