import React, { useContext, useEffect, useState } from 'react'
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { post_json } from '../../../../../../utils/httpClient';
import EditBenchmarkContext from '../../context/editBenchmarkContext';
import { LabelGrid, ValueGrid } from '../manualSettings';

export default function TrackingCyclesManual({formBenchmarkData, changeFormBenchmarkData}) {
    // Local state
    const [trackingCycles, setTrackingCycles] = useState([]);
    
    const { periodData } = useContext(EditBenchmarkContext)

    // Query backend to calculate tracking cycles 
    useEffect(() => {
        if (formBenchmarkData.tracking_freq === '') return;
        
        const targetURL = '/tracking-periods/';
        console.log(targetURL);
        /*
        let data = {
            'benchmark_freq': formBenchmarkData.benchmark_freq,
            'benchmark_start_period': formBenchmarkData.benchmark_start_period,
            'tracking_freq': formBenchmarkData.tracking_freq,
            'periods': periodData.periods_list.map((period) => { return period.period; }),
            'include_next_baseline': formBenchmarkData.include_next_baseline,
        };
        */
        let data = {
            'benchmark_freq': formBenchmarkData.benchmark_freq,
            'benchmark_start_period': periodData.periods_list.filter((period) => {
                return period.period_id === formBenchmarkData.benchmark_start_period;
            })?.[0]?.date_string,
            'tracking_freq': formBenchmarkData.tracking_freq,
            'period_list': periodData.periods_list.map((period) => { return period.date_string; }),
            'include_next_baseline': formBenchmarkData.include_next_baseline,
        };
        console.log('Tracking Post data', data);
        
        post_json(targetURL, data)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status + ' : ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log('tracking cycles: ', data.dict[1]);
                setTrackingCycles(data.dict[1]);
            })
            .catch(error => {
                console.log(error);
            });
            
    }, [
        formBenchmarkData.benchmark_freq, formBenchmarkData.benchmark_start_period,
        formBenchmarkData.tracking_freq, formBenchmarkData.include_next_baseline, periodData.periods_list]);

    //{ const firstCycle = trackingCycles.filter((cycle) => { return cycle.cycle_id === 1; }); }
  return (
    <Grid container spacing={1} marginY={2} fontSize={12}>
        <LabelGrid> Tracking Frequency: </LabelGrid>
        <ValueGrid>                            
            {/*Disable if Dataset doesn't have periods*/}
            <RadioGroup
                sx={{display: 'flex', flexFlow: 'row nowrap'}}    
                value={(formBenchmarkData.tracking_freq)}
                onChange={(event) => changeFormBenchmarkData({tracking_freq: event.target.value})}
            >
                {/*Disable if baseline periods are Quarterly or monthly*/}
                <FormControlLabel
                    value='yearly'
                    control={<Radio />}
                    disabled={['quarterly','monthly'].includes(formBenchmarkData.benchmark_freq) 
                    || !periodData.has_periods
                    || formBenchmarkData.benchmark_freq===''
                    || formBenchmarkData.benchmark_start_period===''
                    || formBenchmarkData.benchmark_start_period===null
                }
                    label='Yearly'
                />
                
                {/*Disable if baseline periods are monthly*/}
                {/*Disable if Dataset has yearly periods*/}
                <FormControlLabel value='quarterly' control={<Radio />}
                    disabled={
                        ['monthly'].includes(formBenchmarkData.benchmark_freq) 
                        || periodData.periodicity === 'yearly' 
                        || !periodData.has_periods
                        || formBenchmarkData.benchmark_freq===''
                        || formBenchmarkData.benchmark_start_period===''
                        || formBenchmarkData.benchmark_start_period===null
                    }
                    label='quarterly'
                />
                {/*Disable if Dataset has anything other than Monthly periods*/}
                <FormControlLabel
                    disabled={
                        periodData.periodicity !== 'monthly' ||
                        !periodData.has_periods}
                    value='monthly'
                    control={<Radio />}
                    label='Monthly'
                />
            </RadioGroup>
            <Grid container spacing={2}>
                {/* Tracking Cycles Table*/}
                <Grid item xs={5}>
                    {(formBenchmarkData.benchmark_freq===''||formBenchmarkData.benchmark_start_period==='')&& (
                        < Box color='red'>
                                Baseline frequency information is missing. <br/>
                                Please go back to the previous step and fill in the required information.
                        </Box>
                    )}
                    {trackingCycles.length > 0 && (
                        
                    <Box >
                        <TableContainer sx={{minWidth: 300, maxWidth:400}}>
                            <div style={{textAlign:'center'}}>
                                Period setup for the First Cycle
                            </div>
                            <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Baseline Period</TableCell>
                                <TableCell>Tracking Period</TableCell>
                            </TableRow>
                        </TableHead>
                                <TableBody>
                                    {console.log()}
                                    {trackingCycles
                                        .filter((cycle) => { return cycle.cycle_id === 1; })
                                        .map((row) => (
                                        <TableRow key={"trackingPeriod"+row.id}>
                                            <TableCell>{row.baseline_period}</TableCell>
                                            <TableCell>{row.tracking_period}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    )}
                </Grid>
            </Grid>
        </ValueGrid>
        <LabelGrid>Include next baseline as last period in cycle?</LabelGrid>
        <ValueGrid>
            <FormControl>
                <FormControlLabel
                    //value={formBenchmarkData.apply_restricted}
                    //label='Include next baseline period'
                        //   labelPlacement='right'
                        disabled={formBenchmarkData.tracking_freq === formBenchmarkData.benchmark_freq}
                    control={
                        <Switch
                            checked={formBenchmarkData.include_next_baseline}
                            onChange={(event) => {
                                changeFormBenchmarkData({include_next_baseline: event.target.checked});
                            }}
                        />}
                />
            </FormControl>
        </ValueGrid>                
    </Grid>
  )
}
