import React from 'react'
import { Box, Typography } from '@mui/material'
import { EditModalCards } from '../editModalContent'

export default function BenchmarkSetupInfo({ summaryData }) {

    //GROUP INPUT AND OUTPUT VARIABLES IN ARRAYS
    const inputVars = summaryData?.benchmarkvars?.filter(v => v.variable_type === 'input')
    const outputVars = summaryData?.benchmarkvars?.filter(v => v.variable_type === 'output')

    return (
    <EditModalCards>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="h6" color="initial">
                Type:
            </Typography>
            <Typography variant="body2" color="initial">
                {summaryData?.orient.replace(/_/g, " ")}
            </Typography>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
        <Typography variant="h6" color="initial">
            Variables
        </Typography>
        <Box sx={{display: 'flex', flexFlow: 'row', gap: '15px'}}>
            <Typography variant="body2" color="initial">
                Inputs: {inputVars?.length}
            </Typography>
            <Typography variant="body2" color="initial">
                Outputs: {outputVars?.length}
            </Typography>
        </Box>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="h6" color="initial">
                Scale:
            </Typography>
            <Typography variant="body2" color="initial">
                {summaryData?.scale_type}
            </Typography>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="h6" color="initial">
                Target Value Type:
            </Typography>
            <Typography variant="body2" color="initial">
                {summaryData?.target_values_type}
            </Typography>
        </Box>
    </EditModalCards>
  )
}
