import { Typography } from '@mui/material'
import { CardUpperContainer } from './commonContainer';
import SearchBar from './searchBar';



export function UpperBarComponent(props) {
    const { text, search, button, value, onChange} = props
  return (
  <div style={{display: 'flex', flexDirection: 'column', marginBottom: '12px'}}>
    <CardUpperContainer sx={{marginBottom: 0}} >
        <Typography variant="subtitle1">
            {text}
        </Typography>
        {search ? <SearchBar showItem={{big: 'flex', small: 'none'}} value={value} onChange={onChange} /> : null}
        {button}
    </CardUpperContainer>
    {search ? <SearchBar showItem={{big: 'none', small: 'flex'}} value={value} onChange={onChange} /> : null}
  </div>
  )
}

