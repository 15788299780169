import React from 'react'
import { GraphContainer, GraphMainCard, MainCard } from '../../../../components/common/commonContainer'
import { Box, CircularProgress, Divider, Tabs, Typography } from '@mui/material'
import { IndividualEfficiencyScores } from '../../../../graphs/individualResults/efficiencyScores'
import IndividualEfficiencyByPeriod from '../../../../graphs/individualResults/efficiencyByPeriod'
import { SmallRoundTab } from '../../../../components/common/tabsDesign'
import IndividualEfficiencyImprovement from '../../../../graphs/individualResults/efficiencyImprovement'
import { useState, useEffect } from 'react'
import { getBoxplotData, getDmuData, getOverallData, getSegmentData } from './services/efficiencyTimeSeriesAndDistributionServices'

export default function EfficiencyTimeSeriesAndDistribution({benchmark_id, periodObject, apply_segmentation, entityField, periodField, orientValue}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);

  //EFFICIENCY SCORES DATA
  const [boxplotData, setBoxplotData] = useState([])
  //EFFICIENCY BY PERIOD DATA
  const [dmu, setDmu] = useState([])
  // const [segmentValidation, setSegmentValidation] = useState([])
  const [segment, setSegment] = useState([])
  const [overall, setOverall] = useState([])

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
  //BOXPLOT AND DMU DATA FETCH
    Promise.all([
      getBoxplotData({ benchmark_id, periodObject, orientValue }),
      getDmuData({ benchmark_id, entityField, orientValue }),
      getOverallData({ benchmark_id, orientValue })
    ])
      .then(([boxplotData, dmuData, overallData]) => {
        setBoxplotData(boxplotData);
        setDmu(dmuData);
        setOverall(overallData);
        // setSegmentValidation(boxplotData.results.filter(v => v.dmu_id === entityField.value))
        const segmentValidation = boxplotData.results.filter(v => v.dmu_id === entityField.value)
        if(segmentValidation?.[0]?.cluster_cd || segmentValidation?.[0]?.cluster_cd === 0){
          //SEGMENT DATA FETCH
          return getSegmentData({benchmark_id, dmuData, orientValue})
        } else {
          return;
        }
      })
      .then((segmentData) => {
        setSegment(segmentData)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error)
      })
      .finally(() => {
        setLoading(false);
      });
  }, [benchmark_id, entityField, periodObject, orientValue]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <MainCard >
      {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Box>
    ) : error ? ( // Render error component when error exists
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
        Error: {error.message}
    </Box>
    ) : (
      <>
        <Typography variant="h5" sx={{padding: '10px', pl: '36px'}}>{ apply_segmentation ? 'Efficiency Time Series and Distribution' : 'Efficiency Distribution'}</Typography>
        <Divider variant="fullWidth" sx={{width: '-webkit-fill-available'}}/>
        <Box sx={{display: 'flex', justifyContent: 'space-around', flexFlow: 'row wrap', padding: '20px', width: '-webkit-fill-available'}}>
          <Box sx={{display: 'flex', flexFlow: 'row wrap', width: '100%'}}>
            <GraphContainer sx={{ width: '40%'}}>
              <IndividualEfficiencyScores 
                api={boxplotData} 
                entity={entityField.value} 
                periodObject={periodObject} 
              />
            </GraphContainer>
            {
              periodField.options.length <= 1 ?
              null
              :
              <GraphContainer sx={{ width: '50%'}}>
                  <IndividualEfficiencyByPeriod 
                      dmu={dmu} 
                      segment={segment} 
                      overall={overall} 
                  />
              </GraphContainer>
            }
          </Box>
          {
            periodField.options.length <= 1 ?
            null
            :
            <GraphContainer>
                <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps= {{sx: {display: 'none'}}}
                sx={{padding: '20px', alignSelf: 'flex-end'}}
                >
                  <SmallRoundTab label="Period / Period" />
                  <SmallRoundTab label="Period / Baseline" />
                </Tabs>
                <GraphMainCard sx={{justifyContent: 'space-evenly', width: '100%'}} >
                    {selectedTab === 0 && (
                      <IndividualEfficiencyImprovement 
                          dmu={dmu} 
                          segment={segment} 
                          overall={overall}  
                          value={0}
                      />  
                    )}
                    {selectedTab === 1 && (
                      <IndividualEfficiencyImprovement 
                          dmu={dmu} 
                          segment={segment} 
                          overall={overall}  
                          value={1}
                      />
                    )}    
                </GraphMainCard>
            </GraphContainer>
          }
        </Box>
      </>
    )}
    </MainCard>
  )
};