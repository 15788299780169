import { Button, DialogActions } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import DeleteBenchmark from '../deleteBenchmark';
import ManualSettings from '../manualSettings';
import { useContext } from 'react';
import EditBenchmarkContext from '../../context/editBenchmarkContext';
import { patch_json } from '../../../../../../utils/httpClient';
import { enqueueSnackbar } from 'notistack';

export default function EditCompleted({ handleClose, scenarioData = null, setDeletedID}) {
    //CONTEXT PROVIDER 
    const { summaryData } = useContext(EditBenchmarkContext)
    
    //COMPONENT STATUS
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    /* HANDLES FOR DELETE OPTION */
    const handleDelete = () => {
    console.log('delete: ', scenarioData?.benchmark_id);
    setOpenDelete(true);

    }
    const handleCloseDelete = () => {
    setOpenDelete(false);
    }

    /* HANDLES FOR EDIT OPTION */
    const handleEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingPublish, setLoadingPublish] = useState(false);

        /*Handle Publish and SAVE button spinners*/
        function handleButtonSpinners(action, enable) {
            if (action==='edit' && enable) {
                setLoadingSave(true);        
            } else if (action==='edit' && !enable) {
                setLoadingSave(false);
            } else if (action==='publish' && enable) {
                setLoadingPublish(true);
            } else if (action==='publish' && !enable) {
                setLoadingPublish(false);
            }
            
        }

    /*Handle create benchmark scenario */
    const handleAction = async (action, editObject) => {
        //TO DO: move this function to a separate file so it can be imported and reused (Look at publishActionPatch.js for example)
        if (!loadingSave || !loadingPublish) {
            handleButtonSpinners(action, true);
        }
        //Make copy of summaryData
        let data = JSON.parse(JSON.stringify(summaryData));
        data.action = action; // 'save' or 'publish'            
        console.log('action', action)
        console.log('Final Benchmark data', data);
        console.log('Final Benchmark Summary Data', summaryData);

        let publish = {}
        let edit = {}

        if( action === 'publish') {
            publish = {
                action: action,
                benchmark_id: summaryData.benchmark_id,
                status: 'published'
            }
        } else if (action === 'edit') {
            editObject.action = action
            editObject.benchmark_id = summaryData.benchmark_id
            
            edit = editObject
        }

        //Target URL for posting form
        const targetURL = '/project/benchmark/';
        patch_json(targetURL, action === 'publish' ? publish : edit)
        .then((response) => {
            console.log('response', response);
            if (!response.ok) {
                throw new Error(response.status + response.statusText);
            }
            return response.json();
        })
        .then((data) => {
            if(action === 'publish') {
                console.log('Benchmark Scenario created successfully');
                console.log(data);
                enqueueSnackbar('Successfully published scenario: ' + summaryData.benchmark_name.trim(), { variant: 'success' });
            } else if(action === 'edit') {
                console.log('Your changes have been successfully saved');
                console.log(data);
                enqueueSnackbar('Your changes have been successfully saved for: ' + summaryData.benchmark_name.trim(), { variant: 'info' });

            }
            handleButtonSpinners(action, false);
            // handleCloseDialog();
            if(action === 'publish') {
                handleClose()
                setTimeout(() => {
                    window.location.reload();
                  }, 2000); // 2000 milliseconds = 2 seconds
            }
            return;
        })
        .catch((error)=>{
            console.log('Benchmark Scenario action failed');
            console.log(error)
            // handleButtonSpinners(action, false);
            //handleCloseDialog();
            return 
        })
    };
  return (
    <>
        <DialogActions>
            {/* <Button color='primary' variant='text' autoFocus onClick={handleClose} >
                Cancel
            </Button> */}
            <Button onClick={handleDelete} color='error' variant='text' autoFocus>Delete</Button>
            <Button onClick={handleEdit} color='primary' variant='text' autoFocus>
                Edit
            </Button>
            {/* <Button onClick={() => { handlePublishAction('publish', summaryData) }} sx={{backgroundColor: '#1AB759'}} variant='contained' autoFocus>Publish</Button> */}
        </DialogActions>
        {openDelete && <DeleteBenchmark
            open={openDelete}
            onClose={handleCloseDelete}
            scenario_id={scenarioData?.benchmark_id}
            setDeletedID={setDeletedID}
            loadingSave={loadingSave}
            loadingPublish={loadingPublish}
            />
        }

        {<ManualSettings
            open={openEdit}
            onClose={handleCloseEdit}
            handleClose={handleClose}
            handleButtonSpinners={handleButtonSpinners}
            handleAction={handleAction}
        />}
    </>
  )
}
