import React from 'react';
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'

ChartJS.register(
  PointElement,
  LineElement,
);

export default function IndividualEfficiencyImprovement({value, dmu, segment, overall}) {

  overall?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  dmu?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  segment?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: `Efficiency Improvement (p/${value === 0 ? 'p' : 'b'})`,
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Period',
          color: '#4c4c4d',
          font: {
            family: "Arial",
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0},
        }
      },
      y: {
        grace: "20%",
        display: true,
        title: {
          display: true,
          align: 'end',
          text: 'Efficiency Improvement % (p/p)',
          color: '#4c4c4d',
          font: {
            family: "Arial",
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      }
    }
  };
  
  const labels = overall?.map(segment => segment.period);
  
  const data = {
    labels,
    datasets: [
      {
        label: dmu[0]?.dmu_id,
        data: value === 0 ? dmu?.map(segment => (segment.pct_improv_prev_period * 100).toFixed(2)) : dmu?.map(segment => (segment.pct_improv_benchmark_period * 100).toFixed(2)),
        borderColor: colors.entity1,
        backgroundColor: colors.entity1,
        yAxisID: 'y',
        datalabels: {
          display: true,
          color: "white",
          align: 'center',
          borderRadius: 50,
          backgroundColor: colors.entity1,
          labels: {
            title: {
              font: {
                size: 10
              }
            },
          },
          formatter: (value) => {
            return `${value}%`
          }
        },
  
      },
      segment ? {
          label: 'Segment',
          data: value === 0 ? segment?.map(segment => (segment.pct_improv_prev_period * 100).toFixed(2)) : overall?.map(segment => (segment.pct_improv_benchmark_period * 100).toFixed(2)),
          borderColor: colors.entity2,
          backgroundColor: colors.entity2,
          yAxisID: 'y',
      } : null,
      {
          label: 'Overall',
          data: value === 0 ? overall?.map(segment => (segment.pct_improv_prev_period * 100).toFixed(2)) : segment?.map(segment => (segment.pct_improv_benchmark_period * 100).toFixed(2)),
          borderColor: colors.entity3,
          backgroundColor: colors.entity3,
          yAxisID: 'y',
      },
    ].filter(Boolean),
  };

  return <div style={{ width: '100%', height: '300px' }}>
      <Line options={options} data={data} />
    </div>
}
