import React, { useEffect, useState, useContext } from 'react';
import {
	FormLabel,
	FormControl,
    Autocomplete,
	Box,
    Grid,
    TextField
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';

export default function VariablesWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData, variableList, numVarList } = useContext(CreateBenchmarkContext);
    const [ inputOptions, setInputOptions ] = useState(numVarList);
    const [ outputOptions, setOutputOptions ] = useState(numVarList);

    function filterVars(variables, filterList) {
        if (filterList.length > 0) {
        let idList = filterList.map((variable) => { return variable.id });
            return variables.filter((variable) => !idList.includes(variable.id));
        } else {
            return variables;
        }
        
    }

    useEffect(() => {
        // console.log('Reset options when variable list changes');
        setInputOptions(numVarList);
        // console.log('Input Options: ', numVarList)
        setOutputOptions(numVarList)
        // console.log('Output Options: ', numVarList)

    }, [numVarList]);

    useEffect(() => {
        // console.log('re-calculate inputOptions');
        let options=filterVars(numVarList, formData.output_vars)
        setInputOptions(options);
        // console.log('Input Options: ', options);
    }, [formData.output_vars, numVarList]);
    
    useEffect(() => {
        // console.log('re-Calculate outputOptions');
        let options = filterVars(numVarList, formData.input_vars)
        setOutputOptions(options);
        // console.log('Output options:', options);
    }, [formData.input_vars, numVarList]);
    

    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Select Input & Output metrics</b></div>
            <br />
            <br />
            <div>Variables can be used as an Input or an Output but not both</div>
            
                <br/>
            <br />
            <Grid container spacing={2} justifyContent="space-around">
                <Grid item xs={9} md={6} lg={6}>
                    <Box >
                        <FormControl fullWidth>
                            <FormLabel htmlFor='input-vars' >Input Metrics </FormLabel>
                                    <Autocomplete
                                        multiple
                                        id='input-vars'
                                        size='small'
                                        //sx = {{width: '450px'}}
                                        required
                                        selectOnFocus
                                        clearOnBlur
                                        clearOnEscape
                                        autoComplete
                                autoHighlight
                                fullWidth
                                        options={inputOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={formData.input_vars}
                                        onChange={(event, newValue) => {
                                            changeFormData('input_vars', newValue);
                                    }}
                                        renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                            </FormControl>
                                    </Box>
                </Grid>
                <Grid item xs={9} md={6} lg={6} >
                    <Box >
                        <FormControl fullWidth>
                            <FormLabel htmlFor='output-vars' >Output Metrics </FormLabel>
                                    <Autocomplete
                                        multiple
                                        id='output-vars'
                                        size='small'
                                        //sx = {{width: '450px'}}
                                        required
                                        selectOnFocus
                                        clearOnBlur
                                        clearOnEscape
                                        autoComplete
                                autoHighlight
                                fullWidth
                                        options={outputOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={formData.output_vars}
                                        onChange={(event, newValue) => {
                                            changeFormData('output_vars', newValue);
                                    }}
                                        renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                            </FormControl>
                        </Box>
                </Grid>
            </Grid>
            </ TabPanel>
            
                                    
                            
        </>
    )
}