import React from 'react'
import { GraphContainer } from '../../../../components/common/commonContainer'
import EfficiencyQuadrantsGraph from '../../../../graphs/aggregatedResults/efficiencyQuadrants'

export default function EfficiencyQuadrants({benchmark_id, periodObject, baseSegment, orientVaue}) {
  return (
      <GraphContainer>
        <EfficiencyQuadrantsGraph benchmark_id={benchmark_id} periodObject={periodObject} baseSegment={baseSegment} orientVaue={orientVaue} />
      </GraphContainer>
  )
}
