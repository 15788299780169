import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getInputData, getOutputData, getTotalMetricsData } from './services/totalMetricsServices';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { MainCard, ShadowCardContentContainer } from '../../../../components/common/commonContainer';
import InputTargetGraphs from './totalMetrics/inputTargetGraphs';
import OutputTargetGraphs from './totalMetrics/outputTargetGraphs';
import TotalMetricsTable from './totalMetrics/totalMetricsTable';

export default function TotalMetrics({ benchmark_id, periodObject, periodOptions, orientValue }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);

  const [inputData, setInputData] = useState([])
  const [outputData, setOutputData] = useState([])
  const [totalMetricsData, setTotalMetricsData] = useState([])


  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //INPUT AND OUTPUT DATA FETCH
    Promise.all([
        getInputData({ benchmark_id, periodObject, orientValue }),
        getOutputData({ benchmark_id, periodObject, orientValue }),
        getTotalMetricsData({ benchmark_id, periodObject, orientValue })
    ])
    .then(([inputData, outputdata, totalMetricData]) => {
        setInputData(inputData)
        setOutputData(outputdata)
        setTotalMetricsData(totalMetricData)
    })
    .catch(error => {
        console.error('Error fetching data:', error);
        setError(error)
    })
    .finally(() => {
        setLoading(false);
    });
  }, [benchmark_id, periodObject, orientValue]);

  return (
    <MainCard>
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Box>
    ) : 
    error ? ( // Render error component when error exists
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
          Error: {error.message}
      </Box>
    ) :
    (
      <>
        <Typography variant="h5" sx={{padding: '10px', pl: '36px'}}>
          Input / Output Targets
        </Typography>
        <Divider variant="fullWidth" sx={{width: '-webkit-fill-available'}}/>
        <ShadowCardContentContainer sx={{ml: '36px', mr:'36px'}}>
            <Typography variant="h6" color="initial" sx={{width: '100%', display: 'flex', justifyContent: 'center', pt: 2}}>
              Input Targets
            </Typography>
            <InputTargetGraphs 
              api={inputData} 
              periodOptions={periodOptions}
            />
        </ShadowCardContentContainer>
        <ShadowCardContentContainer sx={{ml: '36px', mr:'36px'}} >
            <Typography variant="h6" color="initial" sx={{width: '100%', display: 'flex', justifyContent: 'center', pt: 2}}>
              Output Targets
            </Typography>
            <OutputTargetGraphs 
              api={outputData} 
              periodOptions={periodOptions}
            />
        </ShadowCardContentContainer>
        <TotalMetricsTable 
          api={totalMetricsData} 
          periodOptions={periodOptions} 
        />
      </>
      )}
    </MainCard>
  )
}
