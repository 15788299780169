import {
    Chart as ChartJS,
    PointElement,
    LineElement,
  } from 'chart.js';


ChartJS.register(
    PointElement,
    LineElement,
  );
  
  export const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Avg Efficiency by period',
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
  
      },
      legend: {display: false}
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'period',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      },
      y: {    
        yAxes: [{
          stacked: true, // this will stack the bars on top of each other
          gridLines: {
            display: true, // show the vertical grid lines
            color: '#eee', // set the color of the grid lines
          },
        }],
  
        display: true,
        beginAtZero: false,
        grace: "10%",
        title: {
          display: true,
          align:'end',
          text: 'Avg. Efficiency Score',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      }
    }
  };