import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteOutline, EditOutlined, FileCopyOutlined, UploadRounded } from '@mui/icons-material';
import { ReactComponent as ViewIcon } from '../../../../assets/icons/tableKebab/viewLogo.svg';
import { IconButton } from '@mui/material';
import { StyledKebabMenu } from '../../../../components/common/styledKebabMenu';
import DeleteBenchmark from './menuOptions/deleteBenchmark';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import EditBenchmark from './menuOptions/editBenchmark';
import BenchmarkConfiguration from '../../../benchmarkScenarios/benchmarkConfiguration';
import { useEffect } from 'react';
import { getSummaryData } from './menuOptions/services/editBenchmarkServices';
import { handlePublishAction } from './menuOptions/services/publishActionPatch';

export default function BenchmarkKebabMenu({project_id, scenario_id, setDeletedID, status, toEndpoint}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [summaryData, setSummaryData] = useState({'benchmark_name': '','benchmark_id': '',});

  const open = Boolean(anchorEl);
  
  /* SCENARIO MENU HANDLERS */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* VIEW OPTION HANDLERS */
  const handleOpenView = () => {
    setOpenView(true)
  }

  const handleCloseView = () => {
    setOpenView(false)
  }

  /* DELETE OPTION HANDLERS */
  const handleDelete = () => {
    console.log('delete: ', scenario_id);
    setOpenDelete(true);
    handleClose();

  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  }

  /* EDIT OPTION HANDLERS */

  const handleEdit = () => {
    setOpenEdit(true)
    handleClose();
  }
  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

    //FETCH FUNCTION
    useEffect(() => {
      setLoading(true);
      //SCENARIO AND DMU DATA FETCH
      Promise.all([
          getSummaryData({ project_id, scenario_id }),
      ])
      .then(([summaryData]) => {
          setSummaryData(summaryData);

      })
      .catch(error => {
          console.error('Error fetching data:', error);
          setError(error)
      })
      .finally(() => {
          setLoading(false);
      });
  }, [project_id, scenario_id]);

  return (
    <>
      {/* -----KEBAB MENU----- */ }
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledKebabMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
          <MenuItem 
            component={Link} 
            onClick={status === 'processing' || status === 'saved' ? handleOpenView : handleClose} 
            to={status === 'processing' || status === 'saved' ? toEndpoint : "/dashboard?project_id=" + project_id + "&benchmark_id=" + scenario_id} 
            disableRipple 
            disabled={ status === 'new' ? true : false}
          >
            <ViewIcon style={{marginRight: 12}} />
            View
          </MenuItem>
        <MenuItem onClick={handleEdit} disableRipple
          disabled={ status === 'new' ? true : false}
        >
          <EditOutlined sx={{fill: 'rgba(146, 147, 147, 1)'}} />
          Edit
        </MenuItem>
        {
          status === 'completed' ?
          <MenuItem onClick={() => { handlePublishAction('publish', summaryData) }} disableRipple
            disabled={ status === 'new' ? true : false}
          >
            <UploadRounded sx={{fill: 'rgba(146, 147, 147, 1)'}} />
            Publish
          </MenuItem>
          :
          null
        }
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
        <FileCopyOutlined sx={{fill: 'rgba(146, 147, 147, 1)'}} />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleDelete} disableRipple>
          <DeleteOutline sx={{fill: 'rgba(146, 147, 147, 1)'}} />
          Delete
        </MenuItem>
      </StyledKebabMenu>
      </div>
      
      {/* -------- MENU ITEM COMPONENTS -------- */}
      {/* Specific actions for each menu item are being handled inside the menu item component  */}
      {openDelete && <DeleteBenchmark
        open={openDelete}
        onClose={handleCloseDelete}
        scenario_id={scenario_id}
        setDeletedID={setDeletedID}
        />
      }
      { <EditBenchmark
          open={openEdit}
          onClose={handleCloseEdit}
          scenario_id={scenario_id}
          project_id={project_id}

          setDeletedID={setDeletedID}
        />
      }
      {
        (status === 'processing' || status === 'saved') && 
        <BenchmarkConfiguration
          open={openView}
          onClose={handleCloseView}

          project_id={project_id}
          scenario_id={scenario_id}
        />
      }
      
  </>

    
  );
}