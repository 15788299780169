import { Box, Card, CardContent, CardHeader, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { getSummaryData } from './services/benchmarkConfigurationServices';
import { useState } from 'react';
import { CloseRounded } from '@mui/icons-material';
import { getDatasetDetails, getDatasetInfo, getDatasetPeriodData, getProjectData, getScenarioData } from '../home/projectDetails/benchmarkScenarios/menuOptions/services/editBenchmarkServices';
import { getVariablesList } from '../home/projectDetails/benchmarkScenarios/menuOptions/benchmarkWizardSteps/datasetAuxFunctions';
import { getSegmentationData } from '../home/projectDetails/benchmarkScenarios/menuOptions/benchmarkManualConfig/services/SegmentationModelManualServices';
import { getDatasetData } from './services/benchmarkScenarioHomeServices';

export default function BenchmarkConfiguration({project_id, scenario_id, open, onClose}) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [periodData, setPeriodData] = useState({
        'has_periods': false,
        'period_type': '',
        'periods_list': []
    });
    const [scenario, setScenario] = useState({'benchmark_name': '','benchmark_id': '',});
    const [summaryData, setSummaryData] = useState([]);
    const [projectData, setProjectData] = useState([])
    const [datasetDetails, setDatasetDetails] = useState({});
    const [datasetInfo, setDatasetInfo] = useState({});
    const [variableList, setVariableList] = useState([]);
    const [segmentationData, setSegmentationData] = useState(null);

    const [datasetData, setDatasetData] = useState([])

     // Context state
    //  const { editSaved } = useContext(BenchmarkScenariosContext)
 
    //FETCH FUNCTION
    useEffect(() => {
        setLoading(true);
        let newState = {};
        //SCENARIO AND DMU DATA FETCH
        Promise.all([
            getScenarioData({ project_id, scenario_id }),
            getSummaryData({ project_id, scenario_id }),
            getProjectData({ project_id })
        ])
        .then(([scenarioData, summary, projectData]) => {
            setScenario(scenarioData);
            setSummaryData(summary);
            setProjectData(projectData);

            return  Promise.all([
                getDatasetDetails({ projectData }),
                getDatasetInfo({ projectData }),
                getDatasetData({ projectData }),
                summaryData.segmentation_scenario_id ? getSegmentationData({ formBenchmarkData: summaryData }) : null
            ])
        })
        .then(([datasetDetails, datasetInfo, datasetData, segmentation]) => {
            setDatasetDetails(datasetDetails);
            // Identify periods type (monthly, quarterly, yearly)
            let periodField = datasetDetails.datasetvariable.filter((variable) => {
                return (variable.original_field_name === datasetDetails.period_field);
            })[0];
            if (!periodField || !periodField.field_sub_type) return;
            let periodicity = periodField.field_sub_type.toLowerCase();

            setDatasetInfo(datasetInfo)
            newState.variables = datasetData;
            setDatasetData(newState)

            setSegmentationData(segmentation);

            return getDatasetPeriodData({ datasetDetails, periodicity })
        })
        .then((datasetPeriodData) => {
            setPeriodData(datasetPeriodData);
            if(datasetData?.variables?.length > 0) {
                setVariableList(getVariablesList(datasetData, 'all'))
            }

        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setError(error)
        })
        .finally(() => {
            setLoading(false);
        });
    }, [project_id, scenario_id, summaryData?.segmentation_scenario_id, datasetData?.variables?.length]);

    const input_vars = summaryData?.benchmarkvars?.filter( v => v.variable_type === 'input').map( v => ({id: v.variable_detail, label: v.variable_name}))
    const output_vars = summaryData?.benchmarkvars?.filter( v => v.variable_type === 'output').map( v => ({id: v.variable_detail, label: v.variable_name}))
    const restricted_vars = summaryData?.benchmarkvars?.filter( v => v.restricted_var === true).map( v => ({id: v.variable_detail, label: v.variable_name}))
    const undesirable_vars = summaryData?.benchmarkvars?.filter( v => v.undesirable_metrics === true).map( v => ({id: v.variable_detail, label: v.variable_name}))

  return (
    <Dialog 
    open={open} 
    onClose={onClose}
    maxWidth='lg'
    fullWidth={true}
>
    <DialogTitle
        sx={{ display: 'flex', background: '#47254b', color: 'white', justifyContent: 'space-between', alignItems: 'center', p:"8px 24px"}}
    >
        Scenario Details
        <IconButton onClick={onClose} sx={{color: 'white'}}>
            <CloseRounded color='white'/>
        </IconButton>
    </DialogTitle>
    {
        loading ? 
        <DialogContent sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
        }}>
            <CircularProgress/>
        </DialogContent>
        :
        error ?
        <DialogContent sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
        }}>
            Error: {error.message}
        </DialogContent>
        :
        <DialogContent>
            
            <Box sx={{pt:'10px'}}><b>Summary</b></Box>
            <Divider />
            <Grid container spacing={1} marginY={2} fontSize={12}>
                <LabelGrid> Project Name: </LabelGrid>
                <ValueGrid>{projectData.project_name}</ValueGrid>
                <LabelGrid>Scenario Name: </LabelGrid>
                <ValueGrid value={summaryData?.benchmark_name?.trim()} required={true}/>
                <LabelGrid> Description: </LabelGrid>
                <ValueGrid>{summaryData.benchmark_desc}</ValueGrid>
                <LabelGrid> Dataset: </LabelGrid>
                <ValueGrid>{datasetInfo.dataset_name}</ValueGrid>

            </Grid>
            <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Benchmark Setup</b> </Box>
            <Divider />
            <Grid container spacing={1} marginY={2} fontSize={12}>
                <LabelGrid> Purpose: </LabelGrid>
                <ValueGrid>{summaryData.purpose}</ValueGrid>
                <LabelGrid> Objective: </LabelGrid>
                <ValueGrid>{summaryData.orient}</ValueGrid>
                <LabelGrid> Scale:</LabelGrid>
                <ValueGrid>{summaryData.scale_type}</ValueGrid>
                <LabelGrid> Target Values Type: </LabelGrid>
                <ValueGrid>{summaryData.target_values_type}</ValueGrid>
                <LabelGrid>Input Variables:</LabelGrid>
                <ValueGrid
                    value={input_vars}
                    // required={
                    //     formData.objective === 'input' ||
                    //     formData.objective === 'input_output_independent' ||
                    //     formData.objective === 'input_output_together'}
                    type='list'>
                    <ChipsList list={input_vars} />
                </ValueGrid>
                <LabelGrid>Output Variables:</LabelGrid>
                <ValueGrid
                    value={output_vars}
                    // required={
                    //     formData.objective === 'output' ||
                    //     formData.objective === 'input_output_independent' ||
                    //     formData.objective === 'input_output_together'}
                    type='list'>
                    <ChipsList list={output_vars} />
                </ValueGrid>
            </Grid>
            <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Variable treatments</b> </Box>
            <Divider />
            <Grid container spacing={1} marginY={2} fontSize={12}>
                <LabelGrid> Restricted Variables:</LabelGrid>
                <ValueGrid> {summaryData.apply_restricted ?
                    <ChipsList list={restricted_vars} />
                    : 'Not applied'}
                </ValueGrid>
                <LabelGrid> Undesirable Effects Variables:</LabelGrid>
                <ValueGrid> {summaryData.apply_undesirable ?
                    <ChipsList list={undesirable_vars} />
                    : 'Not applied'}
                </ValueGrid>
            </Grid>

            <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Period Information</b></Box>
            <Divider />
            <Grid container spacing={1} marginY={2} fontSize={12}>
                <LabelGrid> Single or Multiple periods: </LabelGrid>
                {summaryData?.benchmarkperiod?.length === 1
                    ? <ValueGrid value="Single" />
                    : <ValueGrid value="Multiple" />
                }
                {(summaryData?.benchmarkperiod?.length === 1)?(
                    <>
                        {/* {console.log('Got to single period')} */}
                        <LabelGrid> Period: </LabelGrid>
                        <ValueGrid
                            value={summaryData?.benchmarkperiod?.[0]?.date_string}
                        />
                    </>
                ) : (null)}
                {(summaryData?.benchmarkperiod?.length > 1) ? (
                    <>
                        {/* {console.log('Got to  All period')} */}
                        <LabelGrid> Apply Tracking Cycles: </LabelGrid>
                        <ValueGrid>{summaryData.apply_tracking_cycles?'Yes':'No'}</ValueGrid>
                    </>
                ) : (null)}
                {summaryData.apply_tracking_cycles ? (
                    <>
                        {/* {console.log('Got to tracking cycles')} */}
                        <LabelGrid> Baseline Frequency </LabelGrid>
                        <ValueGrid
                            value={summaryData.benchmark_freq}
                            required={summaryData.apply_tracking_cycles}
                        />
                        <LabelGrid> Tracking Frequency: </LabelGrid>
                        <ValueGrid
                            value={summaryData.tracking_freq}
                            required={summaryData.apply_tracking_cycles}
                        />
                        <LabelGrid> First Baseline Period: </LabelGrid>
                        <ValueGrid
                            value={periodData.periods_list.filter((period) => {
                                return period.period_id === summaryData.benchmark_start_period
                            })[0]?.date_string}
                            required={summaryData.apply_tracking_cycles}
                        />
                        <LabelGrid> Include Next Baseline: </LabelGrid>
                        {/* <ValueGrid>{formData.include_next_baseline?'Yes':'No'}</ValueGrid> */}
                    </>
                ) : (null)}


            </Grid>
            
            <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Segmentation</b></Box>
            <Divider/>
            <Grid container spacing={1} marginY={2} fontSize={12}>
                <LabelGrid> Segmentation Scenario: </LabelGrid>
                <ValueGrid
                    value={!summaryData.apply_segmentation ? 'Not applied': null}/>

                {summaryData.apply_segmentation && segmentationData && (
                    <Grid>
                        <Card sx={{ mt: 2 }}>
                            <CardHeader size='small' disableTypography sx={{ fontSize: 14, m: 0, p: 1 }} title={<b>{'Scenario: ' + summaryData.segmentation_name} </b>}/>
                            <CardContent sx={{ fontSize: 12, px: 1, pt: 0, pb: 1 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={5} md={4} lg={3}>
                                                <Box>Segmentation Method:</Box>
                                            </Grid>
                                            <Grid item xs={6} sm={7} md={8} lg={9}>
                                                <Box>{segmentationData.segmentation_method}</Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={5} md={4} lg={3}>
                                                <Box>Segments:</Box>
                                            </Grid>
                                            <Grid item xs={6} sm={7} md={8} lg={9}>
                                                <Box>{segmentationData.number_of_clusters}</Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={3}>
                                                <Box>Segmentation Variables:</Box>
                                            </Grid>
                                            <Grid item xs={12} lg={9}>
                                                <Box>
                                                    {/*Create chips from segmentation variables*/}
                                                    {/* {console.log('variableList: ', variableList)} */}
                                                    {segmentationData.segmentationvariable.map((variable) => {
                                                        return (
                                                            <Chip
                                                                key={variable.variable_detail}
                                                                label={variableList.filter((row) => {
                                                                return row.id === variable.variable_detail;
                                                                })?.[0]?.label
                                                                }
                                                                variant='outlined'
                                                                size='small'
                                                                sx={{ margin: '2px' }}
                                                            />
                                                        )
                                                    })}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

            </Grid>
            <Box width={'100%'} sx={{ bgcolor: '#E1E5F2' }}><b>Model</b></Box>
            <Divider />
            <Grid container spacing={1} marginY={2} fontSize={12}>
                <LabelGrid> Suggested Model: </LabelGrid>
                <ValueGrid value={summaryData.dea_model}/> 

            </Grid>
        

        </DialogContent>
    }
    </Dialog>
    
        
  )
}


// Create Chips from a list of objects
function ChipsList({ list }) {
    return (
        <>
            {list?.map((item) => {
                return (
                    <Chip size='small' key={item.id} label={item.label} />
                )
            })}
        </>
    )
}

function LabelGrid({ children, label }) {
    return (
        <Grid item xs={12} sm={4} sx={{}}>
             <Box sx={{minWidth:100}}>
                 {children}
            </Box>
        </Grid>
    )
}

function ValueGrid({ children, value=null, type='text', required=false }) {
    return (
        <Grid item xs={12} sm={8} sx={{}}>
            <Box sx={{ minWidth: 100 }}>
                {required && type==='text'? 
                    (value || value !== '' ? capitalizeFirstLetter(value) : <div style={{ color: 'red' }}>Missing required*</div>)
                    : null}
                {!required && type === 'text' && value !=='' && value?capitalizeFirstLetter(value):null}
                {required && type === 'list' ?
                    (value?.length === 0 ? <div style={{ color: 'red' }}>Missing required*</div> : null)
                    : null}
                {children}
            </Box>
        </Grid>
    )
}

/*Capitalizes first letter of a string*/
function capitalizeFirstLetter(string) {
    // console.log('capitalizeFirstLetter', string)
    if (!string || string==='') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}
