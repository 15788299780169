import React from 'react'
import { MainCard } from '../../common/commonContainer'
import { Box, Typography } from '@mui/material'
import { KnowledgeCardsContent } from './knowledgeBase/knowledgeBaseContent'

export default function VariableTreatment() {
  return (
    <MainCard >
        <Box sx={{display:'flex', p:'0 25px', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
          <Typography variant="subtitle2" color="initial">Variable Treatment</Typography>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="subtitle2" color="initial">Last Update</Typography>
            <Typography variant="caption" color="#777777">21/05/2023</Typography>
          </Box>
        </Box>
        <KnowledgeCardsContent sx={{display: 'flex', flexFlow: 'column nowrap', gap: '15px'}}>
          <Typography variant="h6" color="initial">Undesirable Metrics:</Typography>
          <Typography variant="body2" color="initial">
          There can be both desirable (good) and undesirable (bad) outcomes and inputs in a given scenario. For instance, the presence of defective products represents an undesirable outcome. The goal is to minimize defects in order to enhance performance. In cases where production processes result in inefficiency, leading to the generation of waste and pollutants, these outputs are considered undesirable and should be reduced to improve performance. There are also situations where increasing certain inputs becomes necessary to enhance performance. For example, when aiming to improve the effectiveness of a waste treatment process, the amount of waste (considered an undesirable input) to be treated should be increased rather than decreased, as assumed in traditional scenarios.
          </Typography>
          <Typography variant="h6" color="initial">Restricted Metrics:</Typography>
          <Typography variant="body2" color="initial">
          The default scenario is based on the assumption that there will be proportional improvements in both inputs or outputs. However, this assumption may not hold true in situations where there is a preference structure that evaluates the improvements of different inputs or outputs differently.
          </Typography>
          <Typography variant="body2" color="initial">
            To address this issue, we can utilize restricted metrics to classify inputs and outputs that are beyond our control. These metrics allow us to categorize and differentiate uncontrollable inputs and outputs, enabling a more comprehensive analysis of the system under consideration.
          </Typography>
        </KnowledgeCardsContent>
    </MainCard>
  )
}
