import { ArrowBack, HomeOutlined } from '@mui/icons-material'
import { Box, Button, styled, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const MainUpperContainer = styled(Box)({
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    marginBottom: '10px',
    width: '100%',
})

const UpperTitleContainer = styled(Box)({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
})

export default function MainUpperBar(props) {
    const {UpperTitle, backLink} = props

    const navigate = useNavigate();

    const handleBack = () => {
      if (backLink) {
        navigate(backLink);
      } else {
        navigate(-1);
      }
    };
  return (
    <MainUpperContainer>
        <UpperTitleContainer>
          <Link to="/">
            <HomeOutlined sx={{strokeWidth: 1, stroke: '#E5E5E5'}}/>
          </Link>
            <Typography variant="h6" color="#47254B" sx={{marginLeft: '3px'}}> / {UpperTitle}</Typography>
        </UpperTitleContainer>
            <Button onClick={handleBack}><ArrowBack/> <Typography variant="h6" color="#47254B">Back</Typography></Button>
    </MainUpperContainer>
  )
}
