import { get_new } from "../../../../../utils/httpClient"

export const getInputTargetData = ({ benchmark_id, periodObject, entity, orientValue }) => {
  const url = `/project/benchmark/results/dmu/metrics/?benchmark_id=${benchmark_id}&dmu_id=${entity}&period=${periodObject?.period}&orient=${orientValue}&benchmark_var__variable_type=input`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Input Target data:', error);
    throw error;
  })
};

export const getOutputTargetData = ({ benchmark_id, periodObject, entity, orientValue }) => {
    const url = `/project/benchmark/results/dmu/metrics/?benchmark_id=${benchmark_id}&dmu_id=${entity}&period=${periodObject?.period}&orient=${orientValue}&benchmark_var__variable_type=output`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data.results)
    .catch((error) => {
      console.error('Error fetching Output Target data:', error);
      throw error;
    })
  };