import React from 'react'
import { Box, Typography } from '@mui/material'
import { EditModalCards } from '../editModalContent';

export default function TrackingPeriodSetupInfo({ summaryData }) {
    //FIND THE INDEX OF THE FIRST BASELINE PERIOD
    const firstBaselineIndex = summaryData?.benchmarkperiod?.findIndex((index) => index?.period_id === summaryData?.benchmark_start_period);

  return (
    <EditModalCards>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="h6" color="initial">
                Baseline Frequency:
            </Typography>
            <Typography variant="body2" color="initial">
                {summaryData?.benchmark_freq}
            </Typography>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="h6" color="initial">
                Tracking Frequency:
            </Typography>
            <Typography variant="body2" color="initial">
                {summaryData?.tracking_freq}
            </Typography>
        </Box>
        {
            firstBaselineIndex !== -1 ?
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
                <Typography variant="h6" color="initial">
                    First Baseline Period:
                </Typography>
                <Typography variant="body2" color="initial">
                    {summaryData?.benchmarkperiod[firstBaselineIndex].period_date}
                </Typography>
            </Box>
            :
            null
        }
        
    </EditModalCards>
  )
}
