import React from 'react'
import { LabelGrid, ValueGrid } from '../manualSettings'
import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'

export default function BenchmarkSetupManual({ setFormBenchmarkData, formBenchmarkData, changeFormBenchmarkData}) {
    
  return (
    <>
        <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Benchmark Setup</b> </Box>
        <Divider />
        <Grid container spacing={1} marginY={2} fontSize={12}>
            <LabelGrid> Purpose: </LabelGrid>
            <ValueGrid>
                <RadioGroup
                    value={formBenchmarkData.purpose}
                    onChange={(e) => {changeFormBenchmarkData({purpose: e.target.value})}}
                >
                    <Grid
                        container
                        rowSpacing={2}
                        margin={0}
                        padding={0}
                        justifyContent="space-evenly"
                        alignItems="center"
                        sx={{flexFlow: 'row'}}
                    >
                        {/*Option Benchmark*/}
                        <Grid item xs={6} padding={1} sx={{'&:hover': {backgroundColor: 'lightblue'}}}>
                            <Box>
                            <FormControlLabel value='benchmark' control={<Radio />}
                                label={
                                    <div style={{fontSize: '12px'}}> <b>Benchmark + Improvement Plan </b></div>  
                                } />
                            </Box>
                        </Grid>
                        {/*Options Rank*/}
                        <Grid item xs={6} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                            <Box>
                                <FormControlLabel value='rank' control={<Radio disabled/>}
                                    label={
                                        <div style={{fontSize: '12px'}}> <b>Rank </b></div>  
                                    } 
                                />       
                            </Box>
                        </Grid>   
                    </Grid>
                </RadioGroup>
            </ValueGrid>

            <LabelGrid> Objective: </LabelGrid>
            <ValueGrid>
            <RadioGroup
                value={formBenchmarkData.orient}
                onChange={(e) => {changeFormBenchmarkData({orient: e.target.value})}}
            >
                <Grid
                    container
                    rowSpacing={2}
                    margin={0}
                    padding={0}
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{flexFlow: 'row'}}
                >
                    {/*Option Input Based*/}
                    <Grid item xs={3} padding={1} sx={{'&:hover': {backgroundColor: 'lightblue'}}}>
                        <Box>
                        <FormControlLabel value='input' control={<Radio />}
                            label={
                                <div style={{fontSize: '12px'}}> <b>Input Based </b></div>  
                            } />
                        </Box>
                    </Grid>
                    {/*Option Output Based*/}
                    <Grid item xs={3} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                        <Box>
                            <FormControlLabel value='output' control={<Radio/>}
                                label={
                                    <div style={{fontSize: '12px'}}> <b>Output Based </b></div>  
                                } 
                            />       
                        </Box>
                    </Grid>

                    {/*Option Both Input & Output Based Independently*/}
                    <Grid item xs={3} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                        <Box>
                            <FormControlLabel value='input_output_independent' control={<Radio/>}
                                label={
                                    <div style={{fontSize: '12px'}}> <b>Both Input & Output Based Independently</b></div>  
                                } 
                            />       
                        </Box>
                    </Grid>
                    {/*Option Both Input & Output Based Simultaneously*/}
                    <Grid item xs={3} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                        <Box>
                            <FormControlLabel value='input_output_together' control={<Radio/>}
                                label={
                                    <div style={{fontSize: '12px'}}> <b>Both Input & Output Based Simultaneously</b></div>  
                                } 
                            />       
                        </Box>
                    </Grid> 
                </Grid>
            </RadioGroup>
            </ValueGrid>
            <LabelGrid> Scale:</LabelGrid>
            <ValueGrid>
                <RadioGroup
                    value={formBenchmarkData.scale_type}
                    onChange={(e) => {changeFormBenchmarkData({scale_type: e.target.value})}}
                >
                    <Grid
                        container
                        rowSpacing={2}
                        margin={0}
                        padding={0}
                        justifyContent="space-evenly"
                        alignItems="center"
                        sx={{flexFlow: 'row'}}
                    >
                        {/*Option Constant Returns*/}
                        <Grid item xs={3} padding={1} sx={{'&:hover': {backgroundColor: 'lightblue'}}}>
                            <Box>
                            <FormControlLabel value='crs' control={<Radio />}
                                label={
                                    <div style={{fontSize: '12px'}}> <b>Constant Returns </b></div>  
                                } />
                            </Box>
                        </Grid>
                        {/*Option Variable Return*/}
                        <Grid item xs={3} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                            <Box>
                                <FormControlLabel value='vrs' control={<Radio/>}
                                    label={
                                        <div style={{fontSize: '12px'}}> <b>Variable Return </b></div>  
                                    } 
                                />       
                            </Box>
                        </Grid>

                        {/*Option Increasing Returns*/}
                        <Grid item xs={3} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                            <Box>
                                <FormControlLabel value='irs' control={<Radio/>}
                                    label={
                                        <div style={{fontSize: '12px'}}> <b>Increasing Returns</b></div>  
                                    } 
                                />       
                            </Box>
                        </Grid>
                        {/*Option Decreasing Returns*/}
                        <Grid item xs={3} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                            <Box>
                                <FormControlLabel value='drs' control={<Radio/>}
                                    label={
                                        <div style={{fontSize: '12px'}}> <b>Decreasing Returns</b></div>  
                                    } 
                                />       
                            </Box>
                        </Grid> 
                    </Grid>
                </RadioGroup>
            </ValueGrid>
            <LabelGrid> Target Values Type: </LabelGrid>
            <ValueGrid>
                <RadioGroup
                    value={formBenchmarkData.target_values_type}
                    onChange={(e) => {changeFormBenchmarkData({target_values_type: e.target.value})}}
                >
                    <Grid
                        container
                        rowSpacing={2}
                        margin={0}
                        padding={0}
                        justifyContent="space-evenly"
                        alignItems="center"
                        sx={{flexFlow: 'row'}}
                    >
                        {/*Option Equal Proportion (Radial)*/}
                        <Grid item xs={6} padding={1} sx={{'&:hover': {backgroundColor: 'lightblue'}}}>
                            <Box>
                            <FormControlLabel value='radial' control={<Radio />}
                                label={
                                    <div style={{fontSize: '12px'}}> <b>Equal Proportion (Radial) </b></div>  
                                } />
                            </Box>
                        </Grid>
                        {/*Option Individual Proportion (Non-radial)*/}
                        <Grid item xs={6} sx={{'&:hover': {backgroundColor: 'lightblue'}}} padding={1} >
                            <Box>
                                <FormControlLabel value='non_radial' control={<Radio/>}
                                    label={
                                        <div style={{fontSize: '12px'}}> <b>Individual Proportion (Non-radial) </b></div>  
                                    } 
                                />       
                            </Box>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </ValueGrid>
        </Grid>
    </>
  )
}
