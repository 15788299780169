import React from "react";
import {
  Chart as ChartJS,
} from "chart.js";
import {
  BoxPlotController,
  BoxAndWiskers
} from "@sgratzl/chartjs-chart-boxplot";
import colors from '../../assets/variables.scss'
import { useRef } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { numeralFormatter } from "../../utils/numeralFormatter";
import { calculateQuartiles } from "../../utils/boxplotValues";


ChartJS.register(
  BoxPlotController,
  BoxAndWiskers,
);



export function IndividualEfficiencyScores({api, entity, periodObject}) {
  const canvasRef = useRef(undefined);
  const chartRef = useRef(undefined);



  const index = api?.results?.findIndex(obj => obj.dmu_id === entity);
  const entityInfo = api && api.results && api?.results[index]
  


  const efficiencyValues = api?.results?.map((data) => data.efficiency * 100);
    const { q1, q2, q3, min, max } = calculateQuartiles(efficiencyValues);

    console.log({ q1, q2, q3, min, max });
  const data = React.useMemo(() => {
    const labels = [
      periodObject?.period
    ];
    return {
      labels,
      datasets: [
        {
          label: 'Line',
          type: 'line',
          data: [ numeralFormatter(entityInfo?.efficiency * 100)],
          borderColor: colors.segment1,
          backgroundColor: colors.segment1,
          elements: {
            point: {
              radius: 8
            }
          }
        },
          {
            label: "Dataset 1",
            // data: [ api?.results?.map( data => (data.efficiency * 100))],
            data: [{
              min: min,
              q1: q1,
              median: q2,
              q3: q3,
              max: max,
              // mean: mean,
            }],
            borderColor: colors.segment1,
            backgroundColor: colors.segment3
        },
      ]
    };
  }, [api, periodObject?.period, entityInfo?.efficiency]);

  React.useEffect(() => {
    chartRef.current = new ChartJS(canvasRef.current, {
      type: "boxplot",
      data: data,
      options: {
        responsive: false,
        plugins: {
          title: {
            display: true,
            text: 'Efficiency Scores',
            color: '#4c4c4d',
            font: {
              size: 14,
              weight: 'bold',
              lineHeight: 1.2,
            },
      
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const boxplotValues = [
                  `Min: ${context.parsed.min.toFixed(2)}`,
                  `25% Quartile: ${context.parsed.q1.toFixed(2)}`,
                  `Median: ${context.parsed.median.toFixed(2)}`,
                  // `Mean: ${context.parsed.mean.toFixed(2)}`,
                  `75% Quartile: ${context.parsed.q3.toFixed(2)}`,
                  `Max: ${context.parsed.max}`
                ];
                return boxplotValues
              }
            }
          },
          datalabels: {display: false},
          legend: {display: false}
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'Efficiency',
              color: '#4c4c4d',
              font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2,
              },
              padding: {top: 0, left: 0, right: 0, bottom: 0}
            }
          },
          y: {
            display: true,
            beginAtZero: false,
            grace: "10%",
            title: {
              display: true,
              align: 'end',
              text: 'Efficiency ( % )',
              color: '#4c4c4d',
              font: {
                size: 10,
                weight: 'bold',
                lineHeight: 1.2
              },
            }
          }
        }
      }
    });

    return () => {
      chartRef.current.destroy();
    };
  }, [data]);
  return <Box sx={{display: 'flex', flexFlow: 'row', gap: '40px'}}>
    <canvas ref={canvasRef} height={400} width={200} />
    <Box sx={{display: 'flex', flexFlow: 'column', gap: '10px', justifyContent: 'center'}}>
      <Box>
        <Typography variant="h6" color="initial">Entity</Typography>
        <Typography variant="body1" color="initial">{entityInfo?.dmu_id}</Typography>
      </Box>
      <Divider/>
      <Box>
        <Typography variant="h6" color="initial">Efficiency</Typography>
        <Typography variant="body1" color="initial">{numeralFormatter(entityInfo?.efficiency * 100)} %</Typography>
      </Box>
      <Divider/>
      <Box>
        <Typography variant="h6" color="initial">Overall Rank</Typography>
        <Typography variant="body1" color="initial">{entityInfo?.overall_rank}</Typography>
      </Box>
    </Box>



  </Box>
}
