import {  styled, Table, TableContainer, TablePagination } from '@mui/material';
import React from 'react';
import { EnhancedTableHead } from '../../components/common/table/tableHead'
import { benchmarkScenariosHeadCells } from './benchmarkScenariosHeadcells';
import BenchmarkTableContent from './benchmarkScenarioTableContent';
import { useEffect } from 'react';



export const ProjectContainer = styled(TableContainer) ({
   flex: 1
});


export default function BenchmarkScenarioTable({filteredData, page, count, rowsPerPage, onPageChange, onRowsPerPageChange}) {

  const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        setSelected(newSelected);
      };


    
    const isSelected = (name) => selected.indexOf(name) !== -1;
  
  return (
    <ProjectContainer>
        <Table
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headCells={benchmarkScenariosHeadCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredData.length}
          />
          <BenchmarkTableContent
            orderBy={orderBy}
            handleClick={handleClick}
            isSelected={isSelected}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredData = {filteredData}
          />
        </Table>
        <TablePagination
          rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />

    </ProjectContainer>
  )
}
