import React, { useEffect, useState} from 'react';
import {
    CircularProgress,
	Dialog,
	DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
	Button,
    Box,
    
    
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useSnackbar } from 'notistack';
import { get_new, api_delete } from '../../../../../utils/httpClient';
import { red } from '@mui/material/colors';



export default function DeleteBenchmark({
    open = true,
    onClose = null,
    scenario_id = null,
    setDeletedID = () => {},
}) {

    // COMPONENT STATE
    const [scenario, setScenario] = useState({'benchmark_name': '','benchmark_id': '',});
    const [loadingDelete, setLoadingDelete] = useState(false);

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();
    
    
    // GET SCENARIO INFO
    /* query API using scenario_id and set scenario state */
    useEffect(() => {
        //console.log("Get scenario info");
        const targetURL = '/project/benchmarks/?benchmark_id=' + scenario_id;
        get_new(targetURL)
            .then((response) => {
                //console.log("Response: ", response);
                if (!response.ok) {
                    console.log("Error");
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                //console.log("Data: ", data.results[0]);
                setScenario(data.results[0]);
                return;
            })
            .catch((error) => {
                console.log("Error: ", error);
                return;
            });
    }, [scenario_id]);

    // HANDLE CLOSE
    const handleClose = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    // HANDLE CLICK ON DELETE
    /* send DELETE request to API, close modal and initiate toast confirmation to indicate fail/success */
    const handleDelete = () => {
        if (!loadingDelete) {setLoadingDelete(true);}
        console.log("Deleting scenario: ", scenario.benchmark_id);
        //enqueueSnackbar('Successfully deleted scenario: '+scenario.benchmark_name, { variant: 'error' });
        //enqueueSnackbar('Failed deleting scenario: '+scenario.benchmark_name, {variant: 'info'});
        //handleClose();
        
        const targetURL = '/project/benchmark/';
        const data = { 'benchmark_id': scenario.benchmark_id };
        console.log("Data: ", data);
        api_delete(targetURL, data)
            .then((response) => {
                console.log("Response: ", response);
                if (!response.ok) {
                    console.log("Error");
                    throw new Error(response.statusText);
                }
                //return response.json();
                return;
            })
            .then(() => {
                console.log("Delete Succeeded")
                //console.log("Data: ", data);
                enqueueSnackbar('Successfully deleted scenario: ' + scenario.benchmark_name, { variant: 'success' });
                setDeletedID(scenario.benchmark_id);
                setLoadingDelete(false);
                handleClose();
                return;
            }).catch((error) => {
                caches.log("Delete Failed")
                console.log("Error: ", error);
                enqueueSnackbar('Failed deleting scenario: '+scenario.benchmark_name, {variant: 'error'});
                setLoadingDelete(false);
                handleClose();
                return;
            });
            
    }

    return (
        <>
            <Dialog
                maxWidth='sm'
        open={open}
        onClose={handleClose}
                aria-labelledby="delete-benchmark-title"
                aria-describedby='delete-benchmark-description'
      >
        <DialogTitle id="delete-benchmark-title" sx={{background: '#47254b', color: 'white'}}>
          Delete Benchmark Scenario?"
        </DialogTitle>
                <DialogContent>
                    <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                        <Grid item xs={12}>
                            <div id='delete-benchmark-description'>
                                  <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                        <Box sx={{ }}>
                                            <WarningIcon fontSize='large' color='error' sx={{ float: 'left', mr: 1 }} />
                                        </Box>
                                        <Box sx={{ textAlign:'center', }}>
                                            Caution! You are about to delete scenario
                                            "<b>{scenario.benchmark_name}</b>" 
                                            ID: {scenario.benchmark_id}
                                        </Box>
                                    </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width:'100%', textAlign:'center'}}>
                                <b>Deleting a scenario will permanently remove its configuration 
                                and all associated results. </b>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{fontSize:12}}>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Box sx={{}}>
                                    <ul>
                                        <li>
                                            No other benchmark scenarios will be affected by this action.
                                        </li>
                                        <li>
                                            Segmentation scenarios associated with this scenario will not be deleted.
                                        </li>
                                        { scenario.status === 'processing' && (
                                            <li>
                                                Results are currently being computed, deleting this scenario will
                                                stop the process and any progress will be lost.
                                            </li>
                                        )}
                                        {scenario.status === 'published' && (
                                            <li>
                                                The scenario is currently being published and is visible to other users.
                                                If removed, users will no longer be able to see published results for this project.
                                            </li>
                                        )}
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{mx:1}}>
          <Button disabled={loadingDelete} onClick={handleDelete} color='error' variant='text' >
            Yes, Delete </Button>
            {loadingDelete && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: red[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                            />
                        )}
            </Box>
            <Button onClick={handleClose} color='primary' variant='contained' autoFocus>Cancel, Keep</Button>
        </DialogActions>
      </Dialog>
        </>
    );
}