import React from 'react';
import { Line } from 'react-chartjs-2';
import { options } from '../graphProps/timeseries'
import colors from '../../assets/variables.scss'

import {Chart, Filler} from 'chart.js';

Chart.register(Filler);




export default function AvgEfficiencyImprovement({ value, api}) {

  
  api?.results?.sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateA - dateB;
  });
  const scale = { scales: {
      x: {
        display: true,
        grid: {display: false},
  
        title: {
          display: true,
          text: 'Period',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      },
      y: {
        display: true,
        beginAtZero: false,
        // grace: "10%",
        ticks: {stepSize: 0.2},
        title: {
          align: 'end',
          display: true,
          text: `Avg. Efficiency Improvement % (p/${value ? 'b' : 'p'})`,
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        }
      }
    }
  }

  const title = {
    tension: 0.2,
    plugins: {
      title: {
        text: `Avg. Efficiency Improvement (p/${value ? 'b' : 'p'})`,
        display: true,
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      datalabels: {
        display: true,
        color: "white",
        align: 'center',
        borderRadius: 50,
        backgroundColor: '#9061C2',
        labels: {
          title: {
            font: {
              size: 10
            }
          },
        },
        formatter: (value) => {
          return `${value}%`
        }
      },
  
      legend: {display: false}
    }
  }

  const options133 = Object.assign({}, options, title, scale)

  

const labels = api.results && api.results.map(segment => segment.period);

const data = {
    labels,
    datasets:  [
      {
        label: 'Cluster',
        data: value === 0 ? api.results && api.results.map(segment => (segment.pct_improv_prev_period * 100).toFixed(1)) : api.results && api.results.map(segment => (segment.pct_improv_benchmark_period * 100).toFixed(1)),
        borderColor: '#9061C2',
        backgroundColor: 'rgb(144, 97, 194, 0.2)',
        yAxisID: 'y',
        //fill: true
      }
    ],
  };


  return <div style={{ width: '100%', height: '250px' }}>
    <Line options={options133} data={data} />
  </div>
}
