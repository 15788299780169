import React from "react";
import {
  CardContentContainer,
  CardUpperContainer,
  MainCard,
} from "../../../components/common/commonContainer";
import Typography from "@mui/material/Typography";
import { EmptyContainer } from "../../../components/common/emptyCardMessage";
import { styled } from "@mui/material";
import { get_new } from "../../../utils/httpClient";
import { useState } from "react";
import { useEffect } from "react";
import ResultsProjectHomeTable from "./publishedResults/resultsProjectHomeTable";

export const PublishEmptyContainer = styled(EmptyContainer)({
  width: "619px",
  height: "69px",
});

export default function PublishResults({ projectData }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  //PUBLISHED RESULTS HANDLE TABLE PAGINATION

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    get_new(
      `/project/benchmarks/?project_id=${
        projectData?.project_id
      }&status=published&page_size=${rowsPerPage}&page=${page + 1}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve API data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
      });
  }, [page, rowsPerPage, projectData?.project_id]);
  return (
    <MainCard sx={{ minHeight: "0", pb: 0 }}>
      <CardUpperContainer>
        <Typography variant="subtitle1">Publish Results</Typography>
      </CardUpperContainer>
      {data.count === 0 ? (
        <CardContentContainer>
          <PublishEmptyContainer>
            <Typography
              variant="body1"
              color="initial"
              sx={{ padding: "0 35px" }}
              align="center"
            >
              You don´t have Publish Results created yet
            </Typography>
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{ padding: "0 35px" }}
            >
              Create a Benchmark Scenario to generate a result.
            </Typography>
          </PublishEmptyContainer>
        </CardContentContainer>
      ) : (
        <ResultsProjectHomeTable
          filteredData={data?.results}
          page={page}
          count={data.count}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </MainCard>
  );
}
