import { Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch } from '@mui/material'
import React from 'react'
import { LabelGrid, ValueGrid } from '../manualSettings'
import EditBenchmarkContext from '../../context/editBenchmarkContext';
import { useContext } from 'react';
import { useEffect } from 'react';

export default function PeriodInformationManual({ setFormBenchmarkData, formBenchmarkData, changeFormBenchmarkData}) {
  const { periodData } = useContext(EditBenchmarkContext)

  
  useEffect(() => {
    if (formBenchmarkData.apply_tracking_cycles === false) {
      console.log(formBenchmarkData.benchmark_freq)
      changeFormBenchmarkData({benchmark_freq: ''});
      changeFormBenchmarkData({benchmark_start_period: ''});
    }
}, [formBenchmarkData.apply_tracking_cycles]);

  return (
    <>
      <Grid container spacing={1} marginY={2} fontSize={12}>        
        {/* {console.log('Got to tracking cycles')} */}
        <LabelGrid> Baseline Frequency </LabelGrid>
        <ValueGrid >   
          <RadioGroup
            sx={{display: 'flex', flexFlow: 'row nowrap'}}    
            value={(formBenchmarkData?.benchmark_freq ?? '')}
            onChange={(event) => changeFormBenchmarkData({benchmark_freq: event.target.value})}
          >
            <FormControlLabel
              disabled={!periodData.has_periods}
              value='yearly'
              control={<Radio />}
              label='Yearly'
            />
            {/*Disable if Dataset only has yearly periods*/}
            <FormControlLabel
              disabled={periodData.periodicity==='yearly' || !periodData.has_periods}
              value='quarterly'
              control={<Radio />}
              label='quarterly'
            />
            {/*Disable if Dataset has anything other than Monthly periods*/}
            <FormControlLabel
              disabled={periodData.periodicity!=='monthly' || !periodData.has_periods}
              value='monthly'
              control={<Radio />}
              label='Monthly'
            />
          </RadioGroup>

                    
            
        </ValueGrid>
        <LabelGrid> First Baseline Period: </LabelGrid>
        <ValueGrid>
          <Box sx={{ minWidth: 60, maxWidth:300}}>
            <FormControl fullWidth>
              <InputLabel id="baselineFrequencyLabel">First baseline period</InputLabel>
              <Select
                size='small'
                id="baselineFrequency"
                labelId="baselineFrequencyLabel"
                label='First baseline period'
                value={formBenchmarkData?.benchmark_start_period ?? ''}
                onChange={(event) => changeFormBenchmarkData({benchmark_start_period: event.target.value})}
              >
                {(periodData.has_periods) && (
                  periodData.periods_list.map((period) => { 
                    return <MenuItem key={period.period_id} value={period.period_id}>{period.date_string}</MenuItem>
                  })
                )}
              </Select>
            </FormControl>
          </Box>
        </ValueGrid>
      </Grid>
    </>
  )
}
