import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { ShadowCardContentContainer } from "../../../../components/common/commonContainer";
import { get_new } from "../../../../utils/httpClient";
import { useState } from "react";
import { useEffect } from "react";
import { numeralFormatter } from "../../../../utils/numeralFormatter";

export default function MetricsHighLevelMetrics({
  entity,
  periodObject,
  benchmark_id,
  orientValue,
}) {
  const [api, setApi] = useState([]);

  useEffect(() => {
    get_new(
      `/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&dmu_id=${entity}&period=${periodObject?.period}&orient=${orientValue}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve projects");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log('Projects List');
        // console.log(data);
        // console.log(data.results);
        setApi(data.results);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
      });
  }, [entity, periodObject, benchmark_id, orientValue]);

  return (
    <ShadowCardContentContainer
      sx={{
        flexFlow: "row wrap",
        alignItems: "center",
        flexGrow: "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          padding: "5px 0px",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="initial">
          {numeralFormatter(api[0]?.efficiency * 100)}%
        </Typography>
        <Typography variant="body2" color="initial">
          Efficiency
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          padding: "5px 0px",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="initial">
          {numeralFormatter(
            (api[0]?.efficiency - api[0]?.pct_improv_benchmark_period) * 100
          )}
          %
        </Typography>
        <Typography variant="body2" color="initial">
          Baseline Efficiency
        </Typography>
      </Box>
      {api[0]?.cluster_cd !== null && api[0]?.cluster_cd !== undefined && (
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            padding: "5px 0px",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="initial">
            {api[0]?.cluster_cd}
          </Typography>
          <Typography variant="body2" color="initial">
            Segment test
          </Typography>
        </Box>
      )}
      <Divider variant="middle" orientation="vertical" flexItem />
      <Box
        sx={{
          marginRight: "10px",
          padding: "10px",
          borderRadius: "8px",
          height: "fit-content",
        }}
      >
        <Typography variant="h5" color="initial">
          {periodObject?.period}
        </Typography>
        <Typography variant="body2" color="initial">
          Current Period
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: "10px",
          padding: "10px",
          borderRadius: "8px",
          height: "fit-content",
        }}
      >
        <Typography variant="h5" color="initial">
          {periodObject?.benchmark_period}
        </Typography>
        <Typography variant="body2" color="initial">
          Baseline Period
        </Typography>
      </Box>
    </ShadowCardContentContainer>
  );
}
