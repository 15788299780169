import React from 'react'
import { MainCard } from '../../common/commonContainer'
import { Box, Typography } from '@mui/material'
import { KnowledgeCardsContent } from './knowledgeBase/knowledgeBaseContent'

export default function About() {
  return (
    <MainCard>
        <Box sx={{display:'flex', p:'0 25px', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
            <Typography variant="subtitle2" color="initial">About</Typography>
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
                <Typography variant="subtitle2" color="initial">Last Update</Typography>
                <Typography variant="caption" color="#777777">21/05/2023</Typography>
            </Box>
        </Box>
        <KnowledgeCardsContent>
            <Typography variant="body2" color="initial">
                Performing segmentation before evaluating the performance of entities can provide several benefits. Here are a few reasons why it's a good practice:
            </Typography>
            <Typography variant="body2" color="initial">
            <strong>Granularity of analysis:</strong> Segmentation allows you to analyze and evaluate the performance of entities at a more granular level. By dividing your dataset into distinct segments or clusters, you can gain insights into how different groups of entities are performing individually. This granularity helps you understand the specific strengths, weaknesses, and characteristics of each segment.
            </Typography>
            <Typography variant="body2" color="initial">
            <strong>Targeted analysis:</strong> Segmentation enables targeted analysis by focusing on specific subsets of entities that share similar attributes or behaviors. This approach allows you to tailor your evaluation and assessment based on the unique characteristics of each segment. By understanding the performance of entities within each segment, you can make more informed decisions and take targeted actions to improve performance.
            </Typography>
            <Typography variant="body2" color="initial">
            <strong>Identifying variations:</strong> Segmentation can reveal variations or patterns within your dataset that might not be apparent when analyzing all entities together. By segmenting entities, you may discover that certain groups are outperforming others or that specific segments exhibit unique trends or behaviors. These insights can help you identify opportunities for improvement or make informed decisions on resource allocation.
            </Typography>
            <Typography variant="body2" color="initial">
            <strong>Benchmarking and comparison:</strong> Segmentation facilitates benchmarking and performance comparison between different segments or clusters. By evaluating the performance of entities within each segment independently, you can establish benchmarks or performance standards specific to each segment. This allows you to compare entities within a segment against their peers, leading to a more meaningful and relevant evaluation.
            </Typography>
            <Typography variant="body2" color="initial">
            <strong>Improved decision-making:</strong> Segmentation provides a more nuanced view of performance, which can support better decision-making. By considering the performance of entities within different segments, you can make decisions based on a deeper understanding of the underlying factors and dynamics that impact performance. This targeted approach allows you to allocate resources, prioritize actions, and develop strategies that are tailored to the specific needs and characteristics of each segment.
            </Typography>
            <Typography variant="body2" color="initial">
                In summary, performing segmentation before evaluating entity performance offers a more detailed and targeted analysis, enabling you to understand variations, identify trends, benchmark performance, and make informed decisions. By evaluating entities within specific segments, you can uncover valuable insights and take appropriate actions to optimize performance.
            </Typography>
        </KnowledgeCardsContent>
    </MainCard>
  )
}
