import React, {useContext, useEffect} from 'react';
import { styled, Table, TableContainer, TablePagination } from '@mui/material';

import { EnhancedTableHead } from '../../../../components/common/table/tableHead';
import { SegmentationScenariosHeadCells } from './segmentationProjectHomeHeadcells';
import ProjectHomeSegmentationTableContent from './segmentationProjectHomeTableContent';
//import { SegmentationScenariosContext } from '../home/projectDetails/segmentationScenarios';



export const ProjectContainer = styled(TableContainer) ({
  flex: 1
});

export default function SegmentationProjectHomeTable(props) {
  // PROPS
  const {segmentationScenarios, setDeletedID, page, rowsPerPage, onPageChange, onRowsPerPageChange, count} = props;

  // LOCAL STATE
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    //const [dense, setDense] = React.useState(false);
  
    // CONTEXT STATE
    // const {segmentationScenarios, setDeletedID} = useContext(SegmentationScenariosContext);
  
    const dense = false;
  
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        setSelected(newSelected);
      };


/*
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = filteredData.map((n) => console.log(n.name));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
  };
  */



    
    const isSelected = (name) => selected.indexOf(name) !== -1;



  
  return (
    <ProjectContainer>
        <Table
            aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headCells={SegmentationScenariosHeadCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={segmentationScenarios.length}
          />
          
          <ProjectHomeSegmentationTableContent
            orderBy={orderBy}
            handleClick={handleClick}
            isSelected={isSelected}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredData={segmentationScenarios}
            setDeletedID={setDeletedID}
          /> 
        </Table>
        <TablePagination
          rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          />
    </ProjectContainer>
  )
}
