import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ClickAwayListener, Toolbar } from '@mui/material';

import benchmarkLogo from '../../assets/icons/commons/evsWhiteLogo.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/icons/sidebar/projectsIcon.svg';
import { ReactComponent as DatasetsIcon } from '../../assets/icons/sidebar/datasetsIcon.svg';
import { ReactComponent as BenchmarksIcon } from '../../assets/icons/sidebar/benchmarksIcon.svg';
import { ReactComponent as SegmentationsIcon } from '../../assets/icons/sidebar/segmentationsIcon.svg';
import { ReactComponent as ResultsIcon } from '../../assets/icons/sidebar/resultsIcon.svg';
import { ReactComponent as SidebarItemsSpan } from '../../assets/icons/sidebar/sidebarItemsSpan.svg';
import { ReactComponent as HomeIcon }  from "../../assets/icons/sidebar/homeIcon.svg";
import { SideBarLink } from '../common/styledLinks';


const drawerWidth = 253;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
  backgroundColor: '#47254B',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#47254B',
  overflow: 'hidden',
  width: '55px',
});

const DrawerHeader = styled('div')({
  marginTop: '74px'
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
          backgroundColor: 'transparent',
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      backgroundColor: 'transparent',
    }),
  }),
);


const DrawerButton = styled(ListItemButton)({
  minHeight: 48,
  justifyContent: 'initial',
  width: 'fit-content',
  '& .MuiListItemIcon-root': {padding: '6px'},
  '& .spanItem': {visibility: 'hidden', marginRight: '2px'},
  '&:hover':{'& .MuiListItemIcon-root': {backgroundColor: '#664A6A', borderRadius: '6px'}, '& .spanItem': {visibility: 'visible'}}
})
export default function MiniDrawer() {
  const [open, setOpen] = React.useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'flex' }}>
        <Drawer variant="permanent" open={open}>
              <Toolbar variant="dense" sx={{marginTop:'1rem', alignItems: 'flex-start', padding: '0 24px'}}>
                  <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawer}
                      edge="start"     
                      >
                      <MenuIcon sx={{ color: '#E0044E',transform: open ? 'rotate(90deg)' : 'rotate(0deg)', transition: '250ms ease-in-out'}}/>
                  </IconButton>
                  <img src={benchmarkLogo} alt='white EVs logo' />
              </Toolbar>
              <List>
                  {['Home', 'Projects', 'Datasets'].map((text, index) => (
                  <ListItem key={text} disablePadding sx={{ display: 'block', '& .MuiButtonBase-root': {padding: '8px 10px', width: '15.8rem'}}}>
                    <SideBarLink to={index === 0 ? '/' : index === 1 ? '/projects' : index === 2 ? '/datasets' : 'error'}>
                    <DrawerButton>
                      <SidebarItemsSpan className='spanItem'/>
                      <ListItemIcon
                      sx={{
                          minWidth: 0,
                          mr: '21px',
                          justifyContent: 'center',
                      }}
                      >
                        {index === 0 ? <HomeIcon /> : index === 1 ? <ProjectsIcon /> : <DatasetsIcon />}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </DrawerButton>
                  </SideBarLink>
                  </ListItem>
                  ))}
              </List>
              <Divider variant='middle' sx={{backgroundColor: '#FFFFFF'}} />
              <List>
              {['Segmentations', 'Benchmarks', 'Results'].map((text, index) => (
                  <ListItem key={index} disablePadding sx={{ display: 'block', '& .MuiButtonBase-root': {padding: '8px 10px', width: '15.8rem'}}}>
                    <SideBarLink to={index === 0 ? '/segmentationScenarios' : index === 1 ? '/benchmarkScenarios' : index === 2 ? '/publishedResults' : 'error'}>
                      <DrawerButton>
                          <SidebarItemsSpan className='spanItem'/>
                          <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: '21px',
                            justifyContent: 'center',
                          }}
                          >
                          {index === 0 ? <SegmentationsIcon /> : index === 1 ? <BenchmarksIcon /> : <ResultsIcon />}
                          </ListItemIcon>
                          <ListItemText primary={text} />
                      </DrawerButton>
                  </SideBarLink>
                  </ListItem>
                  ))}
              </List>
          </Drawer>
          <DrawerHeader />
      </Box>
    </ClickAwayListener>
  );
}