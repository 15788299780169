import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./ThemeProviderStyles/globalStyles";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    {/*<React.StrictMode>*/}
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        disableWindowBlurListener
      >
        <App />
      </SnackbarProvider>
    </BrowserRouter>
    {/*</React.StrictMode>*/}
  </ThemeProvider>
);
