import React, { useContext } from 'react';
import {
	
    InputLabel,
    FormControl,
    Select,
    MenuItem,
	FormControlLabel,
	Radio,
    RadioGroup,
	Box,
    Grid
} from '@mui/material';
import { CreateBenchmarkContext } from './createBenchmarkContext';
import TabPanel from './tabPanel';

export default function BaselinePeriodsWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData, periodData } = useContext(CreateBenchmarkContext);
    


    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Tracking Cycles Baseline Frequency</b></div>
            <br /> 
                <div>How often should the benchmark baseline be re-calculated?</div>
                <div>Benchmark baseline periods serve as reference points in
                    a time series to assess progress. Benchmarks and improvement targets
                    calculated in these periods are used as reference points during the tracking cycle.
                    All periods between the baseline periods will be compared against the most immediate
                baseline before it.</div>
            <br/>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box >
                            
                        
                            <RadioGroup
                                
                                value={(formData.baseline_frequency)}
                                onChange={(event) => changeFormData('baseline_frequency', event.target.value)}
                            >
                                <FormControlLabel
                                    disabled={!periodData.has_periods}
                                    value='yearly'
                                    control={<Radio />}
                                    label='Yearly'
                                />
                                {/*Disable if Dataset only has yearly periods*/}
                                <FormControlLabel
                                    disabled={periodData.periodicity==='yearly' || !periodData.has_periods}
                                    value='quarterly'
                                    control={<Radio />}
                                    label='quarterly'
                                />
                                {/*Disable if Dataset has anything other than Monthly periods*/}
                                <FormControlLabel
                                    disabled={periodData.periodicity!=='monthly' || !periodData.has_periods}
                                    value='monthly'
                                    control={<Radio />}
                                    label='Monthly'
                                />

                            </RadioGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ minWidth: 60, maxWidth:300}}>
                            <FormControl fullWidth>
                                <InputLabel id="baselineFrequencyLabel">First baseline period</InputLabel>
                                <Select
                                    size='small'
                                    id="baselineFrequency"
                                    labelId="baselineFrequencyLabel"
                                    label='First baseline period'
                                    value={formData?.first_baseline_period ?? ''}
                                    onChange={(event) => changeFormData('first_baseline_period', event.target.value)}

                                >
                                    {(periodData.has_periods) && (
                                        periodData.periods_list.map((period) => { 
                                            return <MenuItem key={period.period_id} value={period.period_id}>{period.date_string}</MenuItem>
                                        })
                                    )}
                                </Select>
                                </FormControl>
                        </Box>

                    </Grid>
                </Grid>                    
         </TabPanel>                   
        </>
    )
}