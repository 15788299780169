export const tableHeadCellsSummary = [
	{
		id: 'name',
		numeric: false,
		disablePadding: true,
		label: 'Name',
	},
	{
		id: 'description',
		numeric: false,
		disablePadding: true,
		label: 'Description',
	},
];
