import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";

export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead >
        <TableRow >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align='left'
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                whiteSpace: {xs:'pre-wrap', md:'nowrap'},
                display: headCell.customDisplay
              }}
              >
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              > */}
                <Typography variant="h7" sx={{display: 'flex'}}>
                  {headCell.label} {headCell.complement ? headCell.complement : null}
                </Typography>
              {/* </TableSortLabel> */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    // onRequestSort: PropTypes.func.isRequired,
    // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    // orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
  };
  
