import { Box, CircularProgress, Divider, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { MainCard, ShadowCardContentContainer } from '../../../../components/common/commonContainer';
import VariableHistogramComparison from '../../../../graphs/comparisonResults/variableHistogram';
import VariableTable from './variableTable';
import { useState } from 'react';
import { useEffect } from 'react';
import { getMetricsApi } from './services/metricsServices';

export default function Metrics({selectedValues, periodObject, benchmark_id, orientValue}) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);


    const [plots, setPlots] = useState('current');
    const [type, setType] = useState('input');
    const [metricsApi, setMetricsApi] = useState([])

    const handlePlotsChange = (event) => {
        setPlots(event.target.value);
      };

    const handleTypeChange = (event) => {
      setType(event.target.value);
      setMetricsApi([])
    };
    
  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);

    const fetchSelectedValuesData = () => {
      //Clear previous data
      setMetricsApi([])
      const selectedValuesCopy = [...selectedValues];
      for(const selectedValue of selectedValuesCopy) {
        // const controller = new AbortController();
        const encodedSelectedOption = encodeURIComponent(selectedValue);
        Promise.all([
          getMetricsApi({ benchmark_id, periodObject, encodedSelectedOption, type, orientValue }),
        ])
        .then(([metricsApiData]) => {
          setMetricsApi(metricsApiData);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
        // return () => controller.abort()
      };
    };

    // Use a timeout to delay fetching data after selectedValues change
    const delay = 3000;
    const timeoutId = setTimeout(() => {
      fetchSelectedValuesData();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [benchmark_id, orientValue, selectedValues, periodObject, type]);

    useEffect(() => {
    // Remove unselected option data from the array
    setMetricsApi((prevData) =>
        prevData.filter((item) => selectedValues.includes(item.selectedOption))
    );
    }, [selectedValues, periodObject]);
    const arr = metricsApi.map( value => value.results)
    const groupedData = arr.reduce((acc, dmuArray) => {
      dmuArray.forEach(obj => {
        const { variable__variable_name } = obj;
        if (!acc[variable__variable_name]) {
          acc[variable__variable_name] = [];
        }
        acc[variable__variable_name].push(obj);
      });
      return acc;
    }, {});

    const groupedMetrics = Object.entries(groupedData).map(([variable_name, objects]) => ({
      [variable_name]: objects
    }));

    if(loading) {
      return <Box sx={{width: '100%'}}>
        <MainCard sx={{justifyContent: 'center', alignItems: 'center', height: '300px'}}>
          <CircularProgress/>
        </MainCard>
      </Box>
    }
  
    if(error) {
      return <Box sx={{width: '100%'}}>
        <MainCard sx={{justifyContent: 'center', alignItems: 'center', height: '300px'}}>
          Error: {error.message}
        </MainCard>
      </Box>
    }

  return (
    <Box sx={{width: '100%'}}>
        <Typography variant="h5" color="initial" sx={{p: '8px 16px' }}>Metrics</Typography>
        <Divider/>
        <Box sx={{display: 'flex', flexFlow: 'row', gap: '40px', width: '100%'}}>
            <Box sx={{display: 'flex', flexFlow: 'column', width: '30%', m: '10px 16px'}}>
              <Typography variant="h6" color="initial" >Plots:</Typography>
              <FormControl fullWidth>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={plots}
                    onChange={handlePlotsChange}
                    >
                        <MenuItem value={'current'}>Actual</MenuItem>
                        <MenuItem value={'target'}>Target</MenuItem>
                        <MenuItem value={'change_current_benchmark'}>% Change</MenuItem>
                        <MenuItem value={'progress_current_target'}>% Progress</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column', width: '30%', m: '10px 16px'}}>
              <Typography variant="h6" color="initial" >Variable Type:</Typography>
              <FormControl fullWidth>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    onChange={handleTypeChange}
                    >
                        <MenuItem value={'input'}>Input</MenuItem>
                        <MenuItem value={'output'}>Output</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'column', gap: '10px', pr: '16px', mb: '16px'}}>
            <Box sx={{display: 'flex', flexFlow: 'column', gap: '20px'}}>
                {groupedMetrics.map((metrics, index) => (
                    <Box key={index} sx={{display: 'flex', flexFlow: {xs:'column', md: 'row'}, alignItems: {xs:'center', md: 'flex-start'}}}>
                        <ShadowCardContentContainer sx={{width: 'fit-content', p: '10px'}}>
                            <VariableHistogramComparison key={index} metrics={metrics} plots={plots}/>
                        </ShadowCardContentContainer>
                        <VariableTable key={index} metrics={metrics} />
                    </Box>
                ))}
            </Box>
        </Box>
    </Box>
  )
}
