import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
	Dialog,
	DialogActions,
    DialogContent,
    DialogTitle,
	Button,
    Box,
    List,
    ListItem,
    ListItemIcon,
    Grid,
    
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { red } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { api_delete, get_new } from '../../../../../utils/httpClient';


export default function DeleteSegmentation({
    open = true,
    onClose = null,
    scenario_id = null,
    setDeletedID = () => {},
}) {

    // COMPONENT STATE
    const [scenario, setScenario] = useState({'segmentation_name': '','segmentation_scenario_id': '',});
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [benchmarkDependencies, setBenchmarkDependencies] = useState([]);

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();
    
    
    // GET SCENARIO INFO
    /* query API using scenario_id and set scenario state */
    useEffect(() => {
        //console.log("Get scenario info");
        const targetURL = '/project/segmentations/?segmentation_scenario_id=' + scenario_id;
        get_new(targetURL)
            .then((response) => {
                //console.log("Response: ", response);
                if (!response.ok) {
                    console.log("Error");
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                //console.log("Data: ", data.results[0]);
                setScenario(data.results[0]);
                return;
            })
            .catch((error) => {
                console.log("Error: ", error);
                return;
            });
    }, [scenario_id]);

    // HANDLE CLOSE
    const handleClose = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    // HANDLE CLICK ON DELETE
    /* send DELETE request to API, close modal and initiate toast confirmation to indicate fail/success */
    const handleDelete = () => {
        //if (!loadingDelete) {setLoadingDelete(true);}
        console.log("Deleting scenario: ", scenario.segmentation_scenario_id);
        //enqueueSnackbar('Successfully deleted scenario: '+scenario.segmentation_name, { variant: 'error' });
        //enqueueSnackbar('Failed deleting scenario: ' + scenario.segmentation_name, { variant: 'info' });
        handleClose();
        
        const targetURL = '/project/segmentation/';
        const data = { 'segmentation_scenario_id': scenario.segmentation_scenario_id };
        console.log("Data: ", data);
        api_delete(targetURL, data)
            .then((response) => {
                console.log("Response: ", response);
                if (!response.ok) {
                    console.log("Error");
                    throw new Error(response.statusText);
                }
                //return response.json();
                return;
            })
            .then(() => {
                console.log("Delete Succeeded")
                //console.log("Data: ", data);
                enqueueSnackbar('Successfully deleted scenario: ' + scenario.segmentation_name, { variant: 'success' });
                setDeletedID(scenario.segmentation_scenario_id);
                setLoadingDelete(false);
                handleClose();
                return;
            }).catch((error) => {
                console.log("Delete Failed")
                console.log("Error: ", error);
                enqueueSnackbar('Failed deleting scenario: ' + scenario.segmentation_name, { variant: 'error' });
                setLoadingDelete(false);
                handleClose();
                return;
            }); 
    }

    return (
        <>
            <Dialog
                maxWidth='sm'
        open={open}
        onClose={handleClose}
                aria-labelledby="delete-title"
                aria-describedby='delete-description'
      >
        <DialogTitle id="delete-title" sx={{background: '#47254b', color: 'white'}}>
          Delete Segmentation Scenario?
        </DialogTitle>
        <DialogContent>
            <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                <Grid item xs={12}>
                    <div id='delete-description'>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                    <Box sx={{ }}>
                                        <WarningIcon fontSize='large' color='error' sx={{ float: 'left', mr: 1 }} />
                                    </Box>
                                    <Box sx={{ textAlign:'center', }}>
                                        Caution! You are about to delete scenario
                                        "<b>{scenario.segmentation_name}</b>" 
                                        ID: {scenario.segmentation_scenario_id}
                                    </Box>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12}>
                        <Box sx={{ width:'100%', textAlign:'center'}}>
                            <b>Deleting a scenario will permanently remove its configuration 
                            and all associated results. </b>
                        </Box>
                </Grid>
                        <Grid item xs={12} sx={{fontSize:12}}>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Box sx={{}}>
                                    <ul>
                                        <li>
                                            No other segmentation scenarios will be affected by this action.
                                        </li>
                                        
                                        {scenario.status === 'processing' && (
                                            <li>
                                                Results are currently being computed, deleting this scenario will 
                                                stop the process and any progress will be lost.
                                            </li>
                                        )}
                                        {benchmarkDependencies.length > 0 && (
                                            <li>
                                                The scenario is currently being used by the following Benchmark scenarios.
                                                Please remove these scenarios before proceeding. 
                                            </li>
                                        )}
                                    </ul>
                                </Box>
                            </Box>
                    </Grid>
                        <Grid item xs={12}>
                        {benchmarkDependencies.length > 0 && (
                            <Box>
                                LIST OF DEPENDENT SCENARIOS
                            </Box>
                            )}
                            </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{mx:1}}>
                        <Button
                            disabled={loadingDelete}
                            onClick={handleDelete || benchmarkDependencies.length > 0}
                            color='error'
                            variant='text' >
            Yes, Delete </Button>
            {loadingDelete && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: red[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                            />
                        )}
            </Box>
            <Button onClick={handleClose} color='primary' variant='contained' autoFocus>Cancel, Keep</Button>
        </DialogActions>
      </Dialog>
        </>
    );
}