import { styled } from '@mui/material/styles';
import { ReactComponent as SearchIcon }  from "../../assets/icons/sidebar/filterBarSearchIcon.svg"
import { TextField } from '@mui/material';


export const Search = styled('div')({
  width:'32vw',
  borderRadius: '0.375rem',
  border: '1px solid var(--forms-eui-form-border-color, rgba(19, 34, 149, 0.10))',
  background: '#FFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  height: '35px',
  lineHeight:'14px',
  padding: '2px 10px',
  '&:hover': {
    backgroundColor: 'white',
    opacity: 0.7
  }, 
});




export default function SearchBar(props) {
  const { showItem, value, onChange } = props
  return (
    <Search sx={{ alignSelf: 'center', display: {xs: showItem.small, md: showItem.big}}}>
      <SearchIcon fill="#A28DA5"/>
      <TextField
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={value} onChange={onChange}
        variant="outlined"
        sx={{
          color: 'inherit',
          width: '100%',
          '& .MuiInputBase-input': {
            padding: 1,
            // vertical padding + font size from searchIcon
            width: '100%',
        
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none' 
          }
        }}
      
      />
    </Search>
  )
}


  