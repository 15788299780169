import React, { useEffect, useState, useContext } from 'react';
import {
    FormLabel,
	FormControl,
    Autocomplete,
    Switch,
    TextField,
	FormControlLabel,
	Box,
    Grid
} from '@mui/material';
import TabPanel from './tabPanel';
import { CreateBenchmarkContext } from './createBenchmarkContext';

export default function VarTreatmentWS({ tabValue, index}) {
    // Context state
    const { formData, setFormData, changeFormData } = useContext(CreateBenchmarkContext);
    const [restrictedOptions, setRestrictedOptions] = useState([]);
    const [undesirableOptions, setUndesirableOptions] = useState([]);
    
    // Set restricted options if objective or input/output variables change
    useEffect(() => {
        let varOptions = [];
        if (formData.objective === 'input') {
            varOptions = formData.input_vars;
        }
        if (formData.objective === 'output') {
            varOptions = formData.output_vars;
        }
        if (formData.objective === 'input_output_independent') {
            varOptions = formData.input_vars.concat(formData.output_vars);
        }
        if (formData.objective === 'input_output_together') {
            varOptions = [];
            let newFormData = formData;
            newFormData.apply_restricted = false;
            newFormData.restricted_vars = [];
            setFormData(newFormData);
        }
        setRestrictedOptions(varOptions);
        // console.log('Re-calculated restricted Options:', varOptions);
    }, [formData.objective, formData.input_vars, formData.output_vars]);
    
    // Set undesirable options if objective or input/output variables change
    useEffect(() => { 
        let options=formData.input_vars.concat(formData.output_vars)
        setUndesirableOptions(options);
        // console.log('Re-calculated Undesirable Options:', options);
    }, [formData.objective, formData.input_vars, formData.output_vars]);

 
    // if restrictedOptions changes remove variables from restricted_vars that are not in restrictedOptions
    // if undesirableOptions changes remove variables from undesirable_vars that are not in undesirableOptions
    useEffect(() => {
        // console.log('Removing unavailable variables');
        let optionsIDs = restrictedOptions.map((option) => {return option.id});
        let newRestrictedVars = formData.restricted_vars.filter((variable) => {
            return optionsIDs.includes(variable.id);
            });
        // console.log({ 'New restricted vars': newRestrictedVars });
        

        optionsIDs = undesirableOptions.map((option) => { return option.id });
        let newUndesirableVars = formData.undesirable_vars.filter((variable) => {
            return optionsIDs.includes(variable.id);
        });
        // console.log({ 'New undesirable vars': newUndesirableVars });
        
        const newFormData = {
			...formData,
			...{
                'restricted_vars': newRestrictedVars,
                'undesirable_vars': newUndesirableVars
			},
		};

        setFormData(newFormData);
        //console.log({ 'New form data': newFormData });
        //eslint-disable-next-line
    }, [restrictedOptions]);

    
    return (
        <>
            <TabPanel value={tabValue} index={index}>
            <div><b>Variable Treatment</b></div>
                <br />
                <div>Variables/metrics used in this scenario might require specific considerations. 
                    Use the options below to indicate if and how the model should treat them.
                </div>
            <br />
            
            <Grid container spacing={2}>
                <Grid item xs={9} md={6} lg={6}>
                    <Box >
                        <FormControl>
                            <FormControlLabel
                                //value={formData.apply_restricted}
                                label='Restrict variables'
                                    labelPlacement='end'
                                    disabled={formData.objective === 'input_output_together'}
                                control={
                                    <Switch
                                        checked={formData.apply_restricted}
                                        onChange={(event) => {
                                            changeFormData('apply_restricted', event.target.checked);
                                        }}
                                    />}
                            />
                            </FormControl>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='restricted-vars' >Restricted Metrics </FormLabel>
                                    <Autocomplete
                                        multiple
                                        id='restricted-vars'
                                        size='small'
                                        //sx = {{width: '450px'}}
                                        disabled={!formData.apply_restricted}
                                        selectOnFocus
                                        clearOnBlur
                                        clearOnEscape
                                        autoComplete
                                autoHighlight
                                fullWidth
                                        options={restrictedOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={formData.restricted_vars}
                                        onChange={(event, newValue) => {
                                            changeFormData('restricted_vars', newValue);
                                    }}
                                        renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                            </FormControl>
                                    </Box>
                </Grid>
            <Grid item xs={9} md={6} lg={6}>
                    <Box >
                        <FormControl>
                            <FormControlLabel
                                //value={formData.apply_restricted}
                                label='Control Undesirable effects variables'
                                labelPlacement='end'
                                control={
                                    <Switch
                                        checked={formData.apply_undesirable}
                                        onChange={(event) => {
                                            if (!event.target.checked) changeFormData('undesirable_vars', []);
                                            changeFormData('apply_undesirable', event.target.checked);
                                        }}
                                    />}
                            />
                            </FormControl>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='undesirable_vars-vars' >Undesirable effect metrics  </FormLabel>
                                    <Autocomplete
                                        multiple
                                        id='undesirable_vars-vars'
                                        size='small'
                                        //sx = {{width: '450px'}}
                                        disabled={!formData.apply_undesirable}
                                        selectOnFocus
                                        clearOnBlur
                                        clearOnEscape
                                        autoComplete
                                        autoHighlight
                                        fullWidth
                                options={undesirableOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={formData.undesirable_vars}
                                onChange={(event, newValue) => {
                                            changeFormData('undesirable_vars', newValue);
                                    }}
                                        renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                            </FormControl>
                                    </Box>
                </Grid>
                </Grid>
            
               </TabPanel>                     
                            
        </>
    )
}