import React, { useEffect, useContext } from 'react';
import {
    InputLabel,
    MenuItem,
    Switch,
    FormControl,
    FormControlLabel,
    Select,
	Radio,
    RadioGroup,
	Box,
    Grid,
} from '@mui/material';
import TabPanel from './tabPanel';
import {Link} from 'react-router-dom';
import { CreateBenchmarkContext } from './createBenchmarkContext';


export default function PeriodsWS({ tabValue, index}) {
    // Context state
    const { formData, changeFormData, periodData,
        disabledTabs, setDisabledTabs, dataset_id } = useContext(CreateBenchmarkContext);
    
    useEffect(() => {
        if (formData.multi_periods !== 'all') {
            handleDisableTabs(true);
            changeFormData('apply_tracking_cycles', false);
        }

    }, [formData.multi_periods]);

      // Disable tabs if not using tracking cycles    
   function handleDisableTabs (disableStatus) {
        console.log('disable_tabs_apply_tracking_cycles:', formData.apply_tracking_cycles);
        let newDisabledTabs = disabledTabs;
        newDisabledTabs.baselinePeriodsWS = disableStatus;
        newDisabledTabs.trackingPeriodsWS = disableStatus;
        console.log('newDisabledTabs', newDisabledTabs);
        setDisabledTabs(newDisabledTabs);
        return;
    }

    return (
        <>
            <TabPanel value={tabValue} index={index}>
                <Box sx={{position:'relative'}}>
                    
                    <Box>
            <div><b>Benchmark Periods</b></div>
            <br /> 
                <div>Benchmark all periods in the dataset or just one?</div>
            <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5} md={4}  lg={3}>
                        <Box >
                            
                            {/*Disable if Dataset doesn't have periods*/}
                            <RadioGroup
                                value={(formData.multi_periods)}
                                onChange={(event) => changeFormData('multi_periods', event.target.value)}
                            >
                                <FormControlLabel
                                    disabled={!periodData.has_periods}
                                    value='single'
                                    control={<Radio />} label='Just one' />
                                <FormControlLabel
                                    disabled={!periodData.has_periods}
                                    value='all'
                                    control={<Radio />} label='All' />
                                
                            </RadioGroup>
                                    </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ minWidth: 60, maxWidth:120}}>
                            <FormControl fullWidth disabled={formData.multi_periods!=='single' || !periodData.has_periods}>
                                <InputLabel id="singlePeriod-Label">Period</InputLabel>
                                {/*Disable if Dataset doesn't have periods*/}
                                <Select
                                    size='small'
                                    id="singlePeriod"
                                    labelId="singlePeriodLabel"
                                    label='Period'
                                    value={formData.single_baseline_period}
                                    onChange={(event) => changeFormData('single_baseline_period', event.target.value)}

                                        >   
                                    {(periodData.has_periods) && (
                                        periodData.periods_list.map((period) => { 
                                            return <MenuItem key={period.period_id} value={period.period_id}>{period.date_string}</MenuItem>
                                        })
                                    )}
                
                                </Select>
                                </FormControl>
                        </Box>

                    </Grid>
                    <Grid item xs={12}>
                    <Box >
                        <FormControl>
                            <FormControlLabel
                                //value={formData.apply_restricted}
                                label='Enable Tracking Cycles'
                                labelPlacement='end'
                                control={
                                    <Switch
                                        disabled={formData.multi_periods!=='all' || !periodData.has_periods}
                                        checked={formData.apply_tracking_cycles}
                                        onChange={(event) => {
                                            handleDisableTabs(!event.target.checked);
                                            changeFormData('apply_tracking_cycles', event.target.checked);
                                        }}
                                    />}
                            />
                            </FormControl>
                            <div>
                BenchmarkLens can assess improvement by tracking two benchmarking
                cycles. The first cycle defines how often a benchmark baseline should be 
                calculated. The second cycle determines how often progress should be 
                assessed against the corresponding baseline for that cycle.
            </div>
                    </Box>
                </Grid>
            
                    </Grid>
                    </Box>
                    
                    {/*Superpose a message in case periods are not available*/}
                    {!periodData.has_periods && (<>
                        <Box  sx={{
                            bgcolor: 'white',
                            border: '1px solid',
                            borderColor: 'grey.500',
                            borderRadius: '5px',
                        zIndex: 'tooltip',
                            position: 'absolute',
                            minHeight: '40%',
                        //height: '40%',
                            top: '20%',
                        left: '10%',

                            width: '80%',
                            justifySelf: 'center',
                            padding: '10px',
                        boxShadow: 2,

                    }}>
                            <Box sx={{width: '100%', mb: 1, textAlign: 'center',}} >
                            <b> This dataset doesn't have period information</b></Box>
                            <div style={{ fontSize: 12 }}>
                                If the dataset should have multiple periods, navigate to the dataset section,
                                make sure the 'Period' field has been properly annotated and restart this wizard.
                                <br/>
                                <Link to={'/datasetDetails?dataset_id=' + dataset_id}>
                                        Go to Dataset
                                </Link>
                                <br /> <br />
                                
                               You can still create a benchmark scenario without period data, all the data will be used as
                                a single period.
                            </div>
                        </Box>
                        <Box sx={{
                        bgcolor: 'white',
                        zIndex: 'modal',
                        position: 'absolute',
                        height: '90%',
                        top: '10%',
                        left: '0%',
                        width: '100%',
                        opacity: 0.4,
                        p: 0,
                        m: 0
                    }}>
                        </Box>
                    </>)}
            
                
                </Box>               
            </TabPanel>                   
        </>
    )
}