export const datasetsHeadCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name/ ID',
    },
    {
      id: 'records',
      numeric: true,
      disablePadding: false,
      label: 'records',
    },
    {
      id: 'variables',
      numeric: true,
      disablePadding: false,
      label: 'variables',
    },
    {
      id: 'creationDate',
      numeric: true,
      disablePadding: false,
      label: 'Creation Date',
      customDisplay: {xs: 'none', md: 'table-cell'},
    },
    {    
        id: 'lastUpdate',
        numeric: true,
        disablePadding: false,
        label: 'Last Update',
        customDisplay: {xs: 'none', md: 'table-cell'},
    },
    {    
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
  ];