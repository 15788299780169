import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import {
  PurpleBox,
  ShadowCardContentContainer,
} from "../../../components/common/commonContainer";
import { useState } from "react";
import { useEffect } from "react";
import { get_new } from "../../../utils/httpClient";

export default function Setup({
  segmentationMethod,
  variables,
  period,
  segment,
  segmentation_scenario_id,
  project_id,
  periodLabel,
  segmentCount,
}) {
  const [segmentationResults, setSegmentationResults] = useState([]);
  //GET SEGMENTATION DATA
  useEffect(() => {
    get_new(
      `/project/segmentation/results/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}&period_id=${period}&cluster_cd=${segment}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve data");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setSegmentationResults(data);
      })
      .catch((error) => {
        console.log("Error: " + error);
        // TODO: Show user error that data couldn't be retrieved
      });
  }, [project_id, segmentation_scenario_id, period, segment]);

  const entitiesCount = segmentationResults?.results?.[0].clusters.reduce(
    (acc, obj) => acc + obj?.cluster_count,
    0
  );
  // function calculateAverage(array, propName) {
  //     const sum = array?.reduce((acc, obj) => acc + obj?.segmentation_scenario[propName], 0);
  //     const average = sum / array?.length;
  //     return isNaN(average) ? 0 : average.toFixed(1);
  //   }

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-around",
      }}
    >
      <ShadowCardContentContainer
        sx={{
          flexFlow: "column",
          alignItems: "flex-start",
          flexGrow: "0",
          p: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "row wrap",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexFlow: "column", width: "45%" }}>
            <Typography variant="h6" color="initial">
              Segmentation Method
            </Typography>
            <Typography variant="body2" color="initial">
              {segmentationMethod}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  gap: "25px",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", flexFlow: "column wrap" }}>
                  <Typography variant="h6" color="initial">
                    Segments
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {segmentCount}
                  </Typography>
                </Box>
                <Divider variant="middle" orientation="vertical" flexItem />

                <Box sx={{ display: "flex", flexFlow: "column nowrap" }}>
                  <Typography variant="h6" color="initial">
                    Entities
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {entitiesCount}
                  </Typography>
                </Box>
                <Divider variant="middle" orientation="vertical" flexItem />

                <Box sx={{ display: "flex", flexFlow: "column nowrap" }}>
                  <Typography variant="h6" color="initial">
                    Variables
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {variables?.length}
                  </Typography>
                </Box>
                {period ? (
                  <>
                    <Divider variant="middle" orientation="vertical" flexItem />

                    <Box sx={{ display: "flex", flexFlow: "column nowrap" }}>
                      <Typography variant="h6" color="initial">
                        Period
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {periodLabel}
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "column", width: "100%" }}>
          <Typography variant="h6" color="initial">
            Variables:
          </Typography>
          <Box sx={{ display: "flex", gap: "10px", flexFlow: "row wrap" }}>
            {variables?.map((variable, index) => (
              <PurpleBox key={index} variant="body2" color="initial">
                {variable.original_field_name}
              </PurpleBox>
            ))}
          </Box>
        </Box>
      </ShadowCardContentContainer>
    </Box>
  );
}
