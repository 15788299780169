import React from 'react';
import { Chart as ChartJS, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../assets/variables.scss';

ChartJS.register(BarElement);

export default function EfficiencyDistributionBySegment({ value, api }) {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        font: {
          size: value === 0 ? 12 : 10,
        },
        anchor: 'end',
        align: 'top',
        formatter: value === 1 ? (value) => `${value}%` : null,
      },

      title: {
        display: true,
        text: 'Efficiency Distribution By Segment',
        color: '#4c4c4d',
        font: {
          size: 14,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Efficiency Bins',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        yAxes: [
          {
            stacked: true, // this will stack the bars on top of each other
            gridLines: {
              display: true, // show the vertical grid lines
              color: '#eee', // set the color of the grid lines
            },
          },
        ],
        display: true,
        grace: '20%',
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          align: 'end',
          text: 'Entities (n)',
          color: '#4c4c4d',
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };

  // Extract available segmentations from the API data
  const availableSegments = Array.from(new Set(api.map((data) => data.assignment__cluster__cluster_cd))).filter(
    (segment) => segment !== null
  );

  const generateDatasets = () => {
    if (api && api.length > 0) {
      const datasets = availableSegments.map((segment) => {
        const binFilter = (bin) => api.filter((data) => data.bin === bin && data.assignment__cluster__cluster_cd === segment)[0]?.bin_count;

        const total = api
          .filter((data) => data.assignment__cluster__cluster_cd === segment)
          .reduce((acc, curr) => acc + curr.bin_count, 0);

        return {
          label: `Segment ${segment}`,
          data:
            value === 0
              ? [binFilter('0% - 60%'), binFilter('60% - 70%'), binFilter('70% - 80%'), binFilter('80% - 90%'), binFilter('90% - 100%')]
              : [
                  ((binFilter('0% - 60%') / total) * 100).toFixed(0),
                  ((binFilter('60% - 70%') / total) * 100).toFixed(0),
                  ((binFilter('70% - 80%') / total) * 100).toFixed(0),
                  ((binFilter('80% - 90%') / total) * 100).toFixed(0),
                  ((binFilter('90% - 100%') / total) * 100).toFixed(0),
                ],
          backgroundColor: colors[`segment${segment + 1}`],
        };
      });

      return datasets;
    }

    return [];
  };

  const labels = ['0% - 60%', '60% - 70%', '70% - 80%', '80% - 90%', '90% - 100%'];

  const data = {
    labels,
    datasets: generateDatasets(),
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Bar options={options} data={data} />
    </div>
  );
}
