import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Typography, Grid, Box, CircularProgress } from "@mui/material";
import MainUpperBar from "../../components/common/mainUpperBar";
import { RoundTab, TabsContainer } from "../../components/common/tabsDesign";
import {
  MainCard,
  ShadowCardContentContainer,
} from "../../components/common/commonContainer";
import Variable from "./variable/variable";
import Summary from "./summary/summary";
import Setup from "./setup/setup";
import FilterSidebar from "../../components/common/filtersSidebar";
import { useEffect } from "react";
import {
  getDatasetId,
  getMetricOptions,
  getPeriodOptions,
  getProject,
  getSegmentOptions,
  getSegmentVariableId,
  getSegmentationScenario,
  getVariables,
} from "./services/indexServices";

export default function DatasetDetails() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();
  const [value, setValue] = useState(0);
  const [periodLabel, setPeriodLabel] = useState("");
  const [project, setProject] = useState([]);
  const [segmentations, setSegmentations] = useState([]);
  const segmentationMethod = segmentations?.results?.[0]?.segmentation_method;
  const project_id = searchParams.get("project_id");
  const segmentation_scenario_id = searchParams.get("segmentation_scenario_id");

  const [segmentVariableId, setSegmentVariableId] = useState([]);
  const [datasetId, setDatasetId] = useState([]);
  const [variables, setVariables] = useState([]);

  //SET TAB VALUE AND CONDITIONALLY SHOW METRIC FILTERFIELD
  const handleChange = (event, newValue) => {
    setValue(newValue);

    setFilterFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.key === "metric") {
          if (newValue === 0) {
            // If the new value is 0, set the type to an empty string
            return { ...field, type: "" };
          } else if (newValue === 1) {
            // If the new value is 1, change the type to "select"
            return { ...field, type: "select" };
          }
        }
        return field;
      });

      return updatedFields;
    });
  };
  const [filterFields, setFilterFields] = useState([
    {
      key: "segment",
      title: "Segment",
      label: "",
      name: "segment",
      value: "",
      type: "select",
      options: [
        { label: "All", value: "" },
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
      ],
    },

    {
      key: "period",
      title: "Period",
      label: "",
      name: "Period",
      value: "",
      type: "select",
      options: [{ label: "", value: "" }],
    },

    {
      key: "metric",
      title: "Metric",
      label: "",
      name: "metric",
      value: "",
      type: "",
      options: [{ label: "", value: "" }],
    },
  ]);

  const segmentField = filterFields[0];
  const periodField = filterFields[1];
  const metricField = filterFields[2];

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //SCENARIO AND DMU DATA FETCH
    Promise.all([
      getSegmentOptions({ project_id, segmentation_scenario_id, periodField }),
      getPeriodOptions({ project_id, segmentation_scenario_id }),
      getMetricOptions({ project_id, segmentation_scenario_id, segmentField }),
      getProject({ project_id }),
      getSegmentationScenario({ project_id, segmentation_scenario_id }),
    ])
      .then(
        ([
          segmentOptionsData,
          periodOptionsData,
          metricOptionsData,
          projectData,
          segmentationScenarioData,
        ]) => {
          const segmentDefaultValue = { label: "All", value: "" }; // set default value to last object of fetched data
          const periodDefaultValue =
            periodField.value !== ""
              ? periodField.value
              : periodOptionsData?.[periodOptionsData?.length - 1]?.value ??
                periodField.value; // set default value to last object of fetched data
          const metricDefaultValue =
            metricField.value !== ""
              ? metricField.value
              : metricOptionsData[0].value; // set default value to last object of fetched data
          setFilterFields((prevFields) => {
            return prevFields.map((field) => {
              if (field.key === "segment") {
                return {
                  ...field,
                  options: [
                    segmentDefaultValue,
                    ...segmentOptionsData.map((cluster) => ({
                      label: cluster,
                      value: cluster,
                    })),
                  ],
                  value: segmentDefaultValue.value, // set default value to last object of fetched data
                };
              }
              if (field.key === "period") {
                return {
                  ...field,
                  options: [...periodOptionsData],
                  value: periodDefaultValue, // set default value to last object of fetched data
                };
              }
              if (field.key === "metric") {
                return {
                  ...field,
                  options: [...metricOptionsData],
                  value: metricDefaultValue, // set default value to last object of fetched data
                };
              }
              return field;
            });
          });
          setProject(projectData);
          setSegmentations(segmentationScenarioData);

          // return  getVariables({ datasetId })
        }
      )
      // .then((variablesData) => {
      // 	setVariables(
      // 		variablesData
      // 		.filter(v => segmentVariableId.includes(v.variable_detail_id))
      // 		.map(v => v.original_field_name)
      // 	);
      // })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [project_id, segmentation_scenario_id, datasetId]);

  useEffect(() => {
    if (!periodField.value) return;
    const index = periodField.options.findIndex(
      (item) => item.value === periodField.value
    );
    setPeriodLabel(periodField.options[index].label);
  }, [periodField.value, periodField.options]);

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    //SCENARIO AND DMU DATA FETCH
    Promise.all([
      getSegmentVariableId({ project_id, segmentation_scenario_id }),
      getDatasetId({ project_id }),
    ])
      .then(([segmentVariablesIdData, datasetIdData]) => {
        //This get variables id by mapping each object and storing id in an array
        setSegmentVariableId(
          segmentVariablesIdData.segmentationvariable.map(
            (v) => v.variable_detail
          )
        );
        setDatasetId(datasetIdData);

        return getVariables({ datasetId });
      })
      .then((variablesData) => {
        setVariables(
          variablesData.filter((v) =>
            segmentVariableId.includes(v.variable_detail_id)
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [project_id, segmentation_scenario_id, datasetId]);

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <MainUpperBar
          UpperTitle={
            <span>
              <Link
                to={`/projectDetails?project_id=${project_id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Project
              </Link>{" "}
              / Segmentation Results
            </span>
          }
        />
        <MainCard
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </MainCard>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: "100%" }}>
        <MainUpperBar
          UpperTitle={
            <span>
              <Link
                to={`/projectDetails?project_id=${project_id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Project
              </Link>{" "}
              / Segmentation Results
            </span>
          }
        />
        <MainCard
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          Error: {error.message}
        </MainCard>
      </Box>
    );
  }

  return (
    <>
      <MainUpperBar
        UpperTitle={
          <span>
            <Link
              to={`/projectDetails?project_id=${project_id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Project
            </Link>{" "}
            / Segmentation Results
          </span>
        }
      />
      <Grid container sx={{ justifyContent: "space-between", pb: "20px" }}>
        <Grid item>
          <Typography fontSize="1.5rem" fontWeight="bold">
            Segmentation Results
          </Typography>
        </Grid>
        <Grid item>
          <Grid container sx={{ gap: "20px" }}>
            <Grid item>
              <Typography fontSize="0.9rem" fontWeight="bold">
                Project Name
              </Typography>
              {project && project.results && (
                <Typography fontSize="0.8rem">
                  {project?.results[0]?.project_name}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography fontSize="0.9rem" fontWeight="bold">
                Project ID
              </Typography>
              <Typography fontSize="0.8rem">{project_id}</Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="0.9rem" fontWeight="bold">
                Scenario ID
              </Typography>
              <Typography fontSize="0.8rem">
                {segmentation_scenario_id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <FilterSidebar
          filterFields={filterFields}
          setFilterFields={setFilterFields}
        />
        <MainCard>
          <Setup
            segmentationMethod={segmentationMethod}
            variables={variables}
            period={periodField.value}
            segmentCount={segmentField.options.length - 1}
            segment={segmentField.value}
            segmentation_scenario_id={segmentation_scenario_id}
            project_id={project_id}
            periodLabel={periodLabel}
          />
          <TabsContainer
            value={value}
            onChange={handleChange}
            aria-label="icon position tabs example"
            variant="fullWidth"
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{ p: "16px 0" }}
          >
            <RoundTab iconPosition="start" label="Summary" />
            <RoundTab iconPosition="start" label="Variable" />
            {/* <RoundTab iconPosition="start" label="Data" /> */}
          </TabsContainer>

          <ShadowCardContentContainer>
            {value === 0 && (
              <Summary
                project_id={project_id}
                variables={variables}
                segmentationMethod={segmentationMethod}
                period={periodField?.value}
                segment={segmentField?.value}
                segmentation_scenario_id={segmentation_scenario_id}
              />
            )}
            {value === 1 && (
              <Variable
                project_id={project_id}
                segmentation_scenario_id={segmentation_scenario_id}
                segment={segmentField?.value}
                period={periodField?.value}
                metricObj={filterFields[2]?.value}
              />
            )}
            {/* {value === 2 && (
				  <Data />
              )} */}
          </ShadowCardContentContainer>
        </MainCard>
      </Box>
    </>
  );
}
