import React from 'react'
import VariableTable from './variableTable'
import { Box, Typography } from '@mui/material'
import { GrayBlock, ShadowCardContentContainer } from '../../../components/common/commonContainer'
import { VariableBoxplot } from '../../../graphs/segmentationResults/variableBoxPlot'

export default function VariableContent({ segment, api}) {
    const totalEntities = api?.results?.reduce((accumulator, obj) => {
    return accumulator + obj.count;
    }, 0)
    return (
    <Box sx={{width: '-webkit-fill-available', display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
        <ShadowCardContentContainer  
        sx={{
            flexFlow: 'column',
            alignItems: 'center',
            width: '50%'
        }}>
            <Box sx={{display: 'flex', flexFlow: 'row' , gap: '10px', width:'100%', pt: '15px'}}>
                <GrayBlock>
                    <Typography variant="h6" color="initial">{`${segment === "" ? 'Segments' : 'Segment'}`}</Typography>
                    <Typography variant="body2" color="initial">{segment === "" ? api?.count : segment}</Typography>
                </GrayBlock>
                <GrayBlock>
                    <Typography variant="h6" color="initial">Entities</Typography>
                    <Typography variant="body2" color="initial">
                        {totalEntities}
                    </Typography>
                </GrayBlock>
            </Box>
            <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%', flexFlow: {xs: 'column', md:'row wrap'}}}>
                <VariableBoxplot api={api} segment={segment}/>
            </Box>
        </ShadowCardContentContainer>
        <VariableTable api={api}/>
    </Box>
  )
}
