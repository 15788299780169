import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { GraphContainer, GraphMainCard } from '../../../../components/common/commonContainer'
import EntitiesRankingHistogram from '../../../../graphs/aggregatedResults/entitiesRankingHistogram'
import AverageEfficiency from './efficiency/averageEfficiency'
import MainAvgEfficiencyImprovement from './efficiency/mainAvgEfficiencyImprovement'

export default function Efficiency({periodObject, baseSegment, benchmark_id, previousPeriod, periodOptions, orientVaue, setEfficiency}) {
  return (
    <>
        <AverageEfficiency
            periodOptions={periodOptions}
            baseSegment={baseSegment}
            benchmark_id={benchmark_id}
            periodObject={periodObject}
            orientVaue={orientVaue}
            setEfficiency={setEfficiency}
        />
        <MainAvgEfficiencyImprovement
            periodOptions={periodOptions}
            baseSegment={baseSegment}
            benchmark_id={benchmark_id}
            periodObject={periodObject}
            previousPeriod={previousPeriod}
            orientVaue={orientVaue}
        />
        <Box sx={{width: '100%'}}>
            <Typography variant="h5" sx={{padding: '10px'}}>Ranking</Typography>
            <Divider variant="fullWidth" sx={{width: '-webkit-fill-available'}}/>
            <GraphMainCard>
                <GraphContainer>
                    <EntitiesRankingHistogram periodObject={periodObject} baseSegment={baseSegment} benchmark_id={benchmark_id} orientVaue={orientVaue}/>
                </GraphContainer>
            </GraphMainCard>
        </Box>
    </>
  )
}
