import { Box, CircularProgress, Divider, Tabs, Typography } from '@mui/material'
import React from 'react'
import { GraphContainer, GraphMainCard } from '../../../../../components/common/commonContainer'
import AvgEfficiencyByPeriod from '../../../../../graphs/aggregatedResults/avgEfficiencyByPeriod'
import { SmallRoundTab } from '../../../../../components/common/tabsDesign'
import EfficiencyDistributionPerBinGraph from '../../../../../graphs/aggregatedResults/efficiencyDistributionPerBinGraph'
import { EfficiencyScoresBoxPlot } from '../../../../../graphs/aggregatedResults/efficiencyScoresBoxPlot'
import { useState } from 'react'
import { useEffect } from 'react'
import { getAvgEfficiencyData, getBoxplotData, getEfficiencyDistrData } from './services/averageEfficiencyServices'

export default function AverageEfficiency({periodOptions, baseSegment, benchmark_id, periodObject, orientVaue, setEfficiency}) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);

    const [selectedTab1, setSelectedTab1] = useState(0);
    //AVG EFFICIENCY BY PERIOD DATA
    const [avgEfficiencyData, setAvgEfficiencyData] = useState([])
    //EFFICIENCY DISTRIBUTION PER BIN DATA
    const [efficiencyDistrData, setEfficiencyDistrData] = useState([])
    //EFFICIENCY SCORES DATA
    const [boxplotData, setBoxplotData] = useState([])
    //FETCH FUNCTION
    useEffect(() => {
      setLoading(true);
      //SCENARIO AND DMU DATA FETCH
      Promise.all([
        getAvgEfficiencyData({ benchmark_id, baseSegment, orientVaue }),
        getEfficiencyDistrData({ benchmark_id, baseSegment, periodObject,orientVaue }),
        getBoxplotData({ benchmark_id, baseSegment, periodObject,orientVaue }),
      ])
      .then(([avgEfficiency, efficiencyDistrData, boxplotData]) => {
        setAvgEfficiencyData(avgEfficiency);
        setEfficiency(avgEfficiency)
        setEfficiencyDistrData(efficiencyDistrData);
        setBoxplotData(boxplotData);
      })
      .catch(error => {
          console.error('Error fetching data:', error);
          setError(error)
      })
      .finally(() => {
          setLoading(false);
      });
  }, [benchmark_id, baseSegment, orientVaue, periodObject, setEfficiency]);      

    const handleTabChange1 = (event, newValue) => {
      setSelectedTab1(newValue);
    };
  
  return (
    <Box sx={{width: '100%'}}>
        <Typography variant="h5" sx={{padding: '10px'}}>Average Efficiency</Typography>
        <Divider variant="fullWidth" />
        {loading ?
        (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
            <CircularProgress />
        </Box>
        ) : error ? ( // Render error component when error exists
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
                Error: {error.message}
            </Box>
            ) :
         (
            <>
                <GraphMainCard sx={{gap: '10px'}}>
                    {
                        periodOptions.length <= 1 ?
                        null
                        :
                        <GraphContainer sx={{ width: '50%', m: 0, height: {xs:'250px', lg:'350px', xl: '500px'}}}>
                            <AvgEfficiencyByPeriod api={avgEfficiencyData}/>
                        </GraphContainer>
                    }
                    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '10px', flexFlow: periodOptions.length <= 1 ? 'row nowrap' : 'column nowrap', width: periodOptions.length <= 1 ? {xs: '100%',lg:'100%'} : {xs: '100%',lg:'40%'}}}>
                        <GraphContainer sx={{alignItems: 'flex-end', flexFlow: 'column nowrap', gap: 0, m: 0, p: '5px', height: {xs:'200px', xl: '250px'}, justifyContent: 'space-evenly', width: periodOptions.length <= 1 ? '40%' : '100%'}}>
                            <Tabs
                                value={selectedTab1}
                                onChange={handleTabChange1}
                                TabIndicatorProps= {{sx: {display: 'none'}}}
                                sx={{p: '0.4rem'}}
                            >
                                <SmallRoundTab label={<Typography variant="caption" >Value</Typography>}/>
                                <SmallRoundTab label={<Typography variant="caption" >Percent</Typography>} />
                            </Tabs>

                            {selectedTab1 === 0 && (
                                <EfficiencyDistributionPerBinGraph value={0} api={efficiencyDistrData}/>
                            )}
                            {selectedTab1 === 1 && (
                                <EfficiencyDistributionPerBinGraph value={1} api={efficiencyDistrData}/>
                            )}               

                        </GraphContainer>
                        <GraphContainer sx={{ m: 0, height: periodOptions.length <= 1 ? null : {xs:'150px', lg:'100px', xl: '150px'}, width: periodOptions.length <= 1 ? '40%' : '100%'}}>
                            <EfficiencyScoresBoxPlot api={boxplotData}/>
                        </GraphContainer>
                    </Box>
                </GraphMainCard>
            </>
        )}
    </Box>
  )
}
