import React from 'react'
import { Box, Typography } from '@mui/material'
import { EditModalCards } from '../editModalContent'

export default function SegmentationSetupInfo({summaryData}) {
  return (
    <EditModalCards>
        <Box  sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="h6" color="initial">
                Segmentation Name:
            </Typography>
            <Typography variant="body2" color="initial">{summaryData?.segmentation_name}</Typography>
        </Box>
            <Typography variant="body2" color="gray">ID: {summaryData?.segmentation_scenario_id}</Typography>
    </EditModalCards>
  )
}
