import { useState, useEffect, createContext } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, styled, Typography, Tabs, Tab, Grid, Paper } from "@mui/material";
import MainUpperBar from "../../components/common/mainUpperBar";
import aggregatedIcon from "../../assets/icons/results/aggregatedIcon.svg";
import individualIcon from "../../assets/icons/results/individualIcon.svg";
import iconDatasetName from "../../assets/icons/datasets/Icon_Dataset_Name.svg";
import iconFileName from "../../assets/icons/datasets/Icon_File_Name.svg";
import iconRecords from "../../assets/icons/datasets/Icon_Records.svg";
import iconColumns from "../../assets/icons/datasets/Icon_Columns.svg";
import Overview from "./overview";
import EDA from "./eda";

import { get_new } from "../../utils/httpClient";
import { ProviderDataset } from "../../context/Dataset/ContextDataset";
import { numFormatInt } from "../../utils/numeralFormatter";

export const GridInformationContainer = styled(Grid)({
  padding: "10px 20px",
  backgroundColor: "white",
  marginTop: 10,
  marginBottom: 30,
  marginLeft: 0,
  width: "auto",
  justifyContent: "space-between",
  borderRadius: "5px",
});

export const GridInformationItem = styled(Grid)({
  padding: "0px !important",
});

export const TabsContainer = styled(Tabs)({
  backgroundColor: "#F5F5F5",
  border: "1px solid #E8E8E8;",
  width: "100%",
  borderRadius: "11px 11px 0 0",
});

const CustomTab = styled(Tab)({
  color: "#626262",
  minHeight: "54px",
  "&.Mui-selected": {
    color: "#FFFFFF",
    backgroundColor: "#47254B",
    borderRadius: "10px 10px 0 0",
    boxShadow: "4px 8px 4px rgba(0, 0, 0, 0.25)",
  },
});

export const DatasetContext = createContext();

export default function DatasetDetails() {
  // Local STATE
  const [annotatedID, setAnnotatedID] = useState(0);
  const [dataset, setDataset] = useState({
    dataset_id: null,
    info: {
      dataset_id: null,
      dataset_name: "",
      filename: "",
      rows: 0,
      columns: 0,
      creation_date: "",
      last_update: "",
    },
    variables: [],
    periods: [],
    projects: 0,
    segmentations: 0,
    benchmarks: 0,
  });
  const [state, setState] = useState({
    tabMain: 0,
    tabEDA: 0,
    filters: {
      period: "",
      variable: "",
      bivariate_vars: {
        x: "",
        y: "",
      },
    },
    summaryData: [],
    periods: [],
  });

  // URL PARAMS
  const [searchParams] = useSearchParams();
  const dataset_id = searchParams.get("dataset_id");

  const changeTabMain = (event, newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...{
          tabMain: newValue,
        },
      };
    });
  };

  // GET DATASET MAIN INFO
  useEffect(() => {
    if (!dataset_id) return;

    (async () => {
      let newDataset = {};
      newDataset.dataset_id = dataset_id;

      // Dataset name and file info
      let targetURL = `/datasets/?dataset_id=${dataset_id}`;
      await get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            console.log("Could not retrieve datasets");
            console.log("Response Error" + response.status);
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          newDataset.info = data.results[0];
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
        });

      // Dataset variables
      targetURL = `/dataset/dataset_config/?dataset_id=${dataset_id}`;
      await get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            console.log("Could not retrieve variables");
            console.log("Response Error" + response.status);
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.count === 0) return (newDataset.variables = []);
          newDataset.variables = data.results[0].datasetvariable;
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
        });

      // Dataset periods
      targetURL = `/dataset/periods_list/?dataset_id=${dataset_id}`;
      await get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            console.log("Could not retrieve periods");
            console.log("Response Error" + response.status);
            throw new Error(response.status + ": " + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.results.length === 0) {
            newDataset.periods = [];
            return;
          }
          // fix date format and sorting issue
          let periods = data.results;
          /*
						.map((row) => {
							const period = row.period.split('/');
							return [row.period, period[2] + period[0].padStart(2, '0') + period[1].padStart(2, '0')];
						})
						.sort((a, b) => a[1] - b[1])
						.map((row) => row[0]);
					*/
          newDataset.periods = periods;
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
        });

      // Number of projects and scenarios
      targetURL = "/projects/?dataset_id=" + dataset_id;
      await get_new(targetURL)
        .then((response) => {
          if (!response.ok) {
            console.log("Could not retrieve projects");
            console.log("Response Error" + response.status);
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          newDataset.projects = data.count;
          //count of segmentation scenarios
          let segmentations = data.results.map(
            (row) => row.segmentations_total
          );
          let totalSegmentations = segmentations.reduce((a, b) => a + b, 0);
          newDataset.segmentations = totalSegmentations;
          // count of benchmark scenarios
          let benchmarks = data.results.map((row) => row.benchmarks_total);
          let totalBenchmarks = benchmarks.reduce((a, b) => a + b, 0);
          newDataset.benchmarks = totalBenchmarks;
          return;
        })
        .catch((error) => {
          console.log("Error: " + error);
        });

      // Error control in case of failed fetch
      if (Object.keys(newDataset).length === 0) return;
      console.log("newDataset", newDataset);
      setDataset(newDataset);
    })();
  }, [dataset_id, annotatedID]);

  return (
    <>
      <ProviderDataset value={{ state, setState }}>
        <MainUpperBar UpperTitle={"Datasets"} />
        <Typography fontSize="1.5rem" fontWeight="bold">
          {dataset.info.dataset_name}
        </Typography>

        <GridInformationContainer container component={Paper}>
          <GridInformationItem item>
            <Grid container sx={{ gap: "6px" }}>
              <Grid item sx={{ alignSelf: "center" }}>
                <Box
                  component="img"
                  sx={{ display: "block" }}
                  src={iconFileName}
                />
              </Grid>
              <Grid item>
                <Typography fontSize="0.8rem" marginBottom="2px">
                  File Name
                </Typography>
                <Typography fontSize="0.9rem">
                  {dataset.info.filename}
                </Typography>
              </Grid>
            </Grid>
          </GridInformationItem>
          <GridInformationItem item>
            <Grid container sx={{ gap: "6px" }}>
              <Grid item sx={{ alignSelf: "center" }}>
                <Box
                  component="img"
                  sx={{ display: "block" }}
                  src={iconRecords}
                />
              </Grid>
              <Grid item>
                <Typography fontSize="0.8rem" marginBottom="2px">
                  Records
                </Typography>
                <Typography fontSize="0.9rem">
                  {numFormatInt(dataset.info.rows)}
                </Typography>
              </Grid>
            </Grid>
          </GridInformationItem>
          <GridInformationItem item>
            <Grid container sx={{ gap: "6px" }}>
              <Grid item sx={{ alignSelf: "center" }}>
                <Box
                  component="img"
                  sx={{ display: "block" }}
                  src={iconColumns}
                />
              </Grid>
              <Grid item>
                <Typography fontSize="0.8rem" marginBottom="2px">
                  Columns
                </Typography>
                <Typography fontSize="0.9rem">
                  {numFormatInt(dataset.info.columns)}
                </Typography>
              </Grid>
            </Grid>
          </GridInformationItem>
          <GridInformationItem item></GridInformationItem>
          <GridInformationItem item>
            <Typography fontSize="0.8rem" marginBottom="2px">
              Created:
            </Typography>
            <Typography fontSize="0.8rem">
              {dataset.info.creation_date}
            </Typography>
          </GridInformationItem>
          <GridInformationItem item>
            <Typography fontSize="0.8rem" marginBottom="2px">
              Last Update:
            </Typography>
            <Typography fontSize="0.8rem">
              {dataset.info.last_update}
            </Typography>
          </GridInformationItem>
        </GridInformationContainer>

        <TabsContainer
          value={state.tabMain}
          onChange={changeTabMain}
          aria-label="icon position tabs example"
          TabIndicatorProps={{ sx: { display: "none" } }}
          variant="fullWidth"
          sx={{ width: "50%" }}
        >
          <CustomTab
            icon={<img src={aggregatedIcon} alt="" />}
            iconPosition="start"
            label="Overview"
          />
          <CustomTab
            icon={<img src={individualIcon} alt="" />}
            iconPosition="start"
            label="EDA"
          />
          {/*
					<CustomTab icon={<img src={metricsIcon} alt="" />} iconPosition="start" label="View" />
					*/}
        </TabsContainer>
        {/*
				<Box sx={{ display: 'flex', backgroundColor: '#fff', padding: '20px' }}>{state.tabMain === 0 && <Overview />}</Box>
				<Box sx={{ display: 'flex', backgroundColor: '#fff', padding: '20px' }}>{state.tabMain === 1 && <EDA />}</Box>
				*/}
        <Box sx={{ display: "flex", backgroundColor: "#fff", padding: "20px" }}>
          <DatasetContext.Provider
            value={{ dataset, state, setState, annotatedID, setAnnotatedID }}
          >
            {state.tabMain === 0 && <Overview />}
            {state.tabMain === 1 && <EDA />}
          </DatasetContext.Provider>
        </Box>
      </ProviderDataset>
    </>
  );
}
