import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DetailsContainer,
  DetailsTable,
} from "../../components/common/commonContainer";
import EmptyCardMessage from "../../components/common/emptyCardMessage";
import FilterSidebar from "../../components/common/filtersSidebar";
import MainUpperBar from "../../components/common/mainUpperBar";
import { get_new } from "../../utils/httpClient";
import HomeProject from "../home/homeProject";
export default function ProjectsHome() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:1536px)");

  //Project HANDLE TABLE PAGINATION

  const [projectsPage, setProjectsPage] = React.useState(0);
  const [projectsRowsPerPage, setProjectsRowsPerPage] = React.useState(
    isSmallScreen ? 4 : 8
  );

  const rowsPerPageOptions = isSmallScreen ? [4, 8, 12] : [4, 8, 12, 16];

  //Searchbar POROJECTS Value handler

  const handleProjectsChangePage = (event, newPage) => {
    setProjectsPage(newPage);
  };
  const handleProjectsChangeRowsPerPage = (event) => {
    setProjectsRowsPerPage(parseInt(event.target.value, 10));
    setProjectsPage(0);
  };

  useEffect(() => {
    const newDefaultRowsPerPage = isSmallScreen ? 4 : 8;
    setProjectsRowsPerPage(newDefaultRowsPerPage);
  }, [isSmallScreen]);

  // const { filterFields, setFilterFields } = useSearchProjectsStore();

  const [filterFields, setFilterFields] = useState(() => {
    const savedFilters = sessionStorage.getItem("filterFields");
    if (savedFilters) {
      return JSON.parse(savedFilters);
    } else {
      return [
        {
          key: "searchProject",
          title: "Search Project",
          value: "",
          label: "Search Project",
          name: "searchProject",
          type: "text",
        },
        {
          key: "status",
          title: "Status",
          label: "Status",
          name: "status",
          type: "select",
          placeHolder: "",
          value: "",
          options: [
            { label: "All", value: "" },
            { label: "New", value: "new" },
            { label: "Setup", value: "setup" },
            { label: "Processing", value: "processing" },
            { label: "Completed", value: "completed" },
            { label: "Published", value: "published" },
          ],
        },
      ];
    }
  });

  useEffect(() => {
    sessionStorage.setItem("filterFields", JSON.stringify(filterFields));
  }, [filterFields]);

  const [delayedSearchTerm, setDelayedSearchTerm] = useState("");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDelayedSearchTerm(filterFields[0].value);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterFields[0].value]);

  const getProjects = () => {
    setLoading(true);
    setError(null); // Clear any previous errors

    get_new(
      `/projects/?page_size=${projectsRowsPerPage}&page=${
        projectsPage + 1
      }&search=${delayedSearchTerm}&status=${filterFields[1].value}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Could not retrieve projects");
          console.log("Response Error" + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // console.log('Projects List');
        // console.log(data);
        // console.log(data.results);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: " + error);
        //TODO: Show user error that data couldn't be retrieved
        setError(error); // Set the error state
        setLoading(false);
      });
  };

  useEffect(() => {
    getProjects();
  }, [
    projectsRowsPerPage,
    projectsPage,
    filterFields[1].value,
    delayedSearchTerm,
  ]);

  return (
    <Box>
      <MainUpperBar UpperTitle={"Projects"} backLink={"/"} />
      <DetailsContainer>
        <FilterSidebar
          filteredData={data.results}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
        />

        <DetailsTable
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? ( // Render error component when error exists
            <div>Error: {error.message}</div>
          ) : data.count > 0 ? (
            <HomeProject
              section={false}
              filteredData={data.results}
              page={projectsPage}
              count={data.count}
              rowsPerPage={projectsRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageChange={handleProjectsChangePage}
              onRowsPerPageChange={handleProjectsChangeRowsPerPage}
            />
          ) : (
            <EmptyCardMessage text1={"Results"} text2={"Projects"} />
          )}
        </DetailsTable>
      </DetailsContainer>
    </Box>
  );
}
