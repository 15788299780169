import React from "react";
import { Chart as ChartJS, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import colors from "../../../assets/variables.scss";
import { numeralFormatter } from "../../../utils/numeralFormatter";

ChartJS.register(BarElement);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Efficiency",
      color: "#4c4c4d",
      font: {
        size: 14,
        weight: "bold",
        lineHeight: 1.2,
      },
    },
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "end",
      align: "top",
      formatter: (value) => {
        return `${numeralFormatter(value)}%`;
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y.toFixed(2) + "%";
          }
          return label;
        },
      },
    },
  },
  responsive: false,
  scales: {
    x: {
      stacked: true,
      title: {
        display: false,
        text: "Current",
        color: "#4c4c4d",
        font: {
          size: 10,
          weight: "bold",
          lineHeight: 1.2,
        },
      },
    },
    y: {
      grace: "30%",
      display: false,
      stacked: true,
    },
  },
};

export default function EfficiencyHistogramComparison({ periodEfficiencyApi }) {
  const labels = periodEfficiencyApi.map((value) => value?.results[0]?.dmu_id);
  const data = {
    labels,
    datasets: [
      {
        label: "Entities",
        data: periodEfficiencyApi.map(
          (value) => value?.results[0]?.efficiency * 100
        ),
        backgroundColor: [
          colors.entity1,
          colors.entity2,
          colors.entity3,
          colors.entity4,
          colors.entity5,
        ],
      },
    ],
  };
  return <Bar options={options} data={data} height={200} />;
}
