import { get_new } from "../../../../../utils/httpClient"

export const getBenchmarkData = ({ benchmark_id }) => {
  const url = `/project/benchmark/?benchmark_id=${benchmark_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results[0])
  .catch((error) => {
    console.error('Error fetching benchmark data:', error);
    throw error;
  })
};


export const getPeriodOptions = ({ project_id, benchmark_id }) => {
    const url = `/project/dataset-periods/?benchmark__project_id=${project_id}&benchmark_id=${benchmark_id}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
        const options = data.count > 0 ? data?.results?.map(p => 
            ({ label: p.period, value: p.period }))
            : 
            [{ label: 'All', value: 'All' }];
        return options
    })
    .catch((error) => {
      console.error('Error fetching benchmark data:', error);
      throw error;
    })
  };

  export const getSegmentOptions = ({ project_id, benchmark_id }) => {
    const url = `/project/benchmark/results/dmu/efficiency/?project_id=${project_id}&benchmark_id=${benchmark_id}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
        const options = [...new Set(data.results.map(p => p.cluster_cd))].sort((a, b) => a - b).map(p => ({ label: p, value: p }));
        options.unshift({ label: "All", value: "" });
        return options.filter(v=>v.value !== null)
    })
    .catch((error) => {
      console.error('Error fetching benchmark data:', error);
      throw error;
    })
  };