import React from 'react'
import { MainCard } from '../../common/commonContainer'
import { Box, Typography } from '@mui/material'
import { KnowledgeCardsContent } from './knowledgeBase/knowledgeBaseContent'


export default function InputOutputMetrics() {
  return (
    <MainCard >
    <Box sx={{display:'flex', p:'0 25px', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
      <Typography variant="subtitle2" color="initial">Input & Output Metrics</Typography>
      <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
        <Typography variant="subtitle2" color="initial">Last Update</Typography>
        <Typography variant="caption" color="#777777">21/05/2023</Typography>
      </Box>
    </Box>
    <KnowledgeCardsContent>
      <Typography variant="body2" color="initial">
        Inputs and Outputs play a crucial role in assessing the performance and efficiency of the entities. The following information helps explain what constitutes inputs and outputs within the context of benchmark. Let's delve into it:
      </Typography>
      <Typography variant="body2" color="initial">
        <strong>Inputs:</strong> Inputs represent the resources or factors that are utilized or consumed in a production or service process. They are the resources that an entity needs to allocate in order to generate outputs. Inputs are generally the resources used or required in a process. For example, the number of employees can be considered an input as it represents a resource allocated by the entity. Other examples of inputs could include raw materials, capital, energy, labor hours, or any other resource required for the production or service process.
      </Typography>
      <Typography variant="body2" color="initial">
        <strong>Outputs:</strong> Outputs refer to the outcomes or results generated by an entity as a result of utilizing the inputs. They represent the products, services, or outcomes produced or delivered by the entity. An example of output It’s the profits, which represents the financial outcome of the analyzed process. Outputs can also include physical goods, services provided, sales revenue, customer satisfaction, or any measurable outcome that is produced or achieved by the entity.
      </Typography>
      <Typography variant="body2" color="initial">
        It is important to categorize performance measures or metrics into inputs and outputs accurately when applying benchmark. Inputs are generally pursued to be minimized, while outputs are pursued to be maximized as indicators of improved performance or efficiency. This rule of minimizing inputs and maximizing outputs serves as a guiding principle for categorizing factors as inputs or outputs.
      </Typography>
      <Typography variant="body2" color="initial">
        However, there can be exceptions to this general rule. In some cases, certain factors may be considered as outputs, but higher levels of those factors indicate poorer performance. Pollutants generated by a production process as an example. While they are considered outputs, higher levels of pollutants signify negative externalities and indicate poorer performance. In such cases, specialized treatment exist to handle these undesirable outputs and provide a more accurate assessment of performance.
      </Typography>
    </KnowledgeCardsContent>
  </MainCard>
  )
}
