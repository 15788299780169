import { CircularProgress } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import EmptyCardMessage from '../../../components/common/emptyCardMessage';
import VariableContent from './variableContent';
import { get_new } from '../../../utils/httpClient';
import { useEffect } from 'react';

export default function Variable({segmentation_scenario_id, segment, period, metricObj}) {
  const [loading, setLoading] = useState(false);
  const [api, setApi] = useState([])
  useEffect(()=> {
    setLoading(true);
    get_new(`/project/segmentation/results/metrics/variable/?segmentation_scenario_id=${segmentation_scenario_id}&cluster_cd=${segment}&variable=${metricObj}&period_id=${period}`)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      setApi(data)
      setLoading(false)
    })
    .catch((error) => {
      console.log('Error: ' + error);
      // TODO: Show user error that data couldn't be retrieved
    });
  }, [segmentation_scenario_id, segment, metricObj, period])
  return (
    <div>
      {loading ? <CircularProgress /> : api?.count > 0 ? <VariableContent api={api} segment={segment} /> : <EmptyCardMessage text1={'Variable Results'} text2={'Variable'} />}
    </div>
  )
}
  