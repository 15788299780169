import React, { useEffect, useState, useContext } from 'react';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
    TextField,
    Autocomplete,
	Button,
	Stepper,
	Step,
	StepLabel,
    FormControlLabel,
    Switch,
	Radio,
    RadioGroup,
    Slider,
	Box,
	Typography,
	Chip,
    Grid,
    Divider,
    
} from '@mui/material';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { ProjectContext } from '../../../../../context/Project/ContextProject';
import { get_new, post_json } from '../../../../../utils/httpClient';
import { SegmentationScenariosContext } from '../../segmentationScenarios';



export default function AddSegmentation({ open = true, onClose = null}) {
    // COMPONENT STATE
    const [formData, setFormData] = useState({
        project_id: '',
		name: '',
		description: '',
		method: '',
        number_clusters: '',
        variable: null,
        variables: [],
        outliers: false,
        numClusters: 3,
        action: '',
    });
    const [variableList, setVariableList] = useState([]);
    const [catVarList, setCatVarList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState('');
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingRun, setLoadingRun] = useState(false);
    
    // CONTEXT STATE
    const { projectData, datasetData } = useContext(ProjectContext);
    const { setCreatedID } = useContext(SegmentationScenariosContext);

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();


    /* 
    Get List of variables label and id from datasetData 
    label= original_field_name, use alternative_field_name if available 
    id=variable_detail_id
    exclude records with:
    - field_type='Entity ID'
    - field_type='Entity Name'
    - field_type='Period'
    */
    const getVariablesList = (categorical=false) => {
        //console.log("Processing Dataset data");
        //console.log("categorical only: " + categorical);
        //console.log(datasetData);
        const variablesList = [];
        datasetData.variables.forEach((variable) => {
            if (variable.field_type !== 'Entity ID' && variable.field_type !== 'Entity Name' && variable.field_type !== 'Period') {
                if ((categorical && variable.var_type === 'categorical') || (!categorical)){
                    variablesList.push({
                        label: variable.alternative_field_name ? variable.alternative_field_name : variable.original_field_name,
                        id: variable.variable_detail_id,
                    });
                }
            }
        });
        //console.log(variablesList);
        return variablesList;
    };


    useEffect(() => {
        setVariableList(getVariablesList());
        setCatVarList(getVariablesList(true));
        setFormData(formData => {
            return {
            ...formData,
            ...{
                project_id: projectData.project_id,
                },
            };
        });
        // eslint-disable-next-line
    }, [projectData, datasetData]);


    /*Update form data*/
    /*TODO: Check if name format is valid*/
    /*TODO: Check if name already exists*/
    const changeFormData = (name, newValue) => {
		const newForm = {
			...formData,
			...{
				[name]: newValue,
			},
		};
        
        setFormData(newForm);
        //console.log("ChangeForm: " + name + "=" + newValue)
        //console.log(newForm);
        
	};

    /*Close dialog*/
    const handleCloseDialog = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    function handleButtonSpinners(action, enable) {
        if (action==='save' && enable) {
            setLoadingSave(true);        
        } else if (action==='save' && !enable) {
            setLoadingSave(false);
        } else if (action==='run' && enable) {
            setLoadingRun(true);
        } else if (action==='run' && !enable) {
            setLoadingRun(false);
        }
        
    }

    const handleCreate = async (action) => {
        if (!loadingSave || !loadingRun) {

            handleButtonSpinners(action, true);

            /*Create data object to send*/
            let segmentationVariable = [];
            if (formData.method === 'variable') {
                segmentationVariable = [formData.variable.id];
            } else if (formData.method === 'cluster') {
                formData.variables.forEach((variable) => {
                    segmentationVariable.push(variable.id);
                });
            } else {
                console.log('Error: Invalid segmentation method');
                return;
            }

            let data = {
                'project': formData.project_id,
                'segmentation_name': formData.name,
                'segmentation_desc': formData.description,
                'segmentation_method': formData.method,
                'number_of_clusters': formData.numClusters,
                'cluster_base_period': '',
                'segmentationvariable': segmentationVariable,
                'action': action,
            }
            //console.log('payload: ', data);

            /*Target URL for posting form*/
            const targetURL = '/project/segmentation/';      
            
            post_json(targetURL, data)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 409) {
                        console.log('Raise 409 error ');
                        throw new Error('Project name already exists');
                    }
                    else if (!response.ok) {
                        console.log('Error' + response.status );
                        throw new Error('Could not create project');
                    }
                    return response.json();

                    
                })
                .then((data) => {
                    console.log('Scenario successfully created');
                    console.log("New scenario ID :" + data.segmentation_scenario_id)
                    enqueueSnackbar('Successfully added scenario: ' + formData.name, { variant: 'success' });
                    setCreatedID(data.segmentation_scenario_id);
                    handleButtonSpinners(action, false);
                    handleCloseDialog();
                })
                .catch((error) => {
                    console.log('Create project failed: ', error);
                    handleButtonSpinners(action, false);
                });
        }
    };

	const prevStep = () => {
		setActiveStep(activeStep - 1);
	};

    const nextStep = () => {
        if (activeStep === 0) {
            checkName().then((result_check_name) => {
                if (result_check_name) {
                    return;
                }
                setActiveStep(activeStep + 1);
            });
        }
        else {
            setActiveStep(activeStep + 1);
        }
	};

    const isDisabled = () => {
        if (activeStep === 0) {
            return formData.name === '';
        }
        else if (activeStep === 1) {
            return formData.method === '';
        }
        else if (activeStep === 2) {
            if (formData.method === 'variable') {
                return formData.variable === null;
            }
            else if (formData.method === 'cluster') {
                return formData.variables.length === 0;
            }
        }
    };

    async function checkName(){
        
        if (formData.name === '') return;
        // Check if name already exists
        
        const targetURL = '/project/segmentation/?project_id=' + formData.project_id +
            '&segmentation_name=' + formData.name;
        
        //console.log(targetURL);

        let bad_name= await get_new(targetURL)
            .then((response) => {
                if (!response.ok) {
                    console.log('Error' + response.status );
                    throw new Error('Could not verify name');
                }
                return response.json();
            })
            .then((data) => {
                if (data.count > 0) {
                    console.log('Raise 409 error ');
                    console.log('Scenario name already exists');
                    setNameError(true);
                    setNameHelperText('Scenario name already exists');
                    return true;
                }
                console.log('Scenario name is valid');
                return false;

            })
            .catch((error) => {
                console.log('Name verification failed: ', error);
                return false;
            })

        //console.log("Bad name: " + bad_name);
        return bad_name;
    }; 

    
        
    

    return (
        <>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { mt: "50px" } }}
                fullWidth
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle style={{ background: '#47254b', color: 'white' }}>
                    Create Segmentation Scenario
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
				<DialogContent style={{ marginTop: '0px' }} dividers>
					<Stepper activeStep={activeStep} alternativeLabel>
						<Step key="General">
							<StepLabel>General</StepLabel>
						</Step>
						<Step key="Segmentation">
							<StepLabel>Method</StepLabel>
                        </Step>
                        <Step key="Variables">
                            <StepLabel>Variables</StepLabel>
                        </Step>
						<Step key="Summary">
							<StepLabel>Summary</StepLabel>
						</Step>
					</Stepper>
					<br />
					<br />
					{activeStep === 0 && (
						<Box sx={{mx:2}}>
                            <div>Add a name and short description that can help identify this scenario</div>
                            <div style={{ fontSize: 12 }}>
                                Scenarios represent unique configurations of settings. Adding some of these
                                details to the description can help identify the scenario later.
                            </div>
                            <br/>
                            <TextField
                                id='name'
                                label="Name"
                                size="small"
                                fullWidth
                                variant="outlined"
                                required
                                error={nameError}
                                helperText={nameHelperText}
                                inputProps={{ maxLength: 500 }}
                                value={formData.name}
                                onChange={(event) => {
                                    if (nameError) {
                                        setNameError(false);
                                        setNameHelperText('');
                                    }
                                    changeFormData('name', event.target.value)
                                }} />
                            <br/>
                            <br/>
							<TextField
                                id='description'
                                label="Description"
                                size="small"
                                fullWidth
                                variant="outlined"
                                inputProps={{maxLength:500}}
                                multiline rows={4}
                                value={formData.description}
                                onChange={(event) => changeFormData('description', event.target.value)}
                            />
						</Box>
					)}
					{activeStep === 1 && (
                        <Box sx={{mx:2}}>
                            <div>Select segmentation method:</div>
                            <div style={{ fontSize: 12 }}>
                                The method determines how the entities in the dataset will be grouped into segments.
                            </div>
                            <br />
							<FormControl>
                                <RadioGroup
                                    value={formData.method}
                                    onChange={(event) => changeFormData('method', event.target.value)}>
                                    <FormControlLabel value="variable" control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Use Dataset field</b></div> 
                                            <div style={{fontSize: 12}}>
                                                    Segments are taken from an existing categorical field in the dataset. <br />
                                                    No additional processing is applied.
                                            </div>
                                        </div>   
                                        } />
                                    
                                    <br />
                                    <FormControlLabel value="cluster" control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Create Clusters</b></div> 
                                            <div style={{fontSize: 12}}>
                                                    Create Segments by clustering the unites using one or more fields in the dataset. <br />
                                                    Clustering is performed using k-means.
                                            </div>
                                        </div>   
                                        }
                                        
                                    />
								</RadioGroup>
							</FormControl>
							
						</Box>
                    )}
                    {activeStep === 2 && (
                        <Box sx={{mx:2}}>
                            {formData.method === 'variable' && (
                                <>
                                    <div>Select the dataset variable to use for segmentation:</div>
                                    <div style={{ fontSize: 12 }}>
                                        Segments will be created based on the unique values in the selected variable. This option
                                        uses categorical variables, if you don't see the variable you want, try changing the variable type
                                        to categorical in the dataset.
                                    </div>
                                    <br />
                                    <br />
                                <Box sx={{ maxWidth: '50%' }}>
                                        <Autocomplete
                                            id='singleMetric'
                                            size='small'
                                            //sx={{ width: '450px' }}
                                            required
                                            selectOnFocus
                                            clearOnBlur
                                            clearOnEscape
                                            autoComplete
                                            autoHighlight
                                            disabled={catVarList.length === 0}
                                            options={catVarList}
                                            getOptionLabel={(option) => option.label}
                                            value={formData.variable}
                                            onChange={(event, newValue) => {
                                                changeFormData('variable', newValue);
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                helperText={catVarList.length === 0 ? 'No categorical variables available' : ''}
                                                label="Dataset Variables"
                                                required />}
                                        />

									
                                    </Box>
                                    
                            </>
							)}

                            {formData.method === 'cluster' && (
                                <>
                                    <Box sx={{mx:2}}>
                                    <div>Configure clustering inputs:</div>
                                    <div style={{ fontSize: 12 }}>
                                        Units will be segmented using k-means clustering unsupervised ML. You can choose up to 10 clusters. 
                                        Extreme values in the clustering variables may affect the cluster assignments of other units.
                                        Choose 'Treat outliers' to apply special consideration to these values during clustering calculations. 
                                
                                    </div>
                                        <br />
                                        <br />
                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
								<Box>
                                    <Autocomplete
                                        multiple
                                        id='multiMetrics'
                                        size='small'
                                        //sx = {{width: '450px'}}
                                        required
                                        selectOnFocus
                                        clearOnBlur
                                        clearOnEscape
                                        autoComplete
                                        autoHighlight
                                        options={variableList}
                                        getOptionLabel={(option) => option.label}
                                        value={formData.variables}
                                        onChange={(event, newValue) => {
                                            changeFormData('variables', newValue);
                                    }}
                                        renderInput={(params) => <TextField {...params} label="Clustering Variables" required />}
                                    />
                                    </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box>
                                                Number of Clusters: {formData.numClusters}
                                                <br />
                                        <Slider
                                                    aria-label="Number of Clusters"
                                                    size='small'
                                                    sx={{ width: '50%'}}
                                            defaultValue={3}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            min={1}
                                                    max={10}
                                                    value={formData.numClusters}
                                                    onChange={(event, newValue) => {
                                                        changeFormData('numClusters', newValue);
                                                    }}
                                            />
                                                <br />

                                                <FormControlLabel label="Treat outliers" 
                                                        control={<Switch
                                                        size='small'
                                                        checked={formData.outliers}
                                                        onChange={(event) => changeFormData('outliers', event.target.checked)}
                                                    />}
                                                />

                                                </Box>
                                        </Grid>
                                        
                                        
                                        
                                </Grid>
                                    </Box>
                                </>
							)}
                            </Box>
                    )}
					{activeStep === 3 && (
						<Box sx={{mx:2}}>
							<Typography sx={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Scenario configuration Summary:</Typography>
                            <Divider />
                            <Box sx={{ m:2, mx:2, fontSize:14}}>
                            <Box style={{display: 'flex', flexDirection: 'row'}} sx={{m:1}}>
                            <Box width={170}>
                                <div>Name:</div>
                            </Box>
                            <Box>
                                <div>{formData.name}</div>
                                </Box>
                            </Box>
                            <Box style={{display: 'flex', flexDirection: 'row'}} sx={{m:1}}>
                                <Box width={170}>
                                    <div>Description:</div>
                                </Box>
                                <Box>
                                    <div>{formData.description}</div>
                                </Box>
                            </Box>
                            <Box style={{display: 'flex', flexDirection: 'row'}} sx={{m:1}}>
                                <Box width={170}>
                                    <div>Method:</div>
                                </Box>
                                <Box>
                                    <div>{formData.method}</div>
                                </Box>
                            </Box>
                            {formData.method === 'variable' && (
                                <Box style={{display: 'flex', flexDirection: 'row'}} sx={{m:1}}>
                                    <Box width={170}>
                                        <div>Variable:</div>
                                    </Box>
                                    <Box>
                                        <div>{formData.variable.label}</div>
                                    </Box>
                                </Box>
                            )}
                            {formData.method === 'cluster' && (
                                <>

                                    <Box style={{ display: 'flex', flexDirection: 'row' }} sx={{m:1}}>
                                        <Box width={170}>
                                            <div>Variables:</div>
                                        </Box>
                                        <Box>
                                            
                                                    <Box sx={{ marginBottom: '3px', gap: '5px', display: 'flex', flexWrap: 'wrap'}}>
										                {formData.variables.map((variable) => (
                                                            <Chip sx={{fontSize:11}} size='small' label={variable.label} key={variable.id} color="primary" />
                                                        ))}
                                                        
									            </Box>
                                        </Box>
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'row' }} sx={{m:1}}>
                                        <Box width={170}>
                                            <div>Number of Clusters:</div>
                                        </Box>
                                        <Box>
                                            <div>{formData.numClusters}</div>
                                        </Box>
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'row' }} sx={{m:1}}>
                                        <Box width={170}>
                                            <div>Treat outliers:</div>
                                        </Box>
                                        <Box>
                                            <div>{formData.outliers ? 'Yes' : 'No'}</div>
                                        </Box>
                                    </Box>
                                </>
                            )}
                            </Box>
                            
						</Box>
					)}
				</DialogContent>
                <DialogActions>
					{activeStep > 0 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={loadingSave || loadingRun}
                            onClick={prevStep}
                        >Back</Button>
					)}
					{activeStep < 3 && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isDisabled()|| loadingSave || loadingRun}
                            onClick={nextStep}
                        >Next</Button>
					)}
					
                    {activeStep === 3 && (
                        <Box sx={{position: 'relative', ml:1}}>
                        <Button 
                            variant = "contained"
                                color="primary" 
                                disabled={loadingSave || loadingRun}
                            onClick={() => {handleCreate('save');}}
                        >Save</Button>
                        {loadingSave && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                            />
                            )}
                        </Box>
                    )}
                    {activeStep === 3 && (
                        <Box sx={{position: 'relative', ml:1}}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loadingSave || loadingRun}
                            onClick={() => { handleCreate('run') }}
                        >Run</Button>
                        {loadingRun && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                            />
                            )}
                            </Box>
					)}
				</DialogActions>
            </Dialog>
        </>
    )
}

