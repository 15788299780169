import React from 'react'
import { MainCard } from '../../common/commonContainer'
import { Box, Typography } from '@mui/material'
import { KnowledgeCardsContent } from './knowledgeBase/knowledgeBaseContent'
import radialTarget from '../../../assets/images/radialTarget.svg'
import nonRadialTarget from '../../../assets/images/nonRadialTarget.svg'


export default function TargetValues() {
  return (
    <MainCard >
        <Box sx={{display:'flex', p:'0 25px', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
          <Typography variant="subtitle2" color="initial">Target Values</Typography>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', gap: '5px'}}>
            <Typography variant="subtitle2" color="initial">Last Update</Typography>
            <Typography variant="caption" color="#777777">21/05/2023</Typography>
          </Box>
        </Box>
        <KnowledgeCardsContent sx={{alignItems: 'center'}}>
          <Typography variant="body2" color="initial">
            <strong>Radial Target:</strong> Radial efficiency measures, also known as envelopment DEA models, are referred to as "radial" because they assume that all inputs or outputs of a DMU can be optimized at a certain proportion. In other words, these models consider that the DMU's inefficiency can be entirely attributed to a single source—either inputs or outputs. The objective of a radial model is to find the proportional reduction required in both inputs and outputs for a DMU to achieve efficiency. These models assume that changes in inputs and outputs occur in a balanced manner.
            </Typography>
            <img src={radialTarget} alt="radialTarget" width="477" height="305" />
            <Typography variant="body2" color="initial">
              <strong>Non-radial Target:</strong> Non-radial DEA models, on the other hand, allow for non-proportional reductions in positive inputs or augmentations in positive outputs to assess efficiency. These models provide a more flexible approach by recognizing that inefficiencies in a DMU may not be solely due to a single factor. In contrast to radial models, non-radial models consider variations in the input-output ratio and allow for the possibility of adjusting inputs and outputs independently, without adhering to a proportional relationship.
            </Typography>
            <img src={nonRadialTarget} alt="nonRadialTarget" width="477" height="305"/>
            <Typography variant="body2" color="initial">
              Radial DEA models optimize all inputs or outputs at a certain proportion, assuming that inefficiency can be attributed to a single source. Non-radial DEA models, in contrast, accommodate non-proportional reductions in positive inputs or augmentations in positive outputs, acknowledging the possibility of independent adjustments in inputs and outputs. Non-radial models offer greater flexibility in capturing the diverse sources of inefficiency and allow for a more nuanced assessment of the performance of DMUs.
            </Typography>        
          </KnowledgeCardsContent>
      </MainCard>
  )
}
