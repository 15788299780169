import { enqueueSnackbar } from "notistack";
import { patch_json } from "../../../../../../utils/httpClient";

/*Handle create benchmark scenario */
export const handlePublishAction = async (action, summaryData) => {

    //Make copy of summaryData
    let data = JSON.parse(JSON.stringify(summaryData));
    data.action = action; // 'save' or 'publish'            
    console.log('action', action)
    console.log('Final Benchmark data', data);
    console.log('Final Benchmark Summary Data', summaryData);

    let publish = {}

    publish = {
        action: action,
        benchmark_id: summaryData.benchmark_id,
        status: 'published'
    }

    //Target URL for posting form
    const targetURL = '/project/benchmark/';
    patch_json(targetURL, publish)
    .then((response) => {
        console.log('response', response);
        if (!response.ok) {
            throw new Error(response.status + response.statusText);
        }
        return response.json();
    })
    .then((data) => {
            console.log('Benchmark Scenario created successfully');
            console.log(data);
            enqueueSnackbar('Successfully published scenario: ' + summaryData.benchmark_name.trim(), { variant: 'success' });
            // handleClose()
            setTimeout(() => {
                window.location.reload();
                }, 2000);
        
        return;
    })
    .catch((error)=>{
        enqueueSnackbar('Failed to publish Benchmark Scenario: ' + summaryData.benchmark_name.trim(), { variant: 'error' });
        console.log('Benchmark Scenario action failed');
        console.log(error)
        return 
    })
};