import React, { useState } from 'react';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import MainUpperBar from '../../../common/mainUpperBar';
import KnowledgeBaseContent from './knowledgeBaseContent';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export default function KnowledgeBase() {
  const navigate = useNavigate()
  
  const [open, setOpen] = useState(true);
  const [benchmarkOpen, setBenchmarkOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleBenchmarkClick = () => {
    setBenchmarkOpen(!benchmarkOpen);
  };

  return (
    <>
      <MainUpperBar UpperTitle={"Knowledge Base"} backLink={'/'} />
      <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
        <List
        sx={{ width: '25%', maxWidth: 360, position: 'sticky', top: 70, maxHeight: '60vh', overflowY: 'auto' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <Typography variant="subtitle2" color="initial">Topics</Typography>
        }
        >
        <ListItemButton onClick={handleClick}>
            <ListItemText primary={<Typography variant="subtitle2" color="initial">Segmentation Scenario</Typography>} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("about")}>
                <ListItemText primary="About" />
            </ListItemButton>
            </List>
        </Collapse>

        {/* Benchmark Scenario */}
        <ListItemButton onClick={handleBenchmarkClick}>
            <ListItemText primary={<Typography variant="subtitle2" color="initial">Benchmark Scenario</Typography>} />
            {benchmarkOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={benchmarkOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("inputOutputMetrics")}>
                <ListItemText primary="Input & Output Metrics" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("objective")}>
                <ListItemText primary="Objective" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("variableTreatment")}>
                <ListItemText primary="Variable Treatment" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("scale")}>
                <ListItemText primary="Scale" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("targetValues")}>
                <ListItemText primary="Target Values" />
            </ListItemButton>
            </List>
        </Collapse>
        </List>
        <KnowledgeBaseContent />
      </Box>
    </>
  );
}
