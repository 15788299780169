import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
    DialogTitle,
	Button,
	FormControlLabel,
	Radio,
    RadioGroup,
	Box,
    Grid
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function AddBenchmark({
    open = true,
    onClose = null,
    toggleCreateScenario = null,
    createMethod = 'wizard',
    setCreateMethod = null
}) {
    
    
    /*Close dialog*/
    const handleCloseDialog = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    
    return (
        <>
            {/*Use Dialog sx and PaperProps to position modal at the top*/}
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { mt: "50pt" } }}
                maxWidth='xs'
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle style={{ background: '#47254b', color: 'white' }}>
                    Create Benchmark Scenario
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            //bgcolor: 'white',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers >
                    <RadioGroup
                        value={createMethod}
                        onChange={(event) => setCreateMethod(event.target.value)}
                    >
                    <Box sx={{ width: '100%', flexGrow: 1 }}>
                        <Grid
                            container
                            rowSpacing={3}
                            margin={0}
                            padding={2}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                       
                        
                            {/*Options Wizard*/}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}
                                    padding={2}
                                >
                                    <Box>
                                <FormControlLabel value='wizard' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Wizard (Recommended) </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    Step-by-step guided process to select most settings required to 
                                                    configure a benchmark scenario
                                            </div>
                                        </div>   
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>
                            {/*Options Advanced*/}
                                <Grid
                                    item
                                    xs={12}
                                    padding={2}
                                    sx={{ 
                                        '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                    }}

                                >
                                <Box>
                                <FormControlLabel disabled value='manual' control={<Radio />}
                                        label={
                                         <div>
                                            <div> <b>Manual </b></div> 
                                            <div style={{fontSize: 12}}>
                                                    View all settings in one page. Recommended for advanced users that 
                                                    need minimum guidance. Provides quicker access to advanced settings. 
                                            </div>
                                        </div>   
                                        } />
                                    
                                    <br />
                                </Box>
                            </Grid>

                        
                        </Grid>
                        </Box>
                        </RadioGroup>
				</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="text">
						Cancel
					</Button>

                    <Button onClick={toggleCreateScenario} variant="contained" color="primary">
                        Start
                    </Button>
					
				</DialogActions>
            </Dialog>
        </>
    )

}