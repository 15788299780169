import { get_new } from "../../../../utils/httpClient";

export const getProjectData = ({ project_id }) => {
    const url = `/project/?project_id=${project_id}`;

    return get_new(url)
    .then((response) => {
        if(!response.ok) {
            console.log('Could not retrieve data');
            console.log('Response Error' + response.status);
            throw new Error(response.statusText);      
        }
        return response.json()
    })
    .then((data) => data.results[0])
    .catch((error) => {
        console.error('Error fetching project data:', error);
        throw error;
    })
}

export const getDatasetData = ({projectData}) => {
    const url = `/dataset/?dataset_id=${projectData.dataset_id}`

    return get_new(url)
    .then((response) => {
        if(!response.ok) {
            console.log('Could not retrieve data');
            console.log('Response Error' + response.status);
            throw new Error(response.statusText);      
        }
        return response.json()
    })
    .then((data) => data.results)
    .catch((error) => {
        console.error('Error fetching dataset data:', error);
        throw error;
    })
}

export const getDatasetNameData = ({ projectData }) => {
    const url = `/datasets/?dataset_id=${projectData.dataset_id}`;
    
    return get_new(url)
    .then((response) => {
        if (!response.ok) {
            console.log('Could not retrieve data');
            console.log('Response Error' + response.status);
            throw new Error(response.statusText);
        }
        return response.json();
    })
    .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching dataset name data:', error);
    throw error;
  })
};