import { Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { LabelGrid, ValueGrid } from '../manualSettings'
import { useContext } from 'react'
import EditBenchmarkContext from '../../context/editBenchmarkContext'
import { useEffect } from 'react'

export default function MultiPeriodManual({ setFormBenchmarkData, formBenchmarkData, changeFormBenchmarkData}) {
    const { periodData } = useContext(EditBenchmarkContext)

    useEffect(() => {
        const updatedForm = { ...formBenchmarkData };
        if (formBenchmarkData.multi_periods === 'single' || formBenchmarkData.apply_tracking_cycles === false) {
            updatedForm.apply_tracking_cycles = false
            updatedForm.benchmark_freq = '';
            updatedForm.benchmark_start_period = null;
            updatedForm.tracking_freq = '';
            updatedForm.include_next_baseline = false
        }
        if(formBenchmarkData.multi_periods === 'all') {
            updatedForm.single_baseline_period = ''
        }
        setFormBenchmarkData(updatedForm);
    }, [formBenchmarkData.multi_periods, formBenchmarkData.apply_tracking_cycles]);

  return (
    <>
        <Box width={'100%'} sx={{bgcolor:'#E1E5F2'}}><b>Period Information</b></Box>
        <Divider />
        <Grid container spacing={1} marginY={2} fontSize={12}>
            <LabelGrid> Single or Multiple periods: </LabelGrid>          
            <ValueGrid required={false}>

                <Box sx={{position:'relative'}}>
                    
                    <Box>
            <Grid container spacing={2} sx={{display: 'flex', flexFlow: 'row nowrap'}}>
                <Grid item xs={12} sm={5} md={4}  lg={3}>
                        
                    {/*Disable if Dataset doesn't have periods*/}
                    <RadioGroup
                        value={(formBenchmarkData.multi_periods)}
                        onChange={(event) => changeFormBenchmarkData({multi_periods: event.target.value})}
                        sx={{flexFlow: 'row'}}
                    >
                        <FormControlLabel
                            disabled={!periodData.has_periods}
                            value='single'
                            control={<Radio />} label='Just one' 
                        />
                        <FormControlLabel
                            disabled={!periodData.has_periods}
                            value='all'
                            control={<Radio />} label='All' 
                        />
                        
                    </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ minWidth: 60, maxWidth:120}}>
                        <FormControl fullWidth disabled={formBenchmarkData.multi_periods!=='single' 
                        || !periodData.has_periods
                    }
                        >
                            <InputLabel id="singlePeriod-Label">Period</InputLabel>
                            {/*Disable if Dataset doesn't have periods*/}
                            <Select
                                size='small'
                                id="singlePeriod"
                                labelId="singlePeriodLabel"
                                label='Period'
                                value={formBenchmarkData?.single_baseline_period ?? ''}
                                onChange={(event) => changeFormBenchmarkData({single_baseline_period: event.target.value})}
                            >   
                                {(periodData.has_periods) && (
                                    periodData.periods_list.map((period) => { 
                                        return <MenuItem key={period.period_id} value={period.period_id}>{period.date_string}</MenuItem>
                                    })
                                )}
            
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                    <Grid item xs={12}>
                    <Box >
                        <FormControl>
                            <FormControlLabel
                                value={formBenchmarkData.apply_restricted}
                                label='Enable Tracking Cycles'
                                labelPlacement='end'
                                control={
                                    <Switch
                                        disabled={formBenchmarkData.multi_periods!=='all' || !periodData.has_periods}
                                        checked={formBenchmarkData.apply_tracking_cycles}
                                        onChange={(event) => {
                                            // handleDisableTabs(!event.target.checked);
                                            changeFormBenchmarkData({apply_tracking_cycles: event.target.checked});
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Box>
                </Grid>
            
                    </Grid>
                    </Box>
                    
                    {/*Superpose a message in case periods are not available*/}
                    {/* {!periodData.has_periods && (<>
                        <Box  sx={{
                            bgcolor: 'white',
                            border: '1px solid',
                            borderColor: 'grey.500',
                            borderRadius: '5px',
                        zIndex: 'tooltip',
                            position: 'absolute',
                            minHeight: '40%',
                        //height: '40%',
                            top: '20%',
                        left: '10%',

                            width: '80%',
                            justifySelf: 'center',
                            padding: '10px',
                        boxShadow: 2,

                    }}>
                            <Box sx={{width: '100%', mb: 1, textAlign: 'center',}} >
                            <b> This dataset doesn't have period information</b></Box>
                            <div style={{ fontSize: 12 }}>
                                If the dataset should have multiple periods, navigate to the dataset section,
                                make sure the 'Period' field has been properly annotated and restart this wizard.
                                <br/>
                                <Link to={'/datasetDetails?dataset_id=' + dataset_id}>
                                    Go to Dataset
                                </Link>
                                <br /> <br />
                                
                               You can still create a benchmark scenario without period data, all the data will be used as
                                a single period.
                            </div>
                        </Box>
                        <Box sx={{
                        bgcolor: 'white',
                        zIndex: 'modal',
                        position: 'absolute',
                        height: '90%',
                        top: '10%',
                        left: '0%',
                        width: '100%',
                        opacity: 0.4,
                        p: 0,
                        m: 0
                    }}>
                        </Box>
                    </>)} */}
                </Box>               
            </ValueGrid>
        </Grid>
    </>
  )
}
