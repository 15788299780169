import { get_new } from "../../../../utils/httpClient";

export const GetBenchmarkScenarios = ({ projectData, rowsPerPage, page, delayedBenchmarkTerm }) => {
    const url = `/project/benchmarks/?project_id=${projectData.project_id}&page_size=${rowsPerPage}&page=${page + 1}&search=${delayedBenchmarkTerm}`;

    return get_new(url)
    .then((response) => {
        if(!response.ok) {
            console.log('Could not retrieve data');
            console.log('Response Error' + response.status);
            throw new Error(response.statusText);      
        }
        return response.json()
    })
    .then((data) => data)
    .catch((error) => {
        console.error('Error fetching Benchmark Scenarios data:', error);
        throw error;
    })
}