import React from 'react'
import { ShadowCardContentContainer } from '../../../components/common/commonContainer'
import { Box, Divider, IconButton, List, ListItem, Tooltip, Typography } from '@mui/material'
import SegmentationEntitiesBySegment from '../../../graphs/segmentationResults/segmentationEntitiesBySegment'
import { useState } from 'react'
import { useEffect } from 'react'
import { get_new } from '../../../utils/httpClient'
import { InterClusterScatter } from '../../../graphs/segmentationResults/interClusterScatter'
import Table1 from './table1'
import { InfoOutlined } from '@mui/icons-material'
import { numeralFormatter } from '../../../utils/numeralFormatter'

export default function Summary({project_id, variables, period, segment, segmentation_scenario_id, segmentationMethod}) {
    const [api, setApi] = useState([])
    useEffect(() => {
        if(!period && period !== '') return ;
		get_new(`/project/segmentation/results/?project_id=${project_id}&segmentation_scenario_id=${segmentation_scenario_id}&period_id=${period}&cluster_cd=${segment}`)
			.then((response) => {
			if (!response.ok) {
					console.log('Could not retrieve data');
					console.log('Response Error' + response.status);
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((data) => {
				setApi(data);	
			})
			.catch((error) => {
			console.log('Error: ' + error);
			// TODO: Show user error that data couldn't be retrieved
		});
	}, [project_id, segmentation_scenario_id, segment, period]);

    // function calculateAverage(array, propName) {
    //     const sum = array?.reduce((acc, obj) => acc + obj?.segmentation_scenario[propName], 0);
    //     const average = sum / array?.length;
    //     return isNaN(average) ? 0 : average.toFixed(1);
    //   }

      const tooltipText = <div>
            <List>
                <ListItem>
                    <Typography variant="caption" color="white">
                        MDS 1: MDS 1 meaning
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="caption" color="white">
                        MDS 2: MDS 2 meaning
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="caption" color="white">
                        Distance: distance meaning
                    </Typography>
                </ListItem>
            </List>
        </div>;
  return (
    <Box sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-around',
        flexGrow: '0',
        }}
    >

        <Box sx={{width: {xs: '90%',lg:'35%', xl: '40%'}}}>
            <ShadowCardContentContainer  
                sx={{
                flexFlow: 'column',
                alignItems: 'flex-start',
                flexGrow: '0',
                justifyContent: 'center',
                p: '1rem 0',
                m: '0.5rem 0'
                }}>
                <Box sx={{display: 'flex', gap: '50px', flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%', flexFlow: {xs: 'column', md:'row wrap'}}}>
                    <SegmentationEntitiesBySegment api={api} period={period} segmentation_scenario_id={segmentation_scenario_id} project_id={project_id} segment={segment} />
                </Box>
            </ShadowCardContentContainer>
                { 
                    segmentationMethod?.toLowerCase() === 'cluster' 
                    ?                     
                        <ShadowCardContentContainer  
                            sx={{
                            flexFlow: 'column',
                            alignItems: 'flex-end',
                            flexGrow: '0',
                            justifyContent: 'center',
                            p: '1rem 0px',
                            m: '0.5rem 0', 
                            width: '100%'
                            }}>
                            <ShadowCardContentContainer 
                                sx={{    
                                alignItems: 'center',
                                gap: '0px',
                                padding: 0,
                                m: '10px',
                                flexFlow: 'row nowrap',
                                width: '-webkit-fill-available'
                                }}
                            >
                                <Box sx={{display: 'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                                    <Typography variant="h5" color="initial">{numeralFormatter(api?.results?.[0]?.segxkmeanssegmentation?.[0]?.silhouette_score)}</Typography>
                                    <Typography variant="body2" color="initial" textAlign={'center'}>Silhoutte Score</Typography>                  
                                </Box>        
                                <Divider variant="middle" orientation="vertical" flexItem/>
                                        
                                <Box sx={{display:'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                                    <Typography variant="h5" color="initial">{numeralFormatter(api?.results?.[0].segxkmeanssegmentation?.[0]?.calinski_harabasz)}</Typography>
                                    <Typography variant="body2" color="initial" textAlign={'center'} nowrap="true">Calinski harabasz</Typography>                  
                                </Box>
                                <Divider variant="middle" orientation="vertical" flexItem/>
                                <Box sx={{display:'flex', flexFlow: 'column', flex: 1, alignItems: 'center'}}>
                                    <Typography variant="h5" color="initial">{numeralFormatter(api?.results?.[0].segxkmeanssegmentation?.[0]?.adj_rsq * 100)}%</Typography>
                                    <Typography variant="body2" color="initial" textAlign={'center'}>Adj rsq</Typography>
                                </Box>
                            </ShadowCardContentContainer>
                            <Tooltip title={tooltipText} sx={{width: 'fit-content'}}>
                                <IconButton>
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                            <InterClusterScatter api={api} project_id={project_id} segment={segment} segmentation_scenario_id={segmentation_scenario_id} period={period}/> 
                        </ShadowCardContentContainer>
                    : null
                }

        </Box>
        <Box sx={{width: {xs: '90%',lg:'550px', xl: '50%'}, display: 'flex', flexFlow: 'column', mt: '1rem', gap: '20px'}}>
            <Table1 period={period} variables={variables} segment={segment} segmentation_scenario_id={segmentation_scenario_id} />
        </Box>
    </Box>
  )
}
