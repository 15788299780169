import { Box, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import React, { useState, useEffect } from 'react'
import IndividualhighLevelAndEntities from './individualResults/highLevelAndEntities'
import FilterSidebar from '../../../components/common/filtersSidebar'
import EfficiencyTimeSeriesAndDistribution from './individualResults/efficiencyTimeSeriesAndDistribution'
import InputOutputTargets from './individualResults/inputOutputTargets'
import { getApiData, getEntityFilterData, getPeriodFilterData, getPeriodObjectData } from './individualResults/services/mainDashboardServices'
import { MainCard } from '../../../components/common/commonContainer'
import { useContext } from 'react'
import ResultDashboardContext from './context/resultDashboardContext'


export const TargetContainer = styled(Box)({
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    border: '0.47px solid #C4C4C4',
    width: 'fit-content',
    height: 'fit-content',
    boxShadow: 'none',
    padding: '0px 20px',
    margin: '10px 0',
    flexGrow: '0',
    borderRadius: '6px',
})

export default function IndividualResultsDashboard({benchmark_id, project_id, apply_segmentation, projectType}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)
  //Context
  const { frontierEntityValue, setFrontierEntityValue } = useContext(ResultDashboardContext);

  //DATA STATES HANDLERS
  const [api, setApi] = useState([])
  const [periodObject, setPeriodObject] = useState([])
  const [filterFields, setFilterFields] = useState([
    {
      key: 'period',
      title: "Period",
      label: "",
      name: "Period",
      value: "",
      type: "select",
      options: [
        { label: "", value: "" },
      ]
    },
      {
        key: 'entity',
        title: "Entity",
        label: "",
        name: "entity",
        value: "",
        type: "select",
        options: [
          { label: "", value: "" },
        ]
      },    


      {
        key: 'orient',
        title: "Orient",
        label: "",
        name: "Orient",
        value: "",
        type: "",
        options: [
          { label: "Input", value: "input" },
          { label: "Output", value: "output" },
        ]
      },    

    ])

    //FILTERS
    const periodFilter = filterFields[0]
    const entityFilter = filterFields[1]
    const orientFilter = filterFields[2]

    //If project type is input output independent, set values and input type
    useEffect(() => {
      if(projectType === 'input_output_independent'){
        orientFilter.type = 'select'
        orientFilter.label = 'Input'
        orientFilter.value = 'input'
      }  
    }, [projectType])
  
  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    Promise.all([
      getPeriodFilterData({ benchmark_id, project_id }),
      getEntityFilterData({ benchmark_id })
    ])
    .then(([periodOptionsData, entityOptionsData]) => {
      //SET PERIOD OPTIONS
      setFilterFields(prevFields => {
        return prevFields.map(field => {
          if (field.key === 'period') {
            const defaultOption = filterFields && periodFilter.value !== '' ? periodFilter.value : periodOptionsData[periodOptionsData.length - 1].value
            return {
              ...field,
              options: [...periodOptionsData],
              value: defaultOption
            };
          }
          return field;
        });
      })
      //SET ENTITY OPTIONS
      setFilterFields(prevFields => {
        return prevFields?.map(field => {
          if (field.key === 'entity') {
            const defaultOption = filterFields && entityFilter.value !== '' ? entityFilter.value : entityOptionsData[0]?.value;
            return {
              ...field,
              options: [ ...entityOptionsData],
              value:  frontierEntityValue === '' ? defaultOption : frontierEntityValue
            };
          }
          return field;
        });
      });
      setFrontierEntityValue('')

      return getPeriodObjectData({ benchmark_id, project_id, periodValue: periodFilter.value })
      
    })
    .then((periodObjectData) => {
      if(periodFilter.value === 'All'){
        setPeriodObject({period: 'All'})
      } else {
        const index = periodObjectData.findIndex(item => item.period === periodFilter.value);
        // setPreviousPeriod(index === 0 ? data.results[index] : data.results[index - 1]);
        setPeriodObject(periodObjectData[index])
      }
      const dmuValue = encodeURIComponent(filterFields?.[1]?.value)
      const orientValue = orientFilter.value
      return getApiData({benchmark_id, dmuValue, periodObject, orientValue})
    })
    .then((apiData) => {
      setApi(apiData)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setError(error)
    })
    .finally(() => {
      setLoading(false);
    });
  }, [benchmark_id, project_id,filterFields?.[1]?.value, periodFilter.value, periodObject?.period, orientFilter.value]);
    
    //FILTER OPTIONS VALUES ( ENCODE SPECIAL CHARACTERS)
    const dmuValue = encodeURIComponent(filterFields?.[1]?.value)

  if(loading) {
    return     <Box sx={{width: '100%'}}>
      <FilterSidebar 
        filterFields={filterFields} 
        setFilterFields={setFilterFields}
      />
      <MainCard sx={{display: 'flex',flexFlow: 'row wrap', justifyContent: 'space-around'}}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      </MainCard>
    </Box>
  }

  if(error) {
    return     <MainCard sx={{display: 'flex',flexFlow: 'row wrap', justifyContent: 'space-around'}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        Error: {error.message}
      </Box>
    </MainCard>
  }
  
  return (
    <Box sx={{width: '100%'}}> 
      <FilterSidebar 
        filterFields={filterFields} 
        setFilterFields={setFilterFields}
      />
      <IndividualhighLevelAndEntities
        entity={dmuValue} 
        periodOptions={periodFilter.options} 
        periodObject={periodObject} 
        benchmark_id={benchmark_id}
        orientValue={orientFilter.value}
      />
      <EfficiencyTimeSeriesAndDistribution
        benchmark_id={benchmark_id}
        periodObject={periodObject}
        entityField={entityFilter}
        periodField={periodFilter}
        orientValue={orientFilter.value}
        apply_segmentation={apply_segmentation}
      />
      <InputOutputTargets
        benchmark_id={benchmark_id}
        periodObject={periodObject}
        api={api}
        periodOptions={periodFilter.options}
        basePeriod={periodFilter.value}
        entity={dmuValue}
        orientValue={orientFilter.value}
      />
    </Box>
  )
}
