import { Box, Stack, styled } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/navbar';
import PersistentDrawerRight from './components/layout/sidebar';
import BenchmarkScenarioHome from './views/benchmarkScenarios/benchmarkScenarioHome';
import DatasetsHome from './views/datasets/datasetsHome';
import Home from './views/home/home';
import ProjectDetails from './views/home/projectDetails/projectDetails';
import DatasetDetails from './views/datasetDetails/datasetDetails';
import SegmentationScenarioDetails from './views/segmentationScenarioDetails';
import ProjectsHome from './views/projects/projectsHome';
import ResultsDashboard from './views/results/dashboard/resultsDashboard';
import ResultHome from './views/results/resultsHome';
import SegmentationScenarioHome from './views/segmentationScenarios/segmentationScenarioHome';
import KnowledgeBase from './components/layout/itemsViews/knowledgeBase/knowledgeBase';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import Login from './views/login/login';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { get_new } from './utils/httpClient';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


export const Body = styled(Box)({
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'center',
})

export const Content = styled(Stack)({
  marginTop: '15px',
  paddingLeft: 60,
  width: '80%',
  maxWidth: '2000px'
})


function App() {
  const [token] = useCookies('token');
  //const validation = document.location.href.includes("localhost:") ? token : Cookies.get("sessionid")
  const validation = Cookies.get("token")? token : Cookies.get("sessionid")
  const [userData, setUserData] = useState([])
  useEffect(() => {
    if(!validation) return ;
    get_new(`/rest_auth/user/`)
      .then((response) => {
        if (!response.ok) {
          console.log('Could not retrieve data');
          console.log('Response Error' + response.status);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        // TODO: Show user error that data couldn't be retrieved
      });
  }, [validation]);
  if(!validation) {
    return <Login/>
  }
//console.log('get all cookies',Cookies.get("sessionid"))
//console.log('doesn't exist session id?' ,!Cookies.get("sessionid")  ?  true : false)
console.log('environment: ', process.env.NODE_ENV)
console.log('URL: ', process.env.REACT_APP_API_URL)
  return (
    <Body>
      {/* <PersistentDrawerLeft/> */}
      <PersistentDrawerRight/>
      <Navbar userData={userData}/>
      <Content>
        <Routes>
          <Route exact path="/" element={<Home userData={userData}/>}/>
          <Route path="/knowledgeBase/*" element={<KnowledgeBase/>}/>
          <Route path="/projects"  element={<ProjectsHome/>}/>
          <Route path="/datasets"  element={<DatasetsHome/>}/>
          <Route path="/segmentationScenarios"  element={<SegmentationScenarioHome/>}/>
          <Route path="/benchmarkScenarios"  element={<BenchmarkScenarioHome/>}/>
          <Route path="/publishedResults"  element={<ResultHome/>}/>
          <Route path="/projectDetails"  element={<ProjectDetails/>}/>
          <Route path="/datasetDetails"  element={<DatasetDetails/>}/>
          <Route path="/segmentationScenarioDetails"  element={<SegmentationScenarioDetails/>}/>
          <Route path="/dashboard"  element={<ResultsDashboard/>}/>
          <Route path="*"  element={'Work In Progress'}/>
        </Routes>
      </Content>
    </Body>
  );
}

export default App;
