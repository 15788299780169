import { get_new } from "../../../utils/httpClient";

export const getApiData = ({ benchmark_id }) => {
  const url = `/project/benchmarks/?benchmark_id=${benchmark_id}`
  
  return get_new(url)
  .then((response) => {
    if (!response.ok) {
      console.log('Could not retrieve data');
      console.log('Response Error' + response.status);
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((data) => data.results)
  .catch((error) => {
    console.error('Error fetching Api data:', error);
    throw error;
  })
};

export const getPeriodData = ({ project_id, benchmark_id }) => {
    const url = `/project/dataset-periods/?benchmark__project_id=${project_id}&benchmark_id=${benchmark_id}`
    
    return get_new(url)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
        const options = data.results.map(p => ({ label: p.period, value: p.period }));
        return options})
    .catch((error) => {
      console.error('Error fetching Period data:', error);
      throw error;
    })
  };
