import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../assets/variables.scss'
import { get_new } from '../../utils/httpClient';
import { Box } from '@mui/material';

ChartJS.register(
  BarElement,
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Entities By Segment (n)',
      color: '#4c4c4d',
      font: {
        size: 14,
        weight: 'bold',
        lineHeight: 1.2,
      },
    },
    legend: {
      display: false
    },
    datalabels: {
      align: 'center',
      color: 'white',
      weight: 'bold',

      // anchor: 'end',
      // align: 'center'
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      grace: '30%',
      display: false,
      stacked: true,
    },
  },
};



export default function EntitiesBySegment({entity, periodObject, benchmark_id}) {
  const [api, setApi] = useState([])
  const [dmu, setDmu] = useState([])

  useEffect(()=> {
    if(!periodObject?.period) return;
    get_new(`/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&period=${periodObject?.period}`)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      setApi(Array.from(data?.results?.reduce((map, obj) => {
        const { cluster_cd } = obj;
        const group = map.get(cluster_cd) || { cluster_cd, bins: [] };
        group.bins.push(obj);
        map.set(cluster_cd, group);
        return map;
      }, new Map())
      .values()))
    })
    .catch((error) => {
      console.log('Error: ' + error);
      // TODO: Show user error that data couldn't be retrieved
    });
  }, [periodObject, benchmark_id])

  useEffect(()=> {
    if(!entity) return;
    get_new(`/project/benchmark/results/dmu/efficiency/?benchmark_id=${benchmark_id}&dmu_id=${entity}`)
    .then((response) => {
      if (!response.ok) {
        console.log('Could not retrieve data');
        console.log('Response Error' + response.status);
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      setDmu(data.results[0])
    })
    .catch((error) => {
      console.log('Error: ' + error);
      // TODO: Show user error that data couldn't be retrieved
    });
  }, [entity, benchmark_id])
  const dmuList = api.flatMap(d=>d.bins)
  const index = dmuList.findIndex(v=> v.dmu_id === entity)
  const labels = api?.sort((a, b) => a.cluster_cd - b.cluster_cd).map(d=>`Segment ${d.cluster_cd}`)

  const data = {
    labels,
    datasets: [
      {
        label: "Segments",
        data: api?.sort((a, b) => a.cluster_cd - b.cluster_cd).map( d => d.bins.length ),
        backgroundColor: (context) => {
          
          const value = entity ? dmu?.cluster_cd : false
          return value === false ?  [colors.segment5, colors.segment2,colors.segment3,colors.segment4] : value !== (context.dataIndex) || index === -1 ? '#c1c0c090' :  
          value === 0 ? colors.segment5 : 
          value === 1 ? colors.segment2 : 
          value === 2 ? colors.segment3 : 
          colors.segment4
        }
      },
    ],
  };

  return  <Box sx={{ width: {xs:'100%', md:'70%'}, height: '200px' }}>
    <Bar options={options} data={data} className="segmentation" />
  </Box>
}
