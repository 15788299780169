import numeral from "numeral";

export const numeralFormatter = (number) => {
    return numeral(number).format('0.0 a');
}; 
  
export const numFormatInt = (number) => {
    return numeral(number).format('0,0');
}

export const numFormatPct = (number) => {
    return numeral(number).format('0.00%');
}

export const numFormatFloat = (number) => {
    return numeral(number).format('0,0.00');
}
