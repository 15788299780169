import * as React from 'react';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { get_new } from '../../../utils/httpClient';
import { numeralFormatter } from '../../../utils/numeralFormatter';



export default function Table1({variables, segment, segmentation_scenario_id, period}) {
  const [api, setApi] = useState([])
  //GET METRICS DATA
  useEffect(() => {
		get_new(`/project/segmentation/results/metrics/variable/?segmentation_scenario_id=${segmentation_scenario_id}&cluster_cd=${segment}&period_id=${period}`)
			.then((response) => {
			if (!response.ok) {
					console.log('Could not retrieve dataset periods');
					console.log('Response Error' + response.status);
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((data) => {
				setApi(data);	
			})
			.catch((error) => {
			console.log('Error: ' + error);
			// TODO: Show user error that data couldn't be retrieved
		});
	}, [segment, segmentation_scenario_id, period]);

  const segmentNumbers = Array.from(new Set(api?.results?.map(obj => obj.cluster_cd)));

  // Create an object to store the values for each variable and segment
  const variableSegmentValues = {};

  // Iterate over the data array and populate the variableSegmentValues object
  api?.results?.forEach((item) => {
    const { cluster_cd, std, variable_detail_id } = item;

    //array object name will be the variable detail id
    if (!variableSegmentValues[variable_detail_id]) {
      variableSegmentValues[variable_detail_id] = {};
    }
    variableSegmentValues[variable_detail_id][cluster_cd] = std;
  });
  
  // const otherVariables = Object.keys(variableSegmentValues).filter(variable =>
  //   !variables.includes(variable)
  // );

  //Return one object per segment value
  const entities = [...new Set(api?.results?.map(obj => obj.cluster_cd))].map(cluster_cd => api?.results?.find(obj => obj.cluster_cd === cluster_cd));

  const titlesBgColor = '#e8e8e8'

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 1400 }}>
        <Table stickyHeader aria-label="sticky table">
        <TableHead>
        <TableRow>
          <TableCell align="left" style={{ minWidth: 1 }}>Segments</TableCell>
          {segmentNumbers.map(segment => (
            <TableCell key={segment} align={'right'} style={{ minWidth: 1 }}>{segment}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
            <TableRow>
              <TableCell align="left" colSpan={1} style={{ top: 57, minWidth: 1 }}>
                Entities
              </TableCell>
                {entities.map((column, index) => (
                  <TableCell
                    key={index}
                    align="right"
                    style={{ top: 57, minWidth: 1 }}
                  >
                    {column.count}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow sx={{backgroundColor: titlesBgColor}}>
              <TableCell align="center" colSpan={5} sx={{fontWeight: 'bold'}}>
                Clustering Variables
              </TableCell>
            </TableRow>


              {variables.map((variable, index) => (
                <TableRow key={index}>
                  <TableCell>{variable.original_field_name}</TableCell>
                  {segmentNumbers?.map((segment) => (
                    <TableCell key={segment} align={'right'}>{numeralFormatter(variableSegmentValues?.[variable.variable_detail_id]?.[segment])}</TableCell>
                  ))}
                </TableRow>
              ))}
          {/* <TableRow sx={{backgroundColor: titlesBgColor}}>
            <TableCell align="center" colSpan={5} sx={{fontWeight: 'bold'}}>
              Other Variables
            </TableCell>
          </TableRow> */}

          {/* {otherVariables.map((variable) => (
            <TableRow key={variable}>
              <TableCell>{variable}</TableCell>
              {segmentNumbers.map((segment) => (
                <TableCell key={segment} align={'right'}>{numeralFormatter(variableSegmentValues[variable][segment])}</TableCell>
              ))}
            </TableRow>
          ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
