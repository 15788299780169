import React, { useEffect, useState, useContext } from 'react';
import {
    CircularProgress,
	Typography,
	TextField,
	FormLabel,
    FormControl,
    InputLabel,
    Select,
	MenuItem,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
    DialogTitle,
    IconButton,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { green } from '@mui/material/colors';
import { put} from '../../../utils/httpClient';
import { DatasetContext } from '../datasetDetails';

export default function AnnotateDataset({open, onClose, annotatedID=0, setAnnotatedID}) {
    // LOCAL STATE
    const [altNameState, setAltNameState] = useState({ error: false, helperText: null });
    const [formErrors, setFormErrors] = useState({ error: false, message: '' });
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    //const [overwrite, setOverwrite] = useState(false);    
    const [formData, setFormData] = useState({
        dataset_id: '',
        variable_id: '',
        alternative_name: '',
		variable_type: '',
		field_type: '',
		field_sub_type: '',
    });
    
    

    // CONTEXT STATE
    const { dataset } = useContext(DatasetContext);

    // SET DATASET ID ON DATASET CHANGE
    useEffect(() => {
        console.log('add dataset');
        if (!dataset) return;
        setFormData(oldFormData => ({
            ...oldFormData, 
            ...{ 'dataset_id': dataset.info.dataset_id}
        }));
    }, [dataset]);

    // SET FORM DATA ON VARIABLE CHANGE
    useEffect(() => {
        if (!dataset) return;
        if (!formData.variable_id) return;
        let variable_info = dataset.variables.filter((variable) => {
            return (variable.variable_detail_id === formData.variable_id);
        });
        variable_info = variable_info[0];
        console.log('variable_info', variable_info);
        setFormData(oldFormData =>({
            ...oldFormData,
            ...{
                'alternative_name': variable_info.alternative_field_name??'',
                'variable_type': variable_info.var_type?.toLowerCase()??'',
                'field_type': variable_info.field_type??'',
                'field_sub_type': variable_info.field_sub_type??'',
            },
        }));
    }, [formData.variable_id, dataset]);

    // CHANGE FORM DATASET
    const changeFormData = (name, event) => {
        //console.log(name, event.target.value);

        const newForm = {
            ...formData,
            ...{
                [name]: event.target.value,
            },
        };

        if (name === 'field_type') {
            newForm.field_sub_type = '';
        }

        //console.log('newForm', newForm);
		setFormData(newForm);
    };
    
    /* CLOSE DIALOG */
    const handleClose = (event, reason) => {
        /* Disable backdrop close on click*/
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    // CHECK IF ALTERNATIVE NAME IS BEING USED
    function checkName() {
        if (!formData.alternative_name) return false;
        let variable_info = dataset.variables.filter((variable) => {
            return (
                variable.original_field_name?.toLowerCase() === formData.alternative_name?.toLowerCase() ||
                variable.alternative_field_name?.toLowerCase() === formData.alternative_name?.toLowerCase()
            );
        });
        if (variable_info.length > 0) {
            setAltNameState({ error: true, helperText: 'Name already in use' });
            return true;
        }
        return false;
    }

    // CHECK IF FIELD TYPE AND SUB FIELD TYPE ARE BEING USED BY OTHER VARIABLES
    function checkOverwrite() {
        
        let variable_info = dataset.variables.filter((variable) => {
            return (
                variable.variable_detail_id !== formData.variable_id &&
                variable.field_type?.toLowerCase() === formData.field_type?.toLowerCase() &&
                variable.field_sub_type?.toLowerCase() === formData.field_sub_type?.toLowerCase()
            );
        });
        if (variable_info.length > 0) {
            // Open confirmation dialog
            setConfirmOverwrite(true);
            return true;
        }
        return false;
    }

    // CONFIRM OVERWRITE
    const handleConfirmOverwrite = () => {
        //setOverwrite(true);
        setConfirmOverwrite(false);
        handleCreate(true);

    }

    // CANCEL OVERWRITE
    const handleCancelOverwrite = () => {
        //setOverwrite(false);
        setConfirmOverwrite(false);
    }


    // HANDLE CREATE
    const handleCreate = async (overwrite = false) => {
        setLoadingCreate(true);
        //formData.dataset_id = parseInt(form.dataset_id);
        if (checkName()) { setLoadingCreate(false); return; }
        
        // Ask for confirmation to overwrite other field annotations
        // skip if user already provided confirmation
        if (!overwrite) {            
            if (checkOverwrite()) { setLoadingCreate(false); return; }
        }
        
        let data = { ...formData };

        // API is expecting nulls instead of '' 
        if (data.alternative_name === '') data.alternative_name = null;
        if (data.field_type === '') data.field_type = null;
        if (data.field_sub_type === '') data.field_sub_type = null;

        console.log('Annotation Data', data);
        
        const targetURL = '/annotation/';
        put(targetURL, data)
            .then((response) => {
                if (response.status === '500') {
                    alert('Server Error. Please try again later.');
                    throw new Error('Error: ' + response.status + ' ' + response.statusText);
                } else if (!response.ok) {
                    console.log(response.status + ' ' + response.statusText);
                    response.json().then((data) => {
                        setFormErrors({
                            'error': true,
                            'message': data.Res
                        })
                    })
                    throw new Error("Error: "+ response.status + ' : ' + response.statusText);
                }
                return response.json()
            })
            .then((data) => { 
                console.log('Annotation created');
                console.log(data);
                enqueueSnackbar('Field annotation was successful', { variant: 'success' });
                setLoadingCreate(false);
                let kickback_id = annotatedID + 1;
                console.log('kickback_id', kickback_id);
                setAnnotatedID(kickback_id); //kickback to refresh variable list
                handleClose();
                return;
            })
            .catch((error) => {
                console.log('Could not create annotation')
                console.log('Error: ' + error);
                setLoadingCreate(false);
            });
    };
 
    // DISABLE OPTIONS IF DATASET IS BEING USED 
    function disableUsed() {
        return dataset.segmentations + dataset.benchmarks > 0;
    }

    // DISABLE APPLY IF NO VARIABLE SELECTED
    function disableApply() {
        return formData.variable_id === '' ||
            ((formData.field_type === 'period' || formData.field_type === 'location') &&
                formData.field_sub_type === '');
    }

    return (
        <>
        <Dialog open={open} onClose={handleClose} maxWidth='xs'>
            <DialogTitle style={{ background: '#47254b', color: 'white' }}>
                Annotate Dataset Field
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ mt: '20px' }}>
                
                {/* SHOW WARNING IF DATASET IS BEING USED */}
                {disableUsed() && (
                    <Box mt={2}>
                        <Typography variant='body2'>
                            <b>Warning:</b> This dataset is being used in one or more scenario.
                            Some options have been disabled.
                        </Typography>
                    </Box>
                    )}
                    
                    {formErrors.error ?
                        <Box mt={2} sx={{ color: 'red', fontSize:12 }}>
                            <ul>
                               <li> {formErrors.message}</li>
                            </ul>
                        </Box> : ''
                    }

                {/* VARIABLES DROPDOWN */}
                <FormControl fullWidth sx={{mt:3, mb:2}}>
                    <InputLabel
                        id="variable-select-label"
                        size='small'
                        required
                    >Variable</InputLabel>
                    <Select
                        id='variable_id'
                        labelId='variable-select-label'
                        label="Variable"
                        required
                        displayEmpty
                        value={formData.variable_id}
                        size="small"
                        onChange={(event) => changeFormData('variable_id', event)}
                    >
                    {dataset.variables.map((row, index) => (
                        <MenuItem key={index} value={row.variable_detail_id}>
                            {row.original_field_name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                {/* ALTERNATIVE NAME */}
                <FormControl fullWidth sx={{my:1}}>
						
                    <TextField
                        id='alternative-name'
                        label="Alternative name"
                        size="small"
                        variant="outlined"
                        error={altNameState.error}
                        helperText={altNameState.helperText}
                        inputProps={{ maxLength: 30 }}
                        value={formData.alternative_name}
                        onChange={(event) => {
                            if (altNameState.error) {
                                let newValues = { ...altNameState, ...{ error: false, helperText: '' } };
                                setAltNameState(newValues);;
                            }
                            changeFormData('alternative_name', event)
                        }}
                    />
				</FormControl>

                {/* VARIABLE TYPE */}
                <FormControl fullWidth sx={{my:1}} disabled={disableUsed()}>
                    <FormLabel id='var_type_label' >Variable Type</FormLabel>
                    <RadioGroup
                        id='var_type'
                        row
                        value={formData.variable_type.toLowerCase()}
                        onChange={(event) =>changeFormData('variable_type', event)}
                    >
                        <FormControlLabel value="categorical" control={<Radio />} label="Categorical" />
                        <FormControlLabel value="numerical" control={<Radio />} label="Numerical" />
                        <FormControlLabel value="datetime" control={<Radio />} label="Datetime" />
                    </RadioGroup>
                </FormControl>
                
                {/* FIELD TYPE */}
                <FormControl variant='outlined' fullWidth sx={{my:2}}>
                    <InputLabel
                        id="type-select-label"
                        size='small'
                    >Field Type</InputLabel>
                    <Select
                        id='field_type'
                        labelId='type-select-label'
                        label="Field Type"
                        value={formData.field_type}
                        size="small"
                        
                        onChange={(event) => changeFormData('field_type', event)}
                    >
                        <MenuItem disabled={disableUsed()} value=''> None </MenuItem>
                        <MenuItem disabled={disableUsed()} value="entity_id"> Entity ID </MenuItem>
                        <MenuItem value="entity_name"> Entity name </MenuItem>
                        <MenuItem disabled={disableUsed()} value="period"> Period </MenuItem>
                        <MenuItem value="location"> Location </MenuItem>
                    </Select>
                </FormControl>  
                
                {/* FIELD SUB-TYPE */}
                <FormControl
                    fullWidth
                    variant='outlined'
                    sx={{ my: 1 }}
                    disabled={formData.field_type !== 'period' && formData.field_type !== 'location'}
                >
					<InputLabel
                        id="subtype-select-label"
                        size='small'
                        
                    >Field sub-type</InputLabel>
                    <Select
                        labelId='subtype-select-label'
                        label="Field sub-type"
                        value={formData.field_sub_type}
                        size="small"
                        displayEmpty
                        onChange={(event) => changeFormData('field_sub_type', event)}
                    >
                        <MenuItem aria-label='None' value=''/>
                        {formData.field_type === 'period' && (
                            [
								<MenuItem value="monthly">Monthly</MenuItem>,
								<MenuItem value="quarterly">Quarterly</MenuItem>,
                                <MenuItem value="yearly">Yearly</MenuItem>
                            ]
                        )}
                        {formData.field_type === 'location' && (
						    [
                                <MenuItem value="country">Country</MenuItem>,
                                <MenuItem value="state">State</MenuItem>,
                                <MenuItem value="zip">Zip</MenuItem>,
                                <MenuItem value="longitude">Longitude</MenuItem>,
                                <MenuItem value="latitude">Latitude</MenuItem>,
                                <MenuItem value="coordinates">Coordinates</MenuItem>
                            ]
					)}
					</Select>
				</FormControl>
						
                    
                {/* FIELD LOCATION SUBTYPE */}
					
				</DialogContent>
				<DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    disabled={loadingCreate}
                >
						Cancel
                </Button>
                <Box sx={{position: 'relative', ml:1}}>
                <Button
                            onClick={() => {
                                handleCreate()
                            }}
                    variant="contained"
                    color="success"
                    disabled={disableApply() || loadingCreate}

                >
						Apply
                </Button>
                {loadingCreate && (
                        <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                        />
                    )}
                    </Box>
				</DialogActions>
        </Dialog>
        
        {/* CONFIRM OVERWRITE DIALOG */ }
            <Dialog open={confirmOverwrite} onClose={handleClose} maxWidth='sm'
            sx={{position:'absolute'}}>
                <DialogTitle style={{ background: '#47254b', color: 'white' }}>
                    Overwrite Field Annotation?
                </DialogTitle>
                <DialogContent >
                    <Typography variant='body2' sx={{my:2}}>
                        Warning: This field type and sub-type are being used in another variables. <br />
                        Continuing will  overwrite and remove the annotation from the other variables. <br />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOverwrite}
                        
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmOverwrite}
                        
                    >
                        Overwrite
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )


}