import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { numeralFormatter } from "../../../../utils/numeralFormatter";

export default function EfficiencyTable({ periodEfficiencyApi }) {

  const data = periodEfficiencyApi.map((value) => {
    return {
      entity: value?.results[0]?.dmu_id,
      segment: value?.results[0]?.cluster_cd,
      efficiency: `${numeralFormatter(value?.results[0]?.efficiency * 100)}%`,
      baselineEfficiency: `${numeralFormatter(
        (value?.results[0]?.efficiency -
          value?.results[0]?.pct_improv_benchmark_period) *
          100
      )}%`,
      improvementPp: `${numeralFormatter(
        value?.results?.[0]?.pct_improv_prev_period.toFixed(5) * 100
      )}%`,
      improvementPb: numeralFormatter(
        value?.results?.[0]?.pct_improv_benchmark_period.toFixed(5)
      ),
      rankOverall: value?.results[0]?.overall_rank,
      rankSegment: value?.results[0]?.rank,
    };
  });

  return (
    <TableContainer component={Paper} sx={{ m: "16px" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ verticalAlign: "bottom" }}>
            <TableCell>Entity</TableCell>
            <TableCell>Segment</TableCell>
            <TableCell colSpan={2} sx={{ textAlign: "center" }}>
              Efficiency
              <TableRow
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <TableCell
                  sx={{
                    border: "none",
                    "&:first-of-type": {
                      paddingLeft: "8px",
                      paddingBottom: "0",
                    },
                  }}
                >
                  Period
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    whiteSpace: "nowrap",
                    "&:last-of-type": {
                      paddingRight: "8px",
                      paddingBottom: "0",
                    },
                  }}
                >
                  Baseline
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell colSpan={2} sx={{ textAlign: "center" }}>
              Efficiency Improvement
              <TableRow
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <TableCell
                  sx={{
                    border: "none",
                    "&:first-of-type": {
                      paddingLeft: "8px",
                      paddingBottom: "0",
                    },
                  }}
                >
                  p/p
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    whiteSpace: "nowrap",
                    "&:last-of-type": {
                      paddingRight: "8px",
                      paddingBottom: "0",
                    },
                  }}
                >
                  p/b
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell>Rank Overall</TableCell>
            <TableCell>Rank Segment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.entity}</TableCell>
              <TableCell>{row.segment}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {row.efficiency}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {row.baselineEfficiency}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {row.improvementPp}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {row.improvementPb}
              </TableCell>
              <TableCell>{row.rankOverall}</TableCell>
              <TableCell>{row.rankSegment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
