import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { MainCard } from "../../../components/common/commonContainer";
import { Box, CircularProgress, styled } from "@mui/material";
import BenchmarkScenarios from "./benchmarkScenarios";
import PublishResults from "./publishResults";
import SegmentationScenarios from "./segmentationScenarios";
import ProjectDetailsForm from "./projectDetailsForm";
import MainUpperBar from "../../../components/common/mainUpperBar";
import { ProjectContext } from "../../../context/Project/ContextProject";
import {
  getDatasetNameData,
  getDatasetData,
  getProjectData,
} from "./services/projectDetailsServices";
import ManualSettings from "./benchmarkScenarios/menuOptions/manualSettings";
import { useDataSetStore } from "../../../store/store";

export const SummaryCard = styled(Box)({
  display: "flex",
  width: "90%",
  boxSizing: "border-box",
  flexFlow: "row wrap",
  background: "#FFFFFF",
  border: "0.5px solid #979797",
  borderRadius: "6px",
  justifyContent: "space-evenly",
  padding: "10px",
});

export default function ProjectDetails() {
  // URL STATE
  const [searchParams] = useSearchParams();
  // COMPONENT STATE
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fieldChange, setFieldChange] = useState(false);

  const [projectData, setProjectData] = useState({});
  const [datasetData, setDatasetData] = useState({ variables: [] });
  const [datasetNameData, setDatasetNameData] = useState([]);

  const [segmentationScenarios, setSegmentationScenarios] = useState([]);

  const { setDataSetValuesData } = useDataSetStore();
  // CONTEXT STATE
  // const projectContext = useContext(ProjectContext);

  const project_id = searchParams.get("project_id");

  //FETCH FUNCTION
  useEffect(() => {
    setLoading(true);
    let newState = {};
    // GET PROJECT DATA USING PROJECT ID
    Promise.all([getProjectData({ project_id })])
      .then(([projectData]) => {
        setProjectData(projectData);
        // GET DATASET INFO USING DATASET ID FROM PROJECT DATA (data.dataset_id)
        // GET DATASETS (PLURAL) ENDPOINT INFO USING DATASET ID FROM PROJECT DATA (projectData.dataset_id)
        // Temporary step while the dataset name is added to the dataset endpoint
        return Promise.all([
          getDatasetData({ projectData }),
          getDatasetNameData({ projectData }),
        ]);
      })
      .then(([datasetsData, datasetNameData]) => {
        newState.variables = datasetsData;
        setDatasetData(newState);
        setDataSetValuesData(newState);
        setDatasetNameData(datasetNameData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [project_id, projectData.dataset_id, fieldChange]);

  return (
    <>
      {/*Project Context Provider*/}
      <ProjectContext.Provider
        value={{
          projectData: projectData,
          setProjectData: setProjectData,
          datasetData: datasetData,
          segmentationScenarios: segmentationScenarios,
          setSegmentationScenarios: setSegmentationScenarios,
          setFieldChange: setFieldChange,
        }}
      >
        <MainUpperBar UpperTitle={"Project Details"} backLink={"/"} />
        <MainCard sx={{ minHeight: 0 }}>
          {/*Project Details*/}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                minHeight: "150px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ProjectDetailsForm datasetNameData={datasetNameData} />
          )}
        </MainCard>

        {/*Published Results List*/}
        {projectData?.status?.toLowerCase() === "published" ? (
          <PublishResults projectData={projectData} />
        ) : null}

        {/*Benchmark Scenarios List*/}
        <BenchmarkScenarios projectData={projectData} />

        {/*Segmentation Scenarios List*/}
        <SegmentationScenarios />

        {/*ManualSettings*/}
        {/* <ManualSettings datasetData={datasetData} /> */}
      </ProjectContext.Provider>
    </>
  );
}
